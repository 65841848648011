/* eslint-disable */
import React from 'react';

const third_doc = ({ className }: {className: string}): React.ReactElement => {
  return (<div className={className} dangerouslySetInnerHTML={{
    __html: `<span class='h2'>개인정보 제3자 제공 동의</span>아래의 목적으로 개인정보를 제3자 제공합니다. 내용을 자세히 읽어 보신 후 동의 여부를 결정하여 주시기 바랍니다.<table><tbody>
  <tr>
    <td>제공받는자</td>
    <td>테이블매니저 사용 매장</td>
  </tr>
  <tr>
    <td>제공목적</td>
    <td>KT 통화비서, 테이블매니저를 통해 접수된 예약 이력을 확인하기 위함</td>
  </tr>
  <tr>
    <td>필수 제공 항목</td>
    <td>이름, 휴대폰전화번호, 예약정보, 결제정보</td>
  </tr>
  <tr>
    <td>제공받은 자의 보유/이용 기간</td>
    <td>회원탈퇴까지</td>
  </tr>
</tbody>
</table><div class='dotted'>귀하는 위와 같이 개인정보를 수집/이용하는데 동의를 거부할 권리가 있습니다. 필루 수집 항목에 대한 동의를 거절하는 경우 서비스 이용이 제한 될 수 있습니다.</div>`}} />);
};

export default third_doc;
