import React, { useEffect, useState } from 'react';

import AGREEMENT_LIST from '@/components/Agreement/Agreement.constants';
import { ReactComponent as Arrow_Right } from '@/assets/images/icons/Arrow_Right.svg';
import { devServerApi } from '@/shared/apis/devServerApi';
import styles from './index.module.scss';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useNavigate } from 'react-router-dom';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import { usePartnerStore } from '@/stores/common/usePartnerStore';

const Setting = (): React.ReactElement => {
  // @ts-ignore
  const navigate = useNavigate();
  const { accessToken, payload } = useAuthStore();
  const { state, setState, agreements, setAgreements } = useSettingTutorialStore();
  const [b2cStatus, setb2cStatus] = useState();
  const [isChecked, setIsChecked] = useState(null);
  const { appName } = usePartnerStore();

  useEffect(() => {
    if (appName === 'kt') {
      devServerApi
        .authGet('/terms-agree', accessToken)
        .then(({ response }) => {
          let essentialAgreementsList = AGREEMENT_LIST.filter((a) => a.isEssential).map((a) => a.name);
          for (let a of response) {
            if (a.isAgree) {
              essentialAgreementsList = essentialAgreementsList.filter((e) => e !== a.type);
            }
          }
          setAgreements(essentialAgreementsList.length === 0);
        })
        .catch(() => {
          setAgreements(false);
        });
      devServerApi
        .authGet('/tutorial', accessToken)
        .then(({ tutorial }) => setState(tutorial))
        .catch(() => {
          setState('null');
        });
      devServerApi
        .authGet('/settings/reservation/state', accessToken)
        .then(({ status }) => {
          setb2cStatus(status);
        })
        .catch((e) => {
          setb2cStatus(null);
        });
    }
  }, []);

  useEffect(() => {
    setIsChecked(state === 'done' && agreements && b2cStatus === 'using');
  }, [state, agreements, b2cStatus]);

  const reservationMenuData = [
    {
      id: 'reservationMenu1',
      name: '기본정보',
      path: '/setting/essential',
    },
    {
      id: 'reservationMenu2',
      name: '일정관리',
      path: '/setting/schedule',
    },
    {
      id: 'reservationMenu3',
      name: '테이블',
      path: '/setting/table',
    },
    {
      id: 'reservationMenu4',
      name: '예약정책',
      path: '/setting/policy',
    },
    {
      id: 'reservationMenu5',
      name: '온라인 예약 연동',
      path: '/setting/channel',
    },
  ];

  const routePush = (path) => {
    if (state === 'done' || path === '/setting/test') {
      if (window.NavigatePage) window.NavigatePage.postMessage(path);
      else navigate(path);
    } else {
      if (window.NavigatePage) window.NavigatePage.postMessage('/tutorial/' + (!state || state === 'null' ? 'essential' : ''));
      else navigate('/tutorial/' + (!state || state === 'null' ? 'essential' : ''));
    }
  };

  const reservationOn = (e) => {
    if (!e.target.checked) {
      devServerApi.api.patch('/settings/reservation/state', { mallStatus: 'disable' }, { headers: { 'x-tm-apigw-token': accessToken } }).catch(() => {});
      setIsChecked(false);
      return;
    }
    if (!agreements) {
      if (window.NavigatePage) window.NavigatePage.postMessage('/setting/agreement');
      else navigate('/setting/agreement');
      return;
    }
    if (state !== 'done') {
      if (window.NavigatePage) window.NavigatePage.postMessage('/tutorial');
      else navigate('/tutorial', { replace: true });
      return;
    }
    devServerApi.api.patch('/settings/reservation/state', { mallStatus: 'using' }).catch(() => {});
    setIsChecked(true);
  };

  // @ts-ignore
  return (
    <>
      <ul className={styles.reservation_nav}>
        <li className={styles.reservation_nav__list}>
          예약
          <label className="tblm-switch">
            <input type="checkbox" name="switch_example" checked={Boolean(isChecked)} onChange={reservationOn} />
            <i />
          </label>
        </li>
        {reservationMenuData.map((menu, i) =>
          isChecked ? (
            <li className={styles.reservation_nav__list} onClick={() => routePush(menu.path)} key={`${menu.id}_${i}`}>
              {menu.name}
              <Arrow_Right />
            </li>
          ) : null
        )}
      </ul>
    </>
  );
};

export default Setting;
