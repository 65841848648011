import './ReservationOn.scss';

import Insert from '@/assets/images/icons/insert.gif';
import React from 'react';

const ReservationOn = ({ onClick }): React.ReactElement => {
  return (
    <div className="reservation-off-wrapper" onClick={onClick}>
      <div className="reservation-off-title font__title_32_sb">
        예약 기능을 켜야
        <br />
        이용할 수 있어요.
      </div>
      <div className="reservation-off-subtitle font__subtitle1_sb pt-8">설정 {'>'} 예약관리 에서 예약 기능을 켜주세요.</div>
      <div className="reservation-off-content">
        <div className="content-title font__subtitle1">
          업그레이드 된 AI 통화비서에서
          <br />
          더욱 편리하고 풍부해진 예약 기능을 지금 경험해보세요.
        </div>
        <div className="content-images">
          <img src={Insert} />
        </div>
      </div>
    </div>
  );
};

export default ReservationOn;
