import { useMallStore } from '@/stores/common/useMallStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import HeaderBack from '../HeaderBack/HeaderBack';
import Tabs from '../Tabs/Tabs';
import './MenuList.scss';

const headerInitData = [
  { label: '예약정보', value: '1', active: true },
  { label: '고객 추가정보', value: '2', active: false },
];
const customTabsStyle = { padding: '0 20px', gap: 16, top: 55 };

function MenuList({ tabsWrapperRef }) {
  const [defaultState, setDefaultState] = useState(headerInitData);
  const { mall } = useMallStore();
  const { depositInfo } = useReservationStore();

  const handleOnTabsClick = (e) => {
    const selectedValue = e.target.getAttribute('data-value');
    const newTabs = cloneDeep(defaultState).map((item) => {
      item.active = selectedValue === item.value;
      return item;
    });
    setDefaultState(newTabs);
  };

  useEffect(() => {
    const headerMenuList = [...defaultState];
    if (mall?.menus.length) {
      headerMenuList.push({
        label: '메뉴정보',
        value: '3',
        active: false,
      });
    }
    if (mall?.reservationExtravalues.filter((item) => item.position === 'extra').length) {
      headerMenuList.push({ label: '기타정보', value: '4', active: false });
    }
    // 예약금 관련 설정 - 정책 없어서 일단 삭제
    // if (depositInfo?.depositPaidAt) {
    //   headerMenuList.push({ label: '예약금 설정', value: '5', active: false });
    // }

    setDefaultState(headerMenuList);
  }, []);

  return (
    <div className="menulist-wrapper">
      <HeaderBack>
        <div className="font__subtitle backbutton-title">예약 상세/수정</div>
      </HeaderBack>
      <div className="menulist-tab">
        <Tabs mode="scroll" scrollContentRef={tabsWrapperRef} style={customTabsStyle} onClick={handleOnTabsClick} headerMargin={115}>
          {defaultState.map((item) => (
            <Tabs.Item key={item.value} value={item.value} active={item.active}>
              {item.label}
            </Tabs.Item>
          ))}
        </Tabs>
        <div className="current-bar" />
      </div>
    </div>
  );
}

export default MenuList;
