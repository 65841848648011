import React, { useEffect, useRef, useState } from 'react';
import './AccessAgreement.scss';
import { ReactComponent as TableManagerLogo } from '@/assets/images/icons/Logo.svg';
import AgreementDoc from '@/components/Agreement/AgreementDoc';
import PrivacyAndThird from '@/components/Agreement/docs/PrivacyAndThird';
import Grant from '@/components/Agreement/docs/grant';
import Tos from '@/components/Agreement/docs/tos';
import AgreementHistory from './AgreementHistory/AgreementHistory';

import '@/components/Agreement/Agreement.scss';

const ACCESS_AGREEMENT_DATA = [
  {
    type: 'tos',
    title: '서비스 이용 약관',
    component: Tos,
  },
  {
    type: 'privacy_and_third',
    title: '개인정보 처리방침',
    component: PrivacyAndThird,
  },
  {
    type: 'grant',
    title: '개인정보 처리 위탁',
    component: Grant,
  },
  {
    type: 'agreementHistory',
    title: '선택 동의 내역 확인',
    component: AgreementHistory,
  },
];

const AccessAgreement = () => {
  const [doc, setDoc] = useState(null);

  useEffect(() => {
    if (doc) {
      document.documentElement.style.cssText = `
        position: fixed;
        height: 100%;
        overflow: hidden;
      `;
    } else {
      document.documentElement.style.cssText = '';
    }
  }, [doc]);

  return (
    <div className="access_agreement_wrap">
      <div className="access_agreement">
        <div className={`access_agreement___list ${doc ? 'blockScroll' : ''}`}>
          {ACCESS_AGREEMENT_DATA.map((content) => (
            <article key={content.type}>
              <section onClick={() => setDoc(content.type)}>{content.title}</section>
              {doc === content.type && (
                <AgreementDoc setIsOpenMarketing={null} isOpenMarketing={null} setDoc={setDoc} key={content.type}>
                  <div className={`agreement-doc-title ${doc === 'agreementHistory' && 'agreementHistory-title'}`}>{content.title}</div>
                  <content.component setDoc={setDoc} className={`agreement-doc-body ${content.type}`} />
                </AgreementDoc>
              )}
            </article>
          ))}
        </div>
        <TableManagerLogo className="logo" />
      </div>
    </div>
  );
};

export default AccessAgreement;
