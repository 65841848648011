import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Back } from '@/assets/images/icons/back.svg';
import './HeaderBack.scss';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import { usePartnerStore } from '@/stores/common/usePartnerStore';
import { useNativeRoutingStore } from '@/stores/common/useNativeRouting';

interface IHeaderBack {
  isFixed?: boolean;
  onBack?: () => void;
  inBack?: boolean;
  children: any;
}

const HeaderBack = ({ isFixed = false, onBack, inBack = false, children }: IHeaderBack) => {
  const navigate = useNavigate();
  const { setReservationResetStore } = useReservationStore();
  const { setBackHook } = useNativeRoutingStore();
  const { appName } = usePartnerStore();
  const onClickBack =
    onBack ||
    (() => {
      if (!inBack && window.ExitPage) window.ExitPage.postMessage('');
      setReservationResetStore();
      navigate(-1);
    });


  useEffect(() => {
    if (appName === 'tablemanager' && onBack) {
      setBackHook(onBack);
    } else {
      setBackHook(() => {
        navigate(-1);
      });
    }

  }, [onBack, appName]);
  return (
    <div className={classNames('header-back-wrapper', { 'is-fixed': isFixed })}>
      <Back onClick={onClickBack} className="pointer" />
      {children}
    </div>
  );
};

export default HeaderBack;
