import parsePhoneNumber from 'libphonenumber-js';

export const autoHypenTel = (telNum) => {
  let str = telNum.replace(/[^0-9]/g, '');
  let tmp = '';

  if (!str) return '';
  else if (str.length > 3 && str.substr(0, 1) !== '0' && str.substr(0, 4) !== '1544') {
    if (str.substr(0, 2) === '82') {
      str = str.replace('82', '0');
      return str;
    }
    const parsedPhoneNumber = parsePhoneNumber('+' + str);
    tmp = parsedPhoneNumber.formatInternational();
    tmp = tmp.replace(/\s+/g, '-');
    tmp = tmp.replace('+', '');
    return tmp;
  } else if (str.length < 4) {
    return str;
  } else if (str.length < 7) {
    if (str.substr(0, 2) === '02') {
      tmp += str.substr(0, 2);
      tmp += '-';
      tmp += str.substr(2);
    } else {
      tmp += str.substr(0, 3);
      tmp += '-';
      tmp += str.substr(3);
    }
    return tmp;
  } else if (str.length < 11) {
    if (str.substr(0, 2) === '02') {
      tmp += str.substr(0, 2);
      tmp += '-';
      if (str.length < 10) {
        tmp += str.substr(2, 3);
        tmp += '-';
        tmp += str.substr(5);
      } else {
        tmp += str.substr(2, 4);
        tmp += '-';
        tmp += str.substr(6);
      }
    } else if (str.substr(0, 4) === '1544') {
      if (str.length < 9) {
        tmp += str.substr(0, 4);
        tmp += '-';
        tmp += str.substr(4);
      } else {
        return str;
      }
    } else {
      tmp += str.substr(0, 3);
      tmp += '-';
      tmp += str.substr(3, 3);
      tmp += '-';
      tmp += str.substr(6);
    }
    return tmp;
  } else if (str.length < 12) {
    if (str.substr(0, 2) === '02') {
      return str;
    }
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3, 4);
    tmp += '-';
    tmp += str.substr(7);
    return tmp;
  }
  return str;
};
