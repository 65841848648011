import React, { useEffect, useId, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import HeaderBack from '@/components/HeaderBack/HeaderBack';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useModalStore } from '@/stores/common/useModalStore';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';

const ManagementDay = (): React.ReactElement => {
  const { accessToken } = useAuthStore();
  const navigate = useNavigate();
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
  const { state: locState }: { state: any; key: string } = useLocation();
  const { setToastModal, setAlertModal, alertModal } = useModalStore();
  const [enabledDay, setEnabledDay] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [enabledTimes, setEnabledTimes] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const toastModalId = useId();
  const timeErrorModalId = useId();
  const errorModalId = useId();
  const targetDay = locState.targetDay ? dayjs(locState.targetDay) : dayjs();
  const { isLoading, error: scheduleTimeError, data, isError } = useQuery(['SCHEDULE_TIMES', targetDay.format('YYYY-MM-DD')], () => devServerApi.authGet(`/policy/schedule/times?date=${targetDay.format('YYYY-MM-DD')}`, accessToken), {
    onSuccess: (res: any) => {
      setEnabledDay((res.dailyActivation));
      setEnabledTimes(res.onlineSchedules);
    }, onError: () => {
      setAlertModal({ visible: true, key: timeErrorModalId });
    },
  });

  const enableDayAndTime = (value: { time: number; state: string } | boolean) => {
    const body: any = {};
    let originalValue;
    if (typeof value === 'boolean') {
      originalValue = enabledDay;
      body.enableDailyOnlineSchedule = value;
    } else {
      originalValue = [...enabledTimes];
      body.newOnlineSchedules = [{ time: value.time, activation: value.state === 'opened' }];
    }
    if (typeof value === 'boolean') {
      setEnabledDay(value);
    } else {
      Object.assign(
        enabledTimes.find(({ time }) => time === value.time),
        value
      );
      setEnabledTimes([...enabledTimes]);
    }
    devServerApi.api
      .patch(`/policy/schedule/times/${targetDay.format('YYYY-MM-DD')}`, body)
      .then((res) => {
        // @ts-ignore
        if (res.status < 0) throw new Error(res.message);
        if (res.data.status < 0) {
          setAlertModal({ visible: true, key: errorModalId });
          setErrorMessage(res.data.message);
          throw new Error(res.data.message);
        }
      })
      .catch(() => {
        if (typeof value === 'boolean') {
          setEnabledDay(originalValue);
        } else {
          setEnabledTimes(originalValue);
        }
      });
  };

  const check = (e, timeObject) => {
    enableDayAndTime({ time: timeObject.time, state: e.target.checked ? 'opened' : 'closed' });
  };

  const minToTimeFormat = (time: number) => {
    let isNextDay = time >= 1440 ? '익일' : '';
    let A = time < 1440 && time >= 720 ? '오후' : '오전';
    let hour = String(Math.floor((time % 720) / 60)).padStart(2, '0');
    if (hour === '00' && time >= 720) hour = '12';
    if (time >= 1440) hour = '익일' + hour;
    let minute = String(time % 60).padStart(2, '0');
    return `${isNextDay}${A} ${hour}:${minute}`;
  };
  useEffect(() => {
    if (firstLoad) {
      setToastModal({ visible: true, key: toastModalId });
    } else {
      setFirstLoad(true);
    }
  }, [enabledDay]);

  if ((isLoading || typeof enabledDay !== 'boolean') && !isError) {
    return <SpinnerEffector loading={isLoading || typeof enabledDay !== 'boolean'}/>;
  }

  const goToHome = () => {
    if (window.ExitPage) window.ExitPage.postMessage('');
    else navigate(-1);
    setAlertModal({ visible: false, key: timeErrorModalId });
  };

  return (
    <>
      <HeaderBack isFixed inBack={true}>
        <div className="font__subtitle">일정 관리</div>
      </HeaderBack>
      <section className="management-day-wrapper">
        <div className="management-day-header">
          <div className="left-area">
            <div className="font__subtitle1_sb">{targetDay.year()}년</div>
            <div className="font__title_20_sb">{targetDay.format('MM월 DD일')}</div>
          </div>
          <div className="right-area gap-16">
            <div className="font__small">{enabledDay ? '예약 받음' : '예약 안 받음'}</div>
            <label className="tblm-switch pointer">
              <input type="checkbox" name="switch_example" checked={enabledDay} onChange={(e) => enableDayAndTime(e.target.checked)} />
              <i />
            </label>
          </div>
        </div>
        {enabledDay ? <div className="management-day-content">
          <div className="management-day-title font__body_md">예약 받는 시간</div>
          {enabledTimes.map(({ time, state }, idx) => (
            <div key={time} className={classNames('management-day-item', { disabled: !enabledDay })}>
              <div className="left-area">
                <div className="font__subtitle1_sb">{minToTimeFormat(time)}</div>
              </div>
              <div className="right-area gap-16">
                <div>{state === 'opened' ? '예약 받음' : '예약 안 받음'}</div>
                <label className="tblm-switch pointer">
                  <input type="checkbox" name="switch_example" checked={state === 'opened'} onChange={(e) => check(e, { time, state })} />
                  <i />
                </label>
              </div>
            </div>
          ))}
        </div> : null}
      </section>
      <ModalPortal>
        <Modal.Toast key={toastModalId} modal="toast" autoHideDuration={1000} isAnimation={true} position="bottom">
          <div className="font__small_sb" style={{ color: '#ffffff' }}>
            {enabledDay ? '이날 예약을 다시 받습니다.' : '이날 예약을 받지 않습니다.'}
          </div>
        </Modal.Toast>
        <Modal.Alert classKey="alert-error" key={timeErrorModalId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">일시적인 오류가 발생했습니다.</div>
          <div className="content font__body_sm">1544-2017로 문의해주세요.</div>
          <div className="footer success font__body_sm_sb" onClick={goToHome}>
          홈으로 이동
          </div>
        </Modal.Alert>
        <Modal.Alert key={errorModalId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">알림</div>
          <div className="content font__body_sm">
            {errorMessage}
          </div>
          <div className="footer success font__body_sm_sb" onClick={() => {
            setErrorMessage('');
            setAlertModal({ visible: false, key: errorModalId });
          }}>
            확인
          </div>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default ManagementDay;
