import { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import Input from '../Input/Input';
import FooterSub from '../FooterSub/FooterSub';
import './RefundDepositMenuModal.scss';

const RefundDepositMenuModal = ({ modalKey }) => {
  const [paidPrice, setPaidPrice] = useState(3000);
  const [depositPrice, setDepositPrice] = useState('');
  const [disabledCTA, setDisabledCTA] = useState(!false);

  useEffect(() => {
    setPaidPrice(3000);
  }, []);

  const onChangeRefundValue = (e) => {
    const originValue = e.target.value.replace(/,/g, '');
    const isStartZeroVal = originValue.length > 1 && originValue[0] === '0';

    const formattedValue = originValue
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      .replace(!/0.*0/, '');
    setDepositPrice(formattedValue);

    if (isStartZeroVal) setDepositPrice('0');
    if (Number(originValue) > Number(paidPrice) || !originValue.length) setDisabledCTA(true);
    else setDisabledCTA(false);
  };

  return (
    <ModalPortal>
      <Modal.Menu key={modalKey} modal="menu" isDim={true} isAnimation={true}>
        <div className="paid-deposit-wrapper">
          <div className="title font__subtitle">결제된 예약금이 있습니다</div>
          <section className="price-info">
            <h4>{paidPrice?.toLocaleString()}원</h4>
            <span>
              {'카드결제'} {'2022년 8월 17일'} {'19:00'}
            </span>
          </section>
          <section className="refund-select-button-wrapper">
            <h4>환불금액</h4>
            <ul>
              <li onClick={() => {}}>100%</li>
              <li onClick={() => {}}>50%</li>
              <li onClick={() => {}}>환불 금액 없음</li>
            </ul>
          </section>
          <section className="reservation-amount-input-wrapper">
            <Input autoFocus name="text-basic" type="tel" placeholder="환불금액을 입력해주세요" value={depositPrice} onChange={onChangeRefundValue} />원
          </section>
          <span className={`info-refund-deposit-desc ${!depositPrice ? 'white-space' : null}`}>
            {depositPrice ? `${paidPrice?.toLocaleString()}원중 ${depositPrice?.toLocaleString()}원이 환불됩니다.` : null}
          </span>
        </div>
        <FooterSub isVisible isBorder isBackArea>
          <button className="tblm-button-normal tblm-btn-primary w-full" disabled={disabledCTA} onClick={() => {}}>
            환불만 진행
          </button>
          <button className="tblm-button-normal tblm-btn-primary w-full" disabled={disabledCTA} onClick={() => {}}>
            환불하고 예약취소
          </button>
        </FooterSub>
      </Modal.Menu>
    </ModalPortal>
  );
};

export default RefundDepositMenuModal;
