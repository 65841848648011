import React from 'react';
import './TableGroup.scss';
import { ITablesGroup } from '@/shared/utils/common.type';
import Table from '../Table/Table';

function TableGroup({ tableGroupData }: {tableGroupData: ITablesGroup}) {
  return (
    <div className="table-group-container">
      <div className='tblm-chip-filter-menu selected' role="button">
        {tableGroupData.groupName}
      </div>
      <div className='table-wrapper'>
        {tableGroupData.item.filter(table => Boolean(table.enabled)).map((table) => <Table tableData={table} key={table._id}/>)}
      </div>
    </div>
  );
}

export default TableGroup;
