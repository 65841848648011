import classNames from 'classnames';
import React, { useEffect, useMemo, useRef } from 'react';
import { ReactComponent as Close_Small } from '@/assets/images/icons/Close_Small.svg';
import { ISelectedMenuList } from './SelectedMenuList.type';
import './SelectedMenuList.scss';

const SelectedMenuList = ({ data, isModal, onDelete }: ISelectedMenuList): React.ReactElement => {
  const scrollRef = useRef(null);

  const isSelectedMenuList = useMemo(() => {
    return data
      .map((item) => item.menus.filter((filterItem) => filterItem.menuCount))
      .map((subItem) => Boolean(subItem.length))
      .includes(true);
  }, [data]);

  useEffect(() => {
    if (scrollRef.current) scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [data]);
  return (
    <>
      {!isSelectedMenuList ? (
        <div className="no-menu-list">선택된 메뉴가 없습니다.</div>
      ) : (
        <>
          <div className="body-title">선택 메뉴</div>
          <div ref={isModal ? scrollRef : null} className={classNames('con', { isModal: isModal })}>
            {data.map((item) =>
              item.menus
                .filter((filterItem) => filterItem.menuCount)
                .map((subItem) => (
                  <div key={subItem.menuId}>
                    <div>{subItem.menuName}</div>
                    <span>{subItem.menuCount}</span>
                    <Close_Small onClick={() => onDelete(item.categoryId, subItem.menuId, 'reset')} />
                  </div>
                ))
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SelectedMenuList;
