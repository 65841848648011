import classNames from 'classnames';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import React, { useEffect, useState } from 'react';
import { initialTimeList } from './TimePicker.constants';
import { Hour, ITimeList, ITimePicker, ITimePickerList } from './TimePicker.type';
import './TimePicker.scss';

dayjs.locale('ko');

const TimePickerList = ({ list, startMinute, endMinute, selectTime, selectedTime }: ITimePickerList): React.ReactElement => {
  return (
    <div className="time-container">
      {list[1].map((time, i) => (
        <div className="hour-division" key={i}>
          <div className="hour-division-text font__body_sm_sb">{time.targetHour.format('H시')}</div>
          <div className="minutue-division-contianer">
            {time.minutes.map((minute, j) => {
              const isBeforTime = minute.isBefore(minute.startOf('day').set('minute', startMinute), 'minute');
              const isAfterTime = minute.isAfter(minute.startOf('day').set('minute', endMinute), 'minute');
              return (
                <div
                  key={j}
                  className={classNames('minute-division-box font__body_sm', {
                    selected: minute.isSame(selectedTime, 'minute'),
                    disabled: isBeforTime || isAfterTime,
                  })}
                  onClick={() => ((!isBeforTime || !isAfterTime) && selectTime(minute))}
                >
                  {minute.format('HH:mm')}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

const TimePicker = ({ startMinute, endMinute, selectedDate, onSelect }: ITimePicker): React.ReactElement => {
  const [timeList, setTimeList] = useState<ITimeList>(initialTimeList);
  const [selectedTime, setSelectedTime] = useState<dayjs.Dayjs>(null);
  const endMinutes = endMinute < startMinute ? 1440 : endMinute;

  const makeTimePicker = (date: dayjs.Dayjs) => {
    let timeGap = Math.ceil((endMinutes - startMinute) / 60);
    timeGap = timeGap < 0 ? timeGap * -1 : timeGap;
    let timeArray = new Array<Hour>(timeGap).fill(null);
    let targetDate = date.startOf('day');

    timeArray = timeArray.map((_, index) => {
      let targetHour = targetDate.hour((startMinute / 60) + index);
      let minutes = new Array(6).fill(null).map((__, minuteIndex) => targetHour.minute(minuteIndex * 10));

      return { minutes, targetHour };
    });

    setTimeList({
      times: {
        morning: timeArray.filter((time) => time.targetHour.hour() < 12),
        afternoon: timeArray.filter((time) => time.targetHour.hour() >= 12),
      },
      targetDate: targetDate,
    });
  };

  const selectTime = (time) => {
    setSelectedTime(time);
    onSelect(time);
  };

  useEffect(() => {
    if (selectedDate) {
      makeTimePicker(selectedDate);
      setSelectedTime(selectedDate);
    }
  }, []);

  if (!timeList) return null;

  return (
    <div className="time-picker-container">
      {Object.entries(timeList.times).map((item) => {
        if (item[1].length === 0) return null;
        return (
          <div className="time-division-container" key={item[0]}>
            <div className="division-text font__small_sb">{item[0] === 'morning' ? '오전' : '오후'}</div>
            <TimePickerList list={item} startMinute={startMinute} endMinute={endMinute} selectTime={selectTime} selectedTime={selectedTime} />
          </div>
        );
      })}
    </div>
  );
};

export default TimePicker;
