import create from 'zustand';
import dayjs from 'dayjs';

interface IUseCallHistoryDateStore {
  startDate: any;
  setStartDate: (props) => void;
  endDate: any;
  setEndDate: (props) => void;
}

export const useCallHistoryStore = create<IUseCallHistoryDateStore>((set) => ({
  startDate: dayjs().subtract(7, 'day'),
  setStartDate: (startDate) => set({ startDate }),
  endDate: dayjs(),
  setEndDate: (endDate) => set({ endDate }),
}));
