import Alert from '@/components/Modal/Alert/Alert';
import Menu from '@/components/Modal/Menu/Menu';
import Normal from '@/components/Modal/Normal/Normal';
import Toast from '@/components/Modal/Toast/Toast';
// import './Modal.scss';

export { Alert, Menu, Toast, Normal };

export default {
  Alert,
  Menu,
  Toast,
  Normal,
};
