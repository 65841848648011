import '@/styles/pages/tutorial/tutorial.scss';

import React, { useEffect, useReducer, useRef, useState } from 'react';
import { essentialTutorialApi, reservationTutorialApi, tableTutorialApi } from '@/shared/apis/tutorialApi';
import { questionReducer, useReservationSettingStore } from '@/stores/common/useReservationSettingStore';

/* eslint-disable no-nested-ternary */
import { ReactComponent as Arrow_Down } from '@/assets/images/icons/Arrow_Down.svg';
import Picker from '@/components/Picker/Picker';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import QuestionContent from '@/components/QuestionContent/QuestionContent';
import TutorialCheckbox from '@/components/TutorialCheckbox/TutorialCheckbox';
import TutorialDayTime from '@/components/TutorialDayTime/TutorialDayTime';
import TutorialNaverKakao from '@/components/TutorialNaverKakao/TutorialNaverKakao';
import TutorialReservation from '@/components/TutorialReservation/TutorialReservation';
import TutorialSelector from '@/components/TutorialSelector/TutorialSelector';
// import TutorialSelector2 from '@/components/TutorialSelector2/TutorialSelector2';
import TutorialTable from '@/components/TutorialTable/TutorialTable';
import classnames from 'classnames';
import { devServerApi } from '@/shared/apis/devServerApi';
import template from 'lodash/template';
import templateSettings from 'lodash/templateSettings';
import { useAuthStore } from '@/stores/common/useAuthStore';
import useGetInitialStep from '@/hooks/useGetInitialStep';
import { useLayoutStore } from '@/stores/common/useLayoutStore';
import { useMallStore } from '@/stores/common/useMallStore';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import { instantDisabler } from '@/shared/lib/functions';
import { usePartnerStore } from '@/stores/common/usePartnerStore';

templateSettings.interpolate = /{{([\s\S]+?)}}/g;

const ReservationSettingPage = (): React.ReactElement => {
  const { state, setState } = useSettingTutorialStore();
  const { accessToken } = useAuthStore();
  const { setMallTime } = useMallStore();
  const { setSpinner } = useLayoutStore();
  const { appName } = usePartnerStore();
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });

  useQuery([QUERY_KEYS.GET_EVENT_DAY], () => devServerApi.authGet('/mall/times', accessToken), {
    onSuccess: (res: any) => {
      setMallTime(res.openTime);
    },
  });

  const tutorialPage = useRef(null);
  const { storageQuestion, setStorageQuestion, resetStorageQuestion } = useReservationSettingStore();
  const initialStep = useGetInitialStep(storageQuestion);

  const [renderingQuestion, dispatchRenderingQuestion] = useReducer(questionReducer, storageQuestion);
  const [renderingQuestionVer, setRenderingQuestionVer] = useState(Date.now());
  const [step, setStep] = useState(initialStep);
  const [referenceIdx, setReferenceIdx] = useState(0);
  const [showUntil, setShowUntil] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setRenderingQuestionVer(Date.now());
  }, [renderingQuestion]);

  const { title, optionUnit, value, reference, question, note, notePosition, boolText, option, multiple, type, prevStep, nextStep } = renderingQuestion[step];

  const referenceStep = reference ? renderingQuestion[reference] : null;
  const referenceValue = referenceStep ? (referenceStep.value as number[]) : null;

  const onPrev = () => {
    dispatchRenderingQuestion({ type: 'SET_CHANGE', value: null, step });
    setReferenceIdx(0);
    setStep(prevStep);
  };
  const onNext = async (data?: any) => {
    if (referenceValue && referenceValue.length - 1 > referenceIdx) {
      setReferenceIdx(referenceIdx + 1);
      return;
    }
    setReferenceIdx(0);
    let destinationStep = null;
    try {
      if (!nextStep || (!nextStep.to && !nextStep.bool && !nextStep.range && !nextStep.api)) throw new Error('no more steps');
      if (nextStep.to) {
        destinationStep = nextStep.to;
      } else if (nextStep.bool) {
        if (data === undefined) {
          destinationStep = nextStep.bool[value ? 0 : 1];
        } else {
          if (typeof data !== 'boolean') return;
          destinationStep = nextStep.bool[data ? 0 : 1];
        }
      } else if (nextStep.api) {
        // eslint-disable-next-line no-nested-ternary
        const getTutorial = () => {
          if (appName === 'kt') {
            return essentialTutorialApi;
          }
          return () => null;
        };

        const api =
          nextStep.api[0] === 'table'
            ? tableTutorialApi
            : nextStep.api[0] === 'reservation'
            ? reservationTutorialApi
            : nextStep.api[0] === 'essential'
            ? getTutorial()
            : () => null;
        setSpinner(true);
        await api(data);
        setSpinner(false);
        destinationStep = nextStep.api[1];
      } else if (nextStep.range) {
        for (const range of nextStep.range) {
          const range_destination_step = range[0];
          const range_start = range[1] || -Infinity;
          const range_end = range[2] || Infinity;
          if (value >= range_start && value <= range_end) {
            destinationStep = range_destination_step;
            break;
          }
        }
      }
      const nextTutorial = ['table', 'reservation', 'essential', 'done'].includes(destinationStep);
      if (!destinationStep) throw new Error('no match step');
      if (!nextTutorial) renderingQuestion[destinationStep].prevStep = step;
      setStorageQuestion(renderingQuestion);
      if (nextTutorial) {
        resetStorageQuestion();
        setState(destinationStep);
        if (destinationStep === 'done') {
          if (window.DonePage) {
            window.DonePage.postMessage('');
          } else navigate('/setting', { replace: true });
        } else {
          navigate('/tutorial/' + destinationStep);
        }
      } else {
        setStep(destinationStep);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getOptionElement = () => {
    if (type === 'button') {
      return (
        <>
          <button
            className={classnames({ active: value === true })}
            onClick={instantDisabler(() => {
              dispatchRenderingQuestion({ type: 'SET_CHANGE', value: true, step });
              onNext(true);
            })}
          >
            {boolText ? boolText[0] : '네'}
          </button>
          <button
            className={classnames({ active: value === false })}
            onClick={instantDisabler(() => {
              dispatchRenderingQuestion({ type: 'SET_CHANGE', value: false, step });
              onNext(false);
            })}
          >
            {boolText ? boolText[1] : '아니요'}
          </button>
        </>
      );
    }
    if (type === 'checkbox') {
      return (
        <TutorialCheckbox
          options={option}
          unit={optionUnit}
          value={value}
          onChange={(v) => {
            dispatchRenderingQuestion({ type: 'SET_CHANGE', value: v, step });
          }}
        />
      );
    }
    if (type === 'select') {
      let defaultValue = value;
      if (step === 'step3_0') {
        if (!defaultValue) {
          defaultValue = [2, 0];
        } else {
          // @ts-ignore
          defaultValue = value;
        }
      } else if (step === 'step5_0') {
        if (!defaultValue) {
          defaultValue = [120];
        } else {
          // @ts-ignore
          defaultValue = [value];
        }
      } else if (step === 'step4_0') {
        if (!defaultValue) {
          defaultValue = [30];
        } else {
          // @ts-ignore
          defaultValue = [value];
        }
      }
      return (
        <TutorialSelector
          key={title}
          title={title}
          options={option}
          unit={optionUnit}
          value={defaultValue}
          onChange={(v) => {
            if (referenceValue) {
              if (referenceIdx === 0) {
                const refInitial = referenceValue.map((rv) => [rv, null]);
                refInitial[referenceIdx][1] = v;
                dispatchRenderingQuestion({ type: 'SET_CHANGE', value: refInitial, step });
              } else {
                dispatchRenderingQuestion({ type: 'SET_REF_CHANGE', value: v, idx: referenceIdx, step });
              }
            } else {
              dispatchRenderingQuestion({ type: 'SET_CHANGE', value: v, step });
            }
          }}
        />
      );
    } /*
    if (type === 'multiselect') {
      return (<TutorialSelector2 title={title} options={option} unit={optionUnit} value={value} onChange={(v) => {
        if (referenceValue) {
          if (referenceIdx === 0) {
            const refInitial = referenceValue.map(rv => ([rv, null]));
            refInitial[referenceIdx][1] = v;
            dispatchRenderingQuestion({ type: 'SET_CHANGE', value: refInitial, step });
          } else {
            dispatchRenderingQuestion({ type: 'SET_REF_CHANGE', value:v, idx: referenceIdx, step });
          }
        } else {
          dispatchRenderingQuestion({ type: 'SET_CHANGE', value:v, step });
        }
      }} />);
    }*/
    if (type === 'until') {
      if (!value && step === 'step7_0') dispatchRenderingQuestion({ type: 'SET_CHANGE', value: 30, step });
      return (
        <>
          <div className="row" onClick={() => setShowUntil(true)}>
            <div className={classnames('selectbox')}>
              {value ? value : '30'}
              <Arrow_Down />
            </div>
            일 후
          </div>
          <div className="row">예약까지 고객이 신청할 수 있어요.</div>
          <Picker
            isSingle={true}
            key={'until'}
            title={''}
            modalKey={'until'}
            onSuccess={({ leftArea }) => {
              dispatchRenderingQuestion({ type: 'SET_CHANGE', value: leftArea, step });
              setShowUntil(false);
            }}
            onClose={() => setShowUntil(false)}
            visible={showUntil}
            leftArea={{ selected: value || 30, list: [7, 14, 30, 60, 90], unit: '일 후' }}
          />
        </>
      );
    }
    if (type === 'reservation') {
      return <TutorialReservation question={renderingQuestion} dispatcher={dispatchRenderingQuestion} />;
    }

    if (type === 'naverkakao') {
      return (
        <TutorialNaverKakao
          onNext={(bool) => {
            if (bool) {
              dispatchRenderingQuestion({ type: 'SET_CHANGE', value: true, step });
            } else {
              dispatchRenderingQuestion({ type: 'SET_CHANGE', value: false, step });
            }
            onNext(bool);
          }}
        />
      );
    }
    if (type === 'plain') {
      return null;
    }
    if (type === 'table') {
      return null;
    }

    // eslint-disable-next-line no-unreachable
    if (typeof option === 'object') {
      const checkMultipleValue = value === null && multiple ? [] : value;
      const initialValue = checkMultipleValue === null ? undefined : checkMultipleValue;

      return (
        <select
          className="w-full"
          onChange={(e) => dispatchRenderingQuestion({ type: 'SET_SELECT_CHANGE', value: e.target, step, multiple })}
          multiple={multiple}
          value={initialValue as string | number}
        >
          {option.map((item) => (
            <option key={step + '_' + item} value={item}>
              {item}
            </option>
          ))}
        </select>
      );
    }
  };

  const mainOptionElement = getOptionElement();

  const unitize = () => {
    if (referenceStep && Array.isArray(referenceValue)) {
      const alteredValue = value || referenceValue.map((rv) => [rv, null]);
      const optionIdx = alteredValue[referenceIdx][0];
      if (referenceStep.optionUnit === '층') return `${referenceStep.option[optionIdx]}${referenceStep.optionUnit}`.replace('-', '지하 ');
      return referenceStep.option[optionIdx] + referenceStep.optionUnit;
    }
    return null;
  };

  const formattedQuestion = template(question)({ option: unitize() });

  let content = null;

  switch (type) {
    case 'table':
      content = <TutorialTable question={renderingQuestion} dispatcher={dispatchRenderingQuestion} onSubmit={() => {}} />;
      break;
    case 'time':
      content = <TutorialDayTime question={renderingQuestion} dispatcher={dispatchRenderingQuestion} onPrev={onPrev} onNext={onNext} />;
      break;
    default:
      content = (
        <div className="tutorial-content-container" key={step}>
          <QuestionContent
            question={formattedQuestion}
            option={mainOptionElement}
            note={note}
            notePosition={notePosition}
            type={type}
            value={value}
            key={`${step}_${formattedQuestion}`}
          />
        </div>
      );
  }

  return (
    <div className={classnames('tutorial-page', { unfixed: type === 'table', fulled: type === 'reservation', nobutton: type === 'time' })}>
      {content}
      {type === 'time' || type === 'naverkakao' ? null : (
        <div className={classnames('button-container', { relative: type === 'reservation' })}>
          {step === 'step1_0' || step === 'step10_0' || type === 'reservation' || !prevStep ? null : (
            <button className="prev" onClick={() => onPrev()}>
              이전
            </button>
          )}
          <button
            className="next"
            onClick={instantDisabler(() => onNext(type === 'reservation' ? storageQuestion : undefined))}
            disabled={value === null && !(nextStep.api || nextStep?.to === 'done')}
          >
            {nextStep && (nextStep.api || nextStep?.to === 'done') ? '확인했어요' : '다음'}
          </button>
        </div>
      )}
    </div>
  );
};

export default ReservationSettingPage;
