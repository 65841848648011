import HeaderBack from '@/components/HeaderBack/HeaderBack';
import Picker from '@/components/Picker/Picker';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigate } from 'react-router-dom';
import { usePolicyStore } from '@/stores/common/usePolicyStore';

const dayList = [7, 14, 30, 60, 90];

const AvailableReservation = () => {
  const navigate = useNavigate();
  const { bookingAvailableDays, setBookingAvailableDays } = usePolicyStore();
  const { setMenuModal } = useModalStore();

  const handleOnPickerSuccess = async (selected) => {
    const V = selected.leftArea;
    await devServerApi.api.patch('/policy/reservations', { bookingAvailableDays: V });
    setBookingAvailableDays(V);
  };
  return (
    <>
      <HeaderBack inBack={true}>
        <div className="font__subtitle">예약 신청 가능 기간</div>
      </HeaderBack>
      <section className="settings-policy-detail-wrapper">
        <div className="switch-area pointer" onClick={() => setMenuModal({ visible: true, key: 'available-reservation-picker-modal' })}>
          <div className="left-area">
            <div className="font__title_20_sb">
              예약 신청 <br /> 가능 기간
            </div>
            <div className="font__body_sm_m keep-all">
              오늘부터 며칠 후까지 <br />
              예약을 열어둘까요?
            </div>
          </div>
          <div className="right-area gap-16">
            <div className="font__title_19_sb">{bookingAvailableDays}일 후까지</div>
          </div>
        </div>
      </section>
      <Picker
        title="오늘부터 며칠 후까지 예약을 열어둘까요?"
        modalKey="available-reservation-picker-modal"
        // visible={visibleModal}
        leftArea={{ list: dayList, unit: '일', selected: bookingAvailableDays }}
        onSuccess={handleOnPickerSuccess}
        onSuccessText="완료"
      />
    </>
  );
};

export default AvailableReservation;
