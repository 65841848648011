/* eslint-disable max-lines-per-function */
import HeaderSub from '@/components/HeaderSub/HeaderSub';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Tabs from '@/components/Tabs/Tabs';
import { useModalStore } from '@/stores/common/useModalStore';
import { useEffect, useId, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './callHistory.scss';
import styles from '@/components/ListFilter/ListFilter.module.scss';
import classnames from 'classnames';
import { ReactComponent as Filter } from '@/assets/images/icons/Filter_White.svg';
import { ReactComponent as Edit } from '@/assets/images/icons/ic_edit.svg';
import FooterSub from '@/components/FooterSub/FooterSub';
import Input from '@/components/Input/Input';
import { detectOS } from '@/shared/utils/detectOS';
import { OS_TYPE } from '@/shared/utils/os.constants';
import { ReactComponent as ArrowRight } from '@/assets/images/icons/Arrow_Right.svg';
import { ReactComponent as PersonalIcon } from '@/assets/images/icons/ic_personal.svg';

import { ReactComponent as CallIcon } from '@/assets/images/icons/ic_call.svg';
import { ReactComponent as MessageIcon } from '@/assets/images/icons/ic_message.svg';
import { useCallHistoryStore } from '@/stores/useCallHistoryStore';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import { devServerApi } from '@/shared/apis/devServerApi';
import { ICallLog } from '@/shared/utils/common.type';
import { useAuthStore } from '@/stores/common/useAuthStore';
import Terces from '@/shared/apis/terces';
import { useQuery } from 'react-query';
import Navigation from '@/components/Navigation/Navigation';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import classNames from 'classnames';
import { handleContactEvent } from '@/shared/lib/functions/contact-event';
import CallItem from '@/components/CallHistory/CallItem/CallItem';
import { filteringAgoDay, formatDurationCallTime } from '@/shared/lib/functions/call-item';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import { autoHypenTel } from '@/shared/lib/functions/auto-hypen-tel';

const CALLLOG_TAB_TYPE = [
  {
    name: '전체',
    codeType: 'all',
    id: 'all',
  },
  {
    name: '수신',
    codeType: 'CALLSTS001',
    id: 'incoming',
  },
  {
    name: '발신',
    codeType: 'CALLSTS003',
    id: 'outgoing',
  },
  {
    name: '부재중',
    codeType: 'CALLSTS002CALLSTS004',
    id: 'missing',
  },
];

const CallHistory = () => {
  const dayjs = require('dayjs');
  const duration = require('dayjs/plugin/duration');
  dayjs.extend(duration);
  const endOfDay = dayjs().endOf('day');
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setAlertModal, setMenuModal } = useModalStore();
  const { endDate, startDate, setEndDate, setStartDate } = useCallHistoryStore();
  const { accessToken } = useAuthStore();
  const { userInfo, setUserInfo } = useReservationStore();
  const [activeTab, setActiveTab] = useState('all');
  const [classificationTime, setClassificationTime] = useState(null);
  const [callUserDetail, setCallUserDetail] = useState(null);

  const userNickNameElement = useRef(null);

  const callHistoryFilterModal = useId();
  const callHistoryDetailModal = useId();
  const successConnectedModal = useId();
  const callHistoryAccessModal = useId();

  const handleOnTabsClick = (e) => {
    const selectedValue = e.target.getAttribute('data-value');
    setActiveTab(selectedValue);
  };

  const popFilterModal = () => {
    setMenuModal({ visible: true, key: callHistoryFilterModal });
  };

  const detectScrollStoreList = () => {
    const ele = userNickNameElement.current;
    if (ele?.scrollTop > 0) {
      ele.style.borderTop = '1px solid #EDEDED';
    } else {
      ele.style.borderTop = null;
    }
  };

  const { isLoading: isCallLogLoading, data: callList } = useQuery(
    [QUERY_KEYS.GET_CALL_LOGS, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), activeTab],
    () =>
      devServerApi
        .authGet<{ callLogs: ICallLog[] }>(`call-logs?callFrom=all&callStatus=${activeTab}&from=${startDate.format('YYYY-M-D')}&to=${endDate.format('YYYY-M-D')}`, accessToken)
        .then((enc_res) => Terces.decrypt(enc_res)),
    {
      onSuccess: async (res) => {
        let divisionObj = [];
        await res.callLogs?.forEach((callLog) => {
          const dayjsCallLogTime = dayjs(callLog.callLogTime).format('YYYY-MM-DD');
          if (Object.keys(divisionObj).includes(dayjsCallLogTime)) divisionObj[dayjsCallLogTime].push(callLog);
          else divisionObj[dayjsCallLogTime] = [callLog];
        });

        setClassificationTime(divisionObj);
      },
    }
  );

  const requestUserDetail = (callLogId: string) => {
    devServerApi
      .authGet<{ callLogDetail: any }>(`/call-logs/${callLogId}`, accessToken)
      .then((enc_res) => setCallUserDetail(enc_res?.callLogDetail))
      .then(() => setMenuModal({ visible: true, key: callHistoryDetailModal }));
  };

  const reservationStatusWithTime = (callTime) => {
    const now = dayjs();
    const target = dayjs(callTime);
    const durationTime = dayjs.duration(target.diff(now));

    if (
      Math.abs(durationTime.years()) === 0 &&
      Math.abs(durationTime.months()) === 0 &&
      Math.abs(durationTime.days()) === 0 &&
      Math.abs(durationTime.hours()) === 0 &&
      Math.abs(durationTime.minutes()) === 0 &&
      Math.abs(durationTime.seconds()) < 60
    ) {
      return durationTime.seconds() <= 0 ? '지금 예약' : '1분 후 예약';
    }

    const units = [
      { unit: '년', value: durationTime.years() },
      { unit: '달', value: durationTime.months() },
      { unit: '일', value: durationTime.days() },
      { unit: '시간', value: durationTime.hours() },
      { unit: '분', value: durationTime.minutes() },
    ];

    for (const unit of units) {
      if (unit.value !== 0) return `${Math.abs(unit.value)}${unit.unit} ${unit.value >= 0 ? '후' : '전'} 예약`;
    }
  };

  // 예약 정책 테스트용 ex) 30분후, 2시간 전 등
  // useEffect(() => {
  //   const targettime = '2023-06-14T11:06:00.816Z';
  //   callUserDetail && console.log('result:', reservationStatusWithTime(targettime));

  //   console.log(dayjs(targettime).format('YYYY-MM-DD A HH:mm:ss'), '/', dayjs().format('YYYY-MM-DD A HH:mm:ss'));
  // }, [callUserDetail]);
  ///////////////////////////////////

  const goToReservationProcess = () => {
    setUserInfo({
      ...userInfo,
      userId: callUserDetail.userId,
      userName: callUserDetail.userName,
      userPhone: callUserDetail.userPhone.replace(/-/g, ''),
      isSearch: false,
      newUserId: undefined,
      newUserPhone: undefined,
    });
    navigate('/reservation/accept/step-1');
  };

  const goToMyReservation = () => {};

  useEffect(() => {
    const callLogId = searchParams.get('callLogId');
    if (callLogId) {
      requestUserDetail(callLogId);
      searchParams.delete('callLogId');
      setSearchParams(searchParams);
    }
  }, []);

  if (isCallLogLoading) return <SpinnerEffector loading />;

  return (
    <>
      <HeaderSub isFixedShortHeader isShowClose={false}>
        <div className="call-history-sub-header">
          통화 내역
          <div className="filter-button" onClick={() => popFilterModal()}>
            <Filter className="icon" />
            필터
          </div>
        </div>
      </HeaderSub>
      {/* app ver2 */}
      {/* <div className="call-history-sub-header">
        app2 ver
        <Tabs mode="default" onClick={handleOnTabsClick} isFixed>
          {CALLLOG_TAB_TYPE.map((item) => (
            <Tabs.Item value={item.id} active={activeTab === item.id} key={item.id} onClick={handleOnTabsClick}>
              {item.name}
            </Tabs.Item>
          ))}
        </Tabs>
        <div className="filter-button" onClick={() => popFilterModal()}>
          <Filter className="icon" />
          필터
        </div>
      </div> */}
      {callList?.callLogs.length === 0 ? (
        <div className="no-result">통화 내역이 없습니다.</div>
      ) : (
        <div className="call-group-wrapper">
          {classificationTime &&
            Object.keys(classificationTime)?.map((item, idx) => (
              <div className="call-group-list" key={item + idx}>
                <>
                  <div className="group-header">
                    {/* 오늘 ~ 7일 전만 표기 */}
                    {dayjs(item).isAfter(dayjs().subtract(8, 'day')) && dayjs(item).isBefore(endOfDay) ? `${filteringAgoDay(dayjs(item))} · ` : null}
                    {dayjs(item).format('MM월 DD일(ddd)')}
                  </div>
                  <div className="call-list-container">
                    {classificationTime[item].map((log, logIdx) => (
                      <div className="call-item-box" key={log.callLogId + logIdx} onClick={() => requestUserDetail(log.callLogId)}>
                        {/* app ver2 */}
                        {/* <div className={classnames('call-item-icon', log.callLogStatus)} /> */}
                        <div className="call-item-info">
                          <div className="call-item-detail-info">
                            <div className={classnames('call-item-phone', { missing: log.callLogStatus === 'CALLSTS002' || log.callLogStatus === 'CALLSTS004' })}>
                              {log.userName || autoHypenTel(log.userPhone)}
                            </div>
                            {/* app ver2 */}
                            {/* {log.callLogType === 'CALLTY0002' && <i className={classnames('call-item-call-type')}>개인</i>} */}
                          </div>
                          {log.userNickname && <div className="call-item-nickname">{log.userNickname}</div>}
                        </div>
                        <div className="call-item-time">
                          {dayjs(log.callLogTime).format('A hh:mm')}
                          <br />
                          {formatDurationCallTime(log.callLogDurationSecond, 'short')}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              </div>
            ))}
        </div>
      )}
      <Navigation />

      <ModalPortal>
        <Modal.Alert key={callHistoryAccessModal} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">테이블매니저의 통화기록 접근 권한 허용 시 사용 가능한 기능입니다.</div>
          <div className="content font__body_sm">허용하시고 내 휴대폰의 통화 기록도 테이블매니저 앱에서 한 번에 관리하세요.</div>
          <div className="footer-multiple-buttons">
            <div onClick={() => setAlertModal({ visible: false, key: callHistoryAccessModal })} className="footer success font__body_sm_sb">
              취소
            </div>
            <div onClick={() => setAlertModal({ visible: true, key: successConnectedModal })} className="footer success font__body_sm_sb">
              동의
            </div>
          </div>
        </Modal.Alert>
        <Modal.Alert key={successConnectedModal} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">내 휴대폰 통화 기록 연동이 완료되었습니다.</div>
          <div className="content font__body_sm">앞으로 내 통화 기록이 앱에 표시됩니다.</div>
          <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: successConnectedModal })}>
            확인
          </div>
        </Modal.Alert>
        <Modal.Menu key={callHistoryFilterModal} modal="menu" isDim isAnimation>
          {/* app ver2 */}
          {/* {detectOS() === OS_TYPE.IOS ? null : (
            <div className={classnames([styles.list_filter], 'call-history-filter-wrapper')}>
              <div className="title font__subtitle call-history-filter-modal-title">어떤 통화내역을 보여 드릴까요?</div>
              <div className={classnames(styles.list__filter_content, styles.list__filter_status_list)}>
                <label>
                  <input type="checkbox" className="tblm-chip-filter-check-handle" name="filter_status" value={'mall'} checked={true} onChange={() => {}} />
                  <div className="tblm-chip-filter-check">매장 전화</div>
                </label>
                <label onClick={() => setAlertModal({ visible: true, key: callHistoryAccessModal })}>
                  <input type="checkbox" className="tblm-chip-filter-check-handle" name="filter_status" value={'personal'} checked={true} onChange={() => {}} />
                  <div className="tblm-chip-filter-check">고객 휴대폰</div>
                </label>
              </div>
            </div>
          )} */}
          <div className="call-history-period-wrapper">
            <div className="title font__subtitle call-history-filter-modal-title">얼마동안의 통화기록을 보여드릴까요?</div>
            <section className="period-input-wrapper">
              <Input
                onClick={() => navigate('/reservation/accept/step-1?type=callHistoryStart')}
                name="text-basic"
                type="text"
                value={startDate.format('YYYY.MM.DD')}
                readOnly
                className="table-name-input"
              />
              <i>~</i>
              <Input
                onClick={() => navigate('/reservation/accept/step-1?type=callHistoryEnd')}
                name="text-basic"
                type="text"
                value={endDate.format('YYYY.MM.DD')}
                readOnly
                className="table-name-input"
              />
            </section>
          </div>
          <FooterSub isVisible isBorder isBackArea>
            <button className="tblm-button-normal tblm-btn-primary w-full" onClick={() => {}}>
              필터 적용
            </button>
          </FooterSub>
        </Modal.Menu>
        <Modal.Menu key={callHistoryDetailModal} modal="menu" isDim isAnimation>
          <div className="call-history-user-detail-wrapper">
            <section className="user-info-wrapper">
              <div className="user-text">
                <div className="user-info">
                  {callUserDetail?.userName && <h3>{callUserDetail?.userName}</h3>}
                  <span>{callUserDetail?.userPhone && autoHypenTel(callUserDetail?.userPhone)}</span>
                </div>
                {callUserDetail?.userNickname && <p className="user-nickname">{callUserDetail?.userNickname}</p>}
              </div>
              <div className="edit-icon" onClick={() => navigate(`/tablemanager/customer-detail/${callUserDetail?.userId}`)}>
                <Edit />
              </div>
            </section>
            {callUserDetail?.userMemo && <section className="user-memo">{callUserDetail?.userMemo}</section>}
            <section className="detail-hashtag-wrapper">
              <ul>
                {<li>{callUserDetail?.userVisitedCount}회 방문</li>}
                {callUserDetail?.userNoshowLevel > 0 && <li>노쇼 {callUserDetail?.userNoshowLevel}단계</li>}
              </ul>
            </section>
            <section className="call-info-data-list-wrapper">
              <ul className={classNames('call-info-data-list', { multipleList: callUserDetail?.reservationMallId })}>
                <CallItem
                  targetCall={{
                    callTime: callUserDetail?.callTime,
                    durationSecond: callUserDetail?.callDurationSecond,
                  }}
                />
                {!callUserDetail?.reservationMallId && (
                  <li
                    onClick={() => goToMyReservation()}
                    className={classnames('user-reservation-info', { isBeforeReservation: dayjs.duration(dayjs(callUserDetail?.reservationVisitDatetime).diff(dayjs())) < 0 })}
                  >
                    {callUserDetail?.reservationOrderName && <h5>{callUserDetail?.reservationOrderName}</h5>}
                    <div className="user-reservation-date-wrapper">
                      <div className="reservation-date-content">
                        <div className="time-from-now">{reservationStatusWithTime(callUserDetail?.reservationVisitDatetime)}</div>
                        <ul className="reservation-date">
                          <li>{dayjs(callUserDetail?.reservationVisitDatetime).format('YY.MM.DD(ddd)')}&nbsp;</li>
                          <li>{dayjs(callUserDetail?.reservationVisitDatetime).format('A hh:mm')}&nbsp;</li>
                          <li>{callUserDetail?.reservationPartySize || 0}명</li>
                        </ul>
                      </div>
                      <ArrowRight />
                    </div>
                  </li>
                )}
              </ul>
            </section>
            <hr className="call-detail-separator" />
            <FooterSub isVisible style={{ flexWrap: 'wrap' }}>
              <button className="tblm-button-normal w-full" onClick={() => goToReservationProcess()}>
                예약 접수
              </button>
              <button
                className="flexible-auto-btn tblm-button-normal w-full tblm-btn-primary menu-modal-with-icon"
                onClick={() => handleContactEvent('message', callUserDetail?.userPhone)}
              >
                <MessageIcon />
                문자
              </button>
              <button
                className="flexible-auto-btn tblm-button-normal w-full tblm-btn-primary menu-modal-with-icon"
                onClick={() => handleContactEvent('call', callUserDetail?.userPhone)}
              >
                <CallIcon />
                전화
              </button>
            </FooterSub>
          </div>
        </Modal.Menu>
      </ModalPortal>
    </>
  );
};

export default CallHistory;
