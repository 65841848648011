import { useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useModalStore } from '@/stores/common/useModalStore';
import { IModalPortal } from './ModalPortal.type';

const ModalPortal = ({ children }: IModalPortal) => {
  const { menuModal, alertModal, toastModal, normalModal } = useModalStore();
  const modals = [
    { key: 'menu', modal: menuModal },
    { key: 'alert', modal: alertModal },
    { key: 'toast', modal: toastModal },
    { key: 'normal', modal: normalModal },
  ];

  const container = useRef(document.body);

  const isOpenModal = modals.map((modal) => modal.modal.visible).includes(true);

  useEffect(() => {
    document.querySelector('body').style.overflow = isOpenModal ? 'hidden' : 'auto';
  }, [isOpenModal]);

  const content = useMemo(() => {
    if (!children || !children.length) {
      const modalObject = modals.find((modalItem) => modalItem.key === children?.props?.modal);
      if (!modalObject) return children;
      return children.key === modalObject.modal.key ? children : null;
    }
    const menus = children.filter((item) => item.props.modal === 'menu').find((item) => item.key === menuModal.key);
    const alerts = children.filter((item) => item.props.modal === 'alert').find((item) => item.key === alertModal.key);
    const toasts = children.filter((item) => item.props.modal === 'toast').find((item) => item.key === toastModal.key);
    const normal = children.filter((item) => item.props.modal === 'normal').find((item) => item.key === normalModal.key);

    return [menus, alerts, toasts, normal];
  }, [children]);

  return ReactDOM.createPortal([content], container.current);
};

export default ModalPortal;
