const QUERY_KEYS = {
  COMMON_MALL: 'COMMON_MALL',
  LIST_RESERVATIONS: 'LIST_RESERVATIONS',
  LIST_STATE: 'LIST_STATE',
  LIST_CALENDAR: 'LIST_CALENDAR',
  LIST_TERMS_AGREE: 'LIST_TERMS_AGREE',
  LIST_TUTORIAL: 'LIST_TUTORIAL',
  SETTINGS_GROUPS: 'SETTINGS_GROUPS',
  SETTINGS_TABLES: 'SETTINGS_TABLES',
  RESERVATION_CALENDAR: 'RESERVATION_CALENDAR',
  USER_SEARCH: 'USER_SEARCH',
  SETTINGS_MALL: 'SETTINGS_MALL',
  SETTING_POLICY: 'SETTING_POLICY',
  GET_AGREEMENT_STATE: 'GET_AGREEMENT_STATE',
  GET_TUTORIAL_STATE: 'GET_TUTORIAL_STATE',
  GET_RESERVATIONS_DATE: 'GET_RESERVATIONS_DATE',
  SCHEDULE_CALENDAR: 'SCHEDULE_CALENDAR',
  GET_EVENT_DAY: 'GET_EVENT_DAY',
  GET_MALL_LIST: 'GET_MALL_LIST',
  PATCH_MY_MALL_LIST: 'PATCH_MY_MALL_LIST',
  GET_CALL_LOGS: 'GET_CALL_LOGS',
  SEND_CREATION_MESSAGE: 'SEND_CREATION_MESSAGE',
  SEND_ALARM_MESSAGE: 'SEND_ALARM_MESSAGE',
  CANCEL_ALARM_MESSAGE: 'CANCEL_ALARM_MESSAGE',
  MAKE_DEPOSIT: 'MAKE_DEPOSIT',
  GET_CALLLOG_LIST: 'GET_CALLLOG_LIST',
  GET_CUSTOMER_INFO: 'GET_CUSTOMER_INFO',
  GET_CUSTOMER_CALL_LOG: 'GET_CUSTOMER_CALL_LOG',
  GET_CALL_ALARM_SETTING: ' GET_CALL_ALARM_SETTING',
  UPDATE_PAUSE_SETTING: 'UPDATE_PAUSE_SETTING',
  UPDATE_NOTIFICATION_SETTING: 'UPDATE_NOTIFICATION_SETTING',
  GET_SAME_PHONE_USER_DATA: 'GET_SAME_PHONE_USER_DATA',
};

export default QUERY_KEYS;
