import './TableEdit.scss';

import { Dispatch, useEffect, useState } from 'react';
import { ITablesGroup, ITablesTable, Nullable } from '@/shared/utils/common.type';

import FooterSub from '../FooterSub/FooterSub';
import { ReactComponent as GroupAdd } from '@/assets/images/icons/ic_group_add.svg';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import Input from '../Input/Input';
import { ReactComponent as Minus } from '@/assets/images/icons/Minus.svg';
import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import { ReactComponent as Plus } from '@/assets/images/icons/Plus.svg';
import ScrollPicker from '@/components/ScrollPicker/ScrollPicker';
import Select from '../Select/Select';
import TableGroup from './TableGroup/TableGroup';
import classnames from 'classnames';
import { devServerApi } from '@/shared/apis/devServerApi';
import { throttle } from 'lodash';
import { useLayoutStore } from '@/stores/common/useLayoutStore';
import { useModalStore } from '@/stores/common/useModalStore';

const checkSpecial = (value: string) => {
  // eslint-disable-next-line no-useless-escape
  const specialRule = /[`~!@#$%^&*|\\\'\";:\/?]/gi;
  return specialRule.test(value);
};

function TableEdit({
  list: tableGroups,
  edit,
  commit: setTableGroups,
}: {
  list: Nullable<ITablesGroup[]>;
  edit: (bool: boolean) => void;
  commit: Dispatch<Nullable<ITablesGroup[]>>;
}) {
  const { setSpinner } = useLayoutStore();
  const { setMenuModal, setAlertModal, setToastModal } = useModalStore();
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupNameError, setNewGroupNameError] = useState(null);
  const [tableNameLengthError, setTableNameLengthError] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState<ITablesGroup['groupId']>();
  const [newTable, setNewTable] = useState<ITablesTable>({ enabled: true, groupId: null, _id: null, peopleMax: 4, peopleMin: 2, name: '' });
  const [targetGroup, setTargetGroup] = useState<ITablesGroup>();

  useEffect(() => {
    setNewGroupNameError(null);
  }, [newGroupName]);

  const commitTableGroupsChange = () => {
    setTableGroups([...tableGroups]);
  };

  const openGroupAddModal = () => {
    setNewGroupName('');
    setMenuModal({ visible: true, key: 'add-group' });
  };

  const makeGroup = async () => {
    let duplicateItem = tableGroups.find((group) => group.groupName === newGroupName);
    if (duplicateItem) {
      setNewGroupNameError(true);
      return;
    }
    const { status, group } = await devServerApi.api.post('/settings/tablegroups', { name: newGroupName }).then((res) => res.data);
    if (status <= 0) return;
    tableGroups.push({
      groupName: group.groupName,
      item: [],
      groupId: group.groupId,
    });
    commitTableGroupsChange();
    setMenuModal({ visible: false, key: null });
  };

  const changeGroupName = async () => {
    if (newGroupName.trim() === '') {
      setNewGroupNameError('테이블 그룹명을 입력해주세요.');
      return;
    }
    let findGroup = tableGroups.find((group) => group.groupId === targetGroup.groupId);
    const { status } = await devServerApi.api.patch('/settings/groups/' + findGroup.groupId, { name: newGroupName }).then((res) => res.data);
    if (status <= 0) return;
    findGroup.groupName = newGroupName;
    commitTableGroupsChange();
    setMenuModal({ visible: false, key: null });
  };

  const openChangeGroupNameModal = (data: ITablesGroup) => {
    setTargetGroup(data);
    setNewGroupName(data.groupName);
    setMenuModal({ visible: true, key: 'change-group-name' });
  };

  const openTableAddModal = (data: ITablesGroup) => {
    setNewTable({ enabled: true, groupId: data.groupId, _id: null, peopleMax: 4, peopleMin: 2, name: '' });
    setTargetGroup(data);
    setMenuModal({ visible: true, key: 'make-new-table' });
  };

  const openTableGroupRemoveModal = (data: ITablesGroup) => {
    if (data.item.length > 0) {
      setAlertModal({ visible: false, key: null });
      setAlertModal({ visible: true, key: 'reservation-leftover' });
      return;
    }
    setTargetGroup(data);
    setAlertModal({ visible: true, key: 'remove-group' });
  };

  const openTableSettingModal = (data: ITablesTable) => {
    setNewTable(data);
    setTableNameLengthError(null);
    setMenuModal({ visible: true, key: 'setting-table' });
  };

  const setEnable = (data: ITablesTable) => {
    if (data.enabled) {
      setToastModal({ visible: true, key: 'enable' });
    } else {
      setToastModal({ visible: true, key: 'disable1' });
      setTimeout(() => {
        setToastModal({ visible: true, key: 'disable2' });
      }, 1500);
    }
    devServerApi.api.patch('/settings/tables/' + data._id, data).then((res) => res.data);
    tableGroups.find((group) => group.groupId === data.groupId).item.find((item) => item._id === data._id).enabled = data.enabled;
  };

  const removeGroup = async () => {
    setTableGroups(tableGroups.filter((group) => group.groupId !== targetGroup.groupId));
    const { status } = await devServerApi.api.delete('/settings/tablegroups/' + targetGroup.groupId).then((res) => res.data);
    if (status <= 0) return;
    setAlertModal({ visible: false, key: null });
  };

  const removeGroupCheck = () => {
    setAlertModal(false);
    removeGroup();
  };

  const groupChange = async (sourceGroupId, targetGroupId, tableId) => {
    const { status } = await devServerApi.api.patch(`/settings/tables/${tableId}/changeGroup`, { sourceGroupId, targetGroupId }).then((res) => res.data);
    if (status <= 0) return false;
    const sourceGroupIdx = tableGroups.findIndex((group) => group.groupId === sourceGroupId);
    const targetGroupIdx = tableGroups.findIndex((group) => group.groupId === targetGroupId);
    const tableIdx = tableGroups[sourceGroupIdx].item.findIndex((table) => table._id === tableId);
    const table = tableGroups[sourceGroupIdx].item.splice(tableIdx, 1)[0];
    table.groupId = targetGroupId;
    tableGroups[targetGroupIdx].item.push(table);
    commitTableGroupsChange();
    return true;
  };

  const setNewTableInfo = async (action, value) => {
    const newState = { ...newTable };
    const exceptions = ['@', '!'];

    switch (action) {
      case 'MIN_PERSON_CHANGE':
        newState.peopleMin += value;
        if (newState.peopleMin >= newState.peopleMax) {
          newState.peopleMin = newState.peopleMax;
        }
        if (newState.peopleMin <= 0) {
          newState.peopleMin = 1;
        }
        break;
      case 'MAX_PERSON_CHANGE':
        newState.peopleMax += value;
        if (newState.peopleMax < newState.peopleMin) {
          newState.peopleMax = newState.peopleMin;
        }
        break;
      case 'NAME_CHANGE':
        if (!value.trim().length) setTableNameLengthError('테이블 이름을 입력하세요.');
        else {
          if (checkSpecial(value)) setTableNameLengthError('올바르지 못한 테이블명입니다.');
          else setTableNameLengthError(null);
        }
        newState.name = value;
        break;
      case 'GROUP_CHANGE':
        if (await groupChange(newState.groupId, value, newState._id)) newState.groupId = value;
        break;
      default:
        throw new Error('Unexpected type');
    }
    setNewTable(newState);
  };

  const makeNewTable = async () => {
    let { name, peopleMin, peopleMax, groupId } = newTable;
    if (!name.length) {
      setTableNameLengthError('테이블 이름을 입력하세요.');
      return;
    }

    const { status, list } = await devServerApi.api.post('/settings/tables/' + groupId, { name, peopleMin, peopleMax, enabled: true }).then((res) => res.data);
    if (status <= 0) return;
    let findGroup = tableGroups.find((group) => group.groupId === newTable.groupId);
    let targetTable = list.find((group) => group.groupId === newTable.groupId).item.pop();
    if (findGroup) {
      findGroup.item.push(targetTable);
      commitTableGroupsChange();
    }
    setMenuModal({ visible: false, key: null });
  };

  const settingTable = async () => {
    let { name, peopleMin, peopleMax, groupId, _id, enabled } = newTable;
    if (!name.length) {
      setTableNameLengthError('테이블 이름을 입력하세요.');
      return;
    }
    if (checkSpecial(name)) {
      setTableNameLengthError('올바르지 못한 테이블명입니다.');
      return;
    }
    let findGroup = tableGroups.find((group) => group.groupId === newTable.groupId);
    if (findGroup) {
      Object.assign(findGroup.item[findGroup.item.findIndex((item) => _id === item._id)], newTable);
      commitTableGroupsChange();
    }
    setMenuModal({ visible: false, key: null });
    const { status } = await devServerApi.api.patch('/settings/tables/' + _id, { name, peopleMin, peopleMax, enabled }).then((res) => res.data);
  };

  useEffect(() => {
    const page = document.querySelector('.main-container-desktop');
    const heads = Array.from(document.querySelectorAll<HTMLElement>('.table-group-header'));
    const onScroll = throttle(() => {
      heads.forEach((head) => {
        head.toggleAttribute('stuck', head.getBoundingClientRect().y <= 56);
      });
    }, 100);
    page.addEventListener('scroll', onScroll);
    return () => {
      page.removeEventListener('scroll', onScroll);
    };
  }, [tableGroups]);

  function cursorToEnd(e) {
    const maxLength = 1000;
    setTimeout(() => {
      const target = e.target;
      try {
        if (target.setSelectionRange) {
          target.setSelectionRange(maxLength, maxLength);
        } else if (target.selectionStart && target.selectionEnd) {
          target.selectionStart = maxLength;
          target.selectionEnd = maxLength;
        }
      } catch (err){/**/}
    }, 100);
  }

  return (
    <>
      <HeaderBack isFixed onBack={() => edit(false)}>
        <div className="font__subtitle">테이블 편집</div>
      </HeaderBack>
      <div className="table-edit-wrapper">
        <div className="table-group-list-wrapper">
          {tableGroups
            ? tableGroups.map((group) => (
                <TableGroup
                  key={group.groupId}
                  tableGroupData={group}
                  openChangeGroupNameModal={openChangeGroupNameModal}
                  openTableAddModal={openTableAddModal}
                  openTableGroupRemoveModal={openTableGroupRemoveModal}
                  openTableSettingModal={openTableSettingModal}
                  setEnable={setEnable}
                />
              ))
            : null}
        </div>
        <button className={classnames('addTableGroup')} onClick={() => openGroupAddModal()}>
          <GroupAdd />
          그룹 추가
        </button>
        <ModalPortal>
          <Modal.Menu key="add-group" modal="menu" isDim={true} isAnimation={true} isClose={false}>
            <div className="add-group-modal-wrapper">
              <div className="add-group-modal-header">새 그룹 이름을 정해주세요.</div>
              <Input name="text-basic" type="text" placeholder="그룹 이름" error={newGroupNameError} onChange={(e) => setNewGroupName(e.target.value)} />
            </div>
            <FooterSub isVisible isBorder isBackArea>
              <button className="tblm-button-normal tblm-btn-primary w-full" onClick={makeGroup}>
                완료
              </button>
              <button className="tblm-button-normal tblm-btn-gray w-full" onClick={() => setMenuModal({ visible: false, key: null })}>
                취소
              </button>
            </FooterSub>
          </Modal.Menu>
          <Modal.Menu key="change-group-name" modal="menu" isDim={true} isAnimation={true} isClose={false}>
            <div className="add-group-modal-wrapper">
              <div className="add-group-modal-header">그룹 이름을 어떻게 바꿀까요?</div>
              <Input
                name="text-basic"
                type="text"
                placeholder="그룹 이름"
                value={newGroupName}
                error={newGroupNameError}
                onFocus={(e)=>cursorToEnd(e)}
                onChange={(e) => setNewGroupName(e.target.value)}
              />
            </div>
            <FooterSub isVisible isBorder isBackArea>
              <button className="tblm-button-normal tblm-btn-primary w-full" onClick={changeGroupName}>
                완료
              </button>
            </FooterSub>
          </Modal.Menu>
          <Modal.Alert key="remove-group" modal="alert" isAnimation={true} isDim={true}>
            <div className="title font__subtitle">그룹을 정말 삭제할까요?</div>
            <div className="content font__body_sm">삭제한 그룹은 다시 복구할 수 없어요.</div>
            <div className="footer success font__body_sm_sb" onClick={removeGroupCheck}>
              삭제
            </div>
          </Modal.Alert>
          <Modal.Alert key="reservation-leftover" modal="alert" isAnimation={true} isDim={true}>
            <div className="title font__subtitle">테이블이 남아 있는 그룹은 삭제할 수 없어요.</div>
            <div className="content font__body_sm">테이블 이동 후 다시 시도해주세요.</div>
            <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: null })}>
              확인
            </div>
          </Modal.Alert>
          <Modal.Menu key="make-new-table" modal="menu" isDim={true} isAnimation={true} isClose={false}>
            <div className="make-new-table-header font__subtitle">새 테이블을 세팅해주세요.</div>
            <div className="new-table-info-wrapper">
              <Input
                name="text-basic"
                type="text"
                placeholder="테이블 이름"
                value={newTable.name}
                error={tableNameLengthError}
                onFocus={(e)=>cursorToEnd(e)}
                onChange={(e) => setNewTableInfo('NAME_CHANGE', e.target.value)}
                className="table-name-input"
              />
              <div className="people-selector">
                <div className="people-selector-label font__body_sm">최소 인원</div>
                <div className="people-icon-box">
                  <div className="people-minus sign-icon" onClick={() => setNewTableInfo('MIN_PERSON_CHANGE', -1)}>
                    <Minus />
                  </div>
                  <div className="now-people font__body_sm_sb">{newTable.peopleMin}명</div>
                  <div className="people-plus sign-icon" onClick={() => setNewTableInfo('MIN_PERSON_CHANGE', 1)}>
                    <Plus />
                  </div>
                </div>
              </div>
              <div className="people-selector">
                <div className="people-selector-label font__body_sm">최대 인원</div>
                <div className="people-icon-box">
                  <div className="people-minus sign-icon" onClick={() => setNewTableInfo('MAX_PERSON_CHANGE', -1)}>
                    <Minus />
                  </div>
                  <div className="now-people font__body_sm_sb">{newTable.peopleMax}명</div>
                  <div className="people-plus sign-icon" onClick={() => setNewTableInfo('MAX_PERSON_CHANGE', 1)}>
                    <Plus />
                  </div>
                </div>
              </div>
            </div>
            <FooterSub isVisible isBorder isBackArea>
              <button
                className="tblm-button-normal w-full"
                onClick={() => {
                  setMenuModal({ visible: false, key: null });
                }}
              >
                취소
              </button>
              <button className="tblm-button-normal tblm-btn-primary w-full" onClick={makeNewTable}>
                완료
              </button>
            </FooterSub>
          </Modal.Menu>
          <Modal.Menu key="setting-table" modal="menu" isDim={true} isAnimation={true} isClose={false}>
            <div className="make-new-table-header font__subtitle">{newTable.groupId ? '테이블 설정을 어떻게 변경할까요?' : '새 테이블을 세팅해주세요.'}</div>
            <div className="new-table-info-wrapper">
              <Input
                name="text-basic"
                type="text"
                placeholder="테이블 이름"
                value={newTable.name}
                error={tableNameLengthError}
                onFocus={(e)=>cursorToEnd(e)}
                onChange={(e) => setNewTableInfo('NAME_CHANGE', e.target.value)}
                className="table-name-input"
              />
              <div className="people-selector">
                <div className="people-selector-label font__body_sm">최소 인원</div>
                <div className="people-icon-box">
                  <div className="people-minus sign-icon" onClick={() => setNewTableInfo('MIN_PERSON_CHANGE', -1)}>
                    <Minus />
                  </div>
                  <div className="now-people font__body_sm_sb">{newTable.peopleMin}명</div>
                  <div className="people-plus sign-icon" onClick={() => setNewTableInfo('MIN_PERSON_CHANGE', 1)}>
                    <Plus />
                  </div>
                </div>
              </div>
              <div className="people-selector">
                <div className="people-selector-label font__body_sm">최대 인원</div>
                <div className="people-icon-box">
                  <div className="people-minus sign-icon" onClick={() => setNewTableInfo('MAX_PERSON_CHANGE', -1)}>
                    <Minus />
                  </div>
                  <div className="now-people font__body_sm_sb">{newTable.peopleMax}명</div>
                  <div className="people-plus sign-icon" onClick={() => setNewTableInfo('MAX_PERSON_CHANGE', 1)}>
                    <Plus />
                  </div>
                </div>
              </div>
              {newTable.groupId ? (
                <div className="group-selector">
                  <div className="label font__small_sb">그룹 이동하기</div>
                  <Select
                    onClick={() => setMenuModal({ key: 'group-picker', visible: true })}
                    value={(() => {
                      const selected = tableGroups.find((group) => newTable.groupId === group.groupId);
                      return selected?.groupName;
                    })()}
                  />
                </div>
              ) : null}
            </div>
            <FooterSub isVisible isBorder isBackArea>
              <button
                className="tblm-button-normal w-full"
                onClick={() => {
                  setMenuModal({ visible: false, key: null });
                }}
              >
                취소
              </button>
              <button className="tblm-button-normal tblm-btn-primary w-full" onClick={settingTable}>
                완료
              </button>
            </FooterSub>
          </Modal.Menu>
          <Modal.Menu
            key="group-picker"
            modal="menu"
            isDim={true}
            isAnimation={true}
            onClose={() => {
              setMenuModal({ visible: true, key: 'group-picker' });
              setMenuModal({ visible: true, key: 'setting-table' });
            }}
          >
            <div className="picker-body modal-content">
              <div className="picker-title">어느 그룹으로 보낼까요?</div>
              <ScrollPicker
                optionGroups={{ 0: { list: tableGroups.map((group) => `${group.groupId}|${group.groupName}`), unit: 'split' } }}
                valueGroups={{ 0: tableGroups.filter(({ groupId }) => groupId === selectedGroup).map((group) => `${group.groupId}|${group.groupName}`)[0] }}
                onChange={(_, g) => {
                  setSelectedGroup(g.split('|')[0]);
                }}
              />
            </div>
            <div className="picker-footer">
              <button
                className="set-button tblm-button-normal tblm-btn-primary"
                onClick={() => {
                  setNewTableInfo('GROUP_CHANGE', selectedGroup);
                  setMenuModal({ visible: false, key: 'group-picker' });
                  setMenuModal({ visible: false, key: 'make-new-table' });
                  setMenuModal({ visible: true, key: 'setting-table' });
                }}
              >
                이동
              </button>
            </div>
          </Modal.Menu>
          <Modal.Toast key="disable1" modal="toast" autoHideDuration={1000} isAnimation={true} position="bottom">
            <div className="font__small_sb" style={{ color: '#ffffff' }}>
              테이블이 비활성화 되었습니다.
            </div>
          </Modal.Toast>
          <Modal.Toast key="disable2" modal="toast" autoHideDuration={1000} isAnimation={true} position="bottom">
            <div className="font__small_sb" style={{ color: '#ffffff' }}>
              비활성화해도 기존 예약은 이 테이블에 유지됩니다.
            </div>
          </Modal.Toast>
          <Modal.Toast key="enable" modal="toast" autoHideDuration={1000} isAnimation={true} position="bottom">
            <div className="font__small_sb" style={{ color: '#ffffff' }}>
              테이블이 복구 되었습니다.
            </div>
          </Modal.Toast>
        </ModalPortal>
      </div>
    </>
  );
}

export default TableEdit;
