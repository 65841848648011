import React, { useState } from 'react';
import { useQuery } from 'react-query';
import '@/styles/pages/settings/information.scss';
import { useNavigate } from 'react-router-dom';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import Facilities from '@/components/Settings/Facilities';
import { devServerApi } from '@/shared/apis/devServerApi';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import { MallSetting, MallSettingInfoOpenTimeDate } from '@/shared/utils/common.type';
import { useAuthStore } from '@/stores/common/useAuthStore';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import Terces from '@/shared/apis/terces';

function Information() {
  const { accessToken } = useAuthStore();
  const navigate = useNavigate();
  // @ts-ignore
  const { data, isFetching } = useQuery([QUERY_KEYS.SETTINGS_MALL], () => devServerApi.authGet<MallSetting>('/settings/mall', accessToken).then((enc_res)=>Terces.decrypt(enc_res)), {
    cacheTime: 0,
  });
  const mallSetting: Partial<MallSetting> = isFetching ? {} : data;

  const timeMaker = (time: MallSettingInfoOpenTimeDate) => {
    return `${time.hour < 12 ? '오전' : '오후'} ${String(time.hour % 12).padStart(2, '0')}:${String(time.minute).padStart(2, '0')}`;
  };

  const verifyValueExist = (value) => {
    const validPureValue = (pure) => {
      if (mallSetting.eid === value && value) return pure.replaceAll('-', '');
      return pure;
    };

    return <div className={`font__subtitle1_sb ${validPureValue(value)?.length ? '' : 'value-not-exist'}`}>{validPureValue(value)?.length ? value : '입력 안 함'}</div>;
  };

  if (isFetching) {
    return <SpinnerEffector loading={isFetching} />;
  }

  return (
    <div className="information-wrapper">
      <HeaderBack isFixed>
        <div className="font__subtitle">기본정보</div>
      </HeaderBack>
      <section className="information-body">
        <div>
          <div className="font__body_sm">매장명</div>
          <div className="font__subtitle1_sb">{mallSetting.mallName}</div>
        </div>
        <div>
          <div className="font__body_sm">업종명</div>
          <div className="font__subtitle1_sb">요식업</div>
        </div>
        <div>
          <div className="font__body_sm">음식종류</div>
          <div className={'font__subtitle1_sb'}>{mallSetting.foods?.map(({ name }) => name).join(', ')}</div>
        </div>
        <div>
          <div className="font__body_sm">사업자번호</div>
          {verifyValueExist(mallSetting.eid)}
        </div>
        <div>
          <div className="font__body_sm">매장 대표번호</div>
          <div className="font__subtitle1_sb">{mallSetting.mainPhone}</div>
        </div>
        <div>
          <div className="font__body_sm">매장 주소</div>
          <div className="font__subtitle1_sb">{mallSetting.address?.road}</div>
        </div>
        <div>
          <div className="font__body_sm">대표자 이름</div>
          {verifyValueExist(mallSetting.representative)}
        </div>
        <div>
          <div className="font__body_sm">대표자 번호</div>
          <div className="font__subtitle1_sb">{verifyValueExist(mallSetting.representativePhone)}</div>
        </div>
        {/*<div>
          <div className="font__body_sm">영업시간</div>
          <div className="font__subtitle1_sb">
            {mallSetting.infoOpenTime?.map((openTime, idx) => (
              <div key={openTime.type + openTime.week + idx}>
                {openTime.week === '매일' ? '평일' : openTime.week}{' '}
                {openTime.type === '휴무' ? (
                  '휴무'
                ) : (
                  <>
                    {timeMaker(openTime.date_start)}~{timeMaker(openTime.date_end)}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>*/}
        {/*<div>
          <div className="font__body_sm">브레이크 타임</div>
          <div className="font__subtitle1_sb">{mallSetting.breakTime}</div>
        </div>*/}
        {/*<div>
          <div className="font__body_sm">주차정보</div>
          <div className="font__subtitle1_sb">{mallSetting.parkingInfo}</div>
        </div>*/}
        <div className="flex-col items-start">
          <div className="font__body_sm w-full">편의시설 정보</div>
          {/* <div className="font__subtitle1_sb nomargin"> */}
          {mallSetting.facility ? (
            <Facilities hidden={false} edit={false} facilitiesList={mallSetting.facility?.map((e) => ({ checked: true, ...e }))} setFacilitiesList={() => {}} />
          ) : (
            '작성 안 함'
          )}
          {/* </div> */}
        </div>
        <div>
          <div className="font__body_sm">매장 설명</div>
          {verifyValueExist(mallSetting.mallDescription)}
        </div>
      </section>
      <footer>
        <button className="tblm-button-normal tblm-btn-primary" onClick={() => navigate('/setting/essential/edit')}>
          편집
        </button>
      </footer>
    </div>
  );
}

export default Information;
