import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Agreement from '@/components/Agreement/Agreement';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';

const AgreementSetting = ({ isComponent, onYes }: {isComponent?: boolean; onYes?: ()=>void}): React.ReactElement => {
  const navigate = useNavigate();
  const { accessToken } = useAuthStore();
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
  const [showAgreement, setShowagreement] = useState(true);
  const [showAgreementPortal, setShowagreementPortal] = useState(true);
  const close = (yn) => {
    setShowagreement(false);
    setTimeout(() => {
      setShowagreementPortal(false);
      if (!isComponent) {
        if (yn) {
          setTimeout(() => {
            if (onYes) onYes();
            else {
              if (window.NavigateReplacePage) window.NavigateReplacePage.postMessage('/tutorial/essential');
              else {
                navigate('/tutorial/essential', { replace: true });
              }
            }
          }, 0);
        } else {
          if (window.ExitPage) window.ExitPage.postMessage('');
          else navigate(-1);
        }
      }
    }, 0);
  };
  return (<>
    {showAgreementPortal ? <ModalPortal>{showAgreement ? <Agreement onClose={close}/> : null}</ModalPortal> : null}
  </>
  );
};

export default AgreementSetting;
