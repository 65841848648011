// import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import './CustomerAdditionalInformation.scss';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { ControlledInput } from '@/hook-form/ControlledInput';
import { ControlledRadioBox } from '@/hook-form/ControlledRadioBox';
import { ControlledToggle } from '@/hook-form/ControlledToggle';
import { LocalStorage } from '@/shared/utils/storage';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import Line from '../Line/Line';
import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import Select from '../Select/Select';
import dayjs from 'dayjs';
import { instantDisabler } from '@/shared/lib/functions';
import FooterSub from '../FooterSub/FooterSub';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useMutation, useQuery } from 'react-query';
import SpinnerEffector from '../Spinner/SpinnerEffector';
import { IMutationProps } from '@/shared/utils/common.type';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';

// alarm: 당일 예약 알림
// creation: 예약 메시지 알림

function CustomerAdditionalInformation() {
  const { setAlertModal, setMenuModal } = useModalStore();
  const { setUserInfo, userInfo, reservationInfo } = useReservationStore();
  const { accessToken } = useAuthStore();

  const { mall } = useMallStore();

  const methods = useFormContext();
  const { control, setValue, getValues, register } = methods;

  const { data: creationMessageData, isFetching: isCreationFetching } = useQuery(['create'], () => devServerApi.authGet<any>('/messages/types/create/labels', accessToken));

  const { data: alarmMessageData, isFetching: isAlarmFetching } = useQuery(['alarm'], () => devServerApi.authGet<any>('/messages/types/alarm/labels', accessToken));

  const { mutate: sendCreationMutate, isLoading: isCreationSendMutateLoading } = useMutation((mutatedata: IMutationProps) => devServerApi.mutation(mutatedata), {
    onMutate: () => ({
      key: [QUERY_KEYS.SEND_CREATION_MESSAGE],
    }),
    onSuccess: (data, variables, context) => {
      setUserInfo({ ...userInfo, creationMessageTemplate: variables.data.message.type, isCreationMessageSent: true, creationMessageSentAt: dayjs() });
      setAlertModal({ visible: false, key: 'creationMessageAlert' });
    },
  });

  const { mutate: cancelAlarmMutate, isLoading: isCancelAlarmMutateLoading } = useMutation((mutatedata: IMutationProps) => devServerApi.mutation(mutatedata), {
    onMutate: () => ({
      key: [QUERY_KEYS.CANCEL_ALARM_MESSAGE],
    }),
    onSuccess: (data, variables, context) => {
      setUserInfo({ ...userInfo, alarmMessageTemplate: undefined, alarmMessageState: 'cancel', alarmMessageWillBeSentAt: null });
      setAlertModal({ visible: false, key: 'cancelMessage' });
    },
  });

  const { mutate: sendAlarmMutate, isLoading: isSendAlarmMutateLoading } = useMutation((mutatedata: IMutationProps) => devServerApi.mutation(mutatedata), {
    onMutate: () => ({
      key: [QUERY_KEYS.SEND_ALARM_MESSAGE],
    }),
    onSuccess: (data, variables, context) => {
      setUserInfo({ ...userInfo, alarmMessageTemplate: variables.data.message.type, alarmMessageWillBeSentAt: null, alarmMessageSentAt: dayjs() });
      setAlertModal({ visible: false, key: 'alarmMessageAlert' });
      setAlertModal({ visible: false, key: 'alarmMessageTemplate' });
    },
  });

  const onClickCreationMessage = (type) => {
    return sendCreationMutate({
      operation: 'post',
      url: `/reservations/${reservationInfo.reservationId}/messages/guide`,
      data: { message: { type } },
    });
  };

  const onClickCancelAlarmMassage = () => {
    return cancelAlarmMutate({
      operation: 'patch',
      url: `/reservations/${reservationInfo.reservationId}/messages/reminder`,
      data: { message: { status: 'CANCELLED' } },
    });
  };

  const onClickNowSendAlarmMassage = (type) => {
    return sendAlarmMutate({
      operation: 'post',
      url: `/reservations/${reservationInfo.reservationId}/messages/reminder`,
      data: { message: { type } },
    });
  };

  const getAlarmMessageState = () => {
    if (userInfo.alarmMessageWillBeSentAt) return '전송 예약';
    if (userInfo.alarmMessageSentAt) return '전송 예약';
    return '미전송';
  };


  useEffect(() => {
    setValue(
      'userExtravalues',
      mall.userExtravalues.map((item, i) => {
        const extraValue = getValues().userExtravalues.find((extra) => extra.id === item.id);
        return { ...item, value: extraValue?.value };
      })
    );
  }, []);

  const isMultipleMessageTemplate = (templateType: string) => {
    if (templateType === 'creation') return creationMessageData?.labels.length && creationMessageData.labels.length > 1;
    else if (templateType === 'alarm') return alarmMessageData?.labels.length && alarmMessageData.labels.length > 1;
  };

  if (isCreationFetching || isAlarmFetching) return <SpinnerEffector loading />;

  return (
    <div className="customeradditionalinformation-wrapper" data-scrolled="2">
      <div className="customeradditional-title">고객 추가정보</div>
      <div className="customeradditionalinformation-body">
        <div>
          <div>고객 별칭</div>
          <ControlledInput
            disabled={reservationInfo.reservationState === 'canceled'}
            name="userNickname"
            control={control}
            placeholder={reservationInfo.reservationState === 'canceled' ? '' : '별칭을 입력해주세요'}
          />
        </div>
        <div>
          <div>고객 메모</div>
          <ControlledInput
            disabled={reservationInfo.reservationState === 'canceled'}
            name="userMemo"
            control={control}
            placeholder={reservationInfo.reservationState === 'canceled' ? '' : '메모 입력해주세요'}
          />
        </div>
        <div>
          <div>고객 이메일</div>
          <ControlledInput
            disabled={reservationInfo.reservationState === 'canceled'}
            name="userEmail"
            control={control}
            placeholder={reservationInfo.reservationState === 'canceled' ? '' : '이메일을 입력해주세요'}
          />
        </div>

        {getValues('userExtravalues').map((item: any, i) => {
          return (
            <div key={item.id}>
              <div className="font__body_sm">{item.name}</div>
              {item.type === 'string' || item.type === 'text' || item.type === 'textarea' || item.type === 'user' || item.type === 'longtext' ? (
                <ControlledInput name={`userExtravalues.${i}.value`} id={item.id} control={control} placeholder={`${item.name} 입력`} />
              ) : null}
              {item.type === 'toggle' ? <ControlledToggle name={`userExtravalues.${i}.value`} control={control} defaultChecked={Boolean(item.value)} /> : null}
              {item.type === 'select' || item.type === 'multiselect' ? (
                <>
                  <Select
                    onClick={() => {
                      setMenuModal({ visible: true, key: item.id });
                    }}
                    value={getValues(`userExtravalues.${i}.value`)}
                  />
                  <ModalPortal>
                    <Modal.Menu key={item.id} modal="menu" isDim={true} isAnimation={true}>
                      <div className="user-info-menu-modal-wrapper">
                        <div className="title font__subtitle">{item.name}</div>

                        {item.type === 'multiselect' ? (
                          <div className="content custom-check-wrapper">
                            {item.choices.map((subItem, j) => (
                              <div className="custom-check-item" key={`${item}_${j}`}>
                                <label className="tblm-rc">
                                  <input type="checkbox" value={subItem} {...register(`userExtravalues.${i}.value`)} />
                                  <i />
                                  <span>{subItem}</span>
                                </label>
                              </div>
                            ))}
                          </div>
                        ) : null}

                        {item.type === 'select' ? (
                          <ControlledRadioBox
                            name={`userExtravalues.${i}.value`}
                            options={item.choices.map((subItem) => ({ label: subItem, value: subItem }))}
                            control={control}
                            defaultChecked={getValues(`userExtravalues.${i}.value`)}
                            onChange={() => setMenuModal({ visible: false, key: item.id })}
                          />
                        ) : null}
                      </div>
                      {item.type === 'multiselect' ? (
                        <div className="footer is-border">
                          <button className="tblm-button-normal tblm-btn-primary" onClick={() => setMenuModal({ visible: false, key: item.id })}>
                            확인
                          </button>
                        </div>
                      ) : null}
                    </Modal.Menu>
                  </ModalPortal>
                </>
              ) : null}
            </div>
          );
        })}
      </div>
      <Line />
      {
        <div className="reservation-message-box">
          <div className="reservation-message">
            <div>예약 안내 메시지 전송</div>
            <div>
              {/* 예약이 생성된 시점에 발송되는 메시지
              전송 완료, 미완료는 서버에서 보낸 시간을 보고 판단.
              */}
              <div>{userInfo.isCreationMessageSent ? '전송 완료' : '미전송'}</div>
              <button
                className="tblm-button-small"
                onClick={() =>
                  isMultipleMessageTemplate('creation')
                    ? setMenuModal({ visible: true, key: 'creationMessageTemplate' })
                    : setAlertModal({ visible: true, key: 'creationMessageAlert' })
                }
              >
                {userInfo.isCreationMessageSent ? '재전송' : '전송'}
              </button>
            </div>
          </div>
          <Line />
          <div className="reservation-message">
            <div>당일 알림 메시지 전송</div>
            <div>
              <div>{getAlarmMessageState()}</div>
              {/* 미전송 / 전송 예약 / 전송 완료 / 전송 예약 없음 */}
              <div className="button-wrapper">
                {
                  userInfo.alarmMessageWillBeSentAt ?
                    <button className="tblm-button-small" onClick={() => setAlertModal({ visible: true, key: 'cancelMessage' })}>
                      전송 예약 취소
                    </button> : null
                }
                <button
                  className="tblm-button-small"
                  onClick={() =>
                    isMultipleMessageTemplate('alarm') ? setMenuModal({ visible: true, key: 'alarmMessageTemplate' }) : setAlertModal({ visible: true, key: 'alarmMessageAlert' })
                  }
                >
                  {userInfo.isAlarmMessageSent ? '재전송' : '전송'}
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      {/* 당일알림메시지 - 1개 */}
      <ModalPortal>
        <Modal.Alert key="alarmMessageAlert" classKey="reservationInformationMassage" modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">당일알림 메시지를 지금 {userInfo.isAlarmMessageSent ? '재' : ''}전송하시겠습니까?</div>
          <div className="content font__body_sm">
            예약된 메시지가 당일이 아닌 지금 바로 {userInfo.isAlarmMessageSent ? '재' : ''}전송됩니다.
            {userInfo.alarmMessageSentAt ? <b>이전 발송일시: {dayjs(userInfo.alarmMessageSentAt).format('YYYY/MM/DD (dddd) a hh:mm')}</b> : null}
          </div>
          <div className="footer success font__body_sm_sb" onClick={instantDisabler(() => {
            onClickNowSendAlarmMassage(alarmMessageData.labels[0]);
          })}>
            {userInfo.isAlarmMessageSent ? '재' : ''}전송
          </div>
        </Modal.Alert>
      </ModalPortal>
      {/* 당일랄림 전송예약 취소 */}
      <ModalPortal>
        <Modal.Alert key="cancelMessage" classKey="reservationInformationMassage" modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">당일알림 메시지를 전송 예약을 취소하시겠습니까?</div>
          <div className="content">예약 당일에 알림 메시지가 발송되지 않습니다.</div>
          <div className="footer success font__body_sm_sb" onClick={instantDisabler(() => {
            onClickCancelAlarmMassage();
          })}>
            전송 취소
          </div>
        </Modal.Alert>
      </ModalPortal>
      {/* 예약알림메시지 - 1개 */}
      <ModalPortal>
        <Modal.Alert key="creationMessageAlert" classKey="reservationInformationMassage" modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">메시지를 지금 {userInfo.isCreationMessageSent ? '재' : ''}전송 하시겠습니까?</div>
          <div className="content font__body_sm">
            예약안내 메시지가 지금 바로 {userInfo.isCreationMessageSent ? '재' : ''}발송됩니다.
            {/* 시간이 있으면 보여줄 수 있게 */}
            {userInfo.isCreationMessageSent ? (
              <b>이전 전송일시: {userInfo.creationMessageSentAt ? dayjs(userInfo.creationMessageSentAt).format('YYYY/MM/DD (dddd) a hh:mm') : null}</b>
            ) : null}
          </div>
          {/* 재 전송 AP받아야 함 */}
          <div className="footer success font__body_sm_sb" onClick={instantDisabler(() => {
            onClickCreationMessage(creationMessageData.labels[0]);
          })}>
            {userInfo.isCreationMessageSent ? '재' : ''}전송
          </div>
        </Modal.Alert>
      </ModalPortal>
      {/* 예약안내메시지 - n개 */}
      <ModalPortal>
        <Modal.Menu key="creationMessageTemplate" modal="menu" isDim={true} isAnimation={true}>
          <div className="title font__subtitle no-size">예약안내 메시지 전송</div>
          <ControlledRadioBox
            name="creationMessageTemplate"
            options={creationMessageData?.labels.map((item) => ({ label: item, value: item }))}
            control={control}
            onChange={(e) => {
              setUserInfo({ ...userInfo, creationMessageTemplate: e.target.value });
              setMenuModal({ visible: false, key: 'creationMessageTemplate' });
            }}
            defaultChecked={userInfo.creationMessageTemplate}
          />
          <FooterSub isVisible isBorder isBackArea>
            <button className="tblm-button-normal tblm-btn-gray w-full" onClick={() => setMenuModal({ visible: false, key: null })}>
              취소
            </button>
            <button className="tblm-button-normal tblm-btn-primary w-full" onClick={instantDisabler(() => {
              onClickCreationMessage(userInfo.creationMessageTemplate);
            })}>
              전송
            </button>
          </FooterSub>
        </Modal.Menu>
      </ModalPortal>
      {/* 당일알림메시지 - n개 */}
      <ModalPortal>
        <Modal.Menu key="alarmMessageTemplate" modal="menu" isDim={true} isAnimation={true}>
          <div className="user-info-menu-modal-wrapper">
            <div className="title font__subtitle">당일알림 메시지 전송</div>
            <ControlledRadioBox
              name="alarmMessageTemplate"
              options={alarmMessageData?.labels.map((item) => ({ label: item, value: item }))}
              control={control}
              onChange={(e) => {
                setUserInfo({ ...userInfo, alarmMessageTemplate: e.target.value });
                setMenuModal({ visible: false, key: 'alarmMessageTemplate' });
              }}
              defaultChecked={userInfo.alarmMessageTemplate}
            />
            <FooterSub isVisible isBorder isBackArea>
              <button className="tblm-button-normal tblm-btn-gray w-full" onClick={() => setMenuModal({ visible: false, key: null })}>
                취소
              </button>
              <button className="tblm-button-normal tblm-btn-primary w-full" onClick={instantDisabler(() => {
                onClickNowSendAlarmMassage(userInfo.alarmMessageTemplate);
              })}>
                전송
              </button>
            </FooterSub>
          </div>
        </Modal.Menu>
      </ModalPortal>
    </div>
  );
}

export default CustomerAdditionalInformation;
