import React, { useEffect } from 'react';

import AGREEMENT_LIST from '@/components/Agreement/Agreement.constants';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import classnames from 'classnames';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useNavigate } from 'react-router-dom';
import { useReservationSettingStore } from '@/stores/common/useReservationSettingStore';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import { useTableSettingStore } from '@/stores/common/useTableSettingStore';
import { instantDisabler } from '@/shared/lib/functions';
import { usePartnerStore } from '@/stores/common/usePartnerStore';

const TutorialIndexPage = (): React.ReactElement => {
  const { accessToken } = useAuthStore();
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
  const { state, setState, agreements, setAgreements } = useSettingTutorialStore();
  const { appName } = usePartnerStore();
  const navigate = useNavigate();

  const tableReset = useTableSettingStore((s) => s.resetStorageQuestion);
  const reservationReset = useReservationSettingStore((s) => s.resetStorageQuestion);

  // @ts-ignore
  // const { isLoading: isLoadingA, error: errorA, data: dataA } = useQuery(['TA'], ()=>devServerApi.authGet('/terms-agree', accessToken));
  // @ts-ignore
  // const { isLoading: isLoadingT, error: errorT, data: dataT } = useQuery(['TT'], ()=>devServerApi.authGet('/tutorial', accessToken));

  const no = () => {
    if (window.ExitPage) window.ExitPage.postMessage('');
    else navigate(-1);
  };
  const unProceed = (withApi?: boolean) => {
    if (withApi === true) {
      devServerApi.api.patch('/tutorial', { status: 'done' });
    }
    setTimeout(() => {
      tableReset();
      reservationReset();
    }, 0);
    no();
  };
  const start = () => {
    if (state === 'done') {
      if (window.ExitPage) window.ExitPage.postMessage('');
      else navigate('/reservation/list');
      return;
    }
    navigate('/tutorial/' + (['table', 'reservation', 'essential', 'done'].includes(state) ? state : 'essential'));
  };

  useEffect(() => {
    if (agreements && state && state !== 'done') {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = 'auto';
    }
    if (agreements && state === 'done') {
      unProceed();
    }
  }, [state, agreements]);

  useEffect(() => {

    if (appName === 'kt') {
      devServerApi
        .authGet('/terms-agree', accessToken)
        .then((dataA) => {
          // @ts-ignore
          const { response } = dataA;
          let essentialAgreementsList = AGREEMENT_LIST.filter((a) => a.isEssential).map((a) => a.name);
          for (let a of response) {
            if (a.isAgree) {
              essentialAgreementsList = essentialAgreementsList.filter((e) => e !== a.type);
            }
          }
          setAgreements(essentialAgreementsList.length === 0);
          essentialAgreementsList.length !== 0 && navigate('/setting/agreement');

          devServerApi.authGet('/tutorial', accessToken).then((dataT) => {
            // @ts-ignore
            const { tutorial } = dataT;
            setState(tutorial);
            if (tutorial === null) {
              tableReset();
              reservationReset();
              navigate('/tutorial/essential');
            }
            if (tutorial === 'table') {
              tableReset();
              reservationReset();
              navigate('/tutorial/table');
            }
            if (tutorial === 'reservation') {
              reservationReset();
              navigate('/tutorial/reservation');
            }
            if (tutorial === 'done') {
              tableReset();
              reservationReset();
              if (window.ExitPage) window.ExitPage.postMessage('');
              else navigate(-1);
            }
          });
        })
        .catch(() => {
          setAgreements(false);
          navigate('/setting/agreement');
        });
    }
  }, [appName]);

  return (
    <>
      <div className={classnames('tutorial-page')} />
      {agreements && state && state !== 'done' ? (
        <ModalPortal>
          <div className={'modal-wrapper setting animation'}>
            <div className="modal-dim" onClick={() => null} />
            <div className="modal-content continue">
              <div className="content-wrapper">
                설정을 진행하던 기록이 있어요.
                <div className={'emphasis'}>이어서 진행하시겠어요?</div>
              </div>
              <hr />
              <div className="button-wrapper">
                <button className={'tblm-btn-gray'} onClick={() => unProceed()}>
                  안 하기
                </button>
                <button className={'tblm-btn-primary'} onClick={instantDisabler(start)}>
                  진행하기
                </button>
              </div>
            </div>
          </div>
        </ModalPortal>
      ) : null}
    </>
  );
};

export default TutorialIndexPage;
