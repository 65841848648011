import * as React from 'react';
import { Controller } from 'react-hook-form';
import { isParameterPropertyDeclaration } from 'typescript';
import { HookFormProps, IRadioBox } from './HookFormProps';

export const ControlledRadioBox: React.FC<HookFormProps & IRadioBox> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue ?? ''}
      render={({ field: { onChange, name: fieldName }, fieldState: { error } }) => {
        return (
          <div className="custom-radio-wrapper">
            {props.options.map((item, i) => {
              return (
                <div className="custom-radio-item" key={`${props.name}_${i}`}>
                  <label className="tblm-rc">
                    <input
                      type="radio"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(e);
                        props.onChange && props.onChange(e);
                      }}
                      name={fieldName}
                      value={item.value}
                      defaultChecked={props.defaultChecked === item.value}
                    />
                    <i />
                    <span>{item.label}</span>
                  </label>
                </div>
              );
            })}
          </div>
        );
      }}
    />
  );
};
