function getRelativePos(elm, container) {
  let pPos = container.getBoundingClientRect(),
    cPos = elm.getBoundingClientRect(),
    pos: { [key: string]: number } = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    };

  pos.top = cPos.top - pPos.top + container.scrollTop;
  pos.right = cPos.right - pPos.right;
  pos.bottom = cPos.bottom - pPos.bottom;
  pos.left = cPos.left - pPos.left;

  return pos;
}

function easeInOutQuad(t) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

function scrollTo(element, to, duration, onDone?: () => void) {
  let start = element.scrollTop,
    change = to - start,
    startTime = performance.now(),
    now,
    elapsed,
    t;

  function animateScroll() {
    now = performance.now();
    elapsed = (now - startTime) / 1000;
    t = elapsed / duration;

    element.scrollTop = start + change * easeInOutQuad(t);

    if (t < 1) window.requestAnimationFrame(animateScroll);
    else onDone && onDone();
  }

  animateScroll();
}

export function scrollToElm(container, elm, duration) {
  if (container === 'global') {
    document.querySelector('.main-container-desktop').scrollTo({
      top: elm,
      behavior: duration ? 'smooth' : 'auto',
    });
  } else {
    const pos = getRelativePos(elm, container);
    scrollTo(container, pos.top, duration / 1000);
  }
}
