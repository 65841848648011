import { padStart } from 'lodash';
import groupBy from 'lodash/groupBy';
import React, { useId, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow_Right } from '@/assets/images/icons/Arrow_Right.svg';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import { devServerApi } from '@/shared/apis/devServerApi';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import { IPolicy } from '@/shared/utils/common.type';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useMallStore } from '@/stores/common/useMallStore';
import { usePolicyStore, dayKoreaWord } from '@/stores/common/usePolicyStore';
import { useModalStore } from '@/stores/common/useModalStore';
import Modal from '@/components/Modal/Modal';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';

const Policy = (): React.ReactElement => {
  const { accessToken } = useAuthStore();
  const navigate = useNavigate();
  const errorModalId = useId();
  const { setAlertModal } = useModalStore();
  const [errorMessage, setErrorMessage] = useState<String>('');
  const { bookingToday, bookingDeadLine, maxAvailableTime, bookingAvailableDays, reservationDays, reservationTimeUnit, setPolicy } = usePolicyStore();
  const { mallTime, setMallTime } = useMallStore();
  const [delimiters, setDelimiters] = useState([]);
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });

  const { isFetching } = useQuery([QUERY_KEYS.SETTING_POLICY, accessToken], () => devServerApi.api.get('/policy/reservations'), {
    onSuccess: (res) => {
      const data: IPolicy = res.data;
      const { status }: { status: number } = data;
      if (status < 0) {
        setErrorMessage(res.data.message);
        setAlertModal({ visible: true, key: errorModalId });
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-shadow
      let reservationTimeUnit = data.reservationTimeUnit;
      if (!data.reservationTimeUnit) {
        try {
          let resDelimiters = Array.from(
            new Set(
              Object.values(data.reservationDays)
                .map((r) => (Array.isArray(r.bookingTimes) ? r.bookingTimes.map((t) => t.split(':')[1]) : []))
                .flat(1)
            )
          );
          if (resDelimiters.includes('10') || resDelimiters.includes('20') || resDelimiters.includes('40')) {
            reservationTimeUnit = 10;
          } else if (resDelimiters.includes('30')) {
            reservationTimeUnit = 30;
          } else {
            reservationTimeUnit = 60;
          }
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
      setPolicy({ ...data, reservationTimeUnit });
    },
  });

  const timeMaker = (arg_minutes) => {
    const hours = Math.floor(arg_minutes / 60);
    const minutes = arg_minutes % 60;
    return `${hours > 0 ? hours + '시간' : ''} ${minutes > 0 ? minutes + '분' : ''} `;
  };

  const timeRanger = (times: string[]) => {
    if (times.length === 0) return '예약받는 시간 없음';
    const pTimes = times.map(time => time.split(':').map(Number));
    const timers = [
      [pTimes[0]],
    ];
    let lastPTime = [...pTimes[0]];
    // @ts-ignore
    let nextPTime = ((lastPTime[1] + reservationTimeUnit) === 60) ? [lastPTime[0] + 1, 0] : [lastPTime[0], lastPTime[1] + reservationTimeUnit];
    for (let iForPTime = 1;iForPTime < pTimes.length; iForPTime += 1) {
      if (pTimes[iForPTime].join(':') !== nextPTime.join(':')) {
        if (timers[timers.length - 1].length === 1) {
          timers[timers.length - 1].push(pTimes[iForPTime - 1]);
          timers.push([pTimes[iForPTime]]);
        }
      }
      lastPTime = [...pTimes[iForPTime]];
      // @ts-ignore
      nextPTime = ((lastPTime[1] + reservationTimeUnit) === 60) ? [lastPTime[0] + 1, 0] : [lastPTime[0], lastPTime[1] + reservationTimeUnit];
    }
    if (timers[timers.length - 1].length === 1) {
      timers[timers.length - 1].push(lastPTime);
    }
    return timers.map(time => {
      if (time[0].join(':') === time[1].join(':')) return time[0].map(t => String(t).padStart(2, '0')).join(':');
      return time.map(item => item.map(t => String(t).padStart(2, '0')).join(':')).join(' ~ ');
    }).join(', ');
  };

  // policy 리스트 데이터
  const policyData = [
    {
      title: '당일 예약',
      subTitle: `${bookingToday === 'Y' ? '가능' : '불가'}
      ${// eslint-disable-next-line no-nested-ternary
        bookingToday === 'Y' ? (bookingDeadLine ? ` / ${timeMaker(bookingDeadLine)}전까지` : ' / 제한없음') : ''}`,
      linkPath: '/setting/policy/same-day-reservation/',
      className: 'settings-same-day-reservation',
    },
    {
      title: '최대 이용시간',
      subTitle: `${maxAvailableTime === 0 ? '0시간' : timeMaker(maxAvailableTime)}`,
      linkPath: '/setting/policy/max-hours-use/',
      className: 'settings-max-hours-use',
    },
    {
      title: '예약 신청 가능 기간',
      subTitle: `${bookingAvailableDays}일 후 까지`,
      linkPath: '/setting/policy/available-reservation/',
      className: 'settings-available-reservation',
    },
    {
      title: '예약 받는 시간',
      linkPath: '/setting/policy/receive-reservation/',
      className: 'settings-receive-reservation',
    },
  ];
  if (isFetching) return <SpinnerEffector loading={isFetching} />;
  if (errorMessage) return (
    <>
      <Modal.Alert key={errorModalId} modal="alert" isDim={true} isAnimation={true}>
        <div className="title font__body_md_sb">알림</div>
        <div className="content font__body_sm">
          {errorMessage}
        </div>
        <div className="footer success font__body_sm_sb" onClick={() => {
          navigate(-1);
          setErrorMessage('');
          setAlertModal({ visible: false, key: errorModalId });
        }}>
          확인
        </div>
      </Modal.Alert>
    </>
  );
  return (
    <div className="policy-wrap">
      <HeaderBack>
        <div className="font__subtitle_sb">예약 정책</div>
      </HeaderBack>
      <section className="settings-policy-wrapper">
        {policyData.map((data) => (
          <div key={data.title} className={data.className}>
            <Link to={data.linkPath}>
              <div className="left-area">
                <div className="font__subtitle_sb">{data.title}</div>
                <div className="font__body_sm_m">{data.subTitle}</div>
              </div>
              <div className="right-area font__body_sm">
                편집
                <Arrow_Right />
              </div>
            </Link>

            {data.title === '예약 받는 시간' && (
              <div className="time-list-wrapper">
                {Object.keys(reservationDays).map((dayName) =>
                  reservationDays[dayName].reservation === 1 && reservationDays[dayName].bookingTimes.length !== 0 ? (
                    <div key={reservationDays[dayName].policyFormId} className="time-list-item">
                      <div className="font__small_sb">{dayKoreaWord[dayName]}</div>
                      <div className="font__s_small_m">{timeRanger(reservationDays[dayName].bookingTimes)}</div>
                    </div>
                  ) : null
                )}
              </div>
            )}
          </div>
        ))}

        {/*<div className="settings-reset font__body_sm_sb">초기화</div>*/}
      </section>
    </div>
  );
};

export default Policy;
