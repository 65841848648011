export const AGREEMENT_LIST = [
  {
    'key': 'tos',
    'title': '테이블매니저 서비스 이용약관',
    'name': 'SERVICE_FOR_KT_SECRETARY',
    'isOpenDoc': true,
    'isEssential': true,
  },
  {
    'key': 'privacy',
    'title': '개인정보 수집 및 이용 동의',
    'name': 'PRIVACY_FOR_KT_SECRETARY',
    'isOpenDoc': true,
    'isEssential': true,
  },
  {
    'key': 'third',
    'title': '개인정보 제3자 제공 동의',
    'name': 'PRIVACY_FOR_THIRD_PARTY_FOR_KT_SECRETARY',
    'isOpenDoc': true,
    'isEssential': true,
  },
  {
    'key': 'grant',
    'title': '개인정보 처리 위탁 동의',
    'name': 'PRIVACY_CONSIGN_FOR_KT_SECRETARY',
    'isOpenDoc': true,
    'isEssential': true,
  },
  {
    'key': 'marketing',
    'title': '홍보 및 마케팅 수집/이용 동의',
    'name': 'MARKETING_FOR_KT_SECRETARY',
    'isOpenDoc': true,
    'isEssential': false,
  },
  {
    'key': 'night',
    'title': '야간시간 광고성 정보 수신 동의',
    'name': 'LATE_NIGHT_MARKETING_FOR_KT_SECRETARY',
    'isOpenDoc': false,
    'isEssential': false,
  },
  {
    'key': 'keep',
    'title': '장기 미접속 시 계정을 별도 요청 시까지 활성 상태 유지 동의',
    'name': 'KEEP_ACTIVE_EVEN_DORMANT_FOR_KT_SECRETARY',
    'isOpenDoc': false,
    'isEssential': false,
  },
];

export default AGREEMENT_LIST;
