import React, { useEffect, useMemo, useState } from 'react';
import './TableView.scss';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import { ITablesGroup, Nullable } from '@/shared/utils/common.type';
import { useModalStore } from '@/stores/common/useModalStore';
import FooterSub from '../FooterSub/FooterSub';
import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import TableGroup from './TableGroup/TableGroup';

function TableView({ list, edit } : {list: Nullable<ITablesGroup[]>; edit: (bool: boolean)=>void}) {
  const { setAlertModal, setMenuModal, setToastModal } = useModalStore();
  const [tableGroups, setTableGroups] = useState(null);
  useEffect(() => {
    setTableGroups(list);
  }, [list]);

  const popAlert = () => {
    // 다이얼로그 작성 필요
    // reservation List Check API 필요
    let reservationCheck = true;
    if (reservationCheck) {
      setAlertModal({ visible: true, key: 'alert_table_reservation_check' });
    } else {
      setAlertModal({ visible: true, key: 'alert_table_reset' });
    }

  };

  const reset = () => {
    setTableGroups([]);
    setAlertModal({ visible: false, key: null });
  };

  return (
    <>
      <HeaderBack isFixed>
        <div className="font__subtitle">테이블</div>
      </HeaderBack>
      <div className="table-view-container">
        {tableGroups ? tableGroups.map((group) =>
          <TableGroup tableGroupData={group} key={group.groupId}/>
        ) : null}
        {/*<div className='reset-button' onClick={popAlert}>초기화</div>*/}
        <FooterSub isVisible isBackArea isBorder>
          <button className='footer-button tblm-button-normal tblm-btn-primary' onClick={()=>edit(true)}>편집</button>
        </FooterSub>
        <ModalPortal>
          <Modal.Alert key="alert_table_reservation_check" modal="alert" isDim={true} isAnimation={true}>
            <div className="title font__subtitle">
            예약이 남아 있어<br />초기화할 수 없어요.
            </div>
            <div className="content font__body_sm">
            예약이 남아 있지 않을 때만 초기화 가능해요.
            </div>
            <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: null })}>확인</div>
          </Modal.Alert>
          <Modal.Alert key="alert_table_reset" modal="alert" isDim={true} isAnimation={true}>
            <div className="title font__subtitle">
            테이블 설정을<br />정말 초기화할까요?
            </div>
            <div className="content font__body_sm">
            초기화 한 설정은 다시 복구할 수 없어요.
            </div>
            <div className="footer success font__body_sm_sb" onClick={()=>{}} />
          </Modal.Alert>
        </ModalPortal>
      </div>
    </>
  );
}

export default TableView;
