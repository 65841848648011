import 'dayjs/locale/ko';
import './WeekCalendar.scss';

import { Calendar, Day } from './WeekCalendar.type';
import React, { useEffect, useId, useRef, useState } from 'react';

import { ReactComponent as Arrow_Down } from '@/assets/images/icons/Arrow_Down.svg';
import CalendarPicker from '../CalendarPicker/CalendarPicker';
import { ReactComponent as Dot } from '@/assets/images/icons/Dot.svg';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { useModalStore } from '@/stores/common/useModalStore';
import { useReservationCalendarStore } from '@/stores/reservation/useReservationCalendarStore';
import { throttle } from 'lodash';

dayjs.locale('ko');
// props
// headerHeight sticky 될 위치
// selectedDate 선택된 날

const WeekCalendar = ({ selectedDate, select, className }: { selectedDate: any; select: any; className?: string }) => {
  const { setMenuModal } = useModalStore();
  const [viewDate, setViewDate] = useState<dayjs.Dayjs>(null);
  const [calendar, setCalendar] = useState<Calendar>(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const { reservationList, setReservationList } = useReservationCalendarStore();

  const weekCalendarId = useId();

  const refs: any = useRef([]);

  const containerRef = useRef(null);

  const makeWeekCalendar = (date: dayjs.Dayjs) => {
    if (reservationList.length <= 0) return;
    const today = dayjs();
    const targetMonthDate = date.startOf('month');
    let days = new Array<Day>(targetMonthDate.daysInMonth()).fill(null);
    days = days.map((day, dayIndex) => {
      let targetDay = targetMonthDate.add(dayIndex, 'day');
      const orderInfo = reservationList.find((item) => (item.date as dayjs.Dayjs).format('YYYY-MM-DD') === targetDay.format('YYYY-MM-DD'));
      return {
        isToday: today.isSame(targetDay, 'day'),
        isSameMonth: targetMonthDate.isSame(targetDay, 'month'),
        orderInfo: { reservationCount: orderInfo?.reservationCount, totalPartySize: orderInfo?.totalPartySize },
        selected: selectedDate.isSame(targetDay, 'day'),
        targetDay: targetDay,
      };
    });
    if (!viewDate.isBefore(dayjs().add(-3, 'month'))) {
      days.unshift({
        isToday: false,
        isSameMonth: false,
        orderInfo: { reservationCount: 0, totalPartySize: 0 },
        selected: false,
        targetDay: targetMonthDate.add(-1, 'day'),
      });
    }
    if (!viewDate.add(1, 'month').isAfter(dayjs().add(12, 'month'))) {
      days.push({
        isToday: false,
        isSameMonth: false,
        orderInfo: { reservationCount: 0, totalPartySize: 0 },
        selected: false,
        targetDay: targetMonthDate.add(1, 'month'),
      });
    }
    setCalendar({
      days,
      targetMonth: targetMonthDate,
    });
  };

  const scrollToTargetDate = (date: dayjs.Dayjs, type) => {
    let targetCalendarIndex = calendar.days.findIndex((day) => day.targetDay.isSame(date, 'day'));
    if (targetCalendarIndex > -1) {
      if (refs.current[targetCalendarIndex] && refs.current[targetCalendarIndex].scrollIntoView) {
        if (type === 'move') {
          containerRef.current.scrollLeft = (refs.current[targetCalendarIndex].offsetLeft / 4) * 3;
        } else if (type === 'scroll') {
          refs.current[targetCalendarIndex].scrollIntoView({ behavior: 'auto', inline: 'nearest' });
        } else if (type === 'select') {
          refs.current[targetCalendarIndex].scrollIntoView({ behavior: 'auto', inline: 'nearest' });
          if (refs.current[targetCalendarIndex].offsetLeft + containerRef.current.offsetWidth <= containerRef.current.scrollWidth) {
            containerRef.current.scrollLeft = refs.current[targetCalendarIndex].offsetLeft;
          }
        }
      }
    }
  };

  const onChangeViewDate = () => {
    const lastScroll = containerRef.current.scrollLeft;
    if (lastScroll === 0) {
      if (viewDate.isBefore(dayjs().add(-3, 'month'))) return;
      const targetViewDate = viewDate.startOf('month').add(-1, 'month');
      containerRef.current.style.overflow = 'hidden';
      setViewDate(targetViewDate);
      setTimeout(() => {
        containerRef.current.style.overflow = '';
        containerRef.current.scrollLeft = containerRef.current.scrollWidth - containerRef.current.clientWidth - 42;
      }, 0);
    } else if (lastScroll >= containerRef.current.scrollWidth - containerRef.current.clientWidth - 5) {
      if (viewDate.add(1, 'month').isAfter(dayjs().add(12, 'month'))) return;
      const targetViewDate = viewDate.startOf('month').add(1, 'month');
      containerRef.current.style.overflow = 'hidden';
      setViewDate(targetViewDate.startOf('month'));
      setTimeout(() => {
        containerRef.current.style.overflow = '';
        containerRef.current.scrollLeft = 42;
      }, 0);
    }
  };
  const finishCalendarPicker = (date) => {
    setViewDate(date);
  };

  useEffect(() => {
    if (viewDate) {
      makeWeekCalendar(viewDate);
    }
  }, [viewDate, reservationList]);
  useEffect(() => {
    if (calendar) {
      if (calendar.days.length > 0) {
        if (viewDate.isSame(selectedDate, 'day')) {
          if (firstLoad) {
            scrollToTargetDate(viewDate, 'scroll');
          } else {
            scrollToTargetDate(viewDate, 'select');
            setFirstLoad(true);
          }
        }
        containerRef.current.addEventListener('scroll', onChangeViewDate);
        document.querySelector('.main-container-desktop').scrollTo(0, 0);
        return () => {
          containerRef.current?.removeEventListener('scroll', onChangeViewDate);
        };
      }
    }
  }, [calendar]);

  useEffect(() => {
    setViewDate(selectedDate);
  }, [selectedDate]);

  if (!calendar) return null;
  return (
    <div className={classnames('week-calendar-container', className)}>
      <div className="week-calendar-sticky-header">
        <div className="view-date-text font__body_md" onClick={() => setMenuModal({ visible: true, key: weekCalendarId })}>
          {viewDate.format('M월')}
          <Arrow_Down />
        </div>
      </div>
      <div ref={containerRef} className="week-calendar">
        {calendar.days.map((day, index) => (
          <div
            className="calendar-day"
            key={index}
            onClick={() => select(day.targetDay)}
            ref={(element) => {
              refs.current[index] = element;
            }}
          >
            <div className="calendar-day-text font__s_small">{day.targetDay.format('dd')}</div>
            <div className={classnames('date-box font__subtitle1_sb', { selected: day.selected, today: day.isToday })}>{day.targetDay.format('D')}</div>
            {day.orderInfo.reservationCount > 0 ? <Dot /> : null}
          </div>
        ))}
      </div>
      <CalendarPicker
        viewDate={viewDate}
        modalKey={weekCalendarId}
        finish={(date) => finishCalendarPicker(date)}
        startDate={dayjs().add(-3, 'month')}
        endDate={dayjs().add(12, 'month')}
      />
    </div>
  );
};

export default WeekCalendar;
