import React, { useEffect, useState } from 'react';
import Facilities from '@/components/Settings/Facilities';
import Food from '@/components/Settings/Food';
import '@/styles/pages/tutorial/SettingBasicInformaion.scss';
import classnames from 'classnames';
import QuestionContent from '@/components/QuestionContent/QuestionContent';
import { useNavigate } from 'react-router-dom';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import { essentialTutorialApi } from '@/shared/apis/tutorialApi';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { instantDisabler } from '@/shared/lib/functions';
import { initialFacilities } from '@/shared/lib/controlInfo';
import { usePartnerStore } from '@/stores/common/usePartnerStore';

const initStep = [
  {
    title: '예약 설정을 \n 시작할게요.',
    next: '시작',
    comment: '예약을 받으시려면 \n 기본 세팅을 마쳐주셔야 해요!',
  },
  {
    title: '우리 매장은 \n 어떤 종류의 음식을 \n 판매하고 있나요?',
    next: '다음',
  },
  {
    title: '우리 매장에 있는 \n 편의시설을 모두 \n 골라주세요.',
    prev: '이전',
    next: '다음',
  },
];

function SettingBaseInformaion() {
  const { accessToken } = useAuthStore();
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
  const { state, setState } = useSettingTutorialStore();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [stepList, setStepList] = useState(initStep);
  const [basicData, setBasicData] = useState({
    foods: [],
    facility: [],
  });
  const { appName } = usePartnerStore();

  const onClickRouterButton = (e) => {
    const { name } = e.target;
    if (appName === 'kt') {
      if (name === 'next') {
        if (currentStep === initStep.length - 1) {
          essentialTutorialApi(basicData);
          setState('table');
          navigate('/tutorial/table');
          return;
        }
        setCurrentStep(currentStep + 1);
      } else {
        // if (currentStep === 2) {
        //   setBasicData({ ...basicData, facility: [] });
        // }
        setCurrentStep(currentStep - 1);
      }
    }
  };

  const setFacilitiesList = (facility) => {
    if (basicData.facility.some(item => item.type_facility_id === facility.type_facility_id)) {
      basicData.facility = basicData.facility.filter(item => item.type_facility_id !== facility.type_facility_id);
    } else {
      basicData.facility = [...basicData.facility, facility];
    }
    setBasicData(basicData);
  };

  const sectionSplit = () => {
    switch (currentStep) {
      case 0:
        return;
      case 1:
        return <Food basicData={basicData} setBasicData={setBasicData} />;
      case 2:
        return <Facilities edit={true} facilitiesList={initialFacilities.map(f=>({ ...f, checked: basicData.facility.some(ff=>ff.type_facility_id === f.type_facility_id) }))} setFacilitiesList={setFacilitiesList}/>;
      default:
        alert('error');
        return <></>;
    }
  };

  useEffect(() => {
    sectionSplit();
  }, [currentStep]);

  return (
    <div className={classnames('tutorial-page')}>
      <div className="tutorial-content-container essential-tutorial-content-container">
        <QuestionContent question={stepList[currentStep].title} option={sectionSplit()} note={stepList[currentStep].comment} type={null} value={null} key={currentStep}/>
      </div>
      <div className={classnames('button-container')}>
        { !stepList[currentStep]?.prev ? null : <button className='prev' name="prev" onClick={onClickRouterButton}>{stepList[currentStep]?.prev}</button>}
        { !stepList[currentStep]?.next ? null : <button className='next' name="next" onClick={instantDisabler(onClickRouterButton)}>{stepList[currentStep]?.next}</button>}
      </div>
    </div>
  );
}

export default SettingBaseInformaion;
