import React, { useState } from 'react';
import './Facilities.scss';
import { confirmedFacilities, initialFacilities } from '@/shared/lib/controlInfo';

function Facilities({ hidden = false, edit = true, facilitiesList = initialFacilities, setFacilitiesList }) {
  const onChangeFacilities = (e, x) => {
    if (!edit) return;
    setFacilitiesList({ ...x, checked: e.target.checked });
  };

  return (
    <div className="wrapper facilities-list">
      {facilitiesList.filter(facility => confirmedFacilities.includes(facility.type_facility_id)).map((x) => {
        return hidden ? (
          x.checked && (
            <label className="facilitiesCheck font__small_sb" key={x.type_facility_id}>
              <input type="checkbox" onChange={(e) => onChangeFacilities(e, x)} defaultChecked={x.checked} readOnly={!edit} />
              <i>{x.name}</i>
            </label>
          )
        ) : (
          <label className="facilitiesCheck font__small_sb" key={x.type_facility_id}>
            <input type="checkbox" onChange={(e) => onChangeFacilities(e, x)} defaultChecked={x.checked} readOnly={!edit} disabled={!edit} />
            <i>{x.name}</i>
          </label>
        );
      })}
    </div>
  );
}

export default Facilities;
