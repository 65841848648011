import apiBase from './apiBase';
import { getAppName } from '@/shared/lib/functions';

let appName = getAppName();

console.log(appName);
const API_HOST = appName === 'tablemanager' ? process.env.REACT_APP_TM_API_HOST : process.env.REACT_APP_API_HOST;
console.log(API_HOST);
export const devServerApi = apiBase({
  baseURL: API_HOST,
});
