import create from 'zustand';
import { Nullable } from '@/shared/utils/common.type';

interface IUsePartnerStore {
  appName: Nullable<'kt' | 'tablemanager' | string>;
  setAppName: (appName: 'kt' | 'tablemanager') => void;
}

export const usePartnerStore = create<IUsePartnerStore>((set) => ({
  appName: localStorage.getItem('appName') || null,
  setAppName: (appName) => set({ appName }),
}));
