import { Nullable } from '@/shared/utils/common.type';

export interface INativeMessage {
	action: 'close_window' | 'request_user_data' | 'update_token' | 'request_logout';
	data?: Nullable<string>;
}

export const sendNativeMessage = (message :INativeMessage) => {
	window.blueberry?.postMessage(JSON.stringify(message));
};
