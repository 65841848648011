const handleContactEvent = (contactType, phoneNum) => {
  switch (contactType) {
    case 'call':
      window.location.href = `tel:${phoneNum}`;
      break;

    case 'message':
      window.location.href = `sms:${phoneNum}`;
      break;
  }
};

export { handleContactEvent };
