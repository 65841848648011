/* eslint-disable no-nested-ternary */
import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames';
import template from 'lodash/template';
import templateSettings from 'lodash/templateSettings';
import React, { useMemo, useReducer, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ReactComponent as Arrow_Down } from '@/assets/images/icons/Arrow_Down.svg';
import Picker from '@/components/Picker/Picker';
import QuestionContent from '@/components/QuestionContent/QuestionContent';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import TutorialCheckbox from '@/components/TutorialCheckbox/TutorialCheckbox';
import TutorialDayTime from '@/components/TutorialDayTime/TutorialDayTime';
import TutorialNaverKakao from '@/components/TutorialNaverKakao/TutorialNaverKakao';
import TutorialReservation from '@/components/TutorialReservation/TutorialReservation';
import TutorialSelector from '@/components/TutorialSelector/TutorialSelector';
// import TutorialSelector2 from '@/components/TutorialSelector2/TutorialSelector2';
import TutorialTable from '@/components/TutorialTable/TutorialTable';
import useGetInitialStep from '@/hooks/useGetInitialStep';
import { devServerApi } from '@/shared/apis/devServerApi';
import { essentialTutorialApi, reservationTutorialApi, tableTutorialApi } from '@/shared/apis/tutorialApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useLayoutStore } from '@/stores/common/useLayoutStore';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import { useTableSettingStore, questionReducer } from '@/stores/common/useTableSettingStore';
import '@/styles/pages/tutorial/tutorial.scss';
import { instantDisabler } from '@/shared/lib/functions';
import { usePartnerStore } from '@/stores/common/usePartnerStore';

templateSettings.interpolate = /{{([\s\S]+?)}}/g;

const TableSettingPage = (): React.ReactElement => {
  const { accessToken } = useAuthStore();
  const { spinner, setSpinner } = useLayoutStore();
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
  const { state, setState } = useSettingTutorialStore();
  const tutorialPage = useRef(null);
  const { storageQuestion, setStorageQuestion, resetStorageQuestion } = useTableSettingStore();
  const initialStep = useGetInitialStep(storageQuestion);
  const { appName } = usePartnerStore();

  const [renderingQuestion, dispatchRenderingQuestion] = useReducer(questionReducer, storageQuestion);
  const [step, setStep] = useState(initialStep);
  const [referenceIdx, setReferenceIdx] = useState(0);
  const [showUntil, setShowUntil] = useState(false);
  const navigate = useNavigate();

  const { title, optionUnit, value, reference, question, note, notePosition, boolText, option, multiple, type, prevStep, nextStep } = renderingQuestion[step];

  const referenceStep = reference ? renderingQuestion[reference] : null;
  const referenceValue = referenceStep ? (referenceStep.value as number[]) : null;

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      table_groups: yup.array(
        yup.object({
          name: yup.string().trim().required('테이블 그룹명을 입력해주세요.'),
          tables: yup.array(
            yup.object({
              name: yup.string().trim().required('필수 입력 사항 입니다.'),
            })
          ),
        })
      ),
    });
  }, []);

  const methods = useForm({
    defaultValues: {
      table_groups: [],
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onPrev = () => {
    if ((step === 'step2_3' || step === 'step3_1' || step === 'step3_2') && referenceIdx !== 0) {
      setReferenceIdx((ri) => ri - 1);
    } else {
      setStep(prevStep);
      // @ts-ignore
      setReferenceIdx(renderingQuestion[prevStep].value ? renderingQuestion[prevStep].value.length - 1 : 0);
      dispatchRenderingQuestion({ type: 'SET_CHANGE', value: null, step });
    }
  };

  const onNext = async (data?: any) => {
    if (step !== 'step4_0') {
      if (referenceValue && referenceValue.length - 1 > referenceIdx) {
        setReferenceIdx(referenceIdx + 1);
        return;
      }
    }
    setReferenceIdx(0);
    let destinationStep = null;
    try {
      if (!nextStep || (!nextStep.to && !nextStep.bool && !nextStep.range && !nextStep.api)) throw new Error('no more steps');
      if (nextStep.to) {
        destinationStep = nextStep.to;
      } else if (nextStep.bool) {
        if (data === undefined) {
          destinationStep = nextStep.bool[value ? 0 : 1];
        } else {
          if (typeof data !== 'boolean') return;
          destinationStep = nextStep.bool[data ? 0 : 1];
        }
      } else if (nextStep.api) {
        // eslint-disable-next-line no-nested-ternary
        const getTutorial = () => {
          if (appName === 'kt') {
            return essentialTutorialApi;
          }
          return () => null;
        };

        const api =
          nextStep.api[0] === 'table'
            ? tableTutorialApi
            : nextStep.api[0] === 'reservation'
            ? reservationTutorialApi
            : nextStep.api[0] === 'essential'
            ? getTutorial()
            : () => null;
        setSpinner(true);
        await api(data);
        setSpinner(false);
        destinationStep = nextStep.api[1];
      } else if (nextStep.range) {
        for (const range of nextStep.range) {
          const range_destination_step = range[0];
          const range_start = range[1] || -Infinity;
          const range_end = range[2] || Infinity;
          if (value >= range_start && value <= range_end) {
            destinationStep = range_destination_step;
            break;
          }
        }
      }
      const nextTutorial = ['table', 'reservation', 'essential', 'done'].includes(destinationStep);
      if (!destinationStep) throw new Error('no match step');
      if (!nextTutorial) renderingQuestion[destinationStep].prevStep = step;
      setStorageQuestion(renderingQuestion);
      if (nextTutorial) {
        resetStorageQuestion();
        setState(destinationStep);
        if (destinationStep === 'done') {
          if (window.DonePage) {
            window.DonePage.postMessage('');
            window.NavigatePopPage.postMessage('');
          } else navigate('/setting', { replace: true });
        } else {
          navigate('/tutorial/' + destinationStep);
        }
      } else {
        setStep(destinationStep);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getOptionElement = () => {
    if (type === 'button') {
      return (
        <>
          <button
            className={classnames({ active: value === true })}
            onClick={instantDisabler(() => {
              dispatchRenderingQuestion({ type: 'SET_CHANGE', value: true, step });
              onNext(true);
            })}
          >
            {boolText ? boolText[0] : '네'}
          </button>
          <button
            className={classnames({ active: value === false })}
            onClick={instantDisabler(() => {
              dispatchRenderingQuestion({ type: 'SET_CHANGE', value: false, step });
              onNext(false);
            })}
          >
            {boolText ? boolText[1] : '아니요'}
          </button>
        </>
      );
    }
    if (type === 'checkbox') {
      return (
        <TutorialCheckbox
          options={option}
          unit={optionUnit}
          value={value}
          onChange={(v) => {
            dispatchRenderingQuestion({ type: 'SET_CHANGE', value: v, step });
          }}
        />
      );
    }
    if (type === 'select') {
      return (
        <TutorialSelector
          key={title}
          title={title}
          options={option}
          unit={optionUnit}
          value={(step === 'step2_3' || step === 'step3_1' || step === 'step3_2') ? value && value[referenceIdx][1] : value}
          idx={referenceIdx}
          onChange={(v, i) => {
            if (referenceValue) {
              if (referenceIdx === 0) {
                const refInitial = referenceValue.map((rv) => [rv, null]);
                refInitial[referenceIdx][1] = v;
                dispatchRenderingQuestion({ type: 'SET_CHANGE', value: refInitial, step });
              } else {
                dispatchRenderingQuestion({ type: 'SET_REF_CHANGE', value: v, idx: referenceIdx, step });
              }
            } else {
              dispatchRenderingQuestion({ type: 'SET_CHANGE', value: v, step });
            }
          }}
        />
      );
    }
    if (type === 'until') {
      return (
        <>
          <div className="row" onClick={() => setShowUntil(true)}>
            <div className={classnames('selectbox', !value && 'select-placeholder')}>
              {value ? value : '00'}
              <Arrow_Down />
            </div>
            일 후
          </div>
          <div className="row">예약까지 고객이 신청할 수 있어요.</div>
          <Picker
            isSingle={true}
            key={'until'}
            title={''}
            modalKey={'until'}
            onSuccess={({ leftArea }) => {
              dispatchRenderingQuestion({ type: 'SET_CHANGE', value: leftArea, step });
              setShowUntil(false);
            }}
            visible={showUntil}
            leftArea={{ selected: 0, list: [7, 14, 30, 60, 90], unit: '일 후' }}
          />
        </>
      );
    }
    if (type === 'reservation') {
      return <TutorialReservation question={renderingQuestion} dispatcher={dispatchRenderingQuestion} />;
    }

    if (type === 'naverkakao') {
      return (
        <TutorialNaverKakao
          onNext={(bool) => {
            if (bool) {
              dispatchRenderingQuestion({ type: 'SET_CHANGE', value: true, step });
            } else {
              dispatchRenderingQuestion({ type: 'SET_CHANGE', value: false, step });
            }
            onNext(bool);
          }}
        />
      );
    }
    if (type === 'table') {
      return null;
    }

    // eslint-disable-next-line no-unreachable
    if (typeof option === 'object') {
      const checkMultipleValue = value === null && multiple ? [] : value;
      const initialValue = checkMultipleValue === null ? undefined : checkMultipleValue;

      return (
        <select
          className="w-full"
          onChange={(e) => dispatchRenderingQuestion({ type: 'SET_SELECT_CHANGE', value: e.target, step, multiple })}
          multiple={multiple}
          value={initialValue as string | number}
        >
          {option.map((item) => (
            <option key={step + '_' + item} value={item}>
              {item}
            </option>
          ))}
        </select>
      );
    }
  };

  const mainOptionElement = getOptionElement();

  const unitize = () => {
    if (referenceStep && Array.isArray(referenceValue)) {
      const alteredValue = value || referenceValue.map((rv) => [rv, null]);
      const optionIdx = alteredValue[referenceIdx][0];
      if (referenceStep.optionUnit === '층') return `${referenceStep.option[optionIdx]}${referenceStep.optionUnit}`.replace('-', '지하 ');
      return referenceStep.option[optionIdx] + referenceStep.optionUnit;
    }
    return null;
  };

  const formattedQuestion = template(question)({ option: unitize() });

  if (spinner) return <SpinnerEffector loading={true} />;

  return (
    <FormProvider {...methods}>
      <div className={classnames('tutorial-page', { unfixed: type === 'table' })} ref={tutorialPage}>
        {type === 'table' ? (
          <TutorialTable question={renderingQuestion} dispatcher={dispatchRenderingQuestion} onSubmit={onNext} parentref={tutorialPage} />
        ) : (
          <div className="tutorial-content-container">
            <QuestionContent question={formattedQuestion} option={mainOptionElement} type={type} value={value} key={`${step}_${formattedQuestion}`} />
          </div>
        )}

        {nextStep && nextStep.api ? null : (
          <div className="button-container">
            {step === 'step1_0' || !prevStep || (nextStep && nextStep.api) ? null : (
              <button className="prev" onClick={() => onPrev()}>
                이전
              </button>
            )}
            <button className="next" onClick={instantDisabler(() => onNext())} disabled={value === null && !(nextStep && nextStep.api)}>
              {nextStep && nextStep.api ? '확인했어요' : '다음'}
            </button>
          </div>
        )}
      </div>
    </FormProvider>
  );
};

export default TableSettingPage;
