import 'dayjs/locale/ko';

import { IMutationProps, IReservation, TReservationState, reservationStateList } from '@/shared/utils/common.type';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import AGREEMENT_LIST from '@/components/Agreement/Agreement.constants';
import AccessAgreement from '@/components/Reservation/AccessAgreement/AccessAgreement';
import { ReactComponent as Calendar_Month } from '@/assets/images/icons/Calendar_Month.svg';
import Card from '@/components/Card/Card';
import { ReactComponent as EmptyDataCommonList } from '@/assets/images/icons/EmptyData-CommonList.svg';
import { ReactComponent as Filter } from '@/assets/images/icons/Filter.svg';
import { ReactComponent as Arrow_Down } from '@/assets/images/icons/Arrow_Down.svg';
import ListFilter from '@/components/ListFilter/ListFilter';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import ReservationListCalendar from '@/components/ReservationListCalendar/ReservationListCalendar';
import ReservationOn from '@/components/Reservation/List/ReservationOn';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import WeekCalendar from '@/components/WeekCalendar/WeekCalendar';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useReservationCalendarStore } from '@/stores/reservation/useReservationCalendarStore';
import { useReservationSettingStore } from '@/stores/common/useReservationSettingStore';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import { useTableSettingStore } from '@/stores/common/useTableSettingStore';

import Terces from '@/shared/apis/terces';
import Navigation from '@/components/Navigation/Navigation';
import { ISelectBoxOptions } from '@/hook-form/HookFormProps';
import { useGetMyMalls, usePatchMyMalls } from '@/hooks/useSelectedMall';
import { IMallOptions } from '@/shared/utils/my-malls.type';
import TimeFilter from '@/components/TimeFilter/TimeFilter';
import { useMallStore } from '@/stores/common/useMallStore';
import { usePartnerStore } from '@/stores/common/usePartnerStore';
import { INativeMessage, sendNativeMessage } from '@/shared/lib/functions/native-messge';
import classnames from 'classnames';

dayjs.locale('ko');

const List = (): React.ReactElement => {
  const [searchParams] = useSearchParams({ type: 'default' });
  const { appName } = usePartnerStore();
  const navigate = useNavigate();
  const { setMenuModal, setToastModal, setAlertModal } = useModalStore();
  const { setReservationList } = useReservationCalendarStore();
  const { accessToken, setAccessToken, isMultiMall, setIsMultiMall, appPushContext } = useAuthStore();
  const { mall } = useMallStore();
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
  const { data: myMallList, isLoading: isMallListLoading } = useGetMyMalls(accessToken);
  const mallListOptions: any[] = useMemo(() => myMallList?.accessibleMalls?.map((mallItem) => ({ label: mallItem.name, value: mallItem.id })), [myMallList, accessToken]);
  const { multipleDivision } = useMallStore().mall;

  const tableReset = useTableSettingStore((state) => state.resetStorageQuestion);
  const reservationReset = useReservationSettingStore((state) => state.resetStorageQuestion);

  const { state, setState, agreements, setAgreements, agreementsDetail, setAgreementsDetail } = useSettingTutorialStore();

  const [showCalendar, setShowCalendar] = useState(false);
  const [handleCardIdx, setHandleCardIdx] = useState<number>();
  const [timeFilter, setTimeFilter] = useState<string>('all');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filter, setFilter] = useState<{ statusList: TReservationState[]; selectedGroup: string }>({ statusList: ['valid', 'enter'], selectedGroup: 'all' });
  const [selectedDate, setSelectedDate] = useState(searchParams.get('date') ? dayjs(searchParams.get('date')) : dayjs());
  const [allReservation, setAllReservation] = useState([]);
  const [checkedStore, setCheckedStore] = useState<ISelectBoxOptions>();
  const storeListElement = useRef(null);
  const today = dayjs();
  const reservationOffId = useId();
  const selectStoreModalId = useId();
  const changeMyMallErrorKey = useId();

  const mustTutorial = (e?: any) => {
    try {
      window.NavigatePushPage.postMessage(e || '/tutorial');
    } catch (err) {
      navigate(e || '/tutorial');
    }
  };

  const filterBody = useCallback(
    (reservation) => {
      // 시간대 필터
      let timeFiltered;
      if (multipleDivision.length > 0) {
        let dayDivisions = multipleDivision.filter((division) => selectedDate.get('day') === division.divisionDay);
        let targetDivision = dayDivisions.find((divison) => divison.divisionId === timeFilter);
        timeFiltered = timeFilter === 'all' || (targetDivision && targetDivision.start < reservation.visitTime && targetDivision.end >= reservation.visitTime);
      } else {
        switch (timeFilter) {
          case 'lunch':
            timeFiltered = reservation.visitTime < 960;
            break;
          case 'dinner':
            timeFiltered = reservation.visitTime >= 960;
            break;
          default:
            timeFiltered = true;
        }
      }
      // 상태 필터
      let stateFiltered;
      if (filter.statusList.length === reservationStateList.length) {
        stateFiltered = true;
      } else {
        stateFiltered = filter.statusList.includes(reservation.reservationState);
      }

      // 테이블 그룹 필터
      let tableGroupFiltered;
      switch (filter.selectedGroup) {
        case 'all':
          tableGroupFiltered = true;
          break;
        case 'unset':
          tableGroupFiltered = reservation.rooms.length === 0;
          break;
        default:
          tableGroupFiltered = reservation.rooms.some((room) => room.roomGroupId === filter.selectedGroup);
      }
      return timeFiltered && stateFiltered && tableGroupFiltered;
    },
    [filter, timeFilter]
  );

  const { isFetching: isReservationsLoading } = useQuery(
    [QUERY_KEYS.LIST_RESERVATIONS, selectedDate.format('YYYY-MM-DD'), accessToken],
    () => devServerApi.authGet<{ reservations: IReservation[] }>('reservations?date=' + selectedDate.format('YYYY-MM-DD'), accessToken).then((enc_res) => Terces.decrypt(enc_res)),
    {
      onSuccess: (res) => {
        setAllReservation(res.reservations);
      },
    }
  );

  const { isLoading: isReservationState } = useQuery(
    [QUERY_KEYS.LIST_STATE, accessToken],
    () => appName === 'kt' && devServerApi.authGet('/settings/reservation/state', accessToken),
    {
      onSuccess: (res: any) => {
        res.status === 'disable' && setAlertModal({ visible: true, key: reservationOffId });
      },
    }
  );

  const { isLoading: isCalendarLoading } = useQuery(
    [QUERY_KEYS.LIST_CALENDAR, accessToken],
    () => devServerApi.authGet(`reservation/calendar/?from=${today.add(-1, 'month').format('YYYY-MM')}&to=${today.add(3, 'month').format('YYYY-MM')}`, accessToken),
    {
      onSuccess: (res: any) => {
        const convertDate = res.reservationSummary.map((item) => ({ ...item, date: dayjs(item.date) }));
        setReservationList(convertDate);
      },
    }
  );

  const { isLoading: isTermsAgreeLoading } =
    appName === 'kt' &&
    useQuery([QUERY_KEYS.LIST_TERMS_AGREE, accessToken], () => devServerApi.authGet('/terms-agree', accessToken), {
      onSuccess: (res: any) => {
        const { response } = res;
        setAgreementsDetail(response);
        let essentialAgreementsList = AGREEMENT_LIST.filter((a) => a.isEssential).map((a) => a.name);
        for (let a of response) {
          if (a.isAgree) {
            essentialAgreementsList = essentialAgreementsList.filter((e) => e !== a.type);
          }
        }
        setAgreements(essentialAgreementsList.length === 0);
      },
      onError: () => setAgreements(false),
    });

  const { isLoading: isTutorialLoading } =
    appName === 'kt' &&
    useQuery([QUERY_KEYS.LIST_TUTORIAL, accessToken], () => devServerApi.authGet('/tutorial', accessToken), {
      onSuccess: (res: any) => {
        const { tutorial } = res;
        setState(tutorial);
        tableReset();
        reservationReset();
      },
    });

  const { mutate, isLoading: isReservationMutateLoading } = useMutation((mutatedata: IMutationProps) => devServerApi.mutation(mutatedata), {
    onMutate: () => ({ key: [QUERY_KEYS.LIST_STATE, accessToken] }),
    onSuccess: () => setAlertModal({ visible: false, key: reservationOffId }),
  });

  const isFiltered = useMemo(() => filter.selectedGroup !== 'all' || filter.statusList.length !== 0, [filter]);

  const reservations = allReservation.filter(filterBody) || [];
  const reservationCount = useMemo(() => reservations.length, [reservations]);
  const reservationAllPartySize = useMemo(
    () => reservations.reduce((previousValue: number, currentValue) => previousValue + currentValue.parties.reduce((a, b) => a + Number(b.size), 0), 0),
    [reservations]
  );

  const detectScrollStoreList = () => {
    const ele = storeListElement.current;
    if (ele?.scrollTop > 0) {
      ele.style.borderTop = '1px solid #EDEDED';
    } else {
      ele.style.borderTop = null;
    }
  };

  const selectMyMall = (store: IMallOptions) => {
    usePatchMyMalls(store.value, accessToken, appPushContext)
      .then(({ data }) => {
        if (data.status < 0) throw Error();
        setCheckedStore(store);
        setAccessToken(data.accessToken);
        setMenuModal({ visible: false, key: null });
        const message: INativeMessage = { action: 'update_token', data: data.accessToken };
        sendNativeMessage(message);
        setIsMultiMall(data.isMultiMall);
      })
      .catch((err) => {
        console.log(err, 'err');
        if (err) setAlertModal({ visible: true, key: changeMyMallErrorKey });
      });
  };

  useEffect(() => {
    if (!checkedStore && mallListOptions?.length) {
      const isSelectedMallId = mallListOptions.find((mallItem) => mallItem.value === myMallList?.myMall);
      setCheckedStore(isSelectedMallId);
    }
  }, [mallListOptions, accessToken]);

  useEffect(() => {
    if (mallListOptions?.length > 1) setIsMultiMall(true);
  }, [mallListOptions]);

  if (isReservationState || isReservationMutateLoading || isCalendarLoading) {
    return <SpinnerEffector loading={isReservationState || isReservationMutateLoading || isCalendarLoading} />;
  }
  if (isTermsAgreeLoading && isTutorialLoading && isMallListLoading) {
    return <SpinnerEffector loading={isTermsAgreeLoading && isTutorialLoading && isMallListLoading} />;
  }
  if (appName === 'kt' && (state !== 'done' || !agreements)) {
    return <ReservationOn onClick={mustTutorial} />;
  }

  if (showCalendar) return <ReservationListCalendar visitDate={selectedDate} setVisitDate={setSelectedDate} onClose={() => setShowCalendar(false)} />;

  return (
    <div className="reservation_list_page">
      <div className="reservation_top">
        <div className="main_navi">
          {appName === 'tablemanager' ? (
            <div onClick={() => (isMultiMall ? setMenuModal({ key: selectStoreModalId, visible: true }) : null)} className="title open_store_list">
              {isMultiMall ? (
                <>
                  {checkedStore?.label}
                  <i>
                    <Arrow_Down />
                  </i>
                </>
              ) : (
                <div className="title open_store_list">{mall.mallName}</div>
              )}
            </div>
          ) : (
            <span className="title">예약 현황</span>
          )}
          <span className="today-btn-wrap" onClick={() => setSelectedDate(dayjs())}>
            <button className={classNames('today', { active: selectedDate.isSame(today, 'day') })}>오늘</button>
          </span>
          <span className="calendar-icon-wrap" onClick={() => setShowCalendar(true)}>
            <Calendar_Month />
          </span>
        </div>
        <WeekCalendar className="schedule" selectedDate={selectedDate} select={setSelectedDate} />
        <div className="filter-menu">
          {<TimeFilter selectedType={timeFilter} setSelectedType={setTimeFilter} selectedDate={selectedDate} />}
          <section className="filter-menu-wrap">
            <button
              className={classNames('toggle_filter', { isFiltered })}
              onClick={() => {
                setShowFilter(true);
                setMenuModal({ key: 'listFilterModal', visible: true });
              }}
            >
              <Filter />
              필터
            </button>
          </section>
        </div>
      </div>
      <div className="reservation_list">
        {isReservationsLoading ? (
          <SpinnerEffector loading={isReservationsLoading} />
        ) : (
          <>
            {!reservations.length ? <SpinnerEffector loading={Boolean(reservations.length)} /> : null}
            {reservations.length > 0 ? (
              <>
                <div className="indicator">
                  전체 ({reservationCount}건, {reservationAllPartySize}명)
                </div>
                {reservations.map((reservation, idx) => (
                  <Card key={reservation.reservationId} cardIdx={idx} reservation={reservation} onActivate={setHandleCardIdx} />
                ))}
              </>
            ) : (
              <div className={'empty-data-note'}>
                <EmptyDataCommonList />
                <div>등록된 예약이 없습니다.</div>
              </div>
            )}
          </>
        )}
      </div>
      <Link
        className={classnames('tblm-btn-float no-padding', { tmAppPosition: appName === 'tablemanager' })}
        to={`/reservation/accept/step-1?visitDate=${selectedDate.format('YYYY-MM-DD')}`}
      >
        <button className="tblm-btn-float">
          <i />
        </button>
      </Link>
      <ModalPortal>
        <Modal.Menu key={selectStoreModalId} modal="menu" isDim={true} isAnimation={true}>
          {/* 테이블매니저앱 - 매장 선택 */}
          <div className="user-info-menu-modal-wrapper select-store-list-modal-wrapper">
            <div className="title font__subtitle">
              <span className="store_name_highlight">{mall.mallName}</span>의 관리 매장
            </div>
            <ul className="store-list" ref={storeListElement} onScroll={detectScrollStoreList}>
              {mallListOptions?.map((store) => {
                return (
                  <li key={store.value}>
                    <label className="tblm-rc">
                      <input type="radio" checked={store.value === checkedStore?.value} name="filter_group" value={store.value} onChange={() => selectMyMall(store)} />
                      <i />
                      <span>{store.label}</span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
        </Modal.Menu>
        <Modal.Menu key="listFilterModal" modal="menu" isDim={true} isAnimation={true}>
          <ListFilter
            className={'modal-content'}
            onClose={() => {
              setShowFilter(false);
              setMenuModal({ key: 'listFilterModal', visible: false });
            }}
            filter={filter}
            setFilter={setFilter}
          />
        </Modal.Menu>
        <Modal.Menu key="reservationsModal" modal="menu" isDim={true} isAnimation={true} modalContentClassName="reservation_list_only">
          <Card
            reservation={reservations[handleCardIdx]}
            cardIdx={handleCardIdx}
            className={'modal-content card__active'}
            isModal
            onClose={(type) => {
              setHandleCardIdx(null);
              setMenuModal({ key: 'reservationsModal', visible: false });
              if (type === 'enter' || type === 'exit') setToastModal({ key: type === 'enter' ? 'reservationToastEnter' : 'reservationToastExit', visible: true });
            }}
          />
        </Modal.Menu>
        <Modal.Alert key={reservationOffId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">지금 예약이 꺼져 있어요.</div>
          <div className="content font__body_sm">예약을 끄고 계실 경우 네이버, 카카오 등 타 채널 예약도 받지 않아 예약량이 줄어들 수 있어요.</div>
          <div
            className="footer success font__body_sm_sb"
            onClick={() => {
              mutate({
                operation: 'patch',
                url: '/settings/reservation/state',
                data: { mallStatus: 'using' },
              });
            }}
          >
            예약 켜기
          </div>
        </Modal.Alert>
        <Modal.Alert key={changeMyMallErrorKey} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">매장 접속에 실패하였습니다.</div>
          <div className="content font__body_sm">앱 종료 후 다시 실행해 주세요.</div>
          <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: changeMyMallErrorKey })}>
            확인
          </div>
        </Modal.Alert>
        <Modal.Toast key="reservationToastEnter" modal="toast" position="bottom" autoHideDuration={1500} margin={75} isAnimation={true}>
          <div className="content font__small_sb">입장 처리 되었습니다</div>
        </Modal.Toast>
        <Modal.Toast key="reservationToastExit" modal="toast" position="bottom" autoHideDuration={1500} margin={75} isAnimation={true}>
          <div className="content font__small_sb">퇴장 처리 되었습니다</div>
        </Modal.Toast>
        <Modal.Toast key="reservationToastCancel" modal="toast" position="bottom" autoHideDuration={1500} margin={75} isAnimation={true}>
          <div className="content font__small_sb">예약이 취소되었습니다</div>
        </Modal.Toast>
        <Modal.Toast key="reservationToastRollback" modal="toast" position="bottom" autoHideDuration={1500} margin={75} isAnimation={true}>
          <div className="content font__small_sb">예약이 복구되었습니다</div>
        </Modal.Toast>
      </ModalPortal>

      <AccessAgreement />
      <Navigation />
    </div>
  );
};

export default List;
