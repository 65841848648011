/**
 *
 * @param value {function(): boolean} check 할 value
 * @param filterFunction {Function} 값을 체크할 function, default : 값 존재여부
 * @param checkInterval {Number} 체크 주기 default : 0.05초 50ms
 * @param checkTimeout {Number} 체크 중단 시간 default : 5초 5000ms
 * @returns {Promise}
 */
export function waitUntil(value, filterFunction = (val) => Boolean(val), checkInterval = 50, checkTimeout = 5000) {
  return new Promise((resolve, reject) => {
    let interval = null;
    let timeout = null;
    interval = setInterval(() => {
      if (filterFunction(value)) {
        clearInterval(interval);
        clearTimeout(timeout);
        resolve(value);
      }
    }, checkInterval);
    timeout = setTimeout(() => {
      clearInterval(interval);
      reject(value);
    }, checkTimeout);
  });
}

/**
 *
 * @param func {Function} 결과값을 리턴할 함수
 * @param checkInterval {Number} 체크 주기 default : 0.05초 50ms
 * @param checkTimeout {Number} 체크 중단 시간 default : 5초 5000ms
 * @returns {Promise}
 */
export function waitUntilExist(func, checkInterval = 50, checkTimeout = 5000) {
  return new Promise((resolve, reject) => {
    let interval = null;
    let timeout = null;
    interval = setInterval(async () => {
      const ret = await func();
      if (ret) {
        clearInterval(interval);
        clearTimeout(timeout);
        resolve(ret);
      }
    }, checkInterval);
    timeout = setTimeout(() => {
      clearInterval(interval);
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(null);
    }, checkTimeout);
  });
}

export const promiseSerial = (functionORpromises) => functionORpromises.reduce((promise, functionORpromise) => promise.then((x) => (functionORpromise instanceof Promise ? functionORpromise : functionORpromise())
  .then((result) => ([...x, result]))), Promise.resolve([]));

export const promiseSeq = async (promises) => {
  const results = [];
  // eslint-disable-next-line no-restricted-syntax,no-await-in-loop
  for (let i = 0; i < promises.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const p = await promises[i];
    results.push(p);
  }
  return results;
};

export const delay = (ms = 0, ...values) => new Promise((resolve) => setTimeout(() => resolve(...values), ms));

export default {
  waitUntil,
  waitUntilExist,
  promiseSerial,
  promiseSeq,
  delay,
};
