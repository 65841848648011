import React, { useEffect } from 'react';
import { ReactComponent as Close } from '@/assets/images/icons/Close.svg';
import './docs/AgreementDoc.scss';

const AgreementDoc = ({ setDoc, children, isOpenMarketing, setIsOpenMarketing }): React.ReactElement => {
  const closeHandler = () => {
    isOpenMarketing ? setIsOpenMarketing(false) : setDoc(null);
  };

  return (
    <div className="agreement-doc">
      <div className="agreement-doc-inner">
        <Close onClick={closeHandler} />
        {children}
      </div>
    </div>
  );
};

export default AgreementDoc;
