import {} from './TutorialDayTime.constants';
import './TutorialDayTime.scss';

import React, { useEffect, useState } from 'react';

import { ITutorialDayTime } from './TutorialDayTime.type';
import { Nullable } from '@/shared/utils/common.type';
import classnames from 'classnames';
import groupBy from 'lodash/groupBy';
import styles from '@/components/TutorialDayTime/TutorialDayTime.module.scss';
import { timesMaker } from '@/shared/lib/functions';
import { useMallStore } from '@/stores/common/useMallStore';
import { useReservationSettingStore } from '@/stores/common/useReservationSettingStore';
//import classnames from 'classnames';
const daylist = ['월', '화', '수', '목', '금', '토', '일'];
const daylistMap = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const TutorialDayTime = ({ question, dispatcher, onNext, onPrev }: ITutorialDayTime): React.ReactElement => {
  const { mallTime } = useMallStore();
  const mallTimeMap = daylistMap.map((d) => mallTime[d]);
  const mallTimeCloseMap = mallTimeMap.map((t, idx) => (!t.isOpen ? idx : -1)).filter((i) => i >= 0);
  const { storageQuestion, setStorageQuestion } = useReservationSettingStore();
  const storedValue = storageQuestion.step6_0.value || {
    enabledTimeList: daylist.map((key) => [key, []]),
    copyList: daylist.map((key) => [key, []]),
    dayIndex: mallTimeMap.findIndex((time) => time.isOpen),
    showType: 0,
    ynList: daylist.map((key) => [key, null]),
    step: [mallTimeMap.findIndex(e=>e.isOpen)],
  };
  const term = question.step4_0.value as number;
  // @ts-ignore
  const [enabledTimeList, setEnabledTimeList] = useState<[string | number, Nullable<any[] | any>][]>(storedValue.enabledTimeList);
  // @ts-ignore
  const [copyList, setCopyList] = useState<[string | number, Nullable<any[] | any>][]>(storedValue.copyList);
  // @ts-ignore
  const [dayIndex, setDayIndex] = useState(storedValue.dayIndex);
  // @ts-ignore
  const [showType, setShowType] = useState<number>(storedValue.showType);
  // @ts-ignore
  const [ynList, setYnList] = useState<[string | number, Nullable<any[] | any>][]>(storedValue.ynList);
  // @ts-ignore
  const [step, setStep] = useState(storedValue.step);

  const commitChange = () => {
    const e = Array.from(new Set(enabledTimeList)).map(([a, b]) => [a, b.sort()]);
    // @ts-ignore
    setEnabledTimeList(e);
  };

  const submit = () => {
    onNext();
  };

  const prev = () => {
    if (showType === 0) {
      if (dayIndex === 0) {
        storageQuestion.step6_0.value = {
          // @ts-ignore
          enabledTimeList: daylist.map((key) => [key, []]),
          copyList: daylist.map((key) => [key, []]),
          dayIndex: 0,
          showType: 0,
          ynList: daylist.map((key) => [key, null]),
          step: [],
        };
        setStorageQuestion(storageQuestion);
        onPrev();
        return;
      }
      let lastStep = step.length === 0 ? step[0] : step.splice(step.length - 1)[0];
      setYnList((state) => {
        state[dayIndex][1] = null;
        return state;
      });
      setDayIndex(lastStep === undefined ? 0 : lastStep);
      setShowType(ynList[lastStep][1] || enabledTimeList[lastStep][1].length > 0 ? 2 : 0);
      setStep(step);
      return;
    }
    // @ts-ignore
    if (showType === 2) {
      setCopyList((state) => {
        state[dayIndex][1] = [];
        return state;
      });
    }
    setShowType((state) => state - 1);
  };

  const next = (bool) => {
    if (bool === undefined) return;
    if (showType === 0) {
      if (!bool) {
        setYnList((state) => {
          state[dayIndex][1] = false;
          return state;
        });
        setCopyList((state) => {
          state[dayIndex][1] = [];
          return state;
        });
        enabledTimeList[dayIndex][1] = [];
        commitChange();
        if (dayIndex === 6) {
          submit();
          return;
        }
        let remainDays = [0, 1, 2, 3, 4, 5, 6]
          .filter((d) => d > dayIndex)
          .filter((d) => !mallTimeCloseMap.includes(d))
          .find((d) => copyList.every(([_, list]) => !list.includes(d)));
        if (!remainDays) {
          submit();
        } else {
          setStep((steps) => [...steps, dayIndex]);
          setDayIndex((v) => v + 1);
        }
      } else {
        setYnList((state) => {
          state[dayIndex][1] = true;
          return state;
        });
        setShowType(1);
      }
      return;
    }
    if (showType === 1) {
      let remainDays = [0, 1, 2, 3, 4, 5, 6]
        .filter((d) => d > dayIndex)
        .filter((d) => !mallTimeCloseMap.includes(d))
        .find((d) => copyList.every(([_, list]) => !list.includes(d)));
      if (!remainDays) {
        submit();
      } else {
        setShowType(2);
      }
      return;
    }
    if (showType === 2) {
      let remainDays = [0, 1, 2, 3, 4, 5, 6]
        .filter((d) => d > dayIndex)
        .filter((d) => !mallTimeCloseMap.includes(d))
        .find((d) => copyList.every(([_, list]) => !list.includes(d)));
      if (!remainDays) {
        submit();
      } else {
        setStep((steps) => [...steps, dayIndex]);
        setDayIndex(remainDays);
      }
      setShowType(0);
    }
  };

  const times = timesMaker(term, mallTimeMap[dayIndex]);
  const dayTimeGroup = groupBy(times, (time) => time.split(':')[0]);
  const dayTimeList = Object.entries(dayTimeGroup).sort((a, b) => Number(a[0]) - Number(b[0]));

  const allCheck = (e) => {
    if (e.target.checked) {
      enabledTimeList[dayIndex][1] = [...times];
    } else {
      enabledTimeList[dayIndex][1] = [];
    }
    commitChange();
  };

  const hourChecked = (hour) => {
    return dayTimeGroup[hour].every((time) => enabledTimeList[dayIndex][1].includes(time));
  };
  const minuteChecked = (time) => {
    return enabledTimeList[dayIndex][1].includes(time);
  };

  const hourCheck = (hour, checked) => {
    if (checked) {
      enabledTimeList[dayIndex][1].push(...dayTimeGroup[hour]);
    } else {
      enabledTimeList[dayIndex][1] = enabledTimeList[dayIndex][1].filter((time) => !time.startsWith(hour));
    }
    commitChange();
  };
  const minuteCheck = (time, checked) => {
    if (checked) {
      enabledTimeList[dayIndex][1].push(time);
    } else {
      enabledTimeList[dayIndex][1] = enabledTimeList[dayIndex][1].filter((item) => item !== time);
    }
    commitChange();
  };

  const copyDayCheck = (e) => {
    if (e.target.checked) {
      setCopyList((state) => {
        const tmpState = [...state];
        tmpState[dayIndex][1].push(Number(e.target.value));
        // @ts-ignore
        tmpState[dayIndex][1] = Array.from(new Set(tmpState[dayIndex][1])).sort();
        return tmpState;
      });
    } else {
      setCopyList((state) => {
        const tmpState = [...state];
        tmpState[dayIndex][1] = tmpState[dayIndex][1].filter((item) => item !== Number(e.target.value));
        return tmpState;
      });
    }
  };
  const isDisabledCopy = (idx) => {
    for (let copyDayIndex = 0; copyDayIndex < copyList.length; copyDayIndex += 1) {
      if (copyDayIndex < dayIndex && copyList[copyDayIndex][1].includes(Number(idx))) return true;
    }
    return false;
  };

  const dispatchRealtime = () => {
    storageQuestion.step6_0.value = {
      // @ts-ignore
      enabledTimeList,
      copyList,
      dayIndex,
      showType,
      ynList,
      step,
    };
    setStorageQuestion(storageQuestion);
  };

  useEffect(() => {
    dispatchRealtime();
  }, [enabledTimeList]);
  useEffect(() => {
    dispatchRealtime();
  }, [copyList]);
  useEffect(() => {
    dispatchRealtime();
  }, [dayIndex]);
  useEffect(() => {
    dispatchRealtime();
  }, [showType]);
  useEffect(() => {
    dispatchRealtime();
  }, [ynList]);
  useEffect(() => {
    dispatchRealtime();
  }, [step]);

  return (
    <div className="tutorial-content-container tutorial-content-container-time">
      <div className={classnames('tutorial-content', { time: showType === 1 })} key={dayIndex}>
        {showType === 0 ? (
          <>
            {dayIndex === 0 ? (
              <div className={'tutorial-note before'}>
                이제 요일별 예약 일정을
                <br />
                설정해볼게요!
              </div>
            ) : null}
            <div className={'tutorial-question'}>
              {daylist[dayIndex]}요일에 <br />
              예약 받으세요?
            </div>
            <div className={classnames('tutorial-interaction', { button: showType === 0 })} key={dayIndex}>
              <button
                className={classnames({ active: ynList[dayIndex][1] === true })}
                onClick={() => {
                  next(true);
                }}
              >
                네
              </button>
              <button
                className={classnames({ active: ynList[dayIndex][1] === false })}
                onClick={() => {
                  next(false);
                }}
              >
                아니요
              </button>
            </div>
          </>
        ) : null}
        {showType === 1 ? (
          <>
            <div className="tutorial-subquestion-head">
              <div className={'tutorial-subquestion-title'}>
                <span>{daylist[dayIndex]}요일</span>에 예약 받을 시간을
                <br />
                모두 골라주세요.
              </div>
              <label className="tblm-rc">
                <input type="checkbox" name="day" onChange={allCheck} checked={enabledTimeList[dayIndex][1].length === times.length} />
                <i />
                <span>전체선택</span>
              </label>
            </div>
            <div className="tutorial-subquestion-body">
              <ul>
                {dayTimeList.map(([hour, timeArray]) => {
                  return (
                    <li key={hour}>
                      <label className="tblm-rc">
                        <input type="checkbox" name="day" checked={hourChecked(hour)} onChange={(e) => hourCheck(hour, e.target.checked)} />
                        <i />
                        <span>{hour}시</span>
                      </label>
                      <div className="times">
                        {timeArray.map((time) => (
                          <label className={'time-item'} key={time}>
                            <input type="checkbox" name="time" checked={minuteChecked(time)} onChange={(e) => minuteCheck(time, e.target.checked)} />
                            <div>{time}</div>
                          </label>
                        ))}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        ) : null}
        {showType === 2 ? (
          <>
            <div className="tutorial-subquestion-head">
              <div className={'tutorial-subquestion-title'} style={{ marginBottom: '48px' }}>
                <span>{daylist[dayIndex]}요일</span>과 예약 받는 시간이
                <br />
                같은 요일을 모두 골라주세요.
              </div>
            </div>
            <div className="tutorial-subquestion-body sameday">
              <ul className={classnames(styles.list__filter_content, styles.list__filter_group_list)}>
                {daylist.map((day, idx) =>
                  !mallTimeCloseMap.includes(idx) ? (
                    <li key={idx}>
                      <label className="tblm-rc">
                        {dayIndex <= idx ? (
                          <>
                            <input
                              type="checkbox"
                              name="day"
                              readOnly={idx <= dayIndex}
                              disabled={dayIndex !== idx && isDisabledCopy(idx)}
                              value={idx}
                              checked={copyList[dayIndex][1].includes(idx)}
                              onChange={copyDayCheck}
                            />
                            <i />
                          </>
                        ) : (
                          <>
                            <input type="checkbox" name="day" readOnly={idx <= dayIndex} disabled={isDisabledCopy(idx)} value={idx} />
                            <i />
                          </>
                        )}
                        <span className={daylist[dayIndex] === day ? 'acitve-day' : ''}>{day}</span>
                      </label>
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          </>
        ) : null}
      </div>
      <div className="button-container">
        <button className="prev" onClick={prev}>
          이전
        </button>
        <button className="next" onClick={() => next(ynList[dayIndex][1])}>
          다음
        </button>
      </div>
    </div>
  );
};

export default TutorialDayTime;
