import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import ManagementCalendar from '@/components/ManagementCalendar/ManagementCalendar';
import { useQuery } from 'react-query';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';

const ScheduleManagement = (): React.ReactElement => {
  const { accessToken } = useAuthStore();
  const navigate = useNavigate();
  const today = dayjs();
  const [eventDays, setEventDays] = useState([]);

  const { isLoading } = useQuery([QUERY_KEYS.GET_EVENT_DAY], () => devServerApi.authGet('/eventDays', accessToken), {
    onSuccess: (res: any) => {
      setEventDays(res.eventdays?.map((item) => ({ date: dayjs(`${item.year}-${item.month}-${item.day}`), memo: item.memo })));
    },
  });
  if (isLoading) return <SpinnerEffector loading={true} />;
  return (
    <div className="flex-container flex-col gap-24 bg-white">
      <HeaderBack isFixed>
        <div className="font__subtitle">일정 관리 </div>
      </HeaderBack>
      <section className="month-calendar-wrapper">
        <ManagementCalendar
          select={(targetDay) => navigate('./management-day', { state: { targetDay: targetDay.toISOString() } })}
          startDate={today.add(-1, 'month')}
          targetDate={today}
          headerHeight={56}
          holidays={eventDays}
        />
      </section>
    </div>
  );
};

export default ScheduleManagement;
