import React from 'react';
import Privacy from './privacy';
import Third from './third';

const doc = (): React.ReactElement => {
  return (
    <div className="agreement-doc-body privacy-and-third">
      <Privacy className="privacy" />
      <Third className="third" />
    </div>
  );
};
export default doc;
