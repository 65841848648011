import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import FooterSub from '@/components/FooterSub/FooterSub';
import HeaderSub from '@/components/HeaderSub/HeaderSub';
import TimePicker from '@/components/TimePicker/TimePicker';
import dayjs from 'dayjs';
import { useMallStore } from '@/stores/common/useMallStore';
import { usePolicyStore } from '@/stores/common/usePolicyStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';

const INITIAL_STEP_TEXTS = {
  header: (
    <>
      예약 시간을 <br />
      선택 해주세요
    </>
  ),
  footer: { save: '저장', back: '뒤로', next: '다음' },
  depositHeader: (
    <>
      언제까지 예약금을 <br />
      결제해야 하나요?
    </>
  ),
};

const Step2 = (): React.ReactElement => {
  const { visitDate, visitTime, setVisitTime, setParty, setRooms, depositInfo, setDepositInfo, setReservationResetStore } = useReservationStore();
  const { reservationDays } = usePolicyStore();
  const { mall } = useMallStore();
  const [selectTime, setSelectTime] = useState(false);
  const [searchParams] = useSearchParams({ type: 'default' });

  const navigate = useNavigate();

  const today = dayjs();

  // const bookingTimes = visitDate ? reservationDays[dayNamesTypeB[visitDate.day()]].bookingTimes : [];

  const handleOnRouterMove = (path) => navigate(path);

  const handleOnClose = () => {
    if (searchParams.get('type') === 'default') {
      setReservationResetStore();
      navigate('/reservation/list');
    }
    if (searchParams.get('type') === 'modify' || searchParams.get('type') === 'deposit') navigate(-1);
  };

  const getSelectedDate = () => {
    if (searchParams.get('type') === 'default' || searchParams.get('type') === 'modify') {
      return visitTime ? visitTime : today;
    }
    if (searchParams.get('type') === 'deposit') {
      return depositInfo?.depositDue;
    }
  };

  const getStartMinute = () => {
    if (searchParams.get('type') === 'default' || searchParams.get('type') === 'modify') {
      return mall.operationTime.openTime;
    }
    if (searchParams.get('type') === 'deposit') {
      const gap = depositInfo.depositDue.startOf('d').diff(today.startOf('d'), 'day');
      if (gap === 0) {
        return (today.get('hour') * 60) + today.get('minute');
      } else if (gap >= 1) {
        return mall.operationTime.openTime;
      }
    }
  };

  const getEndMinute = () => {
    if (searchParams.get('type') === 'default' || searchParams.get('type') === 'modify') {
      return mall.operationTime.closeTime;
    }
    if (searchParams.get('type') === 'deposit') {
      const gap = visitDate.diff(today, 'day');
      if (gap === 0) {
        if (visitTime.get('h') > 24) return mall.operationTime.closeTime;
        else if (today.get('h') + 1 === visitTime.get('h')) return visitTime.get('hour') * 60 + visitTime.get('minute');
        else if (today.get('h') + 1 < visitTime.get('h')) return visitTime.get('hour') * 60 + visitTime.get('minute');
      } else if (visitDate.isSame(depositInfo.depositDue, 'd')) {
        return visitTime.get('hour') * 60 + visitTime.get('minute');
      } else if (gap >= 1) {
        return mall.operationTime.closeTime;
      }
    }
  };

  useEffect(() => {
    if (visitTime && today && visitDate) {
      const gap = visitDate.diff(today, 'day');
      if (gap === 0 && today.get('h') >= visitTime.get('h')) {
        navigate('/reservation/accept/step-5?scrollTo=deposit');
      }
    }
  }, [visitTime, today, visitDate]);

  if (searchParams.get('type') === 'deposit' && !depositInfo?.depositDue) navigate('/reservation/accept/step-1?type=deposit');

  return (
    <>
      <HeaderSub onClose={handleOnClose}>
        {
          searchParams.get('type') === 'deposit' ?
          INITIAL_STEP_TEXTS.depositHeader : INITIAL_STEP_TEXTS.header
        }
      </HeaderSub>
      <section className="timepicker-wrapper">
        <TimePicker
          onSelect={(target) => {
            setSelectTime(true);
            if (searchParams.get('type') === 'deposit') {
              setDepositInfo({ ...depositInfo, depositDue: target });
            } else if (searchParams.get('type') === 'modify') {
              setVisitTime(target);
              setParty([]);
              setRooms([]);
              handleOnRouterMove(-1);
            } else {
              setVisitTime(target);
              handleOnRouterMove('/reservation/accept/step-3/');
            }
          }}
          selectedDate={getSelectedDate()}
          startMinute={getStartMinute()}
          endMinute={getEndMinute()}
        />
      </section>
      <FooterSub isVisible isBorder isBackArea>
        {searchParams.get('type') === 'default' || searchParams.get('type') === 'modify' ? (
          <div className="font__subtitle flex-container items-center">
            {visitDate?.format('MM.DD(ddd)')}
            {visitDate && visitTime && ','} {visitTime?.format('a HH:mm')}
          </div>
        ) : null}
        {searchParams.get('type') === 'deposit' ? (
          <div className="font__subtitle flex-container items-center">
            {depositInfo?.depositDue?.format('MM.DD(ddd)')}
            {selectTime && depositInfo?.depositDue?.format(', a HH:mm')}
          </div>
        ) : null}
        <div className="flex-container gap-8">
          {searchParams.get('type') === 'modify' ? (
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="footer tblm-button-normal tblm-btn-primary"
            >
              {INITIAL_STEP_TEXTS.footer.save}
            </button>
          ) : null}
          {searchParams.get('type') === 'default' ? (
            <>
              <Link to={'/reservation/accept/step-1/'}>
                <button className="tblm-button-normal">{INITIAL_STEP_TEXTS.footer.back}</button>
              </Link>
              <Link to={'/reservation/accept/step-3/'}>
                <button disabled={Boolean(!visitTime)} className="tblm-button-normal tblm-btn-primary">
                  {INITIAL_STEP_TEXTS.footer.next}
                </button>
              </Link>
            </>
          ) : null}
          {searchParams.get('type') === 'deposit' ? (
            <Link to='/reservation/accept/step-5/?scrollTo=deposit'>
              <button className="tblm-button-normal tblm-btn-primary">{INITIAL_STEP_TEXTS.footer.next}</button>
            </Link>
          ) : null}
        </div>
      </FooterSub>
    </>
  );
};

export default Step2;
