import React, { useEffect } from 'react';
import { useLayoutStore } from '@/stores/common/useLayoutStore';

const SpinnerEffector = ({ loading }): React.ReactElement => {
  const { setSpinner } = useLayoutStore();
  useEffect(()=> {
    if (loading) setSpinner(true);
    else setSpinner(false);
    return () => {
      setSpinner(false);
    };
  }, [loading]);
  return <></>;
};

export default SpinnerEffector;
