import React, { useEffect, useState } from 'react';
import { } from './TimeFilter.constants';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useMallStore } from '@/stores/common/useMallStore';
import styles from './TimeFilter.module.scss';
import { ITimeFilter } from './TimeFilter.type';

const TimeFilter = ({ selectedType, setSelectedType, selectedDate }: ITimeFilter): React.ReactElement => {
  const { multipleDivision } = useMallStore((state) => state.mall);

  return (
    <section className="time-div-wrap">
      <div className="time-div">
        {multipleDivision.length === 0 ? (
          <>
            <label className="tblm-rc">
              <input type="radio" name="timeFilter" value={'all'} checked={selectedType === 'all'} onChange={(e) => setSelectedType(e.target.value)} />
              <i />
              <span>전체</span>
            </label>
            <label className="tblm-rc">
              <input type="radio" name="timeFilter" value={'lunch'} checked={selectedType === 'lunch'} onChange={(e) => setSelectedType(e.target.value)} />
              <i />
              <span>점심</span>
            </label>
            <label className="tblm-rc">
              <input type="radio" name="timeFilter" value={'dinner'} checked={selectedType === 'dinner'} onChange={(e) => setSelectedType(e.target.value)} />
              <i />
              <span>저녁</span>
            </label>
          </>
        ) : (
          <>
            <label className="tblm-rc">
              <input type="radio" name="timeFilter" value={'all'} checked={selectedType === 'all'} onChange={(e) => setSelectedType(e.target.value)} />
              <i />
              <span>전체</span>
            </label>
            {multipleDivision
              .filter((division) => division.divisionDay === selectedDate.get('day'))
              .map((divison, idx) => (
                <label className="tblm-rc" key={idx}>
                  <input
                    type="radio"
                    name="timeFilter"
                    value={divison.divisionId}
                    checked={selectedType === divison.divisionId}
                    onChange={(e) => setSelectedType(e.target.value)}
                  />
                  <i />
                  <span>{divison.koName}</span>
                </label>
              ))}
          </>
        )}
      </div>
    </section>
  );
};

export default TimeFilter;
