import React from 'react';
import './TableGroup.scss';
import { ReactComponent as Trash } from '@/assets/images/icons/Trash.svg';
import { ReactComponent as Edit } from '@/assets/images/icons/ic_edit.svg';
import { ReactComponent as GroupAdd } from '@/assets/images/icons/ic_group_add.svg';
import { ReactComponent as TableIcon } from '@/assets/images/icons/table.svg';
import Table from '../Table/Table';
function TableGroup({ tableGroupData, openChangeGroupNameModal, openTableAddModal, openTableGroupRemoveModal, openTableSettingModal, setEnable }) {
  return (
    <div className="table-group-container">
      <div className='table-group-header'>
        <span className='table-group-name font__title_20_m' onClick={() => openChangeGroupNameModal(tableGroupData)}>{tableGroupData.groupName}</span>
        <Trash className='icon-trash' onClick={() => openTableGroupRemoveModal(tableGroupData)} />
      </div>
      <div className='table-list-wrapper'>
        {
          tableGroupData.item.map((table, index) => <Table key={table._id + '_' + index} tableData={{ ...table, groupId: tableGroupData.groupId }} openTableSettingModal={openTableSettingModal} setEnable={setEnable} />)
        }
        <button className='tblm-button-normal tblm-btn-paleblue table-add-button' onClick={() => openTableAddModal(tableGroupData)}><TableIcon />테이블 추가</button>
      </div>
    </div>
  );
}

export default TableGroup;
