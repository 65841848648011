import { useEffect } from 'react';
import create from 'zustand';

interface IUseNativeRoutingStore {
	backHook: any;
	setBackHook: (backHook: any) => any;
}

export const useNativeRoutingStore = create<IUseNativeRoutingStore>((set) => ({
	backHook: null,
	setBackHook: (backHook: any) => set(() => ({ backHook })),
}));
