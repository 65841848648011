/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import './CalendarPicker.scss';
import Picker from '@/components/ScrollPicker/ScrollPicker';
import { useModalStore } from '@/stores/common/useModalStore';
import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';

const CalendarPicker = (props): React.ReactElement => {
  const { viewDate, finish, modalKey, startDate, endDate } = props;
  const { setMenuModal } = useModalStore();
  // const [optionGroups, __] = useState({
  //   years: { list: Array.from({ length: endDate.year() - startDate.year() + 1 }, (_, i) => i + startDate.year()), unit: '년' },
  //   months: { list: Array.from({ length: 12 }, (_, i) => i + 1), unit: '월' },
  // });
  const period = Number(`${(endDate.year() - startDate.year()) * 12 + endDate.month() - startDate.month()}`) + 1;

  const [optionGroups, __] = useState({
    yearmonths: {
      list: Array(period)
        .fill(null)
        .map((item, i) => {
          const date = startDate.add(i, 'month');
          return [date.year(), date.month() + 1];
        }),
      unit: '년월',
    },
  });

  // const [valueGroups, setValueGroup] = useState({
  //   years: viewDate.year(),
  //   months: viewDate.month() + 1,
  // });
  const [valueGroups, setValueGroup] = useState({
    yearmonths: [viewDate.year(), viewDate.month() + 1],
  });

  const handlePickerChange = (name, value) => {
    setValueGroup({ ...valueGroups, [name]: value });
  };

  const finishCalendarModal = () => {
    const resultDate = dayjs()
      .year(valueGroups.yearmonths[0])
      .month(valueGroups.yearmonths[1] - 1);

    finish(resultDate);
    setMenuModal({ visible: false, key: modalKey });
  };
  useEffect(() => {
    setValueGroup({ ...valueGroups, yearmonths: [viewDate.year(), viewDate.month() + 1] });
  }, [viewDate]);

  return (
    <div>
      <ModalPortal>
        <Modal.Menu key={modalKey} modal="menu" isDim={true} isAnimation={true}>
          <div className="picker-body modal-content">
            <div className="picker-title">캘린더 선택</div>
            <Picker optionGroups={optionGroups} valueGroups={valueGroups} onChange={handlePickerChange} itemHeight={28} height={164} />
          </div>
          <div className="picker-footer">
            <button className="set-button tblm-button-normal tblm-btn-primary" onClick={finishCalendarModal}>
              확인
            </button>
          </div>
        </Modal.Menu>
      </ModalPortal>
    </div>
  );
};

export default CalendarPicker;
