import { useReservationStore } from '@/stores/reservation/useReservationStore';
import './DepositSetting.scss';
import ModalPortal from '../ModalPortal/ModalPortal';
import Modal from '../Modal/Modal';
import { useId, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useModalStore } from '@/stores/common/useModalStore';
import Select from '../Select/Select';
import { useMallStore } from '@/stores/common/useMallStore';
import Input from '../Input/Input';
import dayjs from 'dayjs';

function DepositSetting() {
  const { setMenuModal, setAlertModal } = useModalStore();
  const { depositInfo } = useReservationStore();
  const cancelAndRefundModal = useId();
  const refundPercentageModal = useId();
  const onlyRefundModal = useId();

  const methods = useFormContext();
  const { control, getValues, watch } = methods;

  const [selectRefundCharge, setSelectRefundCharge] = useState({ label: null, value: null });
  const reservationRefundPercentageData: any[] = useMemo(() => {
    return [
      { label: '100%', value: '5000원' },
      { label: '80%', value: '3000원' },
      { label: '50%', value: '3000원' },
      { label: '30%', value: '3000원' },
      { label: '10%', value: '3000원' },
      { label: '0%', value: '환불 금액 없음' },
      {
        label: '환불금액 직접입력',
        value: <Input name="text-basic" type="text" placeholder="환불금액을 입력해주세요" value={''} className="table-name-input" />,
      },
    ];
  }, []);

  const depositState = () => {
    if (depositInfo?.depositState === 'unused') {
      return '미사용';
    } else if (depositInfo?.depositState === 'unpaid' || depositInfo?.depositState === 'paid') {
      return '사용';
    }
  };

  const getPaymentTypeString = (type) => {
    if (type === 'card') {
      return '카드';
    }
    if (type === 'vbank') {
      return '가상계좌';
    }
    return '';
  };

  return (
    <>
      <div className="depositsetting-wrapper" data-scrolled="5">
        <div className="flex-container justify-between items-center">
          <div className="title">예약금 설정</div>
        </div>
        <div className={'depositsetting-content'}>
          <div>
            <div>예약금</div>
            <div>{depositState()}</div>
          </div>
          <div>
            <div>예약금액</div>
            <div>{depositInfo?.depositAmount ?? '0'}원</div>
          </div>
          <div className="payment-status-wrap">
            <div>결제여부</div>
            <div className="payment-status-values flex-container justify-between items-center">
              <div>{depositInfo?.depositPaidAt ? '결제 완료' : '미결제'}</div>
              {!depositInfo?.depositPaidAt ? (
                <button
                  className="tblm-button-small"
                  onClick={() => {
                    setAlertModal({ visible: true, key: cancelAndRefundModal });
                    // handleMoveRoute('/reservation/accept/step-1?type=deposit');
                  }}
                >
                  환불
                </button>
              ) : null}
            </div>
          </div>
          <div>
            <div>결제기한</div>
            <div>{dayjs(depositInfo?.depositDue).format('YYYY년 M월 D일 a HH시')}</div>
          </div>
          <div>
            <div>결제수단</div>
            <div>{getPaymentTypeString(depositInfo?.depositPaymentType)}</div>
          </div>
        </div>
      </div>
      <ModalPortal>
        <Modal.Alert key={cancelAndRefundModal} modal="alert" isDim={true} isAnimation={true}>
          <div className="deposit-setting-modal-content">
            <div className="title font__subtitle">결제된 예약금이 있습니다.</div>
            <div className="payment-info-desc">{'5,000원(카드결제 2022년 8월 17일 19:00)'}</div>
            <Select
              onClick={(e) => {
                setMenuModal({ visible: true, key: refundPercentageModal });
              }}
              value={selectRefundCharge.label ? `${selectRefundCharge.label} (${selectRefundCharge.value})` : '환불 금액을 설정해주세요.'}
            />
            <p className="refund-info-desc">{'5,000원 중 4,000원이 환불됩니다.'}</p>
          </div>
          <div className="footer-multiple-buttons">
            <div className={`footer success font__body_sm_sb ${selectRefundCharge.label?.length ? null : 'disabled-footer-btn'}`}>환불만 진행</div>
            <div className={`footer success font__body_sm_sb ${selectRefundCharge.label?.length ? null : 'disabled-footer-btn'}`}>환불하고 예약취소</div>
          </div>
        </Modal.Alert>
        <Modal.Alert key={onlyRefundModal} modal="alert" isDim={true} isAnimation={true}>
          <div className="deposit-setting-modal-content">
            <div className="title font__subtitle">예약금을 환불할까요?</div>
            <div className="small-payment-info-desc payment-info-desc">{'5,000원(카드결제 2022년 8월 17일 19:00)'}</div>
            <p className="refund-info-desc">예약금을 환불해도 예약은 취소되지 않습니다.</p>
          </div>
          <div className="footer-multiple-buttons">
            <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: onlyRefundModal })}>
              취소
            </div>
            <div onClick={() => {}} className="footer success font__body_sm_sb">
              환불
            </div>
          </div>
        </Modal.Alert>
      </ModalPortal>
      <ModalPortal>
        <Modal.Menu key={refundPercentageModal} modal="menu" isDim={true} isAnimation={true}>
          <div className="deposit-setting-modal-content">
            <div className="title font__subtitle">예약금 환불 비율 설정</div>
            <ul>
              {reservationRefundPercentageData.map((percentRefund, idx) => (
                <li key={percentRefund.label + idx}>
                  <label className="tblm-rc">
                    <input
                      type="radio"
                      name="filter_group"
                      value={percentRefund.value}
                      checked={selectRefundCharge.label === percentRefund.label}
                      defaultChecked={selectRefundCharge.label || null}
                      onChange={(e) => {
                        setSelectRefundCharge({ label: percentRefund.label, value: percentRefund.value });
                        setMenuModal({ visible: false, key: refundPercentageModal });
                      }}
                    />
                    <i />
                    <span>
                      {percentRefund.label}
                      <b>{percentRefund.value} 환불</b>
                    </span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Menu>
      </ModalPortal>
    </>
  );
}

export default DepositSetting;
