import { useNavigate } from 'react-router-dom';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import { useId, useState } from 'react';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { IMutationProps, Nullable } from '@/shared/utils/common.type';
import { useMutation, useQuery } from 'react-query';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import { useModalStore } from '@/stores/common/useModalStore';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import ScrollPicker from '@/components/ScrollPicker/ScrollPicker';
import dayjs from 'dayjs';
import { min } from 'lodash';

interface INotificationSetting {
  isEnabled: boolean;
}
interface ICustomPause {
  hours: number;
  minutes: number;
}

interface IPauseSetting {
  pauseType: 'CALLPAUSE01' | 'CALLPAUSE02' | 'CALLPAUSE03' | 'CALLPAUSE04' | 'CALLPAUSE05';
  pauseUntil: dayjs.Dayjs;
}

interface IPauseCustomSetting {
  pauseType: 'CALLPAUSE01' | 'CALLPAUSE02' | 'CALLPAUSE03' | 'CALLPAUSE04' | 'CALLPAUSE05';
  customPauseUntil: ICustomPause;
}

interface ICallAlarmSettingResponse {
  status: number;
  message: string;
  'tm-api-request-uuid': string;
  callNotification: INotificationSetting;
  pauseIncomming: IPauseSetting;
}

interface IPauseType {
  name: '10분 동안' | '1시간 동안' | '자정까지' | '내일까지' | '항상 받음';
  value: 'CALLPAUSE01' | 'CALLPAUSE02' | 'CALLPAUSE03' | 'CALLPAUSE04' | 'CALLPAUSE05';
}

const CallAlarmSetting = () => {
  const navigate = useNavigate();
  const { accessToken, appPushContext } = useAuthStore();
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
  const [pauseSetting, setPauseSetting] = useState<Nullable<IPauseSetting>>({ pauseType: 'CALLPAUSE01', pauseUntil: dayjs() });
  const [notificationSetting, setNotificationSetting] = useState<Nullable<INotificationSetting>>({ isEnabled: true });
  const errorModalId = useId();
  const popupOffModalId = useId();
  const tomorrowPopupOffModalId = useId();
  const { setAlertModal, setMenuModal } = useModalStore();
  const [errorMessage, setErrorMessage] = useState<String>('');
  const [tempSetting, setTempSetting] = useState<Nullable<IPauseCustomSetting>>({ pauseType: 'CALLPAUSE01', customPauseUntil: { hours: 0, minutes: 0 } });
  const pauseTypes: IPauseType[] = [
    { name: '10분 동안', value: 'CALLPAUSE01' },
    { name: '1시간 동안', value: 'CALLPAUSE02' },
    { name: '자정까지', value: 'CALLPAUSE03' },
    { name: '내일까지', value: 'CALLPAUSE04' },
    { name: '항상 받음', value: 'CALLPAUSE05' },
  ];
  const popupOffOptionGroups = {
    0: { list: ['오전', '오후'], unit: '', selected: '오전' },
    1: { list: Array.from({ length: 12 }, (_, i) => i), unit: '시', selected: 1 },
    2: { list: ['00', '30'], unit: '분', selected: '00' },
  };
  const [popupOffValueGroups, setPopupOffValueGroups] = useState({
    0: '오전',
    1: 1,
    2: '00',
  });

  const { isFetching, refetch } = useQuery([QUERY_KEYS.GET_CALL_ALARM_SETTING], () => devServerApi.api.get<ICallAlarmSettingResponse>(`/call-notification/settings/${appPushContext.deviceId}`), {
    onSuccess: (res) => {
      if (res.data.status < 0) {
        setErrorMessage(res.data.message);
        setAlertModal({ visible: true, key: errorModalId });
      } else {
        setPauseSetting({
          ...res.data.pauseIncomming,
          pauseUntil: dayjs(res.data.pauseIncomming.pauseUntil),
        });
        setNotificationSetting({
          ...res.data.callNotification,
        });
      }
    },
  });

  const { mutate: pauseMutate, isLoading: pauseLoading } = useMutation((mutatedata: IMutationProps) => devServerApi.mutation(mutatedata), {
    onMutate: () => ({
      key: [QUERY_KEYS.UPDATE_PAUSE_SETTING],
    }),
    onSuccess: (data: any, variables, context) => {
    },
  });

  const { mutate: notificationMutate, isLoading: notificationLoading } = useMutation((mutatedata: IMutationProps) => devServerApi.mutation(mutatedata), {
    onMutate: () => ({
      key: [QUERY_KEYS.UPDATE_NOTIFICATION_SETTING],
    }),
    onSuccess: (data: any, variables, context) => {
    },
  });

  const popPopupOffModal = () => {
    let hour = pauseSetting.pauseUntil.get('hour');
    let minute = pauseSetting.pauseUntil.get('minute');
    setTempSetting({
      pauseType: pauseSetting.pauseType, customPauseUntil: {
        hours: hour,
        minutes: minute,
      },
    });
    setMenuModal({ visible: true, key: popupOffModalId });
  };

  const getCallAlarmTypeString = () => {
    if (pauseSetting.pauseType === 'CALLPAUSE01') {
      return '10분 동안';
    }
    if (pauseSetting.pauseType === 'CALLPAUSE02') {
      return '1시간 동안';
    }
    if (pauseSetting.pauseType === 'CALLPAUSE03') {
      return '자정까지';
    }
    if (pauseSetting.pauseType === 'CALLPAUSE04') {
      let hour = pauseSetting.pauseUntil.get('hour');
      let minute = pauseSetting.pauseUntil.get('minute');
      let blockString = <>내일 {hour < 12 ? '오전' : '오후'}<br />{hour % 12}시 {minute}분까지</>;
      return blockString;
    }
    if (pauseSetting.pauseType === 'CALLPAUSE05') {
      return '항상 받음';
    }

    return '';
  };

  const changeNotificationSetting = (changedSetting: INotificationSetting) => {
    setNotificationSetting({ ...changedSetting });
    const data = { ...changedSetting, deviceId: appPushContext.deviceId };
    pauseMutate({
      operation: 'patch',
      url: '/call-notification/settings',
      data,
    });
  };

  const changePauseSetting = (changedSetting: IPauseSetting, changedTime?: ICustomPause) => {
    if (changedTime) {
      setPauseSetting({ ...changedSetting, pauseUntil: dayjs().add(1, 'day').startOf('date').add(changedTime.hours, 'hour').add(changedTime.minutes, 'minute') });
    } else {
      setPauseSetting(changedSetting);
    }
    const data = { ...changedSetting, customPauseUntil: changedTime, deviceId: appPushContext.deviceId };
    notificationMutate({
      operation: 'patch',
      url: '/call-notification/settings/pause-incomming',
      data,
    });
  };

  const changeTempPauseType = (type) => {
    if (type === 'CALLPAUSE04') {
      setTempSetting({ ...tempSetting, pauseType: type });
      let hour = pauseSetting.pauseUntil?.get('hour') || 0;
      let minute = pauseSetting.pauseUntil?.get('minute') || 0;
      setPopupOffValueGroups({
        0: hour < 12 ? '오전' : '오후',
        1: hour % 12,
        2: minute <= 30 ? '00' : '30',
      });
      setMenuModal({ visible: true, key: tomorrowPopupOffModalId });
    } else {
      setMenuModal({ visible: false, key: popupOffModalId });
      changePauseSetting({ ...pauseSetting, pauseType: type });
    }
  };
  const setOnPopupOffTime = () => {
    let hours = popupOffValueGroups[1];
    let minutes = Number(popupOffValueGroups[2]);
    if (popupOffValueGroups[0] === '오후') {
      hours = hours + 12;
    }
    setMenuModal({ visible: false, key: tomorrowPopupOffModalId });
    changePauseSetting({ ...pauseSetting, pauseType: tempSetting.pauseType }, { hours, minutes });
  };

  if (isFetching) return <SpinnerEffector loading={isFetching} />;
  if (errorMessage) return (
    <>
      <Modal.Alert key={errorModalId} modal="alert" isDim={true} isAnimation={true}>
        <div className="title font__body_md_sb">알림</div>
        <div className="content font__body_sm">
          {errorMessage}
        </div>
        <div className="footer success font__body_sm_sb" onClick={() => {
          navigate(-1);
          setErrorMessage('');
          setAlertModal({ visible: false, key: errorModalId });
        }}>
          확인
        </div>
      </Modal.Alert>
    </>
  );
  return (
    <div>
      <HeaderBack inBack={true}>
        <div className="font__subtitle_sb">통화 알림 설정</div>
      </HeaderBack>
      <section className="settings-policy-detail-wrapper same-day-reservation-wrapper deposit-policy-setting-wrapper">
        <div className="switch-area">
          <div className="left-area">
            <div className="font__title_20_sb">통화팝업 수신 일시 차단</div>
            <div className="font__body_sm_m">통화팝업을 잠깐동안<br />표시하지 않음</div>
          </div>
          <div className="right-area gap-16" onClick={() => popPopupOffModal()}>
            <div className="font__title_20_sb">{getCallAlarmTypeString()}</div>
          </div>
        </div>
        <div className="switch-area">
          <div className="left-area">
            <div className="font__title_20_sb">매장 통화팝업</div>
            <div className="font__body_sm_m">매장에 온 통화 정보를<br />내 휴대폰 알림창에 표시</div>
          </div>
          <div className="right-area gap-16">
            <div className="font__small">알림 {notificationSetting.isEnabled ? '' : '미'}표시</div>
            <label className="tblm-switch pointer">
              <input type="checkbox" name="switch_example" checked={notificationSetting.isEnabled} onChange={() => {
                changeNotificationSetting({
                  ...notificationSetting,
                  isEnabled: !notificationSetting.isEnabled,
                });
              }} />
              <i />
            </label>
          </div>
        </div>
      </section>
      <ModalPortal>
        <Modal.Menu key={tomorrowPopupOffModalId} modal="menu" isDim={true} isAnimation={true} onClose={() => {
          setMenuModal({ visible: false, key: tomorrowPopupOffModalId });
          setMenuModal({ visible: true, key: popupOffModalId });
        }}>
          <div className="picker-body modal-content">
            <div className="picker-title">내일 몇 시까지 통화팝업을 받지 않으시겠어요?</div>
            <ScrollPicker optionGroups={popupOffOptionGroups} valueGroups={popupOffValueGroups} onChange={(key, v) => {
              setPopupOffValueGroups(state => Object.assign({}, state, { [key]: v }));
            }} />
          </div>
          <div className="picker-footer">
            <button className="set-button tblm-button-normal tblm-btn-primary" onClick={() => setOnPopupOffTime()}>
              차단
            </button>
          </div>
        </Modal.Menu>
      </ModalPortal>
      <ModalPortal>
        <Modal.Menu key={popupOffModalId} modal="menu" isDim={true} isAnimation={true} isClose={false}>
          <div className="add-group-modal-wrapper">
            <div className="add-group-modal-header">얼마동안 통화팝업을 받지 않으시겠어요?</div>
            <div className="custom-radio-wrapper no-padding-radio-wrapper">
              {pauseTypes.map(item => (
                <div className="custom-radio-item">
                  <label className="tblm-rc">
                    <input
                      type="radio"
                      name="popupOffType"
                      value={item.value}
                      checked={tempSetting.pauseType === item.value}
                      onChange={() => changeTempPauseType(item.value)}
                      onClick={() => changeTempPauseType(item.value)}
                    />
                    <i />
                    <span>{item.name}</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </Modal.Menu>
      </ModalPortal>
    </div>
  );
};

export default CallAlarmSetting;
