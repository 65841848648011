import React from 'react';
import { ReactComponent as Arrow_Right } from '@/assets/images/icons/Arrow_Right.svg';
import { ReactComponent as Time } from '@/assets/images/icons/Time.svg';
import { IRecentCallList, IRecentCallListItem } from './RecentCallList.type';
import './RecentCallList.scss';

const RecentCallList = ({ data, onClick }: IRecentCallList) => {
  const handleOnClick = (target) => onClick(target);
  return (
    <div className="recent-list-wrapper">
      {data.map((item: IRecentCallListItem) => (
        <div key={item.value} className="recent-list-item" onClick={() => handleOnClick(item)}>
          <Time />
          <div className="font__body_sm">{item.phone}</div>
          <Arrow_Right />
        </div>
      ))}
    </div>
  );
};

export default RecentCallList;
