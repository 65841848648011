import type { Dayjs } from 'dayjs';
import create from 'zustand';

export interface IReservationCalendar {
  date: Dayjs | string;
  reservationCount: number;
  totalPartySize: number;
}

const initialReservationCalendar: IReservationCalendar[] = [];

interface IUseReservationCalendarStore {
  reservationList: IReservationCalendar[];
  setReservationList: (reservationCalendar: IReservationCalendar[]) => void;
}

export const useReservationCalendarStore = create<IUseReservationCalendarStore>((set) => ({
  reservationList: initialReservationCalendar,
  setReservationList: (reservationList) => set(() => ({ reservationList })),
}));
