import React, { useEffect, useId, useState } from 'react';
import { useQuery } from 'react-query';
import TableEdit from '@/components/TableEdit/TableEdit';
import TableView from '@/components/TableView/TableView';
import { devServerApi } from '@/shared/apis/devServerApi';
import QUERY_KEYS from '@/shared/apis/queryKeys/TABLE_SETTING';
import { ITables, ITablesGroup, Nullable } from '@/shared/utils/common.type';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useNavigate } from 'react-router-dom';
import { useModalStore } from '@/stores/common/useModalStore';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import Modal from '@/components/Modal/Modal';


const TablePage = (): React.ReactElement => {
  const { accessToken } = useAuthStore();
  const navigate = useNavigate();
  const errorModalId = useId();
  const { setAlertModal } = useModalStore();
  const [errorMessage, setErrorMessage] = useState<String>('');
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
  const [isTableEdit, setIsTableEdit] = useState(false);
  const [tableGroups, setTableGroups] = useState<Nullable<ITablesGroup[]>>(null);
  const { isFetching } = useQuery([QUERY_KEYS.TABLES], () => devServerApi.api.get<ITables>('/settings/tables'), {
    onSuccess: (res: any) => {
        if (res.data.status < 0) {
          setErrorMessage(res.data.message);
          setAlertModal({ visible: true, key: errorModalId });
        } else {
          setTableGroups(res.data.list);
        }
    },
  });
  if (isFetching) return <SpinnerEffector loading={isFetching} />;
  if (errorMessage) return (
    <>
      <Modal.Alert key={errorModalId} modal="alert" isDim={true} isAnimation={true}>
        <div className="title font__body_md_sb">알림</div>
        <div className="content font__body_sm">
          {errorMessage}
        </div>
        <div className="footer success font__body_sm_sb" onClick={() => {
          navigate(-1);
          setErrorMessage('');
          setAlertModal({ visible: false, key: errorModalId });
        }}>
          확인
        </div>
      </Modal.Alert>
    </>
  );
  return <>
    {isTableEdit ? <TableEdit list={tableGroups} edit={setIsTableEdit} commit={setTableGroups}/> : <TableView list={tableGroups} edit={setIsTableEdit}/>}
  </>;
};


export default TablePage;
