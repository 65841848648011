export const initialFacilities: { type_facility_id: number; name: string; checked?: boolean }[] = [
  { type_facility_id: 11, name: '와이파이' },
  { type_facility_id: 16, name: '대기공간' },
  { type_facility_id: 15, name: '프라이빗 룸' },
  { type_facility_id: 61, name: '단체석' },

  { type_facility_id: 60, name: '테라스' },
  { type_facility_id: 24, name: '루프탑' },
  { type_facility_id: 17, name: '수유실' },
  { type_facility_id: 50, name: '아기의자' },
  { type_facility_id: 4, name: '베이비 시트' },
  { type_facility_id: 3, name: '놀이방' },
  { type_facility_id: 5, name: '아동용 메뉴' },
  { type_facility_id: 12, name: '반려견 동반 가능' },
  { type_facility_id: 13, name: '장애인 편의시설' },
  { type_facility_id: 1, name: '코르키지 (유료)' },
  { type_facility_id: 2, name: '코르키지 (무료)' },
  { type_facility_id: 6, name: '소믈리에' },
  { type_facility_id: 7, name: '주차공간' },
  { type_facility_id: 9, name: '발렛파킹' },
  { type_facility_id: 18, name: '기계식 주차장' },
  { type_facility_id: 19, name: '흡연실' },
  { type_facility_id: 63, name: '심야 영업' },
];
export const initialFoods: { type_food_id: number; name: string; checked?: boolean }[] = [
  { type_food_id: 1, name: '한식' },
  { type_food_id: 20, name: '한정식' },
  { type_food_id: 2, name: '중식' },
  { type_food_id: 3, name: '일식' },
  { type_food_id: 29, name: '육류' },
  { type_food_id: 104, name: '횟집' },
  { type_food_id: 38, name: '치킨' },
  { type_food_id: 5, name: '서양식' },
  { type_food_id: 6, name: '프랑스식' },
  { type_food_id: 7, name: '이탈리아식' },
  { type_food_id: 4, name: '아시아식' },
  { type_food_id: 8, name: '태국식' },
  { type_food_id: 134, name: '베트남식' },
  { type_food_id: 45, name: '홍콩식퓨전' },
  { type_food_id: 14, name: '아랍식' },
  { type_food_id: 15, name: '인도식' },
  { type_food_id: 32, name: '멕시코, 남미식' },
  { type_food_id: 9, name: '퓨전' },
  { type_food_id: 85, name: '샐러드' },
  { type_food_id: 21, name: '카페' },
  { type_food_id: 13, name: '뷔페' },
  { type_food_id: 12, name: '스테이크하우스' },
  { type_food_id: 10, name: '주점' },
  { type_food_id: 110, name: 'BAR' },
  { type_food_id: 11, name: '이자카야' },
  { type_food_id: 36, name: '와인바' },
  { type_food_id: 108, name: '사케바' },
  { type_food_id: 27, name: '오마카세' },
];

export const confirmedFacilities = initialFacilities.map((i) => i.type_facility_id);
export const confirmedFoods = initialFoods.map((i) => i.type_food_id);
