import { ReactComponent as Close } from '@/assets/images/icons/Close.svg';
import { IModalProps } from '@/components/Modal/Modal.type';
import { useModalStore } from '@/stores/common/useModalStore';

const Alert = ({ isDim, classKey = undefined, isAnimation, children, onClose }: IModalProps) => {
  const { alertModal, setAlertModal } = useModalStore();

  if (!alertModal.visible) return <></>;
  return (
    <div className={`modal-wrapper alert ${isAnimation ? 'animation' : ''} ${classKey}`}>
      {isDim ? <div className="modal-dim" onClick={() => {
        if (classKey !== 'alert-error') {
          onClose ? onClose() : setAlertModal(false);
        }
      }} /> : null}
      <div className="modal-content">
        <div className="close">
          <Close onClick={() => onClose ? onClose() : setAlertModal(false)} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Alert;
