import { devServerApi } from '@/shared/apis/devServerApi';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import { IChangeMyMallResponse } from '@/shared/utils/my-malls.type';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { AxiosPromise } from 'axios';
import { useQuery } from 'react-query';

const useGetMyMalls = (accessToken: string) => {
  return useQuery([QUERY_KEYS.GET_MALL_LIST], () => devServerApi.authGet<any>('/my-malls', accessToken));
};

const usePatchMyMalls = (targetMallId, accessToken, appPushContext): AxiosPromise<IChangeMyMallResponse> => {
  return devServerApi.api.patch('/mall/switch', { targetMallId: targetMallId, appPushCtx: appPushContext }, { headers: { 'x-tm-apigw-token': accessToken } });
};

export { useGetMyMalls, usePatchMyMalls };
