import React, { useMemo, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { ControlledInput } from '@/hook-form/ControlledInput';
import { ControlledRadioBox } from '@/hook-form/ControlledRadioBox';
import { ControlledToggle } from '@/hook-form/ControlledToggle';
import { LocalStorage } from '@/shared/utils/storage';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import Select from '../Select/Select';
import './OtherInformation.scss';

function OtherInformation() {
  const { setMenuModal } = useModalStore();

  const methods = useFormContext();
  const { control, getValues, register } = methods;
  return (
    <>
      {getValues('reservationExtravalues').filter((item) => item.position === 'extra').length ? (
        <div className="detail-content" data-scrolled="4">
          <div className="font__body_sm_sb">기타정보</div>
          <div className="detail-body">
            {getValues('reservationExtravalues').map((item: any, i) => {
              if (item.position === 'extra') {
                return (
                  <div key={item.id}>
                    <div>{item.name}</div>
                    <div>
                      {item.type === 'string' || item.type === 'text' || item.type === 'textarea' || item.type === 'user' || item.type === 'longtext' ? (
                        <ControlledInput name={`reservationExtravalues.${i}.value`} control={control} placeholder={`${item.name} 입력`} />
                      ) : null}
                      {item.type === 'toggle' ? <ControlledToggle name={`reservationExtravalues.${i}.value`} control={control} defaultChecked={Boolean(item.value)} /> : null}
                      {item.type === 'count' ? <ControlledInput name={`reservationExtravalues.${i}.value`} control={control} placeholder={`${item.name} 입력`} /> : null}
                      {item.type === 'select' || item.type === 'multiselect' ? (
                        <>
                          <Select
                            onClick={() => {
                              setMenuModal({ visible: true, key: item.id });
                            }}
                            value={getValues(`reservationExtravalues.${i}.value`)}
                          />
                          <ModalPortal>
                            <Modal.Menu key={item.id} modal="menu" isDim={true} isAnimation={true}>
                              <div className="user-info-menu-modal-wrapper">
                                <div className="title font__subtitle">{item.name}</div>

                                {item.type === 'multiselect' ? (
                                  <div className="content custom-check-wrapper">
                                    {item.choices.map((subItem, j) => (
                                      <div className="custom-check-item" key={`${item}_${j}`}>
                                        <label className="tblm-rc">
                                          <input type="checkbox" value={subItem} {...register(`reservationExtravalues.${i}.value`)} />
                                          <i />
                                          <span>{subItem}</span>
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                ) : null}

                                {item.type === 'select' ? (
                                  <ControlledRadioBox
                                    name={`reservationExtravalues.${i}.value`}
                                    options={item.choices.map((subItem) => ({ label: subItem, value: subItem }))}
                                    control={control}
                                    defaultChecked={getValues(`reservationExtravalues.${i}.value`)}
                                    onChange={() => setMenuModal({ visible: false, key: item.id })}
                                  />
                                ) : null}
                              </div>
                              {item.type === 'multiselect' ? (
                                <div className="footer is-border">
                                  <button className="tblm-button-normal tblm-btn-primary" onClick={() => setMenuModal({ visible: false, key: item.id })}>
                                    확인
                                  </button>
                                </div>
                              ) : null}
                            </Modal.Menu>
                          </ModalPortal>
                        </>
                      ) : null}
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default OtherInformation;
