import styles from './notiException.module.scss';
import FooterSub from '@/components/FooterSub/FooterSub';
import HeaderBack from '@/components/HeaderBack/HeaderBack';

const NotiException = () => {
  return (
    <div className={styles.notification_exception__container}>
      <HeaderBack inBack={true}>
        <div className="font__subtitle_sb">수신전화 알림 예외 관리</div>
      </HeaderBack>
      <main className={styles.notification_exception__main}>
        <header className={styles.item_count_info}>총 {'2324234'}개</header>
        <article className={styles.notification_exception__content}>
          <ul>
            <li>
              <section className={styles.content_title}>
                <h5>{'010-4699-9429'}</h5>
                <span>
                  {'김소형'} | {'고객메모'}
                </span>
              </section>
              <section className={styles.button_wrap}>
                <button className="tblm-button-small">해제</button>
              </section>
            </li>
          </ul>
        </article>
      </main>
      <FooterSub isVisible isBorder isBackArea>
        <button className="tblm-button-normal tblm-btn-primary w-full" onClick={() => {}}>
          저장
        </button>
      </FooterSub>
    </div>
  );
};

export default NotiException;
