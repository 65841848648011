import * as yup from 'yup';

import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import { ControlledInput } from '@/hook-form/ControlledInput';
import FooterSub from '@/components/FooterSub/FooterSub';
import HeaderSub from '@/components/HeaderSub/HeaderSub';
import { ReactComponent as SearchInputIcon } from '@/assets/images/icons/Search_Input.svg';
import { debounce } from 'lodash';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import { yupResolver } from '@hookform/resolvers/yup';
import Terces from '@/shared/apis/terces';
import { ReactComponent as CallDefault } from '@/assets/images/icons/Call_Default.svg';
import { ReactComponent as CallMissing } from '@/assets/images/icons/Call_Missing.svg';
import { ReactComponent as CallOutgoing } from '@/assets/images/icons/Call_Outgoing.svg';
import { ReactComponent as CallIncoming } from '@/assets/images/icons/Call_Incoming.svg';
import { ReactComponent as ArrowDown } from '@/assets/images/icons/Arrow_Down.svg';
import { autoHypenTel } from '@/shared/lib/functions/auto-hypen-tel';
import { useQuery } from 'react-query';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import { filteringAgoDay } from '@/shared/lib/functions/call-item';
import HightlightSearchValue from '@/components/HighlightSearchValue/HightlightSearchValue';
import { useMallStore } from '@/stores/common/useMallStore';

const INITIAL_STEP_TEXTS = {
  createHeader: (
    <>
      예약 고객의 정보를 <br />
      입력해 주세요.
    </>
  ),
  modifyHeader: (
    <>
      예약 고객의 정보를 <br />
      검색해 주세요.
    </>
  ),
  footer: { save: '저장', back: '뒤로', next: '다음' },
};

const Step4 = (): React.ReactElement => {
  const dayjs = require('dayjs');
  const duration = require('dayjs/plugin/duration');
  dayjs.extend(duration);
  const { visitDate, visitTime, parties, rooms, userInfo, setUserInfo, setReservationResetStore } = useReservationStore();
  const { accessToken } = useAuthStore();
  const { mall } = useMallStore();
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({ type: 'default' });
  const [userList, setUserList] = useState(null);
  const [successSearch, setSuccessSearch] = useState(false);
  const [choiceUser, setChoiceUser] = useState(true);
  const [moreViewLength, setMoreViewLength] = useState(10);
  const mainRef = useRef(null);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      userName: yup.string().required('이름을 입력하세요.'),
      userPhone: yup
        .string()
        .required('전화번호를 입력하세요.')
        .test('userPhone', '숫자만 입력 가능합니다.', (value) => {
          if (value.length) return /[0-9.;\\-]/.test(value);
          return true;
        }),
    });
  }, []);

  const methods = useForm({
    defaultValues: {
      ...userInfo,
      userPhone: userInfo.userPhone
        .replace(/[^0-9]/g, '')
        .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
        .replace('--', '-'),
    },
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const { control, reset, handleSubmit, getValues, setValue, setError } = methods;

  const { isLoading: isRecentCallLogLoading, data: recentCallLogIn24Data } = useQuery<any>('', () => devServerApi.authGet('/call-log/recent-calls', accessToken));

  const handleOnClose = () => {
    if (searchParams.get('type') === 'default') {
      setReservationResetStore();
      navigate('/reservation/list');
    }
    if (searchParams.get('type') === 'modify' || searchParams.get('type') === 'deposit') navigate('/reservation/accept/step-5');
    if (searchParams.get('type') === 'modify-1' || searchParams.get('type') === 'modify-2') navigate(-1);
  };

  const handleOnUserSearch = debounce((name, phone) => {
    devServerApi.api
      .post(
        '/reservation/get-users',
        Terces.encrypt({
          name,
          phone,
          page: 1,
          pagesize: moreViewLength,
        })
      )
      .then(({ data: enc_res }: any) => Terces.decrypt(enc_res))
      .then((res: any) => {
        setUserList(res);
      });
    setSuccessSearch(false);
  }, 300);

  const onClickMoreView = () => {
    // 10개씩 더 보여준다
    return setMoreViewLength((prev) => prev + 10);
  };

  useEffect(() => {
    // 10개씩 더 보여준다
    if (moreViewLength > 10) handleOnUserSearch(getValues('userName'), getValues('userPhone').replace(/-/g, ''));
  }, [moreViewLength]);

  const handleOnClickSearchListItem = (target) => {
    reset({
      ...target,
      userPhone: autoHypenTel(target.userPhone),
      isSearch: true,
    });
    setSuccessSearch(true);
    setUserList(null);
    setMoreViewLength(10);
    setChoiceUser(true);

    mainRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleResetForm = () => {
    if (userInfo.isSearch) {
      setValue('userId', '');
      setValue('userMemo', '');
      setValue('userNickname', '');
      setValue('isSearch', false);
    }
  };

  const handleOnSubmitUserInfo = (data, path) => {
    if (data.userPhone.slice(0, 2) !== '01') {
      // 01로 시작하는 번호가 아닐 때 validation check - 올바른 휴대폰번호 형식이 아닙니다.
      return setError('userPhone', { message: '올바른 휴대폰번호 형식이 아닙니다.' }, { shouldFocus: true });
    }
    if (data.userPhone?.length < 13) {
      // 11자리 이하일 때 validation check - 휴대폰번호를 전부 입력해 주세요.
      return setError('userPhone', { message: '휴대폰번호를 전부 입력해 주세요.' }, { shouldFocus: true });
    }

    const usableUserExtravalues = mall.userExtravalues.map((mallItem) => {
      const find = data.userExtravalues.find((dataItem) => dataItem.id === mallItem.id);
      return { ...mallItem, value: find.value ?? '' };
    });

    // modify-2 일 때
    // 유저의 정보를 정확히 검사 하지 못했을 때?
    // state로 관리
    // 클릭 시 true
    // 다시 입력 시 false
    // true or falswe
    if (searchParams.get('type') === 'modify-2') {
      if (successSearch) {
        // true 선택 했을 때
        setUserInfo({
          ...userInfo,
          userId: data.userId,
          userName: data.userName,
          userPhone: data.userPhone.replace(/-/g, ''),
          isSearch: data.isSearch,
          userMemo: data.userMemo,
          userNickname: data.userNickname,
          userEmail: data.userEmail ? data.userEmail : '',
          newUserId: data.userId,
          userExtravalues: usableUserExtravalues,
        });
      } else {
        // false
        setUserInfo({
          ...userInfo,
          // userId: data.userId || data.userPhone.replace(/-/g, ''),
          userName: data.userName,
          userPhone: data.userPhone.replace(/-/g, ''),
          isSearch: data.isSearch,
          userMemo: data.userMemo,
          userNickname: data.userNickname,
          userEmail: data.userEmail ? data.userEmail : '',
          newUserPhone: data.userPhone,
          newUserId: undefined,
          userExtravalues: usableUserExtravalues,
        });
      }
    } else {
      setUserInfo({
        ...userInfo,
        userId: data.userId || data.userPhone.replace(/-/g, ''),
        userName: data.userName,
        userPhone: data.userPhone.replace(/-/g, ''),
        isSearch: data.isSearch,
        userMemo: data.userMemo,
        userNickname: data.userNickname,
        userEmail: data.userEmail ? data.userEmail : '',
        newUserId: undefined,
        newUserPhone: undefined,
        userExtravalues: usableUserExtravalues,
      });
    }

    navigate(path);
  };

  const formatTimeIn24h = (targetTime) => {
    const currentTime = dayjs();
    const difference = dayjs(targetTime).diff(currentTime, 'second');
    const durationTime = dayjs.duration(currentTime.diff(targetTime));

    if (difference < -24 * 60 * 60) {
      // 24시간 이후 - 어제
      return `${filteringAgoDay(targetTime)} ${dayjs(targetTime).format('A hh:mm')}`;
    }
    // 24시간 이내 - 오늘, 즉 시간단위만 표기
    if (durationTime.hours() > 0) {
      return `${durationTime.hours()}시간 전`;
    } else if (durationTime.minutes() > 0) {
      return `${durationTime.minutes()}분 전`;
    }
    return `${durationTime.seconds()}초 전`;
  };

  // 시간 단위 테스트 //
  // const test = '2023-06-20T05:48:10.816Z';
  // console.log('result: ', formatTimeIn24h(test), '/ time:', dayjs(test).format('YYYY-MM-DD a hh:mm'));

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  }, []);

  if (isRecentCallLogLoading) return <SpinnerEffector loading />;

  return (
    <div className="accept-step-4-container" ref={mainRef}>
      <FormProvider {...methods}>
        <HeaderSub isNeedPlaceholder isScrollTitleVisible onClose={handleOnClose}>
          {searchParams.get('type') === 'modify-2' ? INITIAL_STEP_TEXTS.modifyHeader : INITIAL_STEP_TEXTS.createHeader}
        </HeaderSub>

        <section className="user-search-wrapper">
          <div className="flex-container flex-col gap-16 pt-20">
            <div className="font__subtitle1_sb">전화번호</div>
            <ControlledInput
              ref={inputRef}
              name="userPhone"
              type="tel"
              maxLength={13}
              control={control}
              placeholder="전화번호를 입력하세요."
              onChange={(e) => {
                // 핸드폰번호 4자리부터 userList 보여주기 시작
                if (getValues('userPhone').length >= 4) handleOnUserSearch(null, getValues('userPhone').replace(/-/g, ''));
                else setUserList(null);
                setValue('userPhone', autoHypenTel(e.target.value));
                handleResetForm();
                setChoiceUser(false);
              }}
            />
          </div>
          <div className="flex-container flex-col gap-16">
            <div className="font__subtitle1_sb">이름</div>
            <div className="flex-container gap-8">
              <ControlledInput
                name="userName"
                control={control}
                placeholder="이름을 입력하세요."
                onEnter={() => handleOnUserSearch(getValues('userName'), null)}
                onChange={handleResetForm}
              />
              <button className="tblm-button-normal no-padding" style={{ minWidth: 40, height: 40 }} onClick={() => handleOnUserSearch(getValues('userName'), null)}>
                <SearchInputIcon />
              </button>
            </div>
          </div>
          <section className="logs-data-container">
            {userList?.users?.length ? (
              <div className="scrollable-user-list">
                <h4>검색 결과(총 {userList?.totalUserCount}건)</h4>
                <ul className="user-list-wrapper">
                  {userList?.users?.map((item) => {
                    return (
                      <li key={item.userId} onClick={() => handleOnClickSearchListItem(item)}>
                        <HightlightSearchValue
                          userText={`${item.userName && item.userName} ${item.userPhone && `(${autoHypenTel(item.userPhone)})`}`}
                          searchWord={getValues('userName').length ? getValues('userName') : getValues('userPhone').length && getValues('userPhone')}
                        />
                      </li>
                    );
                  })}
                </ul>
                {userList?.hasNextPage && (
                  <button className="view-more-btn" onClick={onClickMoreView}>
                    더보기
                    <ArrowDown />
                  </button>
                )}
              </div>
            ) : null}
            {recentCallLogIn24Data?.callLogs.length ? (
              <div className="calllog-container">
                <h4 className="callog-title">최근 24시간 통화기록</h4>
                <div className="calllog-wrapper">
                  <ul>
                    {recentCallLogIn24Data.callLogs.map((callLog) => (
                      <li key={callLog.callLogId} onClick={() => handleOnClickSearchListItem(callLog)}>
                        <div className="calllog-user-info">
                          <CallDefault className="call-icon" />
                          <p>
                            {callLog.userName} ({autoHypenTel(callLog.userPhone)})
                          </p>
                        </div>
                        <span className="calllog-date-info">{formatTimeIn24h(callLog.callLogTime)}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : null}
          </section>
        </section>

        <FooterSub isBackArea isVisible isBorder>
          <div className="font__subtitle hidden">
            <div className="font__subtitle flex-container items-center">
              {visitDate?.format('MM.DD(ddd)')} {visitTime?.format('a HH:mm')}
            </div>
            <div className="font__subtitle flex-container items-center white-pre parties-wrapper">
              {parties.map((item) => {
                if (item.size) return <span key={item.name}>{`${item.name} ${item.size}`}</span>;
                return <Fragment key={item.name} />;
              })}
              {rooms.length ? `/ ${rooms.map((item) => item.name).join(', ')}` : ''}
            </div>
          </div>

          <div className="flex-container gap-8">
            {searchParams.get('type').includes('modify') ? (
              <button
                onClick={handleSubmit((data) => handleOnSubmitUserInfo(data, -1))}
                disabled={searchParams.get('type') === 'modify-2' ? !choiceUser : true}
                className="footer tblm-button-normal tblm-btn-primary"
              >
                {INITIAL_STEP_TEXTS.footer.save}
              </button>
            ) : (
              <>
                <Link to="/reservation/accept/step-3/">
                  <button className="footer tblm-button-normal ">{INITIAL_STEP_TEXTS.footer.back}</button>
                </Link>
                <button onClick={handleSubmit((data) => handleOnSubmitUserInfo(data, '/reservation/accept/step-5/'))} className="footer tblm-button-normal tblm-btn-primary">
                  {INITIAL_STEP_TEXTS.footer.next}
                </button>
              </>
            )}
          </div>
        </FooterSub>
      </FormProvider>
    </div>
  );
};

export default Step4;
