import { delay } from '@/shared/utils/promiseUtil';
import { INativeMessage, sendNativeMessage } from './native-messge';

/* eslint-disable no-prototype-builtins */
export const getSelectValues = (select) => {
  const result = [];
  const options = select && select.options;
  let opt;

  for (let i = 0, iLen = options.length; i < iLen; i++) {
    opt = options[i];

    if (opt.selected) {
      result.push(opt.value || opt.text);
    }
  }
  return result;
};

export const validation = (data) => {
  let result = true;
  for (let key in data) {
    if (data[key].value === null) {
      result = false;
      continue;
    } else {
      if (data[key].hasOwnProperty('subStep')) {
        if (data[key].subStep.value === null) {
          result = false;
          continue;
        }
      }
    }
  }
  return result;
};

const timeStringToMinutes = (timeString) => {
  const [h, m] = timeString.split(':').map(Number);
  return h * 60 + m;
};

export const timesMaker = (delimiter = 30, { startTime = '00:00', endTime = '24:00', isOpen = true, breakTimes = [] } = {}) => {
  const times = [];
  if (!isOpen) return times;
  const startTimeNum = timeStringToMinutes(startTime || '00:00');
  const endTimeNum = timeStringToMinutes(endTime || '24:00');
  const brakeTimeStartNum1 = timeStringToMinutes(breakTimes[0]?.breakTimeStart || '48:00');
  const brakeTimeEndNum1 = timeStringToMinutes(breakTimes[0]?.breakTimeEnd || '00:00');
  const brakeTimeStartNum2 = timeStringToMinutes(breakTimes[1]?.breakTimeStart || '48:00');
  const brakeTimeEndNum2 = timeStringToMinutes(breakTimes[1]?.breakTimeEnd || '00:00');
  const brakeTimeStartNum3 = timeStringToMinutes(breakTimes[2]?.breakTimeStart || '48:00');
  const brakeTimeEndNum3 = timeStringToMinutes(breakTimes[2]?.breakTimeEnd || '00:00');

  let current = 0;
  while (current < 1440) {
    if (
      current >= startTimeNum &&
      current < endTimeNum &&
      !(brakeTimeStartNum1 <= current && brakeTimeEndNum1 > current) &&
      !(brakeTimeStartNum2 <= current && brakeTimeEndNum2 > current) &&
      !(brakeTimeStartNum3 <= current && brakeTimeEndNum3 > current)
    ) {
      times.push(
        [Math.floor(current / 60), current % 60]
          .map(String)
          .map((s) => s.padStart(2, '0'))
          .join(':')
      );
    }
    current += delimiter;
  }
  return times;
};

export const timeFilter = (timesArg = [], { startTime = '00:00', endTime = '24:00', isOpen = true, breakTimes = [] } = {}) => {
  if (!isOpen) return [];
  // eslint-disable-next-line no-unreachable
  const times = timesArg.map((timeString) => timeString.split(':').map(Number)).map(([h, m]) => h * 60 + m);
  const startTimeNum = timeStringToMinutes(startTime || '00:00');
  const endTimeNum = timeStringToMinutes(endTime || '24:00');
  const brakeTimeStartNum1 = timeStringToMinutes(breakTimes[0]?.breakTimeStart || '48:00');
  const brakeTimeEndNum1 = timeStringToMinutes(breakTimes[0]?.breakTimeEnd || '00:00');
  const brakeTimeStartNum2 = timeStringToMinutes(breakTimes[1]?.breakTimeStart || '48:00');
  const brakeTimeEndNum2 = timeStringToMinutes(breakTimes[1]?.breakTimeEnd || '00:00');
  const brakeTimeStartNum3 = timeStringToMinutes(breakTimes[2]?.breakTimeStart || '48:00');
  const brakeTimeEndNum3 = timeStringToMinutes(breakTimes[2]?.breakTimeEnd || '00:00');
  return times
    .filter((time) => {
      if (
        time >= startTimeNum &&
        time < endTimeNum &&
        !(brakeTimeStartNum1 <= time && brakeTimeEndNum1 > time) &&
        !(brakeTimeStartNum2 <= time && brakeTimeEndNum2 > time) &&
        !(brakeTimeStartNum3 <= time && brakeTimeEndNum3 > time)
      ) {
        return true;
      }
      return false;
    })
    .map((time) =>
      [Math.floor(time / 60), time % 60]
        .map(String)
        .map((s) => s.padStart(2, '0'))
        .join(':')
    );
};
export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
}

export function instantDisabler(func?: (...fargs: any) => any, ...args: any) {
  if (!func) return func;
  const isAsyncEvent = func.constructor.name === 'AsyncFunction';
  return async function (event?: any) {
    if (event) {
      try {
        /* eslint-disable require-atomic-updates */
        const target = event.currentTarget;
        const initialDisabled = target.disabled;
        const initialPointerEvent = target.style.pointerEvents;
        const initialCursor = document.body.style.cursor;

        target.disabled = true;
        target.style.pointerEvents = 'none';
        document.body.style.cursor = 'wait';

        if (isAsyncEvent) await func(...(args.length > 0 ? args : [event]));
        else {
          func(...(args.length > 0 ? args : [event]));
          await delay(500);
        }

        target.disabled = initialDisabled;
        target.style.pointerEvents = initialPointerEvent;
        document.body.style.cursor = initialCursor;
      } catch (e) {
        /**/
      }
      return;
    }
    func(...(args.length > 0 ? args : [event]));
  };
}

export const getBankLabel = (value) => {
  if (value === 'KOOKMIN') {
    return '국민은행';
  }
  if (value === 'SC') {
    return 'SC제일은행';
  }
  if (value === 'GWANGJU') {
    return '광주은행';
  }
  if (value === 'IBK') {
    return '기업은행';
  }
  if (value === 'NH') {
    return '농협중앙회';
  }
  if (value === 'BUSAN') {
    return '부산은행';
  }
  if (value === 'SHINHAN') {
    return '신한은행';
  }
  if (value === 'KEB') {
    return 'KEB하나은행';
  }
  if (value === 'WOORI') {
    return '우리은행';
  }
  if (value === 'EPOST') {
    return '우체국';
  }

  return '';
};

export const getAppName = (): 'kt' | 'tablemanager' => {
  // 파트너별 테스트 시 변경
  let appName: 'kt' | 'tablemanager' = 'tablemanager';
  if (window.location.origin.includes('tablemanager')) {
    appName = 'tablemanager';
  }
  if (window.location.origin.includes('kt')) {
    appName = 'kt';
  }
  return appName;
};

export const generateHourMinuteString = (hours: number) => {
  const getFloatPoint = (value) => {
    let result = null;
    if (0.1666 - 1e-3 <= value && value <= 0.1666 + 1e-3) {
      result = 0.166666;
    } else if (0.3333 - 1e-3 <= value && value <= 0.3333 + 1e-3) {
      result = 0.333333;
    } else if (0.6666 - 1e-3 <= value && value <= 0.6666 + 1e-3) {
      result = 0.666666;
    } else if (0.8333 - 1e-3 <= value && value <= 0.8333 + 1e-3) {
      result = 0.833333;
    } else if (1 - 1e-3 <= value && value <= 1 + 1e-3) {
      result = 1;
    }
    return result;
  };
  let minutesString = '';
  const minutes = getFloatPoint(hours - Math.floor(hours));

  if (minutes === 0.166666) {
    minutesString = '10분';
  } else if (minutes === 0.333333) {
    minutesString = '20분';
  } else if (minutes === 0.5) {
    minutesString = '30분';
  } else if (minutes === 0.666666) {
    minutesString = '40분';
  } else if (minutes === 0.833333) {
    minutesString = '50분';
  }
  let hour = Math.floor(hours);

  if (hour < 12) {
    return `오전 ${hour}시 ${minutesString}`;
  } else if (hour === 12) {
    return `정오 12시 ${minutesString}`;
  } else if (hour > 12 && hour < 24) {
    return `오후 ${hour - 12}시 ${minutesString}`;
  } else if (hour === 24) {
    return `자정 12시 ${minutesString}`;
  } else if (hour >= 25) {
    return `새벽 ${hour - 24}시 ${minutesString}`;
  }
  return `새벽 ${hour - 12}시 ${minutesString}`;
};

export const closeWebview = () => {
  const message: INativeMessage = { action: 'close_window' };
  sendNativeMessage(message);
};
