import FooterSub from '@/components/FooterSub/FooterSub';
import Input from '@/components/Input/Input';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useEffect, useId } from 'react';
import { josa } from 'josa';
import './CustomerInfo.scss';
import { useFormContext } from 'react-hook-form';
import { ControlledInput } from '@/hook-form/ControlledInput';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Modal from '@/components/Modal/Modal';
import { autoHypenTel } from '@/shared/lib/functions/auto-hypen-tel';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useQuery } from 'react-query';
import { devServerApi } from '@/shared/apis/devServerApi';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import { useNavigate } from 'react-router-dom';

const CustomerInfo = (propsWrap) => {
  const { onClose, saveUserData, customerInfoData } = propsWrap.propsWrap;
  const { accessToken } = useAuthStore();
  const navigate = useNavigate();

  const { setAlertModal } = useModalStore();
  const { mall } = useMallStore();
  const methods = useFormContext();

  const changeNameId = useId();
  const changePhoneId = useId();
  const alreadyRegisteredUserId = useId();

  const {
    handleSubmit,
    getValues,
    setValue,
    register,
    control,
    formState: { dirtyFields },
  } = methods;

  const showModalEditDefaultUserData = () => {
    if (dirtyFields?.userPhone) return setAlertModal({ visible: true, key: changePhoneId });

    if (dirtyFields?.userName) return setAlertModal({ visible: true, key: changeNameId });
  };

  const saveUserDataController = () => {
    // 일단 api 호출하고 에러나면 그냥 바로 밑에 로직 태우고, 호출했을 때 정상이면 그 데이터 받아서 이동할 수 있도록 모달 띄움
    if (dirtyFields.userName || dirtyFields.userPhone) showModalEditDefaultUserData();
    else handleSubmit((data) => saveUserData(data))();
  };

  const { data: samePhoneUserData, refetch: getSamePhoneUser } = useQuery(
    [QUERY_KEYS.GET_SAME_PHONE_USER_DATA],
    () => devServerApi.authGet<any>(`/user/${getValues('userPhone').replaceAll('-', '')}`, accessToken),
    {
      enabled: false,
      onSettled: () => {
        if (dirtyFields.userName || dirtyFields.userPhone) return showModalEditDefaultUserData();
      },
      onSuccess: () => {
        return setAlertModal({ visible: true, key: alreadyRegisteredUserId });
      },
      onError: (err: any) => {
        if (err && err.stack?.includes('[-2001]')) {
          return saveUserDataController();
        }
        return console.log(err);
      },
    }
  );

  const goToSamePhoneUserPage = () => {
    setAlertModal({ visible: false, key: alreadyRegisteredUserId });
    samePhoneUserData && navigate(`/tablemanager/customer-detail/${samePhoneUserData.id}?&isEditSameUser=true`);
  };

  return (
    <>
      <section className="user-input-list-container">
        {!customerInfoData.user.common.name.length && (
          <section className="user-add-info-container">
            <h3>고객 정보 입력</h3>
            <div className="user-add-info">
              <div className="input-wrap">
                <label htmlFor="userPhone">전화번호</label>
                <div className="fixed-input-value">{autoHypenTel(getValues('userPhone'))}</div>
              </div>
              <div className="input-wrap">
                <label htmlFor="userName">이름</label>
                <ControlledInput
                  control={control}
                  name="userName"
                  type="text"
                  placeholder="실제 성함을 입력해주세요"
                  defaultValue={getValues('userName') || ''}
                  onChange={(e) => setValue('userName', e.target.value)}
                />
              </div>
            </div>
          </section>
        )}
        <section className="user-add-info-container">
          <h3>고객 추가정보</h3>
          <div className="user-add-info">
            <div className="input-wrap">
              <label htmlFor="userNickname">고객 별칭</label>
              <ControlledInput
                control={control}
                name="userNickname"
                type="text"
                placeholder="고객 별칭을 입력해주세요"
                onChange={(e) => setValue('userNickname', e.target.value, { shouldDirty: true })}
              />
            </div>
            <div className="input-wrap">
              <label htmlFor="userMemo">고객 메모</label>
              <ControlledInput control={control} name="userMemo" type="text" placeholder="고객 메모를 입력해주세요" onChange={(e) => setValue('userMemo', e.target.value)} />
            </div>
            {mall.userExtravalues?.map((extraInfo) => (
              <div key={extraInfo.id} className="input-wrap">
                <label htmlFor={extraInfo.id}>{extraInfo.name}</label>
                <Input
                  control={control}
                  name={extraInfo.id}
                  type="text"
                  placeholder={josa(`${extraInfo.name}#{을} 입력해주세요`)}
                  defaultValue={getValues('userExtraValues.' + extraInfo.id)}
                  {...register('userExtraValues.' + extraInfo.id, { value: extraInfo })}
                  onChange={(e) => setValue('userExtraValues.' + extraInfo.id, e.target.value, { shouldDirty: true })}
                />
              </div>
            ))}
          </div>
        </section>
      </section>
      <FooterSub isVisible isBorder isBackArea>
        <button className="tblm-button-normal tblm-btn-gray w-full" onClick={onClose}>
          취소
        </button>
        <button
          disabled={Object.keys(dirtyFields).length <= 0}
          onClick={async () => (dirtyFields?.userPhone ? await getSamePhoneUser() : await saveUserDataController())}
          className="tblm-button-normal tblm-btn-primary w-full"
        >
          저장
        </button>
      </FooterSub>

      <ModalPortal>
        <Modal.Alert key={changeNameId} modal="alert" isDim={true} isAnimation={true}>
          {/* 이름 변경 */}
          <div className="title font__body_md_sb">고객 이름을 변경할까요?</div>
          <div className="content font__body_sm">예약 문자 발송시 입력된 이름으로 전송됩니다.</div>
          <div className="footer-multiple-buttons">
            <div className="footer default font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: changeNameId })}>
              취소
            </div>
            <div onClick={handleSubmit((data) => saveUserData(data))} className="footer success font__body_sm_sb">
              확인
            </div>
          </div>
        </Modal.Alert>
        <Modal.Alert key={changePhoneId} modal="alert" isDim={true} isAnimation={true}>
          {/* 고객 핸드폰번호 변경 */}
          <div className="title font__body_md_sb">
            {customerInfoData.user.common.name.length ? customerInfoData.user.common.name : customerInfoData.user.common.phone} 고객님의 전화번호를 변경할까요?
          </div>
          <div className="content font__body_sm">변경 시 고객님의 정보가 새로운 번호로 모두 이동됩니다.</div>
          <div className="footer-multiple-buttons">
            <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: changePhoneId })}>
              취소
            </div>
            <div onClick={handleSubmit((data) => saveUserData(data))} className="footer success font__body_sm_sb">
              변경
            </div>
          </div>
        </Modal.Alert>
        <Modal.Alert key={alreadyRegisteredUserId} modal="alert" isDim={true} isAnimation={true}>
          {/* 고객 핸드폰번호 변경 */}
          <div className="title font__body_md_sb">해당 번호로 이미 등록된 고객이 있습니다.</div>
          <div className="content font__body_sm">
            이름 : {samePhoneUserData?.name} <br />
            전화번호 : {samePhoneUserData && autoHypenTel(samePhoneUserData.phone)}
          </div>
          <div className="footer-multiple-buttons">
            <div onClick={goToSamePhoneUserPage} className="footer error font__body_sm_sb">
              고객정보 확인
            </div>
            <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: alreadyRegisteredUserId })}>
              취소
            </div>
          </div>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default CustomerInfo;
