import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useId, useRef, useState } from 'react';
import { ReactComponent as Calendar_Arrow_Down } from '@/assets/images/icons/Calendar_Arrow_Down.svg';
import CalendarPicker from '@/components/CalendarPicker/CalendarPicker';
import { useModalStore } from '@/stores/common/useModalStore';
import { Calendar, Day, Week } from './MonthCalendar.type';
import './MonthCalendar.scss';

// const holidays = ['2022-07-14'];

const MonthCalendar = (props): React.ReactElement => {
  const { setMenuModal } = useModalStore();
  const { startDate, targetDate, selectedDate, period, select, headerHeight = 0, reservationList, disabledBeforeDay, disabledAfterDay, holidays } = props;
  const [viewDate, setViewDate] = useState<dayjs.Dayjs>(dayjs());
  const [calendarList, setCalendarList] = useState(new Array<Calendar>());
  const [readyToScroll, setReadyToScroll] = useState(false);
  const refs = useRef([]);
  const scrollElement = document.querySelector('.main-container-desktop');
  const calendarPickerModalKey = useId();

  const makeMonthCalendar = (date: dayjs.Dayjs, datePeriod: number) => {
    const today = dayjs();
    let tempCalendarList = new Array<Calendar>(datePeriod).fill(null);
    tempCalendarList = tempCalendarList.map((calendar, monthIndex) => {
      const targetMonthDate = date.add(monthIndex, 'month').startOf('month');
      const firstDay = date.add(monthIndex, 'month').startOf('month').day();
      const weekOfMonth = Math.ceil((firstDay + targetMonthDate.daysInMonth()) / 7);
      let weeks = new Array<Week>(weekOfMonth).fill(null);
      weeks = weeks.map((week, weekIndex) => {
        let targetWeekDate = targetMonthDate.add(weekIndex, 'week');
        let days = new Array<Day>(7).fill(null);
        days = days.map((day, dayIndex) => {
          let targetDay = targetWeekDate.startOf('week').add(dayIndex, 'day');
          const orderInfo = reservationList.find((item) => item.date.format('YYYY-MM-DD') === targetDay.format('YYYY-MM-DD'));
          return {
            isToday: today.isSame(targetDay, 'day'),
            isAfterDay: disabledAfterDay ? disabledAfterDay.isBefore(targetDay, 'day') : null,
            isBeforeDay: disabledBeforeDay ? disabledBeforeDay.isAfter(targetDay, 'day') : null,
            isSameMonth: targetMonthDate.isSame(targetDay, 'month'),
            orderInfo: { reservationCount: orderInfo?.reservationCount, totalPartySize: orderInfo?.totalPartySize },
            selected: selectedDate ? selectedDate.isSame(targetDay, 'day') : null,
            targetDay: targetDay,
          };
        });
        return {
          days,
          targetWeek: targetWeekDate,
        };
      });
      return {
        weeks,
        targetMonth: targetMonthDate,
      };
    });
    setCalendarList(tempCalendarList);
  };

  const scrollToTargetDate = (date: dayjs.Dayjs) => {
    let targetCalendarIndex = calendarList.findIndex((calendar) => calendar.targetMonth.isSame(date.startOf('month'), 'day'));
    if (targetCalendarIndex > -1) {
        if (refs.current[targetCalendarIndex] && refs.current[targetCalendarIndex].scrollIntoView) {
          refs.current[targetCalendarIndex].scrollIntoView();
          scrollElement.scrollTop = scrollElement.scrollTop - 148;
        }
        setReadyToScroll(true);
    }
  };

  const onChangeViewDate = () => {
    const lastScrollTop = scrollElement.scrollTop + headerHeight + 126;
    const filteredItems = refs.current.filter((item) => lastScrollTop > item.offsetTop);
    let targetItem = filteredItems[filteredItems.length - 1];
    if (targetItem) {
      let index = Number(targetItem.id);
      setViewDate(calendarList[index].targetMonth);
    }
  };

  const finishCalendarPicker = (date) => {
    setViewDate(date);
    scrollToTargetDate(date);
  };

  useEffect(() => {
    if (calendarList.length > 0 && !readyToScroll) {
      scrollToTargetDate(targetDate);
      scrollElement.addEventListener('scroll', onChangeViewDate);
      return () => scrollElement.removeEventListener('scroll', onChangeViewDate);
    }
  }, [calendarList]);

  useEffect(() => {
    makeMonthCalendar(startDate, period);
  }, [selectedDate]);

  if (!calendarList || calendarList.length === 0) return null;
  return (
    <>
      <div className="calendar-sticky-header" style={{ top: headerHeight }}>
        <div
          className="view-date-text font__subtitle1_sb"
          onClick={() => {
            setMenuModal({ visible: true, key: calendarPickerModalKey });
          }}
        >
          {viewDate.format('YYYY년 MM월')}
          <Calendar_Arrow_Down className="icon-arrow-down" />
        </div>
        <div className="week-header">
          <div className="week-header-item font__subtitle1_sb">일</div>
          <div className="week-header-item font__subtitle1_sb">월</div>
          <div className="week-header-item font__subtitle1_sb">화</div>
          <div className="week-header-item font__subtitle1_sb">수</div>
          <div className="week-header-item font__subtitle1_sb">목</div>
          <div className="week-header-item font__subtitle1_sb">금</div>
          <div className="week-header-item font__subtitle1_sb">토</div>
        </div>
      </div>
      {calendarList.map((calendar, index) => (
        <div
          key={index}
          className="calendar-container"
          id={index.toString()}
          ref={(element) => {
            refs.current[index] = element;
          }}
        >
          <div className="calendar-month font__subtitle1_sb last-calendar">{calendar.targetMonth.format('M월')}</div>
          {calendar.weeks.map((week, i) => {
            return (
              <div className="calendar-week" key={i}>
                {week.days.map((day, j) => {
                  const isSunday = week.targetWeek.day(0).isSame(day.targetDay, 'day');
                  const isSaturday = week.targetWeek.day(6).isSame(day.targetDay, 'day');
                  const isHoliday = (holidays || []).find((holiday) => holiday.date.isSame(day.targetDay, 'day'));
                  return (
                    <div
                      className={classNames('calendar-day', { disabled: day.isBeforeDay || day.isAfterDay })}
                      key={j}
                      onClick={() => (day.isSameMonth ? select(day.targetDay) : null)}
                    >
                      <div
                        className={classNames('date-box font__subtitle1_sb', {
                          selected: day.isSameMonth && day.selected,
                          today: day.isSameMonth && day.isToday,
                          sunday: isSunday,
                          saturday: isSaturday,
                          holiday: isHoliday,
                        })}
                      >
                        {day.isSameMonth ? day.targetDay.format('D') : ''}
                      </div>
                      <div className="order-info font__s_small flex-container flex-col">
                        <span className="">{day.isSameMonth && day.orderInfo.reservationCount ? day.orderInfo.reservationCount + '건' : ''}</span>
                        <span className="">{day.isSameMonth && day.orderInfo.totalPartySize ? day.orderInfo.totalPartySize + '명' : ''}</span>
                        {/* <span className="keep-all">{isHoliday ? isHoliday.memo : ''}</span> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      ))}
      <CalendarPicker
        viewDate={viewDate}
        modalKey={calendarPickerModalKey}
        finish={(date) => finishCalendarPicker(date)}
        startDate={calendarList[0].targetMonth}
        endDate={calendarList[calendarList.length - 1].targetMonth}
      />
    </>
  );
};

export default MonthCalendar;
