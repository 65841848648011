import './detail.scss';

import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect, useId, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CustomerAdditionalInformation from '@/components/ReservationDetail/CustomerAdditionalInformation';
import FooterSub from '@/components/FooterSub/FooterSub';
import MenuInfo from '@/components/Reservation/Accept/MenuInfo/MenuInfo';
import MenuList from '@/components/ReservationDetail/MenuList';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import OtherInformation from '@/components/ReservationDetail/OtherInformation';
import Reservationinfo from '@/components/ReservationDetail/ReservationInfo';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import dayjs from 'dayjs';
import { devServerApi } from '@/shared/apis/devServerApi';
import { reservationSchema } from '@/hook-form/schema/reservation';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useReservationInformationStore } from '@/stores/useReservationInformationStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import { yupResolver } from '@hookform/resolvers/yup';
import Terces from '@/shared/apis/terces';
import { instantDisabler } from '@/shared/lib/functions';
import DepositSettingInfo from '@/components/ReservationDetail/DepositSetting';
import DepositSetting from '@/components/Reservation/Accept/DepositSetting/DepositSetting';
import { useMutation } from 'react-query';
import { IMutationProps } from '@/shared/utils/common.type';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';


const ReservationDetail = () => {
  const { reservationDeposit } = useReservationInformationStore();
  const { accessToken } = useAuthStore();
  const { setAlertModal } = useModalStore();
  const { mall } = useMallStore();
  const {
    userInfo,
    initGetAllInfo,
    setReservationResetStore,
    setVisitTime,
    reservationInfo,
    visitDate,
    visitTime,
    parties,
    rooms,
    menus,
    setRooms,
    setReservationInfo,
    depositInfo,
  } = useReservationStore();
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });

  const navigate = useNavigate();
  const requiredModalId = useId();
  const cancleModalId = useId();
  const rollbackModalId = useId();
  const tabsWrapperRef = useRef(null);

  const { id } = useParams();

  const [sendCancelAlarm, setSendCancelAlarm] = useState(false);
  const [loading, setLoading] = useState(false);


  const validationSchema = React.useMemo(() => reservationSchema, []);

  const methods = useForm({
    defaultValues: {
      ...reservationInfo,
      ...userInfo,
      visitDate,
      visitTime,
      parties: parties,
      rooms: rooms,
      menus: menus,
      ...reservationDeposit,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { handleSubmit, reset, getValues } = methods;
  const handleOnRouterMove = (data) => {
    const time = typeof data.visitTime === 'number' ? data.visitTime : Number(data.visitTime.hour() * 60 + data.visitTime.minute());
    const date = typeof data.visitDate === 'string' ? data.visitDate : data.visitDate.format('YYYY-MM-DD');
    devServerApi.api
      .patch(
        'reservations/' + id,
        Terces.encrypt({
          ...data,
          rooms,
          menus,
          visitDate: date,
          visitTime: time,
          userPhone: userInfo.newUserPhone ? undefined : data.userPhone.replace(/-/g, ''),
          alarmMessageState: userInfo.alarmMessageState,
          newUserId: userInfo.newUserId,
          newUserPhone: !userInfo.newUserPhone ? undefined : userInfo.newUserPhone.replace(/-/g, ''),
        })
      )
      .then((res: any) => {
        if (depositInfo.useDeposit) {
          devServerApi.api
            .post(
              `/reservations/${reservationInfo.reservationId}/deposit`,
              {
                 depositPaymentType: depositInfo.depositPaymentType,
                 depositDue: depositInfo.depositDue,
                 depositAmount: Number(depositInfo.depositAmount),
                 depositPaymentBankType: depositInfo.depositPaymentBankType,
              }
            ).then(depositRes => {
              setReservationResetStore();
              setLoading(false);
              reset(res.reservation);
              navigate(`/reservation/list?date=${date}`);
            });
        } else {
          setReservationResetStore();
          setLoading(false);
          reset(res.reservation);
          navigate(`/reservation/list?date=${date}`);
        }
      });
  };

  const handleOnCancel = () => {
    if (!reservationInfo || !reservationInfo.reservationState || reservationInfo.reservationState === 'canceled') return;
    setAlertModal({ visible: true, key: cancleModalId });
  };

  const handleSetCancel = () => {
    const originState = reservationInfo.reservationState;
    devServerApi.api
      .patch(
        '/reservations/' + reservationInfo.reservationId,
        Terces.encrypt({
          reservationState: 'cancel',
          cancelMessageTemplate: sendCancelAlarm,
          userId: userInfo.userId,
        })
      )
      .catch(() => {
        setReservationInfo({ ...reservationInfo, reservationState: originState });
      });
    setReservationInfo({ ...reservationInfo, reservationState: 'canceled' });
    setAlertModal({ visible: false, key: cancleModalId });
  };

  const handleOnRollback = () => {
    if (!reservationInfo || !reservationInfo.reservationState || reservationInfo.reservationState !== 'canceled') return;
    setAlertModal({ visible: true, key: rollbackModalId });
  };

  const handleSetRollback = () => {
    const originState = reservationInfo.reservationState;
    devServerApi.api
      .patch(
        '/reservations/' + reservationInfo.reservationId,
        Terces.encrypt({
          reservationState: 'valid',
          userId: userInfo.userId,
        })
      )
      .catch(() => {
        setReservationInfo({ ...reservationInfo, reservationState: originState });
      });
    setReservationInfo({ ...reservationInfo, reservationState: 'valid' });
    setAlertModal({ visible: false, key: cancleModalId });
  };

  // handleMoveRoute //
  // const handleMoveRoute = (path) => {
  //   setReservationUpdateStore({ ...getValues(), menus });
  //   navigate(path);
  // };

  useEffect(() => {
    if (!reservationInfo.reservationId) {
      setLoading(true);
      devServerApi
        .authGet('reservations/' + id, accessToken)
        .then((enc_res: any) => Terces.decrypt(enc_res))
        .then((res: any) => {
          // @ts-ignore
          const hour = res.reservation.visitTime / 60;
          const minute = res.reservation.visitTime % 60;
          const dayjsVisit = dayjs().hour(hour).minute(minute);
          setVisitTime(dayjsVisit);
          initGetAllInfo(res.reservation);
          const findRoomList = res.reservation.rooms.reduce((list, findRoom) => {
            const findRoomResult = mall.rooms.find((mallRoom) => mallRoom.id === findRoom.id);
            return [...list, findRoomResult];
          }, []);
          setRooms(findRoomList);
          setLoading(false);
          reset({ ...res.reservation, visitTime: dayjsVisit, visitDate: dayjs(res.reservation.visitDate), useDeposit: res.reservation.depositState !== 'unused' });
        });
    }
  }, []);

  useEffect(() => {
    document.querySelector('.main-container-desktop').scrollTop = 0;
  }, []);

  if (loading) {
    return <SpinnerEffector loading={loading} />;
  }

  return (
    <div className="reservation-detail-body">
      <FormProvider {...methods}>
        <MenuList tabsWrapperRef={tabsWrapperRef} />
        {reservationInfo.reservationState === 'canceled' ? <div className="isCancelledReservation">취소된 예약입니다.</div> : null}
        <div ref={tabsWrapperRef} className={reservationInfo.reservationState === 'canceled' ? 'content-wrapper cancel' : 'content-wrapper'}>
          <Reservationinfo />
          <CustomerAdditionalInformation />
          <MenuInfo />
          <OtherInformation />
          {depositInfo?.depositState === 'unused' ? <DepositSetting handleMoveRoute={navigate} /> : <DepositSettingInfo />}
        </div>
        <FooterSub isBackArea isVisible isBorder>
          <div className="flex-container gap-8 w-full">
            {reservationInfo.reservationState === 'canceled' ? (
              <>
                <button className="footer tblm-button-normal w-full tblm-btn-primary" onClick={instantDisabler(handleOnRollback)}>
                  예약복구
                </button>
              </>
            ) : (
              <>
                <button className="footer tblm-button-normal w-full" onClick={instantDisabler(handleOnCancel)}>
                  예약취소
                </button>
                <button
                  className="footer tblm-button-normal w-full tblm-btn-primary"
                  onClick={instantDisabler(
                    handleSubmit((data) => {
                      handleOnRouterMove(data);
                    }, (errors) => {
                      console.log(errors);
                    })
                  )}
                >
                  예약수정
                </button>
              </>
            )}
          </div>
        </FooterSub>
      </FormProvider>
      <ModalPortal>
        <Modal.Alert key={cancleModalId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">예약을 정말로 취소하시겠습니까?</div>
          <div className="content font__body_sm">
            예약 취소 시 취소 확인 문자가 발송됩니다. 취소한 예약은 복구할 수 있습니다.
            <div className="send_cancel_alarm_wrap">
              <label className="tblm-rc">
                <input type="checkbox" name="send_cancel_alarm" checked={sendCancelAlarm} onChange={() => setSendCancelAlarm((state) => !state)} />
                <i />
                <span>취소 문자 발송</span>
              </label>
            </div>
          </div>
          <div className="footer success font__body_sm_sb" onClick={instantDisabler(handleSetCancel)}>
            예약 취소
          </div>
        </Modal.Alert>
        <Modal.Alert key={rollbackModalId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">예약을 복구하시겠어요?</div>
          <div className="content font__body_sm">
            {/* 예약금 설정이 켜져 있을 때 */}
            {depositInfo?.depositState !== 'unused' ? (
              <div className="send_cancel_alarm_wrap receive-deposit-check-wrap">
                <label className="tblm-rc">
                  <input type="checkbox" name="send_cancel_alarm" checked={sendCancelAlarm} onChange={() => setSendCancelAlarm((state) => !state)} />
                  <i />
                  <span>예약금을 받습니다.</span>
                </label>
              </div>
            ) : null}
          </div>
          <div className="footer success font__body_sm_sb" onClick={instantDisabler(handleSetRollback)}>
            예약복구
          </div>
        </Modal.Alert>
        <Modal.Alert key={requiredModalId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">예약 필수 정보를 입력해주세요.</div>
          <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: requiredModalId })}>
            확인
          </div>
        </Modal.Alert>
      </ModalPortal>
    </div>
  );
};

export default ReservationDetail;
