import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import HeaderSub from '@/components/HeaderSub/HeaderSub';

import AOSCache from '@/assets/images/DelCache_AOS.png';
import IOSCache from '@/assets/images/DelCache_IOS.png';
import './clearCache.scss';

const OS_TYPE = {
  AOS: 'aos',
  IOS: 'ios',
};

const CLEAR_CACHE_OS = {
  [OS_TYPE.AOS]: AOSCache,
  [OS_TYPE.IOS]: IOSCache,
};

const ClearCache = () => {
  const navigate = useNavigate();
  const [userMobileOS, setUserMobileOS] = useState(OS_TYPE.AOS);

  const selectMobileType = (type) => {
    setUserMobileOS(type);
  };

  return (
    <div className="clear-cache-container">
      <HeaderSub isFixedShortHeader isBorder={false} onClose={() => navigate(-1)} isInitFixedPosition>
        캐시 삭제 방법
      </HeaderSub>
      <main className="clear-cache-main">
        <section className="desc-wrapper">
          <h3>
            테이블매니저 APP이 잘 작동하지 않을 때, <br />
            캐시 문제일 수 있어요.
          </h3>
          <span>다음과 같은 지침을 따라주세요!</span>
        </section>
        <section className="os-tab-wrapper">
          <ul>
            <li className={userMobileOS === OS_TYPE.AOS ? 'active' : null} onClick={() => selectMobileType(OS_TYPE.AOS)}>
              안드로이드
            </li>
            <li className={userMobileOS === OS_TYPE.IOS ? 'active' : null} onClick={() => selectMobileType(OS_TYPE.IOS)}>
              아이폰
            </li>
          </ul>
        </section>
        <section className="how-to-clear-cache-wrapper">
          <img src={CLEAR_CACHE_OS[userMobileOS]} alt="캐시삭제 방법 이미지" />
        </section>
      </main>
    </div>
  );
};

export default ClearCache;
