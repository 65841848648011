import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { usePartnerStore } from '@/stores/common/usePartnerStore';
import { INativeMessage, sendNativeMessage } from '@/shared/lib/functions/native-messge';
import { useReservationStore } from '@/stores/reservation/useReservationStore';

const AuthPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setAccessToken, setRefreshToken, setAppPushContext, setIsMultiMall, setLoginId } = useAuthStore();
  const { appName } = usePartnerStore();
  const { userInfo, setUserInfo } = useReservationStore();
  const accessToken = searchParams.get('accessToken');
  const refreshToken = searchParams.get('refreshToken');
  const redirect = searchParams.get('redirect');

  useEffect(() => {
    if (appName) {
      if (appName === 'kt') {
        try {
          if (!accessToken || !refreshToken) throw new Error('Invalid Token Data');
          setAccessToken(accessToken);
          setRefreshToken(refreshToken);
        } catch (e) {
          console.error(e);
        }
        navigate(redirect || '/', { replace: true });
      } else if (appName === 'tablemanager' && window.blueberry) {
        window.blueberryLoginHook = (object) => {
          setAccessToken(object.accessToken);
          setRefreshToken(object.accessToken);
          setIsMultiMall(object.isMultiMall);
          setAppPushContext(object.appPushCtx);
          setLoginId(object.id);
          if (redirect) {
            if (redirect.startsWith('/reservation/list')) {
              navigate(redirect, { replace: true });
            } else if (redirect.startsWith('/tablemanager/call-history')) {
              navigate(redirect, { replace: true });
            } else if (redirect.startsWith('/reservation/accept/step-1')) {
              const params = redirect.split('=');
              const userId = params[1];
              const userName = params[2];
              const userPhone = params[3];
              const redirectWithoutParam = redirect.split('?')[0];
              setUserInfo({
                ...userInfo,
                userId: userId,
                userName: userName,
                userPhone: userPhone.replace(/-/g, ''),
                isSearch: false,
                newUserId: undefined,
                newUserPhone: undefined,
              });
              navigate(redirectWithoutParam, { replace: true });
            } else if (redirect.startsWith('/tablemanager/customer-detail')) {
              const paramUserId = redirect.split('?')[1];
              const userId = paramUserId.split('=')[1];
              const redirectChangePath = redirect.split('?')[0] + '/' + userId;
              navigate(redirectChangePath, { replace: true });
            }
          }
          navigate(redirect || '/reservation/list', { replace: true });
        };
        const message: INativeMessage = { action: 'request_user_data' };
        sendNativeMessage(message);
      }
    }

  }, [appName]);

  return <></>;
};

export default AuthPage;
