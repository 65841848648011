/* eslint-disable */
import React from 'react';

const tos_doc = ({ className }: {className: string}): React.ReactElement => {
  return (<div className={className} dangerouslySetInnerHTML={{__html : `<span class='h2'>제 1 조 (목적)</span>본 약관은 테이블매니저(이하 "회사"라 합니다)가 제공하는 테이블매니저 서비스(이하 "서비스"라고 합니다)를 이용함에 있어 이용조건 및 절차에 관한 사항과 기타 필요한 제반 사항을 규정함을 목적으로 합니다. 
<span class='h2'>제 2 조 (용어의 정의)</span> 본 약관에서 사용하는 용어의 정의는 다음과 같습니다. 
1. 회원 : 회사와 서비스 이용계약을 체결하고 회사의 서비스를 이용하는 개인 또는 법인
2. 유료회원:서비스 이용료를 부담하고 서비스를 이용할 수 있는 권한을 가진 회원
3. 무료회원:정해진 조건에 의거 무료로 서비스를 이용할 수 있는 권한을 가진 회원
4. 해지:회사 또는 회원이 서비스 개통 후 서비스 이용계약을 해약하는 것
5. 해제:회사 또는 회원이 서비스 개통 전 서비스 이용계약을 해약하는 것
6. 정지 : 회원이 서비스 이용에 따른 의무를 위반하여 의무위반의 상태가 해소될 때까지 서비스 이용을 일시적으로 제한 또는 유보하거나, 회사의 사정으로 인하여 또는 전시, 사변 또는 불가항력 재앙 등 회사가 통제할 수 없는 사유로 서비스 이용을 일시적으로 제한 또는 유보하는 것
7. 카카오톡 채널 : 회원이 카카오의 카카오톡 채널을 통해 비즈니스를 목적으로 개설한 비즈니스 홈이며, 카카오톡 채널 팀에서 별도의 심사를 통해 관련 정보를 확인한 채널을 말한다.
8. 챗봇예약 서비스 : 회사가 개발한 클라우드 기반의 챗봇예약 서비스를 말한다.
9. AI 통화비서 : 회사가 “주식회사 케이티”와 공동사업으로 개발한 AI 통화비서 서비스를 말한다.
<span class='h2'>제 3 조 (약관의 효력 및 변경)</span> 본 약관에서 사용하는 용어의 정의는 다음과 같습니다. 
1. 본 약관은 회사의 홈페이지(www.tablemanager.io)에 게시하여 공지하고 회원이 동의함으로써 효력이 발생합니다.
2. 회사는영업상필요또는기타사정변경등본약관을변경해야할합리적인사유가 발생할 경우, 관계 법령에 위배되는 않는 범위에서 본 약관을 변경할 수 있으며, 이 경우 변경된 약관적용일 이전에 그 내용을 제 1 항과 같은 방법으로 공지합니다.
3. 본 약관은 회원이 서비스 이용을 신청한 때부터 서비스 이용계약의 해지(또는 해제)후 정산이 완료될때까지 적용됩니다.
4. 회원은 변경된 약관에 동의하지 않을 경우 서비스 이용계약의 해지 또는 해제를 요청할 수 있으며, 변경된 약관의 효력 발생일 이후에도 서비스를 계속해서 이용할 경우
변경된 약관에 동의한 것으로 간주합니다.
5. 회원은 제 1 항에 공지된 홈페이지를 방문하여 본 약관의 변경사항을 확인하여야 합니다. 본 약관의 변경 사실을 확인하지 못하여 회원에게 발생한 손해에 대해서는 회사는 책임지지 않습니다.
<span class='h2'>제 4 조 (서비스 이용계약의 성립 및 체결)</span>1. 서비스 이용을 원하는 회원은 유료 서비스 신청절차에 따라 본 약관을 읽고, 신청서에 서명함으로써 서비스 신청을 하고, 회사는 위 신청서의 기재항목을 검토하여 이상이 없으면 이를 승인함으로써 서비스 이용계약이 성립합니다. 단, AI 통화비서 상에서 회사의 서비스를 이용하는 경우 별도 이용신청 양식 없이 본 약관을 동의하는 절차에 따라 서비스 이용 신청하는 것으로 간주합니다.
2. 서비스 이용계약은 매장단위로 체결하며, 복수의 서비스 이용계약을 체결하고자 하는 경우 개별 신청절차를 통해 신청하여야 합니다.
<span class='h2'>제 5 조 (서비스 신청)</span> 1. 서비스 신청 회원은 회사에서 요청하는 제반정보(매장명, 사업자번호, 매장번호, 담당자명, 이메일, 결제정보 등)을 제공하여야 합니다.
2. 타인 명의를 도용하여 서비스 신청을 한 경우는 즉시 서비스 이용계약이 해지되며, 관계 법령에 따라 처벌받을 수 있습니다.
<span class='h2'>제 6 조 (서비스 신청의 승낙 및 제한)</span> 1. 서비스 신청에 대한 승낙은 신청서의 기재항목에 이상이 없고, 서비스 신청회원이 직접 또는 제3자를 통하여 사용료 결제를 완료시킨 시점에 이루어집니다. 단, 회원이 본 서비스 이용을 위하여 회사와 본 약관과는 별도의 개별계약을 체결한 경우, 계약의 성립시기는 별도의 서면 계약서가 양측에 의해 날인되어 양측 모두 원본 혹은 사본을 교부 받은 때로 합니다.
2. 신청일로부터 해당월 말일까지는 ‘테이블매니저 시범사용’ 서비스를 이용할 수 있으며, 익월 1 일부터 말일까지를 서비스 이용기간으로 하며, 별도의 해지 의사를 밝히지 않는 경우 매월 자동연장됩니다.
3. 제1항에도불구하고회사는다음각호의1에해당하는경우서비스신청에대한 승낙을 거부하거나 제한할 수 있습니다. 본인이 아닌 타인의 명의를 도용하여 가입한 경우
  o 회사에 미납요금이 있는 경우
  o 기타 회원의 귀책사유로 이용승낙이 곤란한 경우
  o 기타 회사가 서비스 제공에 곤란한 객관적인 사유가 있다고 판단한 경우
4. 제1항에도불구하고회사는서비스신청이다음각호의1에해당하는경우,해당 신청에 대하여 승낙 제한 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
  o 회사가 설비의 여유가 없는 경우
  o 회사의 기술상 지장이 있는 경우
  o 기타이용승낙이곤란한사유가있는경우
5. 서비스 신청 회원은 회사에서 요청하는 제반정보(매장명, 사업자번호, 매장번호, 담당자명, 이메일, 결제정보 등)을 제공하여야 합니다.
6. 타인 명의를 도용하여 서비스 신청을 한 경우는 즉시 서비스 이용계약이 해지되며, 관계 법령에 따라 처벌받을 수 있습니다.
<span class='h2'>제 7 조 (아이디 등 관리)</span>1. 회원 아이디는 원칙적으로 변경이 불가능하며 부득이한 사유로 변경하고자 하는 경우에 회사에 변경을 요청할 수 있습니다.
2. 회원은아이디및비밀번호에대한관리책임이있으며,서비스이용상의과실또는 제3자에 대한 부정 사용등으로 인하여 발생하는 모든 문제에 대한 책임을 집니다.
<span class='h2'>제 8 조 (서비스의 종류)</span>1. 서비스는 '테이블매니저 스탠다드', '테이블매니저 베이직', '테이블매니저 시범사용', ‘테이블매니저 프리미엄’, ‘테이블매니저 통화비서’로 구성됩니다.
2. 모든 서비스 결제 금액에는 10% 별도 부가가치세가 부과됩니다.
3. '테이블매니저 시범사용'은 월 예약 건수 200 건까지 사용할 수 있습니다.
4. ‘테이블매니저 통화비서’는 월 예약 건수 200 건까지 사용할 수 있습니다.
5. '테이블매니저 베이직'은 월 예약 건수 200 건까지 사용할 수 있습니다.
6. '테이블매니저 스탠다드'는 월 예약 건수 500 건까지 사용할 수 있습니다.
7. ‘테이블매니저 프리미엄’은 월 예약 건수 1000 건까지 사용할 수 있습니다.
8. '회원'의 카카오톡 채널 생성, 카카오톡 비즈니스 채널의 신청 업무를 위임 받아 업무를
    대행하여 진행합니다. (이 경우 '회원'은 '서비스'의 이용을 위하여 카카오톡 채널 생성,
    카카오톡 비즈니스 채널의 생성을 위한 관련 정보를 '회사'에 제공합니다)
9. '회원'의 카카오톡 채널 운영 및 관리를 대행할 수 있습니다.
10. '회원'은 생성된 카카오톡 채널을 비지니스 목적에 부합하는 방향으로 운영해야 합니다.
11. '회원'은 챗봇예약 서비스의 커스텀 메뉴를 '회사'의 사전 서면 동의없이 임의로 수정할 수 없습니다.
12. 8 항을 통해 생성되는 '카카오톡 채널'은 '서비스'에 종속되어, '서비스' 이용 중 '카카오톡 채널' 이관이 불가하며, 서비스 종료 및 중도 해지 시 '카카오톡 채널'도 즉시 삭제됩니다. 또한 챗봇예약 서비스의 커스텀 메뉴도 사용이 중지됩니다.
<span class='h2'>제 9 조 (계약의 해지 및 이용제한)</span>1. 서비스 이용계약의 해지에는 회원의 서비스 해지 신청에 의한 해지와 회사의 직권에 의한 해지가 있습니다.
2. 회원의 해지 요청에 따라 서비스 이용계약이 해지된 후 발생된 회원정보 소실에 대한 모든 책임은 회원에게 있으며, 회사에 이로 인한 손해배상 등 어떠한 청구도 할 수 없습니다.
3. 해지 신청 접수 후 회원의 계정은 해지상태로 즉시 변경되어 서비스 이용이 제한됩니다.
4. 회원의 계정 및 계정 내에 저장된 데이터에 대한 책임은 각 회원에게 있으며,부정한 사용 또는 각호에 해당하는 행위를 금지하며, 이에 해당하는 경우 이용계약을 해지하거나 자료 삭제 또는 서비스의 전부 또는 일부의 이용을 제한하거나 정지할 수 있습니다. 또한 이로 인해 발생된 모든 피해의 책임은 회원에게 있습니다.
  o 허가받지 않은 데이터와 바이러스에 감염된 데이터, 타 이용자에게 피해를 줄 수 있는 데이터의 저장, 전송, 조회 등의 행위
  o 범죄적 행위, 미풍양속, 공공질서에 반하는 행위
  o 전산,정보,기기의오동작을유발시킬수있는일체의행위
  o 안정된서비스운용을저해할수있는행위
  o 회사, 다른 회원 또는 제3자의 지적재산권을 침해하는 행위
  o 상담 이용 시 욕설이나 제품과 관련 없는 언급으로 정상적인 상담을 불가능하게 함으로써 업무에 상당한 지장을 초래하는 경우
<span class='h2'>제 10 조 (계약의 해지에 따른 환불 정책)</span>1. 서비스 이용계약의 해지에 따른 환불정책은 다음과 같습니다. 단, 회원의 귀책사유로 회사가 이용계약을 직권해지하는 경우 환불하지 않습니다.
  o 결제일로 부터 5 일이내 해지 신청 시 서비스 신청금액에서 해지수수료로 신청금액의 15%를 공제한 나머지 금액이 환불됩니다.
  o 결제일로부터5일초과20일이내해지신청시서비스신청금액에서 해지수수료로 서비스 신청금액의 15%를 공제한 나머지 금액을 기준으로 남은 잔여일에 해당하는 금액이 환불됩니다. 
    즉 환불액은 [서비스 신청금액 *0.85] -[(서비스 신청금액/30 일) * 서비스 사용일]이 됩니다.
  o 단, ‘테이블매니저 통화비서’의 경우 서비스 이용료를 별도로 부과하지 않기 때문에 별도로 환불하지 않습니다.
2. 약정할인 등 할인이 제공된 경우 해지시 환불액에서 할인액을 제외한 금액만 환불합니다. 단, 약정할인의 경우 누적할인액으로 적용하며, 누적할인액이 환불액보다 큰 경우 누적할인액에서 환불액을 차감하고, 회사에 남은 할인액을 회사가 지정한 계좌로 15 일 이내에 반환해야 합니다.
<span class='h2'>제 11 조 (서비스 이용)</span>1. 서비스는회사가서비스신청에대한승낙을한직후부터이용할수있되,유료 서비스의 경우 결제가 이루어지면 이용 가능합니다.
2. 서비스 이용시간은 회사의 업무상 또는 기술상 불가능한 경우를 제외하고는 연중무휴 1일 24시간 원칙으로 합니다. 단, 서비스 안정화를 위한 서비스 설비의 점검 등 회사의 업무상, 기술상의 이유로 서비스 일시정지가 필요한 경우 또는 시스템 및 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등 부득이한 사유가 발생한 경우에는 서비스가 정지될 수 있습니다. 이러한 경우 회사는 사전 또는 사후에 그 사유를 공지합니다.
3. 전항에도불구하고전화지원및원격지원등의기술지원은다음의시간에한해 서비스 이용이 가능하며, 회사 휴무일 등 회사 사정에 따라 이용이 제한될 수 있습니다.
  o 전화지원:평일오전10시~오후6시(토,일및공휴일휴무) 
  o 원격지원:평일오전10시~오후5시(토,일및공휴일휴무)
4. 회원은 회사에서 제공받은 서비스의 전부 또는 일부를 제3자 등에게 재판매를 포함하여 복사, 번역, 재배포, 재전송, 출판, 판매, 대여, 임대, 전매, 이전, 변경, 수정 등의 행위를 할 수 없습니다.
<span class='h2'>제 12 조 (회사의 의무)</span>1. 회사는 회원으로부터 제기되는 의견이나 불만이 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우에는 회원에게 그 사유와 처리 일정을 서면 또는 유선 등으로 통보하여야 합니다.
2. 회사는서비스제공과관련하여취득한회원의정보를사전승낙없이타인에게누설 또는 배포할 수 없으며 상업적 목적으로 사용할 수 없습니다. 다만, 관계 법령에 의한 수사상의 목적으로 관계 기관으로부터 요구받은 경우 또는 회사가 정한 기간 동안의 이용요금을 체납한 경우에는 그렇지 않습니다.
<span class='h2'>제 13 조 (회원의 의무)</span>1. 회원은 다음 각 호의 1에 해당하는 행위를 하지 않아야 합니다.
  o 타인의 아이디 또는 비밀번호를 부정하게 사용하는 행위
  o 타인의 명예를 손상시키거나 불이익을 주는 행위
  o 공공질서 또는 미풍약속에 위배되는 내용의 정보를 유포하는 행위 
  o 범죄적 행위와 결부된다고 판단되는 행위
  o 지적재산권을 침해하는 행위
  o 컴퓨터 바이러스 감염자료를 등록하는 행위
  o 상법및기타법령에위배되는행위
  o 기타 서비스의 정상적 운영, 유지 등을 방해하거나 지연시키는 행위
2. 회원은 서비스 이용에 대한 대가로서 요금을 납입할 의무가 있으며 서비스 이용요금 미납으로 인해 발생되는 모든 문제에 대한 책임은 회원에게 있습니다 .
3. 회원은 서비스 신청 당시의 회원정보에 변경이 발생하면 즉시 그 내용을 회사에 통보하여야 하며, 통보 지연으로 발생한 문제에 대해서는 회사가 일체 책임을 지지 않습니다.
4. 회원이 회사의 설비를 분실 또는 훼손하였거나 장애를 초래하게 하였을 경우에는 그 보충, 수선 또는 기타 공사에 소요되는 비용과 이로 인해 발생되는 회사의 손해를 일체 배상하여야 합니다.
5. 회원이 제공하는 모든 정보의 저작권과 관련하여 발생하는 문제는 회원의 책임입니다.
<span class='h2'>제 14 조 (손해배상 범위)</span>1. 회사의 귀책사유로 서비스를 이용하지 못하는 경우에는 회원이 그 사실을 회사에 통보한 시점 또는 회사가 그 사실을 알게 된 시점으로부터 4 시간 이상 연속적으로 그 상태가 계속될 때에만 회사는 회원의 청구에 대하여 제2항에 따른 손해배상 책임을 부담합니다.
2. 손해 배상은 아래 기준으로 다음달 요금을 자동 할인해드립니다.
  o 4시간~8시간연속서비스사용불가->10%할인 
  o 9시간 ~16시간 연속 서비스 사용 불가 ->20% 할인 
  o 16시간 ~24시간 연속 서비스 사용불가 ->50% 할인
3. 손해배상 대상에는 회사와 서비스 이용계약이 체결된 서비스 내용에 한합니다.
<span class='h2'>제 15 조 (보증과 책임의 제한)</span>1. 회사는 관련 법률이 허용하는 최대한의 범위 내에서 상품성, 특정 목적에 대한 적합성, 지적재산권 또는 비침해성에 대한 묵시적 보증 등을 포함하여 일체의 명시적 또는 묵시적인 보증을 배제합니다. 회사는 서비스 기능이 회원의 요구사항을 충족시키거나 서비스의 사용 시 컴퓨터의 사용에 일시적인 간섭이나 오류가 발생하지 않을 것과 입력된 데이터의 보존에 대해 보증하지 않습니다.
2. 서비스로인하여회사가부담하게될모든책임에대하여회원이구제받을수있는 유일한 방법은 서비스 이용금액의 전부 또는 일부의 환불로 제한됩니다. 회사는 관련 법률이 허용하는 최대한의 범위 내에서 서비스로 인한 손해(결과적, 우연적, 간접적, 특별, 경제적, 징벌적, 또는 기타 이와 유사한 모든 손해 또는 사업상의 이익의 손실, 영업권의 손실, 사업에 대한 간섭, 컴퓨터 기능 마비 또는 오동작, 사업정보의 손실 기타
제반 상업적 또는 금전적 손해 또는 손실을 포함합니다)에 대하여 책임을 지지 않습니다. 회사가 부담하게 되는 회원에 대한 책임은 회사의 고의가 없는 한 회원으로부터 실제로 지급받은 서비스 이용금액을 초과하지 아니합니다.
<span class='h2'>제 16 조 (손해배상의 청구)</span>1. 회원은 손해배상 청구 사유를 안 날로부터 3 개월 이내에 손해배상 청구사유, 산출근거를 기재하여 회사에 서면으로 청구하여야 하고 위 기간을 경과할 경우 회사의 책임은 소멸합니다.
2. 회원의 손해배상 청구권은 사유발생일로부터 6 개월이 경과하면 소멸됩니다.
3. 회사는 회원의 손해배상청구 접수 후 6일 이내 그 결과를 회원에게 회신해야 합니다.
4. 제3항 관련사실 확인기간이 6일 이상 소요되는 경우, 회신 가능일자를 회원에게 통지하여야 하며, 그 일자는 30 일을 넘지 않도록 합니다.
<span class='h2'>제 17 조 (면책)</span>1. 회사는 아래에 해당하는 경우에는 손해배상을 하지 않습니다.
  o ‘테이블매니저 시범사용’, ‘무료 회원’, 테스트 등 회사에게 비용을 지불하지 않은 서비스의 이용으로 인한 손해가 발생한 경우
  o 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태 등 불가항력으로 서비스를 제공할 수 없는 경우
  o 회원의고의또는과실등회원의귀책사유로서비스를제공할수없는 경우
  o 전기통신서비스 특성상 불가피한 사유로 서비스 제공이 불가능하거나 관련 시스템 및 소프트웨어의 변경, 교체 등을 위해 일시적으로 서비스 제공을 정지하는 경우
  o 회원의 귀책사유로 서비스가 해지되어, 회원의 정보가 소실된 경우
  o 회사 이외의 타 통신사업자가 제공하는 전기통신서비스의 장애로 인한 경우
  o 회원이 자신의 아이디, 비밀번호를 타인에게 알려주어 회원에게 어떠한 손해가 발생한 경우
  o 회원이 서비스를 이용하여 기대하는 손익이나 서비스를 통해 얻은 정보나 자료 등으로 인해 손해가 발생한 경우
  o 계약만료 또는 요금 미납의 결과로 회원 계정이 정지되거나, 보관 파일이 삭제된 후 이에 따르는 손해가 발생한 경우
  o 원활한 서비스 제공을 위해서 점검작업을 실시할 때나 불가피하게 긴급작업을 시행할 경우 서비스 제공을 일시적으로 정지할 수 있으며, 이때 서비스를 이용하지 못함으로 인해 손해가 발행한 경우
  o 제3자가 서비스의 접속 및 전송을 방해하거나 서비스를 중단시켜 발생한 경우 
  o 현재의 보안기술 수준으로는 방어가 곤란한 네트워크 해킹 등으로 인한 경우
  o 서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인 이용으로부터 발생하는 손해인 경우
  o 회사가 파산, 해산, 청산, 폐업, 합병 등의 사유로 회사정리절차에 들어간 경우 
  o 기타 회사에 귀책사유가 없는 경우
<span class='h2'>제 18 조 (준거법 및 재판관할)</span>1. 회사와 회원간 제기된 소송은 대한민국법을 준거법으로 한다.
2. 회사와 회원 간의 발생한 분쟁에 관한 소송은 민사소송법상의 법원을 관할법원으로 한다.
<span class='h2'>제 19 조 (사업자 정보)</span>회사의 상호, 주소, 전화번호 그 밖의 연락처는 다음과 같다.
1. 상호 : 테이블매니저(주)
2. 주소 : 서울특별시 성수이로20길 16(성수동2가)
3. 전화번호 : 1544-2017
4. 이메일 주소 : info@tablemanager.io

<span class='h2'>부칙</span>본 약관은 2015년 9월 1일부터 적용됩니다.`}} />);
};

// @ts-ignore
export default tos_doc;
// @ts-ignore
