import create from 'zustand';
import { devServerApi } from '@/shared/apis/devServerApi';
import { Nullable } from '@/shared/utils/common.type';
import { getAppName, parseJwt } from '@/shared/lib/functions';

const appName = getAppName();
const TOKEN_VALUE = appName === 'tablemanager' ? process.env.REACT_APP_TM_TOKEN : process.env.REACT_APP_TOKEN;


export interface IAppPushContext {
  appPushToken: string;
  appPushTokenProvider: '01' | '02';
  deviceOS: '01' | '02';
  deviceId: string;
}

interface IUseAuthStore {
  accessToken: Nullable<string>;
  setAccessToken: (accessToken: Nullable<string>) => void;
  refreshToken: Nullable<string>;
  setRefreshToken: (refreshToken: Nullable<string>) => void;
  loginId: string;
  setLoginId: (loginId: string) => void;
  isMultiMall: boolean;
  setIsMultiMall: (isMultiMall: boolean) => void;
  appPushContext: IAppPushContext;
  setAppPushContext: (context: IAppPushContext) => void;
  resetToken: () => void;
  payload?: {
    pid?: Nullable<string>;
    pmid?: Nullable<string>;
    tmid?: Nullable<string>;
  };
  setPayload: ({ pid, pmid, tmid }) => void;
}

export const useAuthStore = create<IUseAuthStore>((set) => ({
  accessToken: TOKEN_VALUE,
  setAccessToken: (accessToken: string) => {
    const { pid, pmid, tmid } = parseJwt(accessToken);
    devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
    return set(() => ({ accessToken, payload: { pid, pmid, tmid } }));
  },
  refreshToken: TOKEN_VALUE,
  setRefreshToken: (refreshToken: string) => set(() => ({ refreshToken })),
  loginId: '',
  setLoginId: (loginId: string) => set(() => ({ loginId })),
  isMultiMall: false,
  setIsMultiMall: (isMultiMall: boolean) => set(() => ({ isMultiMall })),
  appPushContext: {
    appPushToken: '',
    appPushTokenProvider: '01',
    deviceOS: '01',
    deviceId: '',
  },
  setAppPushContext: (context: IAppPushContext) => set(() => ({ appPushContext: context })),
  resetToken: () => set(() => ({ accessToken: null, refreshToken: null, payload: { pid: null, pmid: null, tmid: null } })),
  payload: { pid: null, pmid: null, tmid: null },
  setPayload: (payload) => set(() => ({ payload })),
}));
