import { cloneDeep } from 'lodash';
import React, { useEffect, useId, useRef, useState } from 'react';
import { ReactComponent as Minus } from '@/assets/images/icons/Minus.svg';
import { ReactComponent as Plus } from '@/assets/images/icons/Plus.svg';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import SelectedMenuList from '@/components/SelectedMenuList/SelectedMenuList';
import Tabs from '@/components/Tabs/Tabs';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import '../../../ReservationDetail/MenuInformation.scss';

const MenuInfo = (): React.ReactElement => {
  const { setMenuModal } = useModalStore();
  const { menus, setMenus } = useReservationStore();
  const { mall } = useMallStore();

  const menuModalId = useId();

  const tabsWrapperRef = useRef(null);

  const [menuList, setMenusList] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);

  const handleOnTabsClick = (e) => {
    const selectedValue = e.target.getAttribute('data-value');
    setActiveCategory(selectedValue);
  };

  const handleOnChangeCount = (value, subValue, status) => {
    const newMenuList = cloneDeep(menuList).map((item) => {
      if (item.categoryId === value) {
        return {
          ...item,
          menus: item.menus.map((subItem) => {
            if (subItem.menuId === subValue) {
              let count = subItem.menuCount;

              if (status === 'plus') count = count + 1;
              else if (status === 'minus') count = count <= 0 ? count : count - 1;
              else count = 0;

              return { ...subItem, menuCount: count };
            }
            return subItem;
          }),
        };
      }
      return item;
    });
    setMenusList(newMenuList);
  };

  useEffect(() => {
    const convertMenus = mall.menus.reduce((accu: any, curr, i) => {
      const findAccu = accu.find((item) => item.categoryId === curr.categoryId);
      if (findAccu) {
        return accu.map((item) => {
          if (item.categoryId === findAccu.categoryId) {
            const matchStoreMenu = menus.find((storeMenu) => curr.menuId === storeMenu.id);
            if (matchStoreMenu) {
              return { ...item, menus: [...item.menus, { menuId: matchStoreMenu.id, menuName: curr.menuName, menuCount: matchStoreMenu.count }] };
            }
            return { ...item, menus: [...item.menus, { menuId: curr.menuId, menuName: curr.menuName, menuPrice: curr.menuPrice, menuCount: 0 }] };
          }
          return item;
        });
      }
      const findMenu = menus.find((menu) => menu.id === curr.menuId);
      if (findMenu) {
        accu.push({
          categoryId: curr.categoryId,
          categoryName: curr.categoryName,
          menus: [{ menuId: curr.menuId, menuName: curr.menuName, menuPrice: curr.menuPrice, menuCount: findMenu.count }],
        });
      } else {
        accu.push({
          categoryId: curr.categoryId,
          categoryName: curr.categoryName,
          menus: [{ menuId: curr.menuId, menuName: curr.menuName, menuPrice: curr.menuPrice, menuCount: 0 }],
        });
      }
      return accu;
    }, []);
    setMenusList(convertMenus);
    if (convertMenus.length > 0) {
      setActiveCategory(convertMenus[0].categoryId);
    }
  }, []);

  useEffect(() => {
    const result = [];
    if (menuList.length) {
      menuList.forEach((item) => {
        item.menus.forEach((subItem) => {
          if (subItem.menuCount) result.push({ id: subItem.menuId, name: subItem.menuName, count: subItem.menuCount });
        });
      });

      setMenus(result);
    }
  }, [menuList]);
  if (menuList.length === 0) {
    return <></>;
  }
  return (
    <>
      <div className="detail-content menuinfomation-content" data-scrolled="3">
        <div className="flex-container justify-between items-center">
          <div className="font__body_sm_sb">메뉴정보</div>
        </div>
        <div className="menuinformation-select-list no-border no-padding">
          <SelectedMenuList data={menuList} onDelete={handleOnChangeCount} />
        </div>
        <button className="tblm-button-normal" onClick={() => setMenuModal({ key: menuModalId, visible: true })}>
          메뉴 추가/변경
        </button>
      </div>
      <ModalPortal>
        <Modal.Menu key={menuModalId} modal="menu" isDim={true} isAnimation={true}>
          <div className="menuinformation-modal flex-container flex-col hidden">
            <div className="title font__body_md_sb">메뉴 추가/변경</div>

            <div className="content flex-container flex-col hidden">
              <div className="menu-tabs-wrapper">
                <Tabs mode="scroll" scrollContentRef={tabsWrapperRef} onClick={handleOnTabsClick}>
                  {menuList.map((item) => (
                    <Tabs.Item value={item.categoryId} active={activeCategory === item.categoryId} key={item.categoryId}>
                      {item.categoryName}
                    </Tabs.Item>
                  ))}
                </Tabs>
              </div>
              <div className="menu-group-wrapper" ref={tabsWrapperRef} style={{ height: '100%', overflowY: 'auto' }}>
                {menuList.map((item) => (
                  <div className="menu-group-list" data-scrolled={item.categoryId} key={item.categoryId}>
                    <div className="group-header font__body_sm_sb">{item.categoryName}</div>
                    {item.menus.map((subItem) => (
                      <div className="menu-item-list" key={subItem.menuId}>
                        <div>{subItem.menuName}</div>
                        <div className="flex-container flex-align-center">
                          <span onClick={() => handleOnChangeCount(item.categoryId, subItem.menuId, 'minus')}>
                            <Minus />
                          </span>
                          <div className="menu-count">{subItem.menuCount}</div>
                          <span onClick={() => handleOnChangeCount(item.categoryId, subItem.menuId, 'plus')}>
                            <Plus />
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="menuinformation-select-list">
            <SelectedMenuList data={menuList} isModal onDelete={handleOnChangeCount} />
          </div>
          <div
            className="footer"
            onClick={() => {
              setMenuModal({ visible: false, key: menuModalId });
            }}
          >
            <button className="tblm-button-normal tblm-btn-primary">확인</button>
          </div>
        </Modal.Menu>
      </ModalPortal>
    </>
  );
};

export default MenuInfo;
