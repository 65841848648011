import classnames from 'classnames';
import React from 'react';
import { IQuestionContent } from './QuestionContent.type';

const QuestionContent = ({ question, option, type, note, notePosition, value }: IQuestionContent) => {
  return (
    <div className={'tutorial-content'}>
      {notePosition === 'before' ? <div className={'tutorial-note before'}>{note}</div> : null}
      <div className={'tutorial-question'}>{question}</div>
      {notePosition !== 'before' ? <div className={'tutorial-note after'}>{note}</div> : null}
      <div className={classnames('tutorial-interaction', type)}>{option}</div>
    </div>
  );
};

export default QuestionContent;
