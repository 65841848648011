import { ReactComponent as ArrowRight } from '@/assets/images/icons/Arrow_Right.svg';
import classnames from 'classnames';
import { useState } from 'react';
import '@/components/CustomerDetail/ReservationHistory/ReservationHistory.scss';
import dayjs from 'dayjs';
import { generateHourMinuteString } from '@/shared/lib/functions';

const ORDER_STATE_VALUE = {
  valid: '방문 예정',
  enter: '방문 완료',
  exit: '방문 완료',
  canceled: '예약 취소',
  noShow: '노쇼',
};

const ReservationHistory = (propsWrap) => {
  const { customerInfoData } = propsWrap.propsWrap;

  return (
    <>
      {customerInfoData.orders?.length ? (
        <section className="reservation-history-container">
          <ul>
            {customerInfoData.orders?.map((reservation) => {
              const formatVisitTime = dayjs().startOf('day').add(reservation.visitTime, 'minute').format('A hh:mm');
              return (
                <li key={reservation.id} className={classnames({ scheduledVisit: !ORDER_STATE_VALUE.valid })}>
                  <div className="content-wrap">
                    <h5>{ORDER_STATE_VALUE[reservation.orderState]}</h5>
                    <p>
                      {`${dayjs(reservation.visitDate).format('YYYY.MM.DD(ddd)')}`} {formatVisitTime}
                    </p>
                    <b>
                      {reservation.peoples}명{reservation.roomNames?.map((room) => ` · ${room}`)}
                    </b>
                  </div>
                  <div className="arrow-icon">
                    <ArrowRight />
                  </div>
                </li>
              );
            })}
          </ul>
        </section>
      ) : (
        <section className="empty-view-container">
          <p>예약 기록이 없습니다.</p>
        </section>
      )}
    </>
  );
};

export default ReservationHistory;
