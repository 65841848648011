import '@/styles/main.scss';
import 'normalize.css';
import colorSet from './configs/colorSet.json';
import sizeSet from './configs/sizeSet.json';

import React, { useEffect } from 'react';

import CustomRouter from '@/Routes';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
/* eslint-disable nonblock-statement-body-position */
import Spinner from '@/components/Spinner/Spinner';
import { checkOS } from '@/shared/lib/functions/check-os';
import i18n from '@/locales/i18n';
import queryClient from '@/shared/utils/queryClient';
import { useLayoutStore } from './stores/common/useLayoutStore';
import { usePartnerStore } from './stores/common/usePartnerStore';
import { useNativeRoutingStore } from './stores/common/useNativeRouting';
import { getAppName } from './shared/lib/functions';
import { useLocation } from 'react-router-dom';

const App = (): React.ReactElement => {
  const { spinner } = useLayoutStore();
  const { appName, setAppName } = usePartnerStore();
  const { backHook } = useNativeRoutingStore();
  const originalViewSize = window.innerHeight;
  useEffect(() => {
    const handleOnDetectInput = () => {
      const elem: any = document.activeElement;
      const isInput = Boolean(elem.tagName.toUpperCase() === 'INPUT' && (elem.type === 'text' || elem.type === 'number' || elem.type === 'email' || elem.type === 'password'));

      const focus = () => {
        if (isInput && !document.body.classList.contains('keyboard-up')) {
          if (checkOS() === 'ios') {
            const interval = setInterval(() => {
              document.body.classList.add('keyboard-up');
            }, 100);
            setTimeout(() => clearInterval(interval), 500);
          } else {
            document.body.classList.add('keyboard-up');
          }
        }
      };
      const focusOut = () => {
        setTimeout(() => {
          const newElem: any = document.activeElement;
          const isNewInput = Boolean(
            newElem.tagName.toUpperCase() === 'INPUT' && (newElem.type === 'text' || newElem.type === 'number' || newElem.type === 'email' || newElem.type === 'password')
          );
          if (isInput && isNewInput) {
            // console.log(isInput, isNewInput, 'call');
          } else {
            document.body.classList.remove('keyboard-up');
          }
        }, 0);
      };
      elem.onFocus = focus;

      elem.addEventListener('blur', focusOut);
    };
    document.addEventListener('click', handleOnDetectInput);
    return () => {
      document.removeEventListener('click', handleOnDetectInput);
    };
  }, []);

  useEffect(() => {
    const handleOnResize = () => {
      if (window.innerHeight >= originalViewSize) document.body.classList.remove('keyboard-up');
    };
    window.addEventListener('resize', handleOnResize);
    return () => {
      window.removeEventListener('resize', handleOnResize);
    };
  }, []);

  useEffect(() => {
    // app 분기 처리
    let tmpAppName = getAppName();
    if (window.location.origin.includes('tablemanager')) {
      tmpAppName = 'tablemanager';
    }
    localStorage.setItem('appName', tmpAppName);
    setAppName(tmpAppName);
    // App별 variable css 적용
    const colorSetKeys = Object.keys(colorSet[tmpAppName]);
    colorSetKeys.forEach((key) => document.body.style.setProperty(key, colorSet[tmpAppName][key]));

    const sizeSetKeys = Object.keys(sizeSet[tmpAppName]);
    sizeSetKeys.forEach((key) => document.body.style.setProperty(key, sizeSet[tmpAppName][key]));
  }, []);

  useEffect(() => {
    let tmpAppName = getAppName();
    const htmlTitle = document.querySelector('title');
    if (window.location.origin.includes('tablemanager') || localStorage.getItem('appName') === 'tablemanager') {
      tmpAppName = 'tablemanager';
      htmlTitle.innerHTML = '테이블매니저';
    }
  }, []);

  return (
    <>
      <div className="main-container-desktop">
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <CustomRouter />
          </QueryClientProvider>
        </I18nextProvider>
        {spinner ? <Spinner /> : null}
        <div className="fake" />
      </div>
    </>
  );
};

export default App;
