import React, { forwardRef } from 'react';
import './Input.scss';
import classnames from 'classnames';

const Input = forwardRef(({ ...props }: any, ref: any) => {
  const { error } = props;
  return (
    <div className={classnames('custom-input-wrapper flex-col gap-4', props.className)}>
      <input ref={ref} {...props} className="font__body_sm" />
      {error && <span className="font__s_small">{error}</span>}
    </div>
  );
});

export default Input;
