import React, { Fragment, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import FooterSub from '@/components/FooterSub/FooterSub';
import HeaderSub from '@/components/HeaderSub/HeaderSub';
import PartySetting from '@/components/Reservation/Accept/PartySetting/PartySetting';
import TableSetting from '@/components/Reservation/Accept/TableSetting/TableSetting';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import { devServerApi } from '@/shared/apis/devServerApi';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import Terces from '@/shared/apis/terces';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useMallStore } from '@/stores/common/useMallStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import { useQuery } from 'react-query';

const INITIAL_STEP_TEXTS = {
  header: (
    <>
      예약 인원 및 테이블을 <br /> 선택 해주세요.
    </>
  ),
  footer: { save: '저장', back: '뒤로', next: '다음' },
};

const convertRooms = (list, exceptionList) => {
  return list.reduce((accu: any, curr) => {
    if (exceptionList.find((item) => item.rooms.find((room) => room.id === curr.id))) return accu;

    // reservations.forEach((item) => {
    //   if (item.rooms.find((room) => room.id === curr.id)) {
    //     curr.reservation = item;
    //   }
    // });

    const findAccu = accu.find((item) => item.roomGroupId === curr.roomGroupId);
    if (findAccu) {
      return accu.map((item) => {
        if (item.roomGroupId === findAccu.roomGroupId) {
          return { ...item, rooms: [...item.rooms, curr] };
        }
        return item;
      });
    }
    accu.push({ roomGroupId: curr.roomGroupId, roomGroupName: curr.roomGroupName, rooms: [curr] });
    return accu;
  }, []);
};

const Step3 = (): React.ReactElement => {
  const { reservationInfo, parties, rooms, visitDate, visitTime, setReservationResetStore } = useReservationStore();
  // const { maxAvailableTime } = usePolicyStore();
  const { accessToken } = useAuthStore();
  const { mall } = useMallStore();

  const [searchParams] = useSearchParams({ type: 'default' });
  const navigate = useNavigate();

  const [groups, setGroups] = useState([]);
  const [reservations, setReservations] = useState([]);

  const { isFetching } = useQuery([QUERY_KEYS.GET_RESERVATIONS_DATE], () => devServerApi.authGet(`/reservations?date=${visitDate.format('YYYY-MM-DD')}`, accessToken).then((enc_res)=>Terces.decrypt(enc_res)), {
    onSuccess: (res: any) => {
      const validReservations = res.reservations.filter((item) => {
        if (item.reservationState === 'exit') return false;
        if (item.reservationState === 'canceled') return false;
        if (item.reservationState === 'noShow') return false;
        if (reservationInfo.reservationId === item.reservationId) return false;
        return visitDate.format('YYYY-MM-DD') === item.visitDate && Number(visitTime.hour() * 60 + visitTime.minute()) === item.visitTime;
      });

      const filterReservations = res.reservations.filter((item) => {
        if (item.reservationState === 'exit') return false;
        if (item.reservationState === 'canceled') return false;
        if (item.reservationState === 'noShow') return false;
        return true;
      });
      setReservations(filterReservations);
      setGroups(convertRooms(mall.rooms, []));
    },
    enabled: Boolean(visitDate) && Boolean(visitTime) && Boolean(searchParams.get('type') !== 'modify-1'),
  });

  const handleOnClose = () => {
    if (searchParams.get('type') === 'default') {
      setReservationResetStore();
      navigate('/reservation/list');
    }
    if (searchParams.get('type') === 'modify' || searchParams.get('type') === 'deposit') navigate('/reservation/accept/step-5');
    if (searchParams.get('type') === 'modify-1' || searchParams.get('type') === 'modify-2') navigate(-1);
  };

  if (isFetching) return <SpinnerEffector loading={isFetching} />;

  return (
    <div className="table-setting-wrapper">
      <HeaderSub onClose={handleOnClose} isNeedPlaceholder={true}>{INITIAL_STEP_TEXTS.header}</HeaderSub>

      <div className="flex-container flex-col setting-area">
        {searchParams.get('type') === 'modify-1' || searchParams.get('type') === 'modify-2' ? (
          <>
            {searchParams.get('type') === 'modify-1' ? <PartySetting searchParamsType={searchParams.get('type')} /> : null}
            {searchParams.get('type') === 'modify-2' ? <TableSetting groups={groups} reservations={reservations} /> : null}
          </>
        ) : (
          <>
            <PartySetting searchParamsType={searchParams.get('type')} />
            {visitDate && visitTime ? (
              <TableSetting groups={groups} reservations={reservations} />
            ) : (
              <div className="pd-20 bg-white">예약 날짜 및 시간을 선택해야 테이블 설정이 가능합니다.</div>
            )}
          </>
        )}
      </div>

      <FooterSub isBackArea isVisible isBorder>
        <div className="font__subtitle hidden">
          <div className="font__subtitle flex-container items-center">
            {visitDate?.format('MM.DD(ddd)')}
            {visitDate && visitTime && ','} {visitTime?.format('a HH:mm')}
          </div>
          {parties[0]?.size ? (
            <div className="font__subtitle flex-container white-pre items-center parties-wrapper">
              {parties.map((item) => {
                if (!item.size) return <Fragment key={item.name} />;
                return <span key={item.name}>{`${item.name} ${item.size}`}</span>;
              })}
              {rooms.length ? `/ ${rooms.map((item) => item.name).join(', ')}` : ''}
            </div>
          ) : null}
        </div>

        <div className="flex-container gap-8">
          {searchParams.get('type').includes('modify') ? (
            <button onClick={() => navigate(-1)} className="footer tblm-button-normal tblm-btn-primary">
              {INITIAL_STEP_TEXTS.footer.save}
            </button>
          ) : (
            <>
              <Link to="/reservation/accept/step-2/">
                <button className="footer tblm-button-normal">{INITIAL_STEP_TEXTS.footer.back}</button>
              </Link>
              <Link to="/reservation/accept/step-4/">
                <button className="footer tblm-button-normal tblm-btn-primary">{INITIAL_STEP_TEXTS.footer.next}</button>
              </Link>
            </>
          )}
        </div>
      </FooterSub>
    </div>
  );
};

export default Step3;
