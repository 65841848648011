import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Picker from '@/components/Picker/Picker';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useModalStore } from '@/stores/common/useModalStore';
import { usePolicyStore } from '@/stores/common/usePolicyStore';

const SameDayReservation = (): React.ReactElement => {
  const navigate = useNavigate();
  const { setToastModal, setMenuModal } = useModalStore();
  const { bookingToday, bookingDeadLine, setBookingToday, setBookingDeadLine } = usePolicyStore();

  const handleOnToggleSwitch = async () => {
    const V = bookingToday === 'Y' ? 'N' : 'Y';
    await devServerApi.api.patch('/policy/reservations', { bookingToday: V, bookingDeadLine: bookingDeadLine });
    setBookingToday(V);
    setToastModal({ visible: true, key: 'same-day-reservation-toast-modal' });
  };
  const handleOnPickerSuccess = async (selected) => {
    const V = selected.leftArea * 60 + selected.rightArea;
    await devServerApi.api.patch('/policy/reservations', { bookingDeadLine: V });
    setBookingDeadLine(V);
  };

  const timeMaker = (arg_minutes) => {
    if (!arg_minutes) return '제한없음';

    const hours = Math.floor(arg_minutes / 60);
    const minutes = arg_minutes % 60;
    return `${hours > 0 ? hours + '시간 ' : ''}${minutes > 0 ? minutes + '분' : ''} 전`;
  };

  useEffect(() => {
    if (bookingToday === 'Y') !bookingDeadLine && setMenuModal({ visible: true, key: 'same-day-reservation-picker-modal' });
  }, [bookingToday]);

  return (
    <>
      <HeaderBack inBack={true}>
        <div className="font__subtitle_sb">당일 예약</div>
      </HeaderBack>
      <section className="settings-policy-detail-wrapper same-day-reservation-wrapper">
        <div className="switch-area">
          <div className="left-area">
            <div className="font__title_20_sb">당일 예약</div>
            <div className="font__body_sm_m">당일 예약을 받을까요?</div>
          </div>
          <div className="right-area gap-16">
            <div className="font__small">{bookingToday === 'Y' ? '당일 예약 허용' : '당일 예약 미허용'}</div>
            <label className="tblm-switch pointer">
              <input type="checkbox" name="switch_example" checked={bookingToday === 'Y'} onChange={handleOnToggleSwitch} />
              <i />
            </label>
          </div>
        </div>

        {bookingToday === 'Y' ? <div
          className={`switch-area ${bookingToday === 'Y' ? 'pointer' : 'disabled'}`}
          onClick={() => (setMenuModal({ visible: true, key: 'same-day-reservation-picker-modal' }))}
        >
          <div className="left-area">
            <div className="font__title_20_sb">당일 예약 마감시간</div>
            <div className="font__body_sm_m keep-all line_height_20">최소한 언제까지는 예약해야 매장을 이용할 수 있나요?</div>
          </div>
          <div className="right-area gap-16">
            <div className="font__title_19_sb">{timeMaker(bookingDeadLine)}</div>
          </div>
        </div> : null}
      </section>

      <Picker
        title="최소한 언제까지는 예약해야 하나요?"
        modalKey="same-day-reservation-picker-modal"
        leftArea={{ list: Array.from({ length: 7 }, (_, i) => i), unit: '시간', selected: Math.floor(bookingDeadLine / 60) }}
        rightArea={{ list: Array.from({ length: 6 }, (_, i) => i * 10), unit: '분전', selected: bookingDeadLine % 60 }}
        onSuccess={handleOnPickerSuccess}
      />
      <ModalPortal>
        <Modal.Toast key="same-day-reservation-toast-modal" modal="toast" autoHideDuration={1000} isAnimation={true} position="bottom">
          <div className="font__small_sb" style={{ color: '#ffffff' }}>
            당일 예약을 {`${bookingToday === 'Y' ? '받습니다.' : '받지 않습니다.'}`}
          </div>
        </Modal.Toast>
      </ModalPortal>
    </>
  );
};

export default SameDayReservation;
