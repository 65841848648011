import React from 'react';
import { ReactComponent as Close } from '@/assets/images/icons/Close.svg';
import { IModalProps } from '@/components/Modal/Modal.type';
import { useModalStore } from '@/stores/common/useModalStore';

const Normal = ({ isDim, isAnimation, children }: IModalProps) => {
  const { normalModal, setNormalModal } = useModalStore();
  if (!normalModal.visible) return <></>;
  return (
    <div className={`modal-wrapper normal ${isAnimation ? 'animation' : ''}`}>
      {isDim ? <div className="modal-dim" onClick={() => setNormalModal(false)} /> : null}
      <div className="modal-content">
        {/* <div className="close">
          <Close onClick={() => setNormalModal(false)} />
        </div> */}
        {children}
      </div>
    </div>
  );
};

export default Normal;
