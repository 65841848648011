import create from 'zustand';
import { IPolicy, IPolicyReservationDays } from '@/shared/utils/common.type';
interface IUsePolicyStore extends IPolicy{
  fetched?: boolean;
  setBookingToday: (bookingToday: string) => void;
  setBookingDeadLine: (bookingDeadLine: number) => void;
  setMaxAvailableTime: (maxAvailableTime: number) => void;
  setBookingAvailableDays: (bookingAvailableDays: number) => void;
  setReservationDays: (reservationDays: IPolicyReservationDays) => void;
  setReservationTimeUnit: (reservationTimeUnit: number) => void;
  setPolicy: (policy: Partial<IUsePolicyStore>) => void;
}

const defaultReservationDay = ()=> ({
  policyFormId: -1,
  reservation: -1,
  bookingTimes: [],
});

export const usePolicyStore = create<IUsePolicyStore>(
  (set) => ({
    status: null,
    fetched: false,
    bookingToday: 'N',
    bookingDeadLine: 0,
    maxAvailableTime: 0,
    bookingAvailableDays: 0,
    reservationDays: {
      monday: defaultReservationDay(),
      tuesday: defaultReservationDay(),
      wednesday: defaultReservationDay(),
      thursday: defaultReservationDay(),
      friday: defaultReservationDay(),
      saturday: defaultReservationDay(),
      sunday: defaultReservationDay(),
    },
    reservationTimeUnit: 60,
    setBookingToday: (bookingToday) => set(()=> ({ bookingToday })),
    setBookingDeadLine: (bookingDeadLine) => set(()=> ({ bookingDeadLine })),
    setMaxAvailableTime: (maxAvailableTime) => set(()=> ({ maxAvailableTime })),
    setBookingAvailableDays: (bookingAvailableDays) => set(()=> ({ bookingAvailableDays })),
    setReservationDays: (reservationDays) => set(()=> ({ reservationDays })),
    setReservationTimeUnit: (reservationTimeUnit) => set(()=> ({ reservationTimeUnit })),
    setPolicy: (policy) => set(() => (Object.assign(policy, { fetched: true }))),
  }),
);

export const dayKoreaWord = {
  monday : '월',
  tuesday: '화',
  wednesday: '수',
  thursday: '목',
  friday: '금',
  saturday: '토',
  sunday: '일',
};
