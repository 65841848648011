// import dayjs from 'dayjs';

const dayjs = require('dayjs');
const duration = require('dayjs/plugin/duration');
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
dayjs.extend(duration);

const filteringAgoDay = (date) => {
  if (dayjs().diff(dayjs(date).format('YYYY-M-D'), 'd') === 0) return '오늘';
  if (dayjs().diff(dayjs(date).format('YYYY-M-D'), 'd') === 1) return '어제';
  return dayjs(date).from(dayjs().format('YYYY-MM-DD'));
};

const getTimeAgoInToday = (callTime) => {
  const now = dayjs();
  const targetTime = dayjs(callTime);
  const isSameDay = now.isSame(targetTime, 'day');
  const durationTime = dayjs.duration(now.diff(targetTime));

  if (isSameDay) {
    if (durationTime.hours() > 0) {
      return ` · ${durationTime.hours()}시간 전`;
    } else if (durationTime.minutes() > 0) {
      return ` · ${durationTime.minutes()}분 전`;
    }
    return ' · 몇 초 전';
  }
  return null;
};

function formatDurationCallTime(seconds, showType) {
  // showType: all(시간 단위 전체 표출) | short (가장 큰 단위만 표출)
  const secDuration = dayjs.duration(seconds, 'seconds');
  let formattedTime = '';

  switch (showType) {
    case 'short':
      if (secDuration.hours() > 0) {
        return secDuration.hours() + '시간';
      } else if (secDuration.minutes() > 0) {
        return secDuration.minutes() + '분';
      } else if (secDuration.seconds() > 0) {
        return secDuration.seconds() + '초';
      }
      break;

    default:
      if (secDuration.hours() > 0) {
        formattedTime += `${secDuration.hours()}시간 `;
      }
      if (secDuration.minutes() > 0) {
        formattedTime += `${secDuration.minutes()}분 `;
      }
      if (secDuration.seconds() > 0) {
        formattedTime += `${secDuration.seconds()}초`;
      }
      return formattedTime.trim();
  }
}

export { filteringAgoDay, getTimeAgoInToday, formatDurationCallTime };
