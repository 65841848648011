import React, { useEffect, useId, useState } from 'react';
import { devServerApi } from '@/shared/apis/devServerApi';
import { IPolicy } from '@/shared/utils/common.type';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useMallStore, IMall } from '@/stores/common/useMallStore';
import { usePolicyStore } from '@/stores/common/usePolicyStore';
import Terces from '@/shared/apis/terces';
import { usePartnerStore } from '@/stores/common/usePartnerStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNativeRoutingStore } from '@/stores/common/useNativeRouting';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Modal from '@/components/Modal/Modal';
import { INativeMessage, sendNativeMessage } from '@/shared/lib/functions/native-messge';
import { useModalStore } from '@/stores/common/useModalStore';

const CommonProvider = ({ children }): React.ReactElement => {
  const { accessToken } = useAuthStore();
  const { setMall } = useMallStore();
  const { setPolicy } = usePolicyStore();
  const [isLoading, setIsLoading] = useState(true);
  const { appName } = usePartnerStore();
  const locaiton = useLocation();
  const navigate = useNavigate();
  const { backHook } = useNativeRoutingStore();
  const { setAlertModal } = useModalStore();
  const mallApiUrl = appName === 'tablemanager' ? '/mall' : '/reservation/mall';
  const closeAppModalId = useId();

  const closeWebview = () => {
    const message:INativeMessage = { action: 'close_window' };
    sendNativeMessage(message);
  };

  useEffect(() => {
    if (accessToken) {
      if (appName === 'kt') {
        devServerApi
          .authGet('/get-webview-key', accessToken)
          .then((res) => {
            // @ts-ignore
            if (res.status < 0) return;
            Terces.fetchYek(res);
          })
          .catch(() => { })
          .finally(() => { });
      }
      devServerApi
        .authGet<IMall>(mallApiUrl, accessToken)
        .then((res) => {
          // @ts-ignore
          if (res.status < 0) return;
          setMall({ ...res, roomGroups: Object.fromEntries(res.rooms.map(({ roomGroupId, roomGroupName }) => [roomGroupId, roomGroupName])) });
        })
        .catch(() => { })
        .finally(() => {
          setIsLoading(false);
        });
      if (appName === 'kt') {
        devServerApi
          .authGet<IPolicy>('/policy/reservations', accessToken)
          .then((data: IPolicy) => {
            if (data.status < 0) return;
            let reservationTimeUnit = data.reservationTimeUnit;
            if (!data.reservationTimeUnit) {
              try {
                let resDelimiters = Array.from(
                  new Set(
                    Object.values(data.reservationDays)
                      .map((r) => (Array.isArray(r.bookingTimes) ? r.bookingTimes.map((t) => t.split(':')[1]) : []))
                      .flat(1)
                  )
                );
                if (resDelimiters.includes('10') || resDelimiters.includes('20') || resDelimiters.includes('40')) {
                  reservationTimeUnit = 10;
                } else if (resDelimiters.includes('30')) {
                  reservationTimeUnit = 30;
                } else {
                  reservationTimeUnit = 60;
                }
                // eslint-disable-next-line no-empty
              } catch (e) { }
            }
            setPolicy({ ...data, reservationTimeUnit });
          })
          .catch(() => { });
      }
    }
  }, [accessToken]);


  useEffect(() => {
    const pathName = location.pathname;
    console.log(pathName);
    if (pathName === '/reservation/list') {
      window.blueberryBackHook = () => {
        setAlertModal({ visible:true, key: closeAppModalId });
      };
    } else if (pathName === '/tablemanager/first-time-customer') {
      window.blueberryBackHook = () => {
        closeWebview();
      };
    } else {
      if (backHook) {
        window.blueberryBackHook = () => {
          backHook();
        };
      } else {
        window.blueberryBackHook = () => {
          history.back();
        };
      }
    }

  }, [backHook, location.pathname]);


  if (isLoading) return <></>;
  return <>
    {children}
    <ModalPortal>
      <Modal.Alert key={closeAppModalId} modal="alert" isDim={true} isAnimation={true}>
        <div className="title font__subtitle">앱 종료</div>
        <div className="content">앱을 종료하시겠습니까?</div>
        <div className="footer success font__body_sm_sb" onClick={() => closeWebview()}>
          확인
        </div>
      </Modal.Alert>
    </ModalPortal>
  </>;
};

export default CommonProvider;
