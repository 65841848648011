import HeaderSub from '@/components/HeaderSub/HeaderSub';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Edit } from '@/assets/images/icons/ic_edit.svg';
import { ReactComponent as CallIcon } from '@/assets/images/icons/ic_call.svg';
import { ReactComponent as MessageIcon } from '@/assets/images/icons/ic_message.svg';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Modal from '@/components/Modal/Modal';
import { useEffect, useId, useLayoutEffect, useMemo, useState } from 'react';
import { useModalStore } from '@/stores/common/useModalStore';
import Tabs from '@/components/Tabs/Tabs';
import { handleContactEvent } from '@/shared/lib/functions/contact-event';
import { useMutation, useQuery } from 'react-query';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import '@/pages/tablemanager/customer-detail/customerDetail.scss';
import CustomerInfo from '@/components/CustomerDetail/CustomerInfo/CustomerInfo';
import ReservationHistory from '@/components/CustomerDetail/ReservationHistory/ReservationHistory';
import CallHistory from '@/components/CustomerDetail/CallHistory/CallHistory';
import classnames from 'classnames';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import Input from '@/components/Input/Input';
import { IGetUserResponse, IMutationProps } from '@/shared/utils/common.type';
import { useMallStore } from '@/stores/common/useMallStore';
import { autoHypenTel } from '@/shared/lib/functions/auto-hypen-tel';
import { ControlledInput } from '@/hook-form/ControlledInput';
import { useReservationStore } from '@/stores/reservation/useReservationStore';


const VALID_ERROR_MESSAGE = {
  name: '이름을 입력해주세요.',
  phone: '휴대폰번호를 입력해주세요.',
};

const TAB_INFO = [
  {
    id: 'customerInformation',
    value: '고객 정보',
    componentRender: (props) => <CustomerInfo propsWrap={props} />,
  },
  {
    id: 'reservationHistory',
    value: '예약 기록',
    componentRender: (props) => <ReservationHistory propsWrap={props} />,
  },
  {
    id: 'callHistory',
    value: '통화 기록',
    componentRender: (props) => <CallHistory propsWrap={props} />,
  },
];

// testId: 6302e5f72d69c457245379c5

const CustomerDetail = () => {
  const navigate = useNavigate();
  const { accessToken } = useAuthStore();
  const { setAlertModal, setToastModal } = useModalStore();
  const { userInfo, setUserInfo } = useReservationStore();
  const { id: paramUserId } = useParams();
  const searchParams = new URLSearchParams(location.search);
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });

  const changeValueId = useId();
  const unsavedValueId = useId();
  const validationErrorId = useId();

  const [validErrorMessage, setValidErrorMessage] = useState(null);
  const [activeTab, setActiveTab] = useState(TAB_INFO[0].id);
  const [isActiveEditUser, setIsActiveEditUser] = useState(false);
  const { mall } = useMallStore();

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      userName: yup.string().required(VALID_ERROR_MESSAGE.name),
      userPhone: yup.string().required(VALID_ERROR_MESSAGE.phone),
    });
  }, []);

  const methods = useForm<any>({
    defaultValues: {
      userName: '',
      userPhone: '',
      userNickname: '',
      userMemo: '',
    },
    mode: 'onBlur',
  });

  const {
    setValue,
    getValues,
    reset,
    control,
    formState: { dirtyFields },
  } = methods;

  const {
    isLoading: isGetCustomerLoading,
    data: customerInfoData,
    refetch: refetchCustomerInfoData,
  } = useQuery([QUERY_KEYS.GET_CUSTOMER_INFO, paramUserId], () => devServerApi.authGet<IGetUserResponse>(`/user/${paramUserId}/details`, accessToken));

  const {
    isLoading: isGetCallHistoryLoading,
    data: callHistoryData,
    refetch: refetchCallHistoryData,
  } = useQuery([QUERY_KEYS.GET_CUSTOMER_CALL_LOG, paramUserId], () => devServerApi.authGet<any>(`/user/${paramUserId}/callLogs`, accessToken));

  const { mutate: editUserInfoMutation, isLoading: isEditUserLoading } = useMutation((mutatedata: IMutationProps) => devServerApi.mutation(mutatedata), {
    onSuccess: () => {
      // edit 상태 해제
      setIsActiveEditUser(false);
      refetchCustomerInfoData();
      refetchCallHistoryData();
      setToastModal({ key: changeValueId, visible: true });

      if (searchParams.get('isEditSameUser') && dirtyFields?.userPhone) return navigate(-1);
    },
    onError: () => {
      setValidErrorMessage('수정이 완료되지 않았습니다. 관리자에게 문의하세요.');
      return setAlertModal({ visible: true, key: validationErrorId });
    },
  });

  useEffect(() => {
    if (customerInfoData) {
      let userExtraValues = {};

      mall.userExtravalues?.forEach((extraInfo) => {
        userExtraValues[extraInfo.id] = customerInfoData?.user.extra.extraValues ? customerInfoData.user.extra.extraValues[extraInfo.id] : '';
      });
      let obj = {
        userName: customerInfoData.user.common.name,
        userPhone: autoHypenTel(customerInfoData.user.common.phone),
        userNickname: customerInfoData.user.extra.nickname,
        userMemo: customerInfoData.user.extra.memo,
        userExtraValues: { ...userExtraValues },
      };
      reset({ ...obj });
    }
  }, [customerInfoData]);

  const goToCallHisotryPage = () => navigate('/tablemanager/call-history');

  const onClose = () => {
    if (Object.keys(dirtyFields).length > 0) {
      return setAlertModal({ key: unsavedValueId, visible: true });
    }
    return goToCallHisotryPage();
  };

  const handleOnTabsClick = (e) => {
    const selectedValue = e.target.getAttribute('data-value');
    setActiveTab(selectedValue);
  };

  const saveUserData = (data) => {
    return validationSchema
      .validate(data, { abortEarly: false })
      .then(() => {
        const userData = {
          name: data.userName.replaceAll(' ', ''),
          phone: data.userPhone.replaceAll('-', '').trim(),
          nickname: data.userNickname,
          memo: data.userMemo,
          extraValues: data.userExtraValues,
          includeLog: '',
        };

        editUserInfoMutation({
          operation: 'patch',
          url: `/user/${paramUserId}`,
          data: userData,
        });
      })
      .catch((error) => {
        if (error.errors?.length) {
          setValidErrorMessage(error.errors[0]);
          return setAlertModal({ visible: true, key: validationErrorId });
        }
      });
  };

  const requiredInTabProps = (tab) => {
    switch (tab) {
      case TAB_INFO[0].id:
        return { onClose, saveUserData, customerInfoData };

      case TAB_INFO[1].id:
        return { customerInfoData };

      case TAB_INFO[2].id:
        return { callHistoryData };
    }
  };

  const goToReservationProcess = () => {
    setUserInfo({
      ...userInfo,
      userId: customerInfoData.user.id,
      userName: customerInfoData.user.common.name,
      userPhone: customerInfoData.user.common.phone,
      isSearch: false,
      newUserId: undefined,
      newUserPhone: undefined,
    });
    navigate('/reservation/accept/step-1');
  };

  if (isGetCustomerLoading || isGetCallHistoryLoading || isEditUserLoading) return <SpinnerEffector loading />;

  return (
    <>
      <FormProvider {...methods}>
        <div className="customer-detail-container">
          <HeaderSub isFixedShortHeader isInitFixedPosition onClose={onClose} isBorder={false}>
            {customerInfoData.user.common.name.length ? customerInfoData.user.common.name : autoHypenTel(customerInfoData.user.common.phone)}
          </HeaderSub>
          <main
            className={classnames('main-container', {
              emptyData: (activeTab === 'reservationHistory' && !customerInfoData.orders?.length) || (activeTab === 'callHistory' && !callHistoryData.callLogs?.length),
            })}
          >
            <article className="user-info-container">
              <section className={classnames('user-default-data', { editableUserDefaultData: customerInfoData.user.common.name.length && isActiveEditUser })}>
                {customerInfoData.user.common.name.length && isActiveEditUser ? (
                  <>
                    <ControlledInput
                      control={control}
                      onChange={(e) => setValue('userName', e.target.value)}
                      name="userName"
                      type="text"
                      defaultValue={getValues('userName')}
                      className="table-name-input"
                    />
                    <ControlledInput
                      control={control}
                      maxLength={13}
                      onChange={(e) => setValue('userPhone', autoHypenTel(e.target.value))}
                      name="userPhone"
                      type="tel"
                      className="table-name-input"
                    />
                  </>
                ) : (
                  <>
                    <div className="user-default">
                      {customerInfoData.user.common.name.length && <h2>{customerInfoData.user.common.name}</h2>}
                      <h5>{autoHypenTel(customerInfoData.user.common.phone)}</h5>
                    </div>
                    {!isActiveEditUser && <Edit onClick={() => setIsActiveEditUser(true)} />}
                  </>
                )}
              </section>
              <section className="user-contact-data">
                <ul>
                  <li>
                    <h3>{customerInfoData.user.common.visitTimes}회</h3>
                    <span>방문</span>
                  </li>
                  <li>
                    <h3>{customerInfoData.user.common.noShowTimes}회</h3>
                    <span>노쇼</span>
                  </li>
                  <li>
                    <h3>{customerInfoData.user.common.callTimes}회</h3>
                    <span>통화</span>
                  </li>
                </ul>
              </section>
              <section className="contact-button-container">
                <ul>
                  <li onClick={() => handleContactEvent('message', customerInfoData.user.common.phone)}>
                    <MessageIcon className="message-icon" />
                    <em>문자</em>
                  </li>
                  <li onClick={() => handleContactEvent('call', customerInfoData.user.common.phone)}>
                    <CallIcon className="call-icon" />
                    <em>전화</em>
                  </li>
                </ul>
                <button onClick={() => goToReservationProcess()} className="submit-reservation-btn">
                  예약 접수
                </button>
              </section>
            </article>
            <article className="user-history-container">
              <Tabs isBorder mode="default" onClick={handleOnTabsClick} isFixed>
                {TAB_INFO.map((item) => (
                  <Tabs.Item value={item.id} active={activeTab === item.id} key={item.id} onClick={handleOnTabsClick}>
                    {item.value}
                  </Tabs.Item>
                ))}
              </Tabs>
              {TAB_INFO.find((activeItem) => activeItem.id === activeTab).componentRender(requiredInTabProps(activeTab))}
            </article>
          </main>
        </div>
      </FormProvider>
      <ModalPortal>
        <Modal.Toast key={changeValueId} modal="toast" position="bottom" autoHideDuration={1500} margin={101} isAnimation={true}>
          <div className="content font__small_sb">변경사항이 저장되었습니다.</div>
        </Modal.Toast>
        <Modal.Alert key={unsavedValueId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">저장되지 않은 변경사항이 있습니다.</div>
          <div className="content font__body_sm">저장 없이 나갈시 변경사항이 적용되지 않습니다.</div>
          <div className="footer-multiple-buttons">
            <div className="footer default font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: unsavedValueId })}>
              취소
            </div>
            <div onClick={goToCallHisotryPage} className="footer success font__body_sm_sb">
              나가기
            </div>
          </div>
        </Modal.Alert>
        <Modal.Alert key={validationErrorId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">{validErrorMessage}</div>
          <div onClick={() => setAlertModal({ visible: false, key: validationErrorId })} className="footer success font__body_sm_sb">
            확인
          </div>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default CustomerDetail;
