import { highlightCurrectWord } from '@/shared/lib/functions/make-highlight-search-value';
import './HightlightSearchValue.scss';

const HightlightSearchValue = (props) => {
  return (
    <div>
      {highlightCurrectWord(props.searchWord, props.userText).map((item, idx) => (
        <span key={item.character + idx} className={item.isHighlight ? 'highlight' : null}>
          {item.character}
        </span>
      ))}
    </div>
  );
};

export default HightlightSearchValue;
