import './ManagementCalendar.scss';

import { Calendar, Day, Week } from './ManagementCalendar.type';
import React, { Fragment, useEffect, useId, useRef, useState } from 'react';

import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useQuery } from 'react-query';
import Modal from '@/components/Modal/Modal';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigate } from 'react-router-dom';

const ManagementCalendar = ({ startDate, targetDate, select, headerHeight = 0, holidays }): React.ReactElement => {
  const { accessToken } = useAuthStore();
  const navigate = useNavigate();
  const errorModalId = useId();
  const { setAlertModal } = useModalStore();
  const [errorMessage, setErrorMessage] = useState<String>('');
  const [viewDate, setViewDate] = useState<dayjs.Dayjs>(dayjs());
  const [calendarList, setCalendarList] = useState<Calendar[]>([]);
  const [scheduleList, setScheduleList] = useState<{ date?: dayjs.Dayjs | string; state?: Day['state'] }[]>([]);

  const [readyToScroll, setReadyToScroll] = useState(false);
  const scrollElement = document.querySelector('.main-container-desktop');
  const refs = useRef([]);
  const today = dayjs();
  const period = today.add(90, 'day').diff(today, 'month') + 1;
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
  const { isFetching } = useQuery(
    [QUERY_KEYS.SCHEDULE_CALENDAR],
    () => devServerApi.api.get(`/policy/schedule/calendar?from=${startDate.startOf('month').format('YYYY-MM-DD')}&to=${startDate.clone().add(4, 'month').endOf('month').format('YYYY-MM-DD')}`),
    {
      onSuccess: (res: any) => {
        if (res.data.status < 0) {
          setErrorMessage(res.data.message);
          setAlertModal({ visible: true, key: errorModalId });
        } else {
          setScheduleList(res.data.onlineScheduleSummary.map((item) => ({ ...item, date: dayjs(item.date) })));
        }
      },
    }
  );

  const makeMonthCalendar = (date: dayjs.Dayjs, datePeriod: number) => {
    let tempCalendarList = new Array<Calendar>(datePeriod).fill(null);
    tempCalendarList = tempCalendarList.map((calendar, monthIndex) => {
      const targetMonthDate = date.add(monthIndex, 'month').startOf('month');
      const firstDay = date.add(monthIndex, 'month').startOf('month').day();
      const weekOfMonth = Math.ceil((firstDay + targetMonthDate.daysInMonth()) / 7);

      let weeks = new Array<Week>(weekOfMonth).fill(null);
      weeks = weeks.map((_, weekIndex) => {
        let targetWeekDate = targetMonthDate.add(weekIndex, 'week');
        let days = new Array<Day>(7).fill(null);
        days = days.map((__, dayIndex) => {
          const targetDay = targetWeekDate.startOf('week').add(dayIndex, 'day');
          const foundSchedule = scheduleList.find((r) => targetDay.isSame(r.date, 'day')) || {};
          return {
            isToday: today.isSame(targetDay, 'day'),
            isBeforeDay: today.isBefore(targetDay, 'day'),
            isSameMonth: targetMonthDate.isSame(targetDay, 'month'),
            isBeforePeriod: today.add(90, 'day').isBefore(targetDay, 'day'),
            orderInfo: { orderCount: 0, personCount: 0 },
            targetDay,
            state: foundSchedule.state || null,
          };
        });
        return { days, targetWeek: targetWeekDate };
      });

      return { weeks, targetMonth: targetMonthDate };
    });
    setCalendarList(tempCalendarList);
  };

  const scrollToTargetDate = (date: dayjs.Dayjs) => {
    let targetCalendarIndex = calendarList.findIndex((calendar) => calendar.targetMonth.isSame(date.startOf('month'), 'day'));
    if (targetCalendarIndex > -1) {
      setTimeout(() => {
        if (refs.current[targetCalendarIndex] && refs.current[targetCalendarIndex].scrollIntoView) {
          refs.current[targetCalendarIndex].scrollIntoView();
          scrollElement.scrollTo(0, scrollElement.scrollTop - 102);
        }
        setReadyToScroll(true);
      }, 400);
    }
  };

  const onChangeViewDate = () => {
    const lastScrollTop = scrollElement.scrollTop + headerHeight + 126;
    const filteredItems = refs.current.filter((item) => lastScrollTop > item.offsetTop);
    let targetItem = filteredItems[filteredItems.length - 1];

    if (targetItem) {
      let index = Number(targetItem.id);
      setViewDate(calendarList[index].targetMonth);
    }
  };

  useEffect(() => {
    if (calendarList.length > 0) {
      scrollToTargetDate(targetDate);
      if (readyToScroll) {
        scrollElement.addEventListener('scroll', onChangeViewDate);
        return () => {
          scrollElement.removeEventListener('scroll', onChangeViewDate);
        };
      }
    }
  }, [calendarList, readyToScroll]);

  useEffect(() => {
    makeMonthCalendar(startDate, period + 2);
  }, [startDate, period, scheduleList]);

  if (!calendarList || calendarList.length === 0 || isFetching) return <SpinnerEffector loading={isFetching} />;
  if (errorMessage) return (
    <>
      <Modal.Alert key={errorModalId} modal="alert" isDim={true} isAnimation={true}>
        <div className="title font__body_md_sb">알림</div>
        <div className="content font__body_sm">
          {errorMessage}
        </div>
        <div className="footer success font__body_sm_sb" onClick={() => {
          navigate(-1);
          setErrorMessage('');
          setAlertModal({ visible: false, key: errorModalId });
        }}>
          확인
        </div>
      </Modal.Alert>
    </>
  );
  if (calendarList && calendarList.length > 0) {
    return (
      <>
        <div className="calendar-sticky-header" style={{ top: headerHeight }}>
          <div className="view-date-text font__subtitle1_sb">{viewDate.format('YYYY년 MM월')}</div>
          <div className="week-header">
            <div className="week-header-item font__subtitle1_sb">일</div>
            <div className="week-header-item font__subtitle1_sb">월</div>
            <div className="week-header-item font__subtitle1_sb">화</div>
            <div className="week-header-item font__subtitle1_sb">수</div>
            <div className="week-header-item font__subtitle1_sb">목</div>
            <div className="week-header-item font__subtitle1_sb">금</div>
            <div className="week-header-item font__subtitle1_sb">토</div>
          </div>
        </div>
        {calendarList.map((calendar, index) => (
          <div
            key={index}
            className="calendar-container"
            id={index.toString()}
            ref={(element) => {
              refs.current[index] = element;
            }}
          >
            <div className="calendar-month font__subtitle1_sb">{calendar.targetMonth.format('M월')}</div>
            {calendar.weeks.map((week, i) => {
              return (
                <div className="calendar-week" key={i}>
                  {week.days.map((day, j) => {
                    const isSunday = week.targetWeek.day(0).isSame(day.targetDay, 'day');
                    const isSaturday = week.targetWeek.day(6).isSame(day.targetDay, 'day');
                    const isHoliday = holidays.find((holiday) => holiday.date.isSame(day.targetDay, 'day'));
                    return (
                      <Fragment key={j}>
                        {day.isSameMonth ? (
                          <div
                            className={classNames('management-calendar-day', { active: (day.isToday || day.isBeforeDay) && !day.isBeforePeriod })}
                            onClick={() => select(day.targetDay)}
                          >
                            <div
                              className={classNames('date-box font__subtitle1_sb', {
                                today: day.isSameMonth && day.isToday,
                                sunday: isSunday,
                                saturday: isSaturday,
                                holiday: isHoliday,
                              })}
                            >
                              {day.targetDay.format('D')}
                            </div>

                            {(day.isToday || day.isBeforeDay) && !day.isBeforePeriod ? (
                              <div className="order-info font__s_small">
                                <div className={classNames('onoff-tag', { on: day.state === 'opened' || day.state === 'edited' })}>
                                  {day.state === 'opened' || day.state === 'edited' ? 'On' : 'Off'}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div className="calendar-day" />
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              );
            })}
          </div>
        ))}
      </>
    );
  }
  return null;
};

export default ManagementCalendar;
