import create from 'zustand';
import { Nullable } from '@/shared/utils/common.type';

interface IModal {
  visible: boolean;
  key: Nullable<string>;
}

interface IUseModalStore {
  alertModal: IModal;
  setAlertModal: (props) => void;
  menuModal: IModal;
  setMenuModal: (props) => void;
  toastModal: IModal;
  setToastModal: (props) => void;
  normalModal: IModal;
  setNormalModal: (props) => void;
}

export const initialModal = { visible: false, key: null };

export const useModalStore = create<IUseModalStore>((set) => ({
  alertModal: initialModal,
  menuModal: initialModal,
  toastModal: initialModal,
  normalModal: initialModal,

  setAlertModal: (alertModal) => set({ alertModal }),
  setMenuModal: (menuModal) => set({ menuModal }),
  setToastModal: (toastModal) => set({ toastModal }),
  setNormalModal: (normalModal) => set({ normalModal }),
}));
