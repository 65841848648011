import classnames from 'classnames';
import groupBy from 'lodash/groupBy';
import React, { useEffect, useId, useState } from 'react';
import { ReactComponent as Arrow_Right } from '@/assets/images/icons/Arrow_Right.svg';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import InfoTooltip from '@/components/InfoTooltip/InfoTooltip';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Picker from '@/components/Picker/Picker';
import Select from '@/components/Select/Select';
import { devServerApi } from '@/shared/apis/devServerApi';
import { timeFilter, timesMaker } from '@/shared/lib/functions';
import { Nullable } from '@/shared/utils/common.type';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { usePolicyStore } from '@/stores/common/usePolicyStore';
import { useReservationSettingStore } from '@/stores/common/useReservationSettingStore';


const timeUnitList = [10, 30, 60];
const daylist = ['월', '화', '수', '목', '금', '토', '일'];
const daylistMap = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const ReceiveReservation = (): React.ReactElement => {
  const { reservationTimeUnit, setReservationTimeUnit, reservationDays } = usePolicyStore();
  // const { mallTime } = useMallStore();
  // const mallTimeMap = daylistMap.map(d=>mallTime[d]);
  // const mallTimeCloseMap = mallTimeMap.map((t, idx) => !t.isOpen ? idx : -1).filter(i => i >= 0);

  // const navigate = useNavigate();
  const { setAlertModal, setMenuModal } = useModalStore();
  const alertModalId = useId();
  const timeSelectModalId = useId();
  const weekSelectModalId = useId();

  const [term, setTerm] = useState(reservationTimeUnit);

  // 개별 세팅 여부
  const [enableCopyAny, setEnableCopyAny] = useState(false);

  const handleOnPickerSuccess = (value) => {
    setAlertModal({ visible: true, key: alertModalId });
    if (value && value.leftArea) {
      setTerm(value.leftArea);
    }
  };

  const closeAlert = () => {
    setAlertModal({ visible: false, key: alertModalId });
    setMenuModal({ visible: false, key: timeSelectModalId });
  };

  const handleOnClickAlertModal = () => {
    setAlertModal({ visible: false, key: alertModalId });
    setMenuModal({ visible: true, key: timeSelectModalId });
  };

  const [copyDayList, setCopyDayList] = useState([]);

  const [enabledTimeList, setEnabledTimeList] = useState<[string|number, Nullable<any[]|any>][]>(daylist.map(key => ([key, []])));
  const [backupEnabledTimeList, setBackupEnabledTimeList] = useState<[string|number, Nullable<any[]|any>][]>(daylist.map(key => ([key, []])));
  const [copyList, setCopyList] = useState<[string|number, Nullable<any[]|any>][]>(daylist.map(key => ([key, []])));
  const [dayIndex, setDayIndex] = useState(0);
  const [showType, setShowType] = useState<number>(0);
  const [ynList, setYnList] = useState<[string|number, Nullable<any[]|any>][]>(daylist.map(key => ([key, null])));
  const [step, setStep] = useState([]);

  const resetStore = () => {
    setEnabledTimeList(JSON.parse(JSON.stringify(backupEnabledTimeList)));
    setCopyList(daylist.map(key => ([key, []])));
    setDayIndex(0);
    setShowType(0);
    setYnList(daylist.map(key => ([key, null])));
    setStep([]);
  };


  const commitChange = () => {
    const e = Array.from(new Set(enabledTimeList)).map(([a, b]) => [a, b.sort()]);
    // @ts-ignore
    setEnabledTimeList(e);
  };

  const submitReservationDays = async (arg_reservationDays) => {
    await devServerApi.api.patch('/policy/reservations', { reservationDays: arg_reservationDays, reservationTimeUnit: term });
    setReservationTimeUnit(term);
  };

  const submit = ()=> {
    for (const dayIndexForCopy in copyList) {
      let day = copyList[dayIndexForCopy];
      for (const i of day[1]) {
        enabledTimeList[i][1] = [...enabledTimeList[dayIndexForCopy][1]];
      }
    }
    for (const timeIdx in enabledTimeList) {
      enabledTimeList[timeIdx][1] = timeFilter(enabledTimeList[timeIdx][1], {});
    }
    commitChange();
    submitReservationDays(Object.fromEntries(enabledTimeList.map(([day, list], i) => {
      // const bookingTimes = timeFilter(list, mallTimeMap[i]);
      const bookingTimes = timeFilter(list, {});
      return [
        daylistMap[i], {
          // policyFormId: reservationDays[daylistMap[i]].policyFormId,
          reservation: bookingTimes.length > 0 ? 1 : 0,
          bookingTimes,
        },
      ];
    })));
    setCopyList(daylist.map(key => ([key, []])));
    closeAlert();
  };

  const next = (bool) => {
    if (bool === undefined) return;
    if (showType === 0) {
      // @ts-ignore
      setStep(steps => [...steps, dayIndex]);
      if (!bool) {
        if (enableCopyAny) {
          for (const idx of copyDayList) {
            enabledTimeList[idx][1] = [];
          }
          commitChange();
          closeAlert();
          setShowType(0);
          return;
        }
        setYnList(state => {
          state[dayIndex][1] = false;
          return state;
        });
        setCopyList(state => {
          state[dayIndex][1] = [];
          return state;
        });
        enabledTimeList[dayIndex][1] = [];
        commitChange();
        if (dayIndex === 6) {
          submit();
          return;
        }
        let remainDays = [0, 1, 2, 3, 4, 5, 6].filter(d => d > dayIndex).find(d => copyList.every(([_, list]) => !list.includes(d)));
        if (!remainDays) {
          submit();
        } else {
          setDayIndex(remainDays);
        }
      } else {
        setYnList(state => {
          state[dayIndex][1] = true;
          return state;
        });
        setShowType(1);
      }
      return;
    }
    if (showType === 1) {
      if (enableCopyAny) {
        for (const copy of copyList) {
          copy[1] = copy[1].filter(idx => !copyDayList.includes(idx));
        }
        setCopyList(copyList);
        for (const idx of copyDayList) {
          enabledTimeList[idx][1] = [...enabledTimeList[dayIndex][1]];
        }
        commitChange();
        submit();
        setShowType(0);
        return;
      }
      let remainDays = [0, 1, 2, 3, 4, 5, 6].filter(d => d > dayIndex).find(d => copyList.every(([_, list]) => !list.includes(d)));
      if (!remainDays) {
        submit();
      } else {
        setDayIndex(remainDays);
      }
      setShowType(0);
    }
  };



  // const times = timesMaker(term, mallTimeMap[dayIndex]);
  const times = timesMaker(term, {});
  const dayTimeGroup = groupBy(times, (time)=>time.split(':')[0]);
  const dayTimeList = Object.entries(dayTimeGroup).sort((a, b) => Number(a[0]) - Number(b[0]));

  const allCheck = (e) => {
    if (e.target.checked) {
      enabledTimeList[dayIndex][1] = [...times];
    } else {
      enabledTimeList[dayIndex][1] = [];
    }
    commitChange();
  };

  const hourChecked = (hour) => {
    return dayTimeGroup[hour].every(time => enabledTimeList[dayIndex][1].includes(time));
  };
  const minuteChecked = (time) => {
    return enabledTimeList[dayIndex][1].includes(time);
  };

  const hourCheck = (hour, checked) => {
    if (checked) {
      enabledTimeList[dayIndex][1].push(...dayTimeGroup[hour]);
    } else {
      enabledTimeList[dayIndex][1] = enabledTimeList[dayIndex][1].filter(time => !time.startsWith(hour));
    }
    commitChange();
  };
  const minuteCheck = (time, checked) => {
    if (checked) {
      enabledTimeList[dayIndex][1].push(time);
    } else {
      enabledTimeList[dayIndex][1] = enabledTimeList[dayIndex][1].filter(item => item !== time);
    }
    commitChange();
  };

  const copyDayCheck = (e) => {
    if (e.target.checked) {
      if (enableCopyAny) {
        setCopyList(state => {
          const tmpState = [...state];
          for (let dl of tmpState) {
            dl[1] = dl[1].filter(item => item !== Number(e.target.value));
          }
          return tmpState;
        });
        setCopyDayList(state => {
          state.push(Number(e.target.value));
          // @ts-ignore
          return Array.from(new Set(state)).sort();
        });
      } else {
        setCopyList(state => {
          const tmpState = [...state];
          tmpState[dayIndex][1].push(Number(e.target.value));
          // @ts-ignore
          tmpState[dayIndex][1] = Array.from(new Set(tmpState[dayIndex][1])).sort();
          return tmpState;
        });
      }
    } else {
      if (enableCopyAny) {
        setCopyDayList(state => {
          return state.filter(item => item !== Number(e.target.value));
        });
      } else {
        setCopyList(state => {
          const tmpState = [...state];
          tmpState[dayIndex][1] = tmpState[dayIndex][1].filter(item => item !== Number(e.target.value));
          return tmpState;
        });
      }
    }
  };
  const isDisabledCopy = (idx) => {
    if (!enableCopyAny) {
      for (let copyDayIndex = 0; copyDayIndex < copyList.length; copyDayIndex += 1) {
        if (copyDayIndex < dayIndex && copyList[copyDayIndex][1].includes(Number(idx))) return true;
      }
    }

    return false;
  };


  useEffect(()=> {
    // setEnabledTimeList(daylistMap.map((d, i)=> [daylist[i], timeFilter(reservationDays[d].bookingTimes, mallTimeMap[dayIndex])]));
    setEnabledTimeList(daylistMap.map((d, i)=> [daylist[i], timeFilter(reservationDays[d].bookingTimes, {})]));
  }, [reservationDays]);

  const openTime = (idx)=> {
    const enabledTime = Array.from(document.querySelectorAll(`.reservation-time-tag-wrapper[data-idx='${idx}'] > div`)).map(e=>e.innerHTML.trim());
    enabledTimeList[idx][1] = enabledTime;
    setCopyList(state => {
      const tmpState = [...state];
      for (let dl of tmpState) {
        dl[1] = dl[1].filter(item => item !== Number(idx));
      }
      return tmpState;
    });
    setEnableCopyAny(true);
    setCopyDayList([]);
    setShowType(1);
    setDayIndex(idx);
    setMenuModal({ visible: true, key: timeSelectModalId });
  };


  const unitize = (option, unit) => {
    if (unit === '층') return `${option}층`.replace('-', '지하 ');
    if (unit === '시간분') {
      const h = Math.floor(option / 60);
      const m = Math.floor(option % 60);

      let ret = '';
      ret += h > 0 ? h + '시간' : '';
      ret += h > 0 && m > 0 ? ' ' : '';
      ret += m > 0 ? m + '분' : '';

      return ret;
    }
    return option + unit;
  };

  return (
    <>
      <HeaderBack inBack={true}>
        <div className="font__subtitle">예약 받는 시간</div>
      </HeaderBack>
      <section className="reservation-time-unit-wrapper">
        <div className="reservation-time-unit pointer">
          <div className="left-area">
            <div className="font__body_sm">예약 시간 단위<InfoTooltip message={'예약 시간 단위를 바꾸면 \n예약 받는 시간을 다시 설정해야 해요.'} /></div>
          </div>
          <div className="right-area gap-16">
            <Select
              value={unitize(term, '시간분')}
              style={{ maxWidth: 130 }}
              onClick={() => {
                setMenuModal({ visible: true, key: 'reservation-time-picker-modal' });
              }}
            />
          </div>
        </div>
        {enabledTimeList.map(([day, list], i) => (
          <div className={classnames('reservation-time-wrapper', { holiday: list.length === 0 })} key={day}>
            <span className="flex-container-center flex-space-between">
              {day} {list.length > 0 ? <Arrow_Right onClick={() => {
              openTime(i);
            }} /> : null}
            </span>
            {list.length > 0 ? (
              <div className="reservation-time-tag-wrapper" data-idx={i}>
                {list.map((v) =>
                  <div className="reservation-time-tag font__body_sm" key={`${day}_${v}`}>
                    {v}
                  </div>
                )}
              </div>
            ) : (
              <div className="font__body_sm_m flex-container-center flex-space-between w-full">
                {
                  // mallTimeCloseMap.includes(i) ? '휴무일' : <>예약받는 시간이 없는 날 <Arrow_Right onClick={() => {
                  // openTime(i);
                // }} /></>
                }
                <>예약받는 시간이 없는 날 <Arrow_Right onClick={() => {
                  openTime(i);
                  }} /></>
              </div>
            )}
          </div>
        ))}
      </section>
      <Picker
        title="예약을 몇 분 간격으로 받으시겠어요?"
        modalKey="reservation-time-picker-modal"
        leftArea={{ list: timeUnitList, selected: term, unit: '시간분' }}
        onSuccess={handleOnPickerSuccess}
      />
      {
        <ModalPortal>
          <Modal.Alert key={alertModalId} modal="alert" isDim isAnimation>
            <div className="flex-container flex-col gap-16">
              <div className="font__subtitle">
                예약 시간 단위를 <br />
                변경할까요?
              </div>
              <div className="font__body_sm">
                시간 단위가 바뀌면
                <br />
                예약 받는 시간을 다시 설정해야 해요.
              </div>
            </div>
            <div
              className="footer success font__body_sm_sb"
              onClick={() => {
                resetStore();
                handleOnClickAlertModal();
              }}
            >
              변경
            </div>
          </Modal.Alert>
          {/*schedule loop*/}
          <Modal.Menu key={timeSelectModalId} modal="menu" isDim isAnimation onClose={closeAlert}>
            <div className="reservation-time-select-wrapper receive-reservation-wrapper" style={{ height: '100%', overflowY: 'auto' }} key={dayIndex}>
              <div className="time-select-header">
                <div className="font__subtitle flex-container justify-center">{daylist[dayIndex]}요일에 예약 받을 시간을 모두 골라주세요.</div>
                <button className="tblm-button-normal w-full" onClick={() => {
                  enabledTimeList[dayIndex][1] = [];
                  commitChange();
                  next(false);
                }}>{daylist[dayIndex]}요일 예약 안 받기</button>
                <label className="tblm-rc font__body_sm_m">
                  <input type="checkbox" name="switch_example" onChange={allCheck} checked={enabledTimeList[dayIndex][1].length === times.length}/>
                  <i />
                  전체선택
                </label>
              </div>
              <div className="time-select-content">
                { dayTimeList.map(([hour, timeArray]) => {
                  return <div className="time-select-item" key={hour}>
                    <label className="tblm-rc">
                      <input type="checkbox" name="day" checked={hourChecked(hour)} onChange={(e)=> hourCheck(hour, e.target.checked)} />
                      <i />
                      <span>{hour}시</span>
                    </label>
                    <div className="times">
                      {timeArray.map((time) => <label className={'time-item'} key={time}>
                        <input type='checkbox' name='time' checked={minuteChecked(time)} onChange={(e)=> minuteCheck(time, e.target.checked)} />
                        <div>{time}</div>
                      </label>)}
                    </div>
                  </div>;
                })}
              </div>
            </div>
            <div className="footer is-border">
              <button
                className="tblm-button-normal tblm-btn-primary"
                onClick={() => {
                  setMenuModal({ visible: false, key: timeSelectModalId });
                  setMenuModal({ visible: true, key: weekSelectModalId });
                  let remainDays = [0, 1, 2, 3, 4, 5, 6].filter(d => d > dayIndex).find(d => copyList.every(([_, list]) => !list.includes(d)));
                  if (!remainDays) {
                    submit();
                  } else {
                    setShowType(1);
                  }
                }}
              >
                완료
              </button>
            </div>
          </Modal.Menu>
          <Modal.Menu key={weekSelectModalId} modal="menu" isDim isAnimation onClose={closeAlert}>
            <div className="week-content-wrapper receive-reservation-wrapper">
              <div className="title font__subtitle">수정사항을 똑같이 적용할 요일이 있나요?</div>
              <div className="content week-select-content">
                {daylist.map((day, idx) => <div className={classnames('week-select-item', { active: idx === dayIndex })} key={idx}>
                  <label className="tblm-rc">
                    {dayIndex <= idx ? <>
                      <input type="checkbox" name="day" readOnly={!enableCopyAny && (idx <= dayIndex)} disabled={dayIndex !== idx && isDisabledCopy(idx)} value={idx} checked={!enableCopyAny ? copyList[dayIndex][1].includes(idx) : copyDayList.includes(idx)} onChange={copyDayCheck} />
                      <i />
                    </> : <>
                      <input type="checkbox" name="day" readOnly={idx <= dayIndex} disabled={isDisabledCopy(idx)} value={idx} />
                      <i />
                    </>}
                    <span>{day}</span>
                  </label>
                </div>)}
              </div>
            </div>
            <div className="footer is-border">{/*
            <button
              className="tblm-button-normal tblm-btn-gray"
              onClick={() => {
                prev();
              }}
            >
              이전
            </button>*/}
              <button
                className="tblm-button-normal tblm-btn-primary"
                onClick={() => {
                  closeAlert();
                  handleOnClickAlertModal();
                  next(true);
                }}
              >
                다음
              </button>
            </div>
          </Modal.Menu>
          {/*schedule loop*/}
        </ModalPortal>
      }
    </>
  );
};

export default ReceiveReservation;
