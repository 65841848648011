import dayjs from 'dayjs';
import create from 'zustand';
import { Nullable, TReservationState } from '@/shared/utils/common.type';

interface IParties {
  name: string;
  size: number;
}
interface IRooms {
  id: string;
  name: string;
  roomGroupId: string;
  roomGroupName: string;
}
interface IMenus {
  id: string;
  name: string;
  count: number;
}
interface IUserExtravalues {
  id: string;
  name: string;
  value: string;
  backgroundColor?: string;
  choice?: string[];
  display?: string;
  fontColor?: string;
  required?: boolean;
  type?: string;
}
interface IUseInfo {
  userId: string;
  userMemo: string;
  userName: string;
  userNickname: string;
  userPhone: string;
  userEmail: string;
  userExtravalues: IUserExtravalues[];
  isCreationMessageSent?: boolean;
  isAlarmMessageSent?: boolean;
  alarmMessageTemplate?: Nullable<string>;
  creationMessageTemplate?: Nullable<string>;
  creationMessageSentAt?: Nullable<dayjs.Dayjs>;
  alarmMessageSentAt?: Nullable<dayjs.Dayjs>;
  alarmMessageWillBeSentAt: Nullable<dayjs.Dayjs>;
  isSearch?: boolean;
  alarmMessageState?: 'send' | 'sendNow' | 'cancel';
  newUserId?: string;
  newUserPhone?: string;
}

interface IReservationExtravalues {
  id: string;
  name: string;
  value: string;
  type?: string;
  required?: string;
  position?: string;
  [key: string]: any;
}
interface IReservationInfo {
  reservationId?: string;
  reservationName: string;
  reservationMemo: string;
  reservationRequests: string;
  reservationState: TReservationState;
  reservationExtravalues: IReservationExtravalues[];
}

interface IDepositInfo {
  useDeposit?: boolean;
  depositAmount?: number;
  depositPaymentType?: 'CARD' | 'BANK';
  depositPaymentBankType?: Nullable<'KOOKMIN' | 'SC' | 'GWANGJU' | 'IBK' | 'NH' | 'BUSAN' | 'SHINHAN' | 'KEB' | 'WOORI' | 'EPOST'>;
  depositDue?: dayjs.Dayjs;
  depositEmailLanguage?: string;
  depositPaidAt?: dayjs.Dayjs;
  depositRequestedAt?: dayjs.Dayjs;
  depositState?: string;
}
interface IUseReservationStore {
  initGetAllInfo: (reservation: any) => void;

  visitDate: Nullable<dayjs.Dayjs>;
  setVisitDate: (visitDate: dayjs.Dayjs) => void;

  visitTime: Nullable<dayjs.Dayjs>;
  setVisitTime: (visitDate: dayjs.Dayjs) => void;

  parties: IParties[];
  setParty: (party: IParties[]) => void;

  rooms: IRooms[];
  setRooms: (rooms: IRooms[]) => void;

  menus: IMenus[];
  setMenus: (menus: IMenus[]) => void;

  userInfo: Nullable<IUseInfo>;
  setUserInfo: (userInfo: IUseInfo) => void;

  menuInfo: any;
  setMenuInfo: (menuInfo: any) => void;

  reservationInfo: Nullable<IReservationInfo>;
  setReservationInfo: (reservationInfo) => void;

  depositInfo: Nullable<IDepositInfo>;
  setDepositInfo: (depositInfo) => void;

  setReservationUpdateStore: (reservation) => void;
  setReservationResetStore: () => void;
}

const initialReservationInfo: IReservationInfo = {
  reservationName: '',
  reservationMemo: '',
  reservationRequests: '',
  reservationExtravalues: [],
  reservationState: null,
};

export const initialUserInfo: IUseInfo = {
  userId: '',
  userNickname: '',
  userMemo: '',
  userEmail: '',
  userName: '',
  userPhone: '',
  userExtravalues: [],
  alarmMessageTemplate: undefined,
  creationMessageTemplate: undefined,
  isSearch: false,
  creationMessageSentAt: null,
  alarmMessageSentAt: null,
  alarmMessageWillBeSentAt: null,
  alarmMessageState: undefined,
  newUserId: undefined,
  newUserPhone: undefined,
};

const initialDepositInfo: IDepositInfo = {
  useDeposit: false,
  depositAmount: 0,
  depositPaymentType: 'CARD',
  depositPaymentBankType: 'KOOKMIN',
  depositDue: null,
  depositEmailLanguage: 'ko',
  depositPaidAt: null,
  depositRequestedAt: null,
  depositState: '',
};

export const useReservationStore = create<IUseReservationStore>((set) => ({
  initGetAllInfo: (reservation) =>
    set(() => {
      return {
        reservationInfo: {
          reservationId: reservation.reservationId,
          createdAt: reservation.createdAt,
          reservationName: reservation.reservationName,
          reservationMemo: reservation.reservationMemo,
          reservationRequests: reservation.reservationRequests,
          reservationMarkingColorIndex: reservation.reservationMarkingColorIndex,
          reservationExtravalues: reservation.reservationExtravalues,
          reservationState: reservation.reservationState,
        },
        userInfo: {
          userId: reservation.userId,
          userNickname: reservation.userNickname,
          userMemo: reservation.userMemo,
          userEmail: reservation.userEmail,
          userName: reservation.userName,
          userPhone: reservation.userPhone,
          userExtravalues: reservation.userExtravalues,

          // 추가로 필요한 사항
          isCreationMessageSent: reservation.isCreationMessageSent,
          alarmMessageTemplate: reservation.alarmMessageTemplate,
          isAlarmMessageSent: reservation.isAlarmMessageSent,
          userPastValidReservationCount: reservation.userPastValidReservationCount,
          creationMessageTemplate: reservation.creationMessageTemplate,
          alarmMessageSentAt: reservation.alarmMessageSentAt,
          alarmMessageWillBeSentAt: reservation.alarmMessageWillBeSentAt,
          creationMessageSentAt: reservation.creationMessageSentAt,
        },
        menus: reservation.menus,
        depositInfo: {
          depositAmount: reservation.depositAmount,
          depositPaidAt: reservation.depositPaidAt,
          depositRequestedAt: reservation.depositRequestedAt,
          depositState: reservation.depositState,
          depositDue: reservation.depositDue ? dayjs(reservation.depositDue) : null,
          depositPaymentType: reservation.depositPaymentType,
          depositPaymentBankType: reservation.depositPaymentBankType,
          useDeposit: reservation.depositState !== 'unused',
        },
        visitDate: dayjs(reservation.visitDate),
        parties: reservation.parties,
      };
    }),

  visitDate: null,
  setVisitDate: (visitDate) => set({ visitDate }),

  visitTime: null,
  setVisitTime: (visitTime) => set({ visitTime }),

  parties: [],
  setParty: (parties) => set({ parties }),

  rooms: [],
  setRooms: (rooms) => set({ rooms }),

  menus: [],
  setMenus: (menus) => set({ menus }),

  userInfo: initialUserInfo,
  setUserInfo: (userInfo) => set({ userInfo }),

  menuInfo: [],
  setMenuInfo: (menuInfo) => set({ menuInfo }),

  reservationInfo: initialReservationInfo,
  setReservationInfo: (reservationInfo) => set({ reservationInfo }),

  depositInfo: initialDepositInfo,
  setDepositInfo: (depositInfo) => set({ depositInfo }),

  setReservationUpdateStore: (reservation) =>
    set(() => {
      return {
        visitDate: reservation.visitDate,
        visitTime: reservation.visitTime,
        parties: reservation.parties ? reservation.parties : [],
        rooms: reservation.rooms ? reservation.rooms : [],
        menus: reservation.menus ? reservation.menus : [],
        userInfo: {
          userExtravalues: reservation.userExtravalues,
          userId: reservation.userId,
          userMemo: reservation.userMemo,
          userName: reservation.userName,
          userNickname: reservation.userNickname,
          userPhone: reservation.userPhone,
          userEmail: reservation.userEmail,
          creationMessageTemplate: reservation.creationMessageTemplate,
          alarmMessageTemplate: reservation.alarmMessageTemplate,
          alarmMessageSentAt: reservation.alarmMessageSentAt,
          alarmMessageWillBeSentAt: reservation.alarmMessageWillBeSentAt,
          creationMessageSentAt: reservation.creationMessageSentAt,
        },
        reservationInfo: {
          reservationExtravalues: reservation.reservationExtravalues,
          reservationMemo: reservation.reservationMemo,
          reservationName: reservation.reservationName,
          reservationRequests: reservation.reservationRequests,
          reservationState: reservation.reservationState,
        },
        depositInfo: {
          useDeposit: reservation.useDeposit,
          depositAmount: reservation.depositAmount,
          depositPaymentType: reservation.depositPaymentType,
          depositPaymentBankType: reservation.depositPaymentBankType,
          depositDue: dayjs(reservation.depositDue),
          depositEmailLanguage: reservation.depositEmailLanguage,
        },
      };
    }),
  setReservationResetStore: () =>
    set(() => {
      return {
        visitDate: null,
        visitTime: null,
        parties: [],
        rooms: [],
        menus: [],
        userInfo: initialUserInfo,
        menuInfo: [],
        reservationInfo: initialReservationInfo,
        depositInfo: initialDepositInfo,
      };
    }),
}));
