import React, { useEffect, useRef, useState } from 'react';
import './FooterSub.scss';

interface IFooterSub {
  isVisible?: boolean;
  isBorder?: boolean;
  isBackArea?: boolean;
  children?: any;
  style?: any;
}

const FooterSub = ({ isVisible, isBorder, isBackArea, children, style = {} }: IFooterSub): React.ReactElement => {
  const [footerAreaHeight, setFooterAreaHeight] = useState(0);
  const footerRef = useRef(null);
  const borderClassName = isBorder ? 'is-border' : '';

  useEffect(() => {
    if (footerRef.current) setFooterAreaHeight(footerRef.current.getBoundingClientRect().height);
  }, [isVisible]);

  if (!isVisible) return <></>;
  return (
    <>
      {isBackArea ? <div className="footer-area keybord-toggler" style={{ height: footerAreaHeight }} /> : null}
      <div ref={footerRef} className={`footer-sub-wrapper keybord-toggler ${borderClassName}`} style={style}>
        {children}
      </div>
    </>
  );
};

export default FooterSub;
