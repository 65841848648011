import create from 'zustand';

export interface userExtravalues {
  id: string;
  name: string;
  value: string;
  display: string;
  kind: string;
  choice: any;
}

export interface ReservationExtravalues {
  id: string;
  name: string;
  value: string;
  display: string;
  backgroundColor: string;
  fontColor: string;
  kind: string;
  choice: any;
  type: string;
  position: string;
  required: boolean;
  hashtagDisplay: string;
}

export interface Party {
  name: string;
  size: number;
}

export interface Rooms {
  id: string;
  name: string;
  roomGroupId: string;
  roomGroupName: string;
}

export interface Menus {
  id: string;
  name: string;
  count: number;
}

export interface AllergyList {
  name: string;
  id: number;
  checked: boolean;
}

export interface UserExtravaluesList {
  id: string;
  name: string;
  value: string;
  display: string;
  kind: string;
  choice: any[];
}

// export interface ReservationManagerType {
//   id: string;
//   name: string;
// }

export interface ReservationInformationType {
  reservationId: string;
  userName: string;
  userPhone: string;
  // reservationManager: ReservationManagerType;
  reservationName: string;
  reservationMemo: string;
  reservationRequests: string;
  reservationMarkingColorIndex: number;
  reservationExtravalues: ReservationExtravalues[];
  parties: Party[];
  rooms: Rooms[];
  visitDate: string;
  visitTime: number;
  createdAt: string;
  state: string;
}
export interface ReservationUserType {
  userNickname: string;
  userMemo: string;
  userPastValidReservationCount: number;
  creationMessageTemplate: string;
  isCreateMessageSent: boolean;
  alarmMessageTemplate: string;
  isAlarmMessageSent: boolean;
  userExtravalues: UserExtravaluesList[];
}
export interface ReservationMenuType {
  menus: Menus[];
}

export interface ReservationDepositType {
  depositAmount: number;
  depositPaymentType: string;
  useDeposit: boolean;
}

export interface CancelReason {
  id: string;
  value: string;
}

export interface ReservationOtherInfoType {
  allergy: AllergyList[];
  secretaryName: string;
  secretaryPhone: string;
  confirmationCall: false;
  cancelReason: CancelReason;
  cancelReasonList: CancelReason[];
}

export interface UseReservationInformationStore {
  reservationInformation: ReservationInformationType;
}

const reservationInformation: ReservationInformationType = {
  reservationId: '',
  createdAt: '',
  visitDate: '',
  visitTime: 960,
  userName: '',
  userPhone: '',
  // reservationManager: {
  //   id: '0',
  //   name: '',
  // },
  reservationName: '',
  reservationMemo: '',
  reservationRequests: '',
  reservationMarkingColorIndex: 0,

  reservationExtravalues: [
    {
      id: '',
      name: '',
      value: '',
      display: '',
      backgroundColor: '',
      fontColor: '',
      kind: '',
      choice: [],
      position: '',
      required: false,
      type: '',
      hashtagDisplay: 'none',
    },
  ],
  rooms: [
    {
      id: '',
      name: '',
      roomGroupId: '',
      roomGroupName: '',
    },
  ],
  parties: [],
  state: '',
};

const reservationUserInfo: ReservationUserType = {
  userNickname: '',
  userMemo: '',
  userPastValidReservationCount: 0,
  userExtravalues: [
    {
      id: '',
      name: '',
      value: '',
      display: '',
      kind: '',
      choice: [],
    },
  ],
  creationMessageTemplate: '',
  isCreateMessageSent: true,
  alarmMessageTemplate: '',
  isAlarmMessageSent: false,
};

const reservationMenu: ReservationMenuType = {
  menus: [
    {
      id: '',
      name: '',
      count: 1,
    },
  ],
};

const reservationDeposit: ReservationDepositType = {
  useDeposit: false,
  depositAmount: 0,
  depositPaymentType: '',
};

const reservationOtherInfo: ReservationOtherInfoType = {
  allergy: [
    {
      name: '오이',
      id: 1,
      checked: true,
    },
    {
      name: '갑각류',
      id: 1,
      checked: false,
    },
  ],
  secretaryName: '원건우',
  secretaryPhone: '010-6666-6666',
  confirmationCall: false,
  cancelReason: {
    id: '1',
    value: '씨기지',
  },
  cancelReasonList: [
    {
      id: '1',
      value: '싸가지',
    },
    {
      id: '2',
      value: '그냥',
    },
  ],
};

export const useReservationInformationStore = create<any>((set) => ({
  reservationInformation: reservationInformation,
  reservationUserInfo,
  reservationMenu,
  reservationOtherInfo,
  reservationDeposit,
  initReservationInformation: (reservation) =>
    set(() => {
      return {
        reservationInformation: {
          reservationId: reservation.reservationId,
          createdAt: reservation.createdAt,

          userName: reservation.userName,
          userPhone: reservation.userPhone,
          reservationName: reservation.reservationName,
          reservationMemo: reservation.reservationMemo,
          reservationRequests: reservation.reservationRequests,
          reservationMarkingColorIndex: reservation.reservationMarkingColorIndex,
          reservationExtravalues: reservation.reservationExtravalues,
          rooms: reservation.rooms,
          parties: reservation.parties,
          // reservationManager: {
          //   id: '1',
          //   name: '원건우',
          // },
        },
        reservationUserInfo: {
          userNickname: reservation.userNickname,
          userMemo: reservation.userMemo,
          userPastValidReservationCount: reservation.userPastValidReservationCount,
          userExtravalues: reservation.userExtravalues,
          isCreateMessageSent: reservation.isCreateMessageSent,
          alarmMessageTemplate: reservation.alarmMessageTemplate,
          isAlarmMessageSent: reservation.isAlarmMessageSent,
        },

        reservationMenu: {
          menus: reservation.menu,
        },
        reservationDeposit: {
          useDeposit: true,
          depositAmount: 10000,
          depositPaymentType: 'string',
        },
      };
    }),
  setReservationDetail: (reservation) => set({ reservationInformation: reservation }),
  setReservationUser: (userInfo) => set({ reservationUserInfo: userInfo }),
  setReservationMenu: (menuList) => set({ reservationMenu: menuList }),
  setReservationother: (otherInfo) => set({ reservationOtherInfo: otherInfo }),
}));
