import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import './firstTimeCustomer.scss';
import ImageCarousel from '@/components/ImageCarousel/ImageCarousel';
import { useNavigate } from 'react-router-dom';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';

// 타입 지정
const SWIPE_CONTENT = [
  {
    title: '언제 어디서든 \n누구나 쉽게 사용',
    desc: '솔루션과 모바일앱으로 언제 어디서든 \n예약현황을 확인하고 관리할 수 있습니다.',
    image: require('@/assets/images/first-time-customer/mobile_1.png'),
  },
  {
    title: '오버부킹 없는 \n예약채널 통합관리',
    desc: '네이버나 카카오톡으로 쉽게 예약하고, \n자동 확정하여 이중 예약을 방지합니다.',
    image: require('@/assets/images/first-time-customer/mobile_2.png'),
  },
  {
    title: '매장 소유의 \n마케팅 채널 구축',
    desc: '쉽고 빠르게 고객과 소통할 수 있는 카카오 \n챗봇 예약은 테이블매니저만 제공합니다.',
    image: require('@/assets/images/first-time-customer/mobile_3.png'),
  },
  {
    title: '업종별 맞춤형 \n예약 관리 솔루션',
    desc: '업종에 특화된 맞춤 솔루션, \n모바일로 바로 체험해보세요!',
    image: require('@/assets/images/first-time-customer/mobile_4.png'),
  },
];

const FirstTimeCustomer = (): React.ReactElement => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const carouselItems: any = useMemo(() => {
    return SWIPE_CONTENT.map((item, index) => ({
      key: `carousel-${index}`,
      name: `carousel-${index}`,
      render: () => (
        <>
          <section className="description-wrap">
            <h2>{item.title}</h2>
            <p>{item.desc}</p>
          </section>
          <div className="image-wrap">
            <img src={item.image} alt="첫 방문 안내 이미지" />
          </div>
        </>
      ),
    }));
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  if (isLoading) {
    return <SpinnerEffector loading={isLoading} />;
  }

  return (
    <main className="first-time-customer-container">
      <ImageCarousel
        className="image-carousel-container"
        infiniteLoop={true}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        swipeable={true}
        emulateTouch={true}
        items={carouselItems}
      />
      {/* free-experience button */}
      <div className="button-container">
        <button onClick={() => navigate('/tablemanager/apply-consultation')} className="footer tblm-button-normal tblm-btn-primary">
          테이블매니저 무료 체험하기
        </button>
      </div>
    </main>
  );
};

export default FirstTimeCustomer;
