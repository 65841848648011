import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { IPickerType } from '@/components/Picker/Picker.type';
import { useModalStore } from '@/stores/common/useModalStore';
import './Picker.scss';
import ScrollPicker from '../ScrollPicker/ScrollPicker';

export interface IScrollPicker {
  optionGroups: { [key: string | number]: { list: any[]; unit?: string } };
  valueGroups: { [key: string | number]: any };
  onChange: (...args: any) => void;
  itemHeight?: number;
  height?: number;
}

const Picker = ({ modalKey, title, visible, leftArea, rightArea, onSuccess, onSuccessText = '확인', isSingle, onClose }: IPickerType): React.ReactElement => {
  const [valueGroups, setValueGroups] = useState<IScrollPicker['valueGroups']>({
    leftArea: leftArea.selected,
    ...(rightArea ? { rightArea: rightArea.selected || rightArea.list[0] } : {}),
  });
  const { setMenuModal } = useModalStore();

  const handleOnSuccessModal = () => {
    onSuccess(valueGroups);
    setMenuModal({ visible: false, key: modalKey });
  };

  useEffect(() => {
    setMenuModal({ visible, key: modalKey });
  }, [visible]);

  const optionGroups: IScrollPicker['optionGroups'] = {
    leftArea: { list: leftArea.list, unit: leftArea.unit || '시간' },
    ...(rightArea ? { rightArea: { list: rightArea?.list, unit: rightArea.unit || '분' } } : {}),
  };

  const onChange = (key, value) => {
    setValueGroups((state) => Object.assign({}, state, { [key]: value }));
  };

  return (
    <div>
      <ModalPortal>
        <Modal.Menu key={modalKey} modal="menu" isDim={true} isAnimation={true} onClose={onClose}>
          <div className="picker-body modal-content">
            <div className="picker-title">{title}</div>
            <ScrollPicker optionGroups={optionGroups} valueGroups={valueGroups} onChange={onChange} />
          </div>
          <div className="picker-footer">
            <button className="set-button tblm-button-normal tblm-btn-primary" onClick={handleOnSuccessModal}>
              {onSuccessText}
            </button>
          </div>
        </Modal.Menu>
      </ModalPortal>
    </div>
  );
};

export default Picker;
