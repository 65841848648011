import HeaderBack from '@/components/HeaderBack/HeaderBack';
import { useModalStore } from '@/stores/common/useModalStore';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Modal from '@/components/Modal/Modal';
import FooterSub from '@/components/FooterSub/FooterSub';
import { useEffect, useId, useState } from 'react';
import Input from '@/components/Input/Input';
import { useMutation, useQuery } from 'react-query';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { IMutationProps } from '@/shared/utils/common.type';

const DEPOSIT_STANDARD_OPTIONS = {
  RESERVATION: '예약건당',
  TABLE: '테이블당',
  PERSON: '인원당',
};

const DepositPolicy = () => {
  const { setMenuModal } = useModalStore();
  const [amountInputErrorMessage, setAmountInputErrorMessage] = useState(null);
  const { accessToken } = useAuthStore();
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
  const [depositAmountVal, setDepositAmountVal] = useState(0);
  const [tmpDepositAmountVal, setTmpDepositAmountVal] = useState(null);
  const [depositStandard, setDepositStandard] = useState(null);
  const [tmpDepositStandard, setTmpDepositStandard] = useState(null);
  const [depositDefaultUse, setDepositDefaultUse] = useState(false);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const depositStandardModal = useId();
  const depositAmountModal = useId();

  const { data: depositSettingData, isFetching: isFetching } = useQuery(['DEPOSIT_SETTING'], () => devServerApi.authGet<any>('/mall/deposit/settings', accessToken));

  const { mutate: setDepositSettingMutate, isLoading: isMutateLoading } = useMutation((mutatedata: IMutationProps) => devServerApi.mutation(mutatedata), {
    onMutate: () => ({
      key: ['SET_DEPOSIT_SETTING'],
    }),
    onSuccess: (data, variables, context) => {
    },
  });


  const submitReservationAmountSet = () => {
    const originDepositAmountNum = tmpDepositAmountVal.replace(',', '');
    if (!originDepositAmountNum?.length) {
      return setAmountInputErrorMessage('예약금을 입력해주세요.');
    }
    if (parseInt(originDepositAmountNum) < 1000) {
      return setAmountInputErrorMessage('1,000원 이상 입력해주세요.');
    }
    setAmountInputErrorMessage(null);
    setDepositAmountVal(parseInt(originDepositAmountNum));
    setMenuModal({ visible: false, key: depositAmountModal });
  };

  const submitDepositStandard = () => {
    setDepositStandard(tmpDepositStandard);
    setMenuModal({ visible: false, key: depositStandardModal });
  };

  const getDepositStandardOptionLabel = (type) => {
    return DEPOSIT_STANDARD_OPTIONS[type];
  };

  const onMutate = () => {
    setDepositSettingMutate({
      operation: 'patch',
      url: '/mall/deposit/settings',
      data: {
        use: depositDefaultUse,
        defaultFee: depositAmountVal,
        defaultType: depositStandard,
      },
    });
  };

  useEffect(() => {
    if (isFetching) setFirstLoaded(true);
    if (isFetching && firstLoaded) {
      onMutate();
    }
  }, [depositDefaultUse, depositAmountVal, depositStandard]);

  useEffect(() => {
    if (depositSettingData && !amountInputErrorMessage) {
      setDepositDefaultUse(depositSettingData.deposit.use);
      setDepositAmountVal(depositSettingData.deposit.defaultFee);
      setTmpDepositAmountVal(depositSettingData.deposit.defaultFee);
      if (['RESERVATION', 'TABLE', 'PERSON'].includes(depositSettingData.deposit.defaultType)) {
        setDepositStandard(depositSettingData.deposit.defaultType);
        setTmpDepositStandard(depositSettingData.deposit.defaultType);
      } else {
        setDepositStandard('RESERVATION');
        setTmpDepositStandard('RESERVATION');
      }
    }
  }, [depositSettingData]);

  return (
    <div>
      <HeaderBack inBack={true}>
        <div className="font__subtitle_sb">예약금 정책 설정</div>
      </HeaderBack>
      <section className="settings-policy-detail-wrapper same-day-reservation-wrapper deposit-policy-setting-wrapper">
        <div className="switch-area">
          <div className="left-area">
            <div className="font__title_20_sb">예약금 미리 설정</div>
            <div className="font__body_sm_m">
              예약금 받을 시 아래 예약금 설정을 <br /> 기본으로 적용합니다.
            </div>
          </div>
          <div className="right-area gap-16">
            <div className="font__small">{depositDefaultUse ? '설정 사용' : '설정 미사용'}</div>
            <label className="tblm-switch pointer">
              <input type="checkbox" name="switch_example" checked={depositDefaultUse} onChange={(e) => {
                setDepositDefaultUse(e.target.checked);
              }} />
              <i />
            </label>
          </div>
        </div>
        <div className="switch-area pointer" onClick={() => setMenuModal({ visible: true, key: depositStandardModal })}>
          <div className="left-area">
            <div className="font__title_20_sb">예약금 지불 기준</div>
            <div className="font__body_sm_m">
              예약금을 예약건당 받을까요, <br />
              인원당 받을까요, 테이블당 받을까요?
            </div>
          </div>
          <div className="right-area gap-16">
            <div className="font__title_19_sb">{getDepositStandardOptionLabel(depositStandard)}</div>
          </div>
        </div>
        <div className="switch-area pointer" onClick={() => setMenuModal({ visible: true, key: depositAmountModal })}>
          <div className="left-area">
            <div className="font__title_20_sb">예약금 금액</div>
            <div className="font__body_sm_m">예약금은 얼마 받을까요?</div>
          </div>
          <div className="right-area gap-16">
            <div className="font__title_19_sb">{depositAmountVal.toLocaleString()}원</div>
          </div>
        </div>
      </section>

      <ModalPortal>
        <Modal.Menu key={depositStandardModal} modal="menu" isDim={true} isAnimation={true} isClose={false} onClose={() => {
          setMenuModal({ visible: false, key: depositStandardModal });
          setTmpDepositStandard(depositStandard);
        }}>
          <div className="add-group-modal-wrapper">
            <div className="add-group-modal-header">예약금 지불 기준</div>
            <div className="custom-radio-wrapper no-padding-radio-wrapper">
              <div className="custom-radio-item">
                <label className="tblm-rc">
                  <input
                    type="radio"
                    name="timeFilter"
                    value={'TABLE'}
                    checked={tmpDepositStandard === 'TABLE'}
                    onChange={() => setTmpDepositStandard('TABLE')}
                  />
                  <i />
                  <span>{DEPOSIT_STANDARD_OPTIONS.TABLE}</span>
                </label>
              </div>
              <div className="custom-radio-item">
                <label className="tblm-rc">
                  <input
                    type="radio"
                    name="timeFilter"
                    value={'PERSON'}
                    checked={tmpDepositStandard === 'PERSON'}
                    onChange={() => setTmpDepositStandard('PERSON')}
                  />
                  <i />
                  <span>{DEPOSIT_STANDARD_OPTIONS.PERSON}</span>
                </label>
              </div>
              <div className="custom-radio-item">
                <label className="tblm-rc">
                  <input
                    type="radio"
                    name="timeFilter"
                    value={'RESERVATION'}
                    checked={tmpDepositStandard === 'RESERVATION'}
                    onChange={() => setTmpDepositStandard('RESERVATION')}
                  />
                  <i />
                  <span>{DEPOSIT_STANDARD_OPTIONS.RESERVATION}</span>
                </label>
              </div>
            </div>
          </div>
          <FooterSub isVisible isBorder isBackArea>
            <button className="tblm-button-normal tblm-btn-primary w-full" onClick={submitDepositStandard}>
              확인
            </button>
          </FooterSub>
        </Modal.Menu>
        <Modal.Menu key={depositAmountModal} modal="menu" isDim={true} isAnimation={true} isClose={false}>
          <div className="add-group-modal-wrapper">
            <div className="add-group-modal-header">예약금 금액</div>
            <div className="reservation-amount-input-wrapper">
              <Input
                name="text-basic"
                type="tel"
                value={tmpDepositAmountVal}
                error={amountInputErrorMessage}
                onChange={(e) =>
                  setTmpDepositAmountVal(
                    e.target.value
                      .replace(' ', '')
                      .replace(/[^0-9]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  )
                }
              />
              원
            </div>
          </div>
          <FooterSub isVisible isBorder isBackArea>
            <button className="tblm-button-normal tblm-btn-primary w-full" onClick={submitReservationAmountSet}>
              완료
            </button>
          </FooterSub>
        </Modal.Menu>
      </ModalPortal>
    </div>
  );
};

export default DepositPolicy;
