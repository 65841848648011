import * as yup from 'yup';

import HeaderSub from '@/components/HeaderSub/HeaderSub';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { useModalStore } from '@/stores/common/useModalStore';
import { useEffect, useId, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TableManagerLogo } from '@/assets/images/icons/Logo.svg';
import { ControlledInput } from '@/hook-form/ControlledInput';
import styles from './applyConsultation.module.scss';
import classnames from 'classnames';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useMutation } from 'react-query';
import { ITablemanagerTerms } from '@/shared/utils/terms.constants';
import { IMutationProps } from '@/shared/utils/common.type';
import { closeWebview } from '@/shared/lib/functions';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';

const INVALID_ERROR_MESSAGES = {
  required: '필수 입력 정보를 모두 입력해 주세요.',
  userPhone: '올바른 휴대폰번호 형식이 아닙니다.',
  userEid: '올바른 사업자번호 형식이 아닙니다.',
  userEmail: '올바른 이메일 형식이 아닙니다.',
};

const ApplyConsultation = () => {
  const navigate = useNavigate();
  const { setAlertModal } = useModalStore();
  const [termsData, setTermsData] = useState<ITablemanagerTerms>();
  const [isLoading, setIsLoading] = useState(true);

  const errorMessageModalId = useId();
  const successApplyId = useId();
  const faildApplyId = useId();

  const [errorMessage, setErrorMessage] = useState(null);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      userStoreName: yup.string().required(INVALID_ERROR_MESSAGES.required),
      userName: yup.string().required(INVALID_ERROR_MESSAGES.required),
      userPhone: yup.string().required(INVALID_ERROR_MESSAGES.required).min(13, INVALID_ERROR_MESSAGES.userPhone),
      userEid: yup.string().required(INVALID_ERROR_MESSAGES.required).min(12, INVALID_ERROR_MESSAGES.userEid),
      userEmail: yup.string().test('email-validation', INVALID_ERROR_MESSAGES.userEmail, (value) => {
        if (value === null || value === '') {
          return true;
        }
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
      }),
    });
  }, []);

  const methods = useForm<any>({
    defaultValues: {
      userStoreName: '',
      userName: '',
      userPhone: '',
      userEid: '',
      userEmail: '',
    },
    mode: 'onBlur',
  });

  const { handleSubmit, control, setValue, getValues, reset } = methods;

  const handleOnClose = () => navigate(-1);

  useEffect(() => {
    // terms data
    devServerApi.api.get('/terms?servicePolicyType=PRIVACY').then((data) => data.data.servicePolicies?.length && setTermsData(data.data.servicePolicies[0]));
  }, []);

  const { mutate: termsMutation, isLoading: termsIsLoading } = useMutation((mutatedata: any) => devServerApi.api.post('/counseling', mutatedata), {
    onSuccess: (res) => {
      if (res.data.status < 0) {
        setErrorMessage(res.data.message);
        setAlertModal({ visible: true, key: errorMessageModalId });
      } else {
        setAlertModal({ visible: true, key: successApplyId });
        reset();
      }
    },
    onError: () => {
      return setAlertModal({ visible: true, key: faildApplyId });
    },
  });

  const applyConsultationForm = (data) => {
    validationSchema
      .validate(data, { abortEarly: false })
      .then(() => {
        const userData = {
          mallName: getValues('userStoreName'),
          applicantName: getValues('userName'),
          applicantPhone: getValues('userPhone').replaceAll('-', ''),
          businessNum: getValues('userEid').replaceAll('-', ''),
          email: getValues('userEmail'),
          serviceType: '05',
          termsAgreements: [
            {
              servicePolicyType: 'PRIVACY',
              agreement: true,
            },
          ],
        };

        return termsMutation(userData);
      })
      .catch((error) => {
        if (error.errors?.length) {
          setAlertModal({ visible: true, key: errorMessageModalId });
          setErrorMessage(error.errors[0]);
        }
      });
  };

  const goToTermsLink = () => {
    const url = termsData?.content.KO.contentUrl;
    if (url) window.open(url, '_blank');
    else alert('관리자에게 문의하세요.');
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  if (termsIsLoading) {
    return <SpinnerEffector loading />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <div className={styles.apply_consultation_container}>
          <HeaderSub isFixedShortHeader isBorder={false} onClose={handleOnClose} isInitFixedPosition>
            테이블매니저 상담 신청
          </HeaderSub>
          <main className={styles.apply_consultation__content}>
            <section className={styles.info_section}>
              <TableManagerLogo className={styles.tm_logo} />
              <ol className={styles.info_desc__list}>
                <li>
                  <span>01.</span>무료 체험도 비즈니스 맞춤형으로 제공해 드립니다.
                </li>
                <li>
                  <span>02.</span>상담을 신청하시면 24시간 이내에 연락드립니다.
                </li>
              </ol>
            </section>
            <section className={styles.form_container}>
              <article>
                <section className={styles.form_container__title}>
                  <h3>서비스 신청정보</h3>
                  <span>필수 입력 정보입니다.</span>
                </section>
                <section className={styles.form_container__input_wrap}>
                  <div className={styles.input_wrap}>
                    <label className={styles.required} htmlFor="userStoreName">
                      매장명
                    </label>
                    <ControlledInput maxLength="30" className={styles.controll_input} name="userStoreName" control={control} placeholder="매장명을 입력해주세요" />
                  </div>
                  <div className={styles.input_wrap}>
                    <label className={styles.required} htmlFor="userName">
                      신청인 이름
                    </label>
                    <ControlledInput maxLength="10" className={styles.controll_input} name="userName" control={control} placeholder="이름을 입력해주세요" />
                  </div>
                  <div className={styles.input_wrap}>
                    <label className={styles.required} htmlFor="userPhone">
                      신청인 휴대폰
                    </label>
                    <ControlledInput
                      type="tel"
                      maxLength="13"
                      className={styles.controll_input}
                      name="userPhone"
                      control={control}
                      placeholder="휴대폰번호를 입력해주세요"
                      onChange={(e) => {
                        setValue(
                          'userPhone',
                          e.target.value
                            .replace(/[^0-9]/g, '')
                            .replace(/^(\d{3})(\d{0,4})(\d{4})$/, '$1-$2-$3')
                            .replace('--', '-')
                        );
                      }}
                    />
                  </div>
                  <div className={styles.input_wrap}>
                    <label className={styles.required} htmlFor="userEid">
                      사업자번호
                    </label>
                    <ControlledInput
                      type="tel"
                      maxLength="12"
                      className={styles.controll_input}
                      name="userEid"
                      control={control}
                      placeholder="사업자번호를 입력해주세요"
                      onChange={(e) => {
                        setValue(
                          'userEid',
                          e.target.value
                            .replace(/[^0-9]/g, '')
                            .replace(/(\d{3})(\d{0,2})(\d{5})/, '$1-$2-$3')
                            .replace('--', '-')
                        );
                      }}
                    />
                  </div>
                  <div className={styles.input_wrap}>
                    <label htmlFor="userEmail">이메일</label>
                    <ControlledInput className={styles.controll_input} name="userEmail" control={control} placeholder="이메일을 입력해주세요" />
                  </div>
                </section>
              </article>
            </section>
          </main>

          {/* apply button */}
          <div className={styles.apply_button_container}>
            <span onClick={goToTermsLink} className={styles.agreement_privacy_button}>
              개인정보 수집 및 이용
            </span>
            <button onClick={handleSubmit((data) => applyConsultationForm(data))} className={classnames([styles.apply_button], 'footer tblm-button-normal tblm-btn-primary')}>
              개인정보 수집 및 이용에 동의하고 신청
            </button>
          </div>
        </div>
      </FormProvider>
      <ModalPortal>
        <Modal.Alert key={errorMessageModalId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">{errorMessage}</div>
          <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: errorMessageModalId })}>
            확인
          </div>
        </Modal.Alert>
        <Modal.Alert key={successApplyId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">신청서 제출이 완료되었습니다.</div>
          <div className="content font__body_sm">테이블매니저 고객센터(1544-2017)에서 곧 연락드릴 거예요.</div>
          <div
            className="footer success font__body_sm_sb"
            onClick={() => {
              setAlertModal({ visible: false, key: successApplyId });
              // app - login page 이동
              closeWebview();
            }}
          >
            확인
          </div>
        </Modal.Alert>
        <Modal.Alert key={faildApplyId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">신청서가 제출되지 않았습니다.</div>
          <div className="content font__body_sm">잠시 후 다시 시도해주세요.</div>
          <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: faildApplyId })}>
            확인
          </div>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default ApplyConsultation;
