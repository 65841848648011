import axios, { AxiosRequestConfig } from 'axios';
import merge from 'lodash/merge';
import { REQUESTS_OPERATION } from '../utils/common.constants';
import { IMutationProps } from '../utils/common.type';

const getRequestUuidFromRes = (res)=> {
  return res.data['tm-api-request-uuid'] ? `\ntm-api-request-uuid: ${res.data['tm-api-request-uuid']}` : '';
};

const handleError = (res, stack) => {
  if (res.data.status < 0) {
    // @ts-ignore
    throw new Error(`[${res.data.status}] ${res.data.message || `\n${JSON.stringify(res.data, null, 2)}`}${getRequestUuidFromRes(res)}\n${stack}`);
  }
};

const apiBase = function (defaultConfig: AxiosRequestConfig) {
  const api = axios.create(defaultConfig);

  function configure(config: AxiosRequestConfig): AxiosRequestConfig {
    return merge(api.defaults, config);
  }

  function get<T>(url: string, config?: any): Promise<T> {
    const stack = new Error('').stack;
    return api.get(url, { ...config }).then((res) => {
      handleError(res, stack);
      return res.data as T;
    });
  }
  function authGet<T>(url: string, Authorization?: string, config?: any): Promise<T> {
    if (!Authorization) throw new Error('인증에 실패했습니다.');
    const stack = new Error('').stack;
    return api.get(url, merge(config, { headers: { 'x-tm-apigw-token': Authorization } })).then((res) => {
      handleError(res, stack);
      return res.data as T;
    });
  }

  function patch<T>(url: string, Authorization: string, body: any, config?: any): Promise<T> {
    if (!Authorization) throw new Error('인증에 실패했습니다.');
    const stack = new Error('').stack;
    return api.patch(url, merge(config, { ...body, id: body.reservationId }, { headers: { 'x-tm-apigw-token': Authorization } })).then((res) => {
      handleError(res, stack);
      return res.data as T;
    });
  }

  function mutation(config: IMutationProps) {
    const { operation, data, headers, url } = config;
    const stack = new Error('').stack;
    switch (operation) {
      case REQUESTS_OPERATION.PATCH:
        return api.patch(url, data).then((res) => {
          handleError(res, stack);
          return res;
        });
      case REQUESTS_OPERATION.POST:
        return api.post(url, data, { headers }).then((res) => {
          handleError(res, stack);
          return res;
        });
      case REQUESTS_OPERATION.PUT:
        return api.put(url, { data }, { headers }).then((res) => {
          handleError(res, stack);
          return res;
        });
      case REQUESTS_OPERATION.DELETE:
        return api.delete(url, { headers, data }).then((res) => {
          handleError(res, stack);
          return res;
        });
      default:
        break;
    }
  }

  return { configure, get, authGet, mutation, api, patch };
};

export default apiBase;
