import { ITimeList } from './TimePicker.type';

export const getOnlyDecimal = (_number: number, _length: number) => {
  let result;
  result = _number % 1;
  result = Number(result.toFixed(_length));
  return result;
};

export const numberToMinute = (_number: number) => {
  let value = _number;
  if (0.1666 - 1e-3 <= value && value <= 0.1666 + 1e-3) {
    value = 0.166666;
  } else if (0.3333 - 1e-3 <= value && value <= 0.3333 + 1e-3) {
    value = 0.333333;
  } else if (0.6666 - 1e-3 <= value && value <= 0.6666 + 1e-3) {
    value = 0.666666;
  } else if (0.8333 - 1e-3 <= value && value <= 0.8333 + 1e-3) {
    value = 0.833333;
  } else if (1 - 1e-3 <= value && value <= 1 + 1e-3) {
    value = 1;
  }
  return value;
};

export const initialTimeList: ITimeList = {
  times: {
    morning: [],
    afternoon: [],
  },
  targetDate: null,
};
