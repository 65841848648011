import CallItem from '@/components/CallHistory/CallItem/CallItem';
import './CallHistory.scss';

const CallHistory = (propsWrap) => {
  const { callHistoryData } = propsWrap.propsWrap;

  return callHistoryData.callLogs?.length ? (
    <div className="customer-detail-call-history-container">
      {callHistoryData.callLogs.map((callLog, idx) => (
        <div className="call-log-itemp-wrapper">
          <CallItem key={callLog + idx} targetCall={{ callTime: callLog.callLogTime, durationSecond: callLog.callLogDurationSec }} />
        </div>
      ))}
    </div>
  ) : (
    <section className="empty-view-container">
      <p>통화 기록이 없습니다.</p>
    </section>
  );
};

export default CallHistory;
