import React, { useEffect, useState, useId } from 'react';
import AgreementDoc from '@/components/Agreement/AgreementDoc';
import Marketing from '@/components/Agreement/docs/marketing';
import './AgreementHistory.scss';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useModalStore } from '@/stores/common/useModalStore';
import dayjs from 'dayjs';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';

const AgreementHistory = ({ className, setDoc }): React.ReactElement => {
  const { accessToken, setAccessToken, refreshToken, setRefreshToken } = useAuthStore();
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
  const { state, setState, agreements, setAgreements, agreementsDetail, setAgreementsDetail } = useSettingTutorialStore();
  const [isOpenMarketing, setIsOpenMarketing] = useState(false);
  const { setAlertModal } = useModalStore();
  const arg = Object.fromEntries(agreementsDetail.map(({ type, isAgree, createdAt }) => ([type, { isAgree, createdAt }])));
  const [checkedAgreement, setCheckedAgreement] = useState({
    promotions: arg.MARKETING_FOR_KT_SECRETARY?.isAgree,
    agreeReceiveNight: arg.LATE_NIGHT_MARKETING_FOR_KT_SECRETARY?.isAgree,
    stayActive: arg.KEEP_ACTIVE_EVEN_DORMANT_FOR_KT_SECRETARY?.isAgree,
  });
  const [modalTitle, setModalTitle] = useState('홍보 및 마케팅 수집/이용 동의');

  const promotions = useId();

  const modalTitles = {
    promotions: '홍보 및 마케팅 수집/이용에 동의',
    agreeReceiveNight: '야간 시간 광고성 정보 수신에 동의',
    stayActive: '장기 미접속 시 계정을 별도 요청 시까지 활성 상태 유지에 동의',
  };

  const realNames = {
    promotions: 'MARKETING_FOR_KT_SECRETARY',
    agreeReceiveNight: 'LATE_NIGHT_MARKETING_FOR_KT_SECRETARY',
    stayActive: 'KEEP_ACTIVE_EVEN_DORMANT_FOR_KT_SECRETARY',
  };


  const checkHandler = (name) => {
    // @ts-ignore
    setCheckedAgreement(prev => ({
      ...prev,
      [name]: !prev[name],
    }));
    setModalTitle(modalTitles[name] + (!checkedAgreement[name] ? '합니다' : '하지 않습니다'));
    setAlertModal({ visible: true, key: promotions });
    devServerApi.api.patch('/terms-agree', { termsAgreements: [{
        servicePolicyType: realNames[name],
        agreement: !checkedAgreement[name],
      }] });
    const target = agreementsDetail.find(({ type }) => type === realNames[name]);
    target.createdAt = Date.now();
    target.isAgree = !checkedAgreement[name];
    setAgreementsDetail( [...agreementsDetail]);
  };

  return (
    <>
      <div className={className}>
        <ul>
          <li className="agreementHistory__check_list">
            <label className="tblm-rc small-checkbox">
              <input type="checkbox" name="filter_group" checked={checkedAgreement.promotions} onChange={()=>checkHandler('promotions')} readOnly />
              <i />
              <section onClick={(e)=> {
                e.preventDefault();
                e.stopPropagation();
              }}>
                <h2>홍보 및 마케팅 수집/이용 동의</h2>
                <span className='agreement-day'>{dayjs(arg.MARKETING_FOR_KT_SECRETARY?.createdAt).format('YYYY.MM.DD')} {arg.MARKETING_FOR_KT_SECRETARY?.isAgree ? '동의' : '철회'}</span>
                <em onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsOpenMarketing(true);
                }}>전문보기</em>
                {isOpenMarketing && (
                  <AgreementDoc setIsOpenMarketing={setIsOpenMarketing} setDoc={setDoc} isOpenMarketing={isOpenMarketing}>
                    <div className="agreement-doc-title">홍보 및 마케팅 수집/이용 동의서</div>
                    <Marketing className="agreement-doc-body marketing" />
                  </AgreementDoc>
                )}
              </section>
            </label>
          </li>
          <li className="agreementHistory__check_list">
            <label className="tblm-rc small-checkbox">
              <input type="checkbox" name="filter_group" checked={checkedAgreement.agreeReceiveNight} onChange={()=>checkHandler('agreeReceiveNight')} readOnly />
              <i />
              <section>
                <h2>야간 시간 광고성 정보 수신 동의</h2>
                <span className='agreement-day'>{dayjs(arg.LATE_NIGHT_MARKETING_FOR_KT_SECRETARY?.createdAt).format('YYYY.MM.DD')} {arg.LATE_NIGHT_MARKETING_FOR_KT_SECRETARY?.isAgree ? '동의' : '철회'}</span>
              </section>
            </label>
          </li>
          <li className="agreementHistory__check_list">
            <label className="tblm-rc small-checkbox">
              <input type="checkbox" name="filter_group" checked={checkedAgreement.stayActive} onChange={()=>checkHandler('stayActive')} readOnly />
              <i />
              <section>
                <h2>장기 미접속 시 계정을 별도 요청 시까지 활성 상태 유지 동의</h2>
                <span className='agreement-day'>{dayjs(arg.KEEP_ACTIVE_EVEN_DORMANT_FOR_KT_SECRETARY?.createdAt).format('YYYY.MM.DD')} {arg.KEEP_ACTIVE_EVEN_DORMANT_FOR_KT_SECRETARY?.isAgree ? '동의' : '철회'}</span>
              </section>
            </label>
          </li>
        </ul>
      </div>
      <ModalPortal>
        <Modal.Alert key={promotions} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">{modalTitle}</div>
          <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: promotions })}>
            확인
          </div>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default AgreementHistory;
