export const highlightCurrectWord = (word, userText) => {
  let lowerName = word.toLowerCase();
  const obj = { characters: [], name: userText };
  let lowerMallName = userText.toLowerCase();
  let splitName = lowerMallName.split(lowerName);
  let index = 0;
  splitName.forEach((charcter) => {
    obj.characters.push({
      character: charcter.toUpperCase(),
      isHighlight: false,
    });
    if (index !== splitName.length - 1) {
      obj.characters.splice(1 + index * 2, 0, {
        character: word.toUpperCase(),
        isHighlight: true,
      });
    }
    index++;
  });
  return obj.characters;
};
