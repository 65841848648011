import { formatDurationCallTime, getTimeAgoInToday } from '@/shared/lib/functions/call-item';
import dayjs from 'dayjs';
import './CallItem.scss';

const CallItem = (props) => {
  const { callTime, durationSecond } = props.targetCall;

  return (
    <li className="call-info">
      <div className="call-date-info">
        <h3>
          {dayjs(callTime).format('MM월 DD일(ddd)')}
          {getTimeAgoInToday(callTime)}
        </h3>
        <span>매장 전화</span>
      </div>
      <div className="call-time-info">
        {dayjs(callTime).format('A hh:mm')}
        {durationSecond > 0 && <b>{formatDurationCallTime(durationSecond, 'all')}</b>}
      </div>
    </li>
  );
};

export default CallItem;
