import React from 'react';
import { ReactComponent as Close_BoottoMenu } from '@/assets/images/icons/Close_BoottoMenu.svg';
import { IModalProps } from '@/components/Modal/Modal.type';
import { useModalStore } from '@/stores/common/useModalStore';

const Menu = ({ isDim, isClose = true, isAnimation, children, onClose, modalContentClassName }: IModalProps) => {
  const { menuModal, setMenuModal } = useModalStore();
  if (!menuModal.visible) return <></>;

  return (
    <div className="modal-wrapper menu">
      {isDim ? <div className="modal-dim" onClick={() => onClose ? onClose() : setMenuModal(false)} /> : null}
      <div className={`modal-content ${isAnimation ? 'animation' : ''} ${modalContentClassName ?? ''}`}>
        <div className="close">{isClose ? <Close_BoottoMenu onClick={() => onClose ? onClose() : setMenuModal(false)} /> : null}</div>
        {children}
      </div>
    </div>
  );
};

export default Menu;
