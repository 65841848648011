import React, { useEffect, useRef, useState } from 'react';
import { ITutorialCheckbox } from './TutorialCheckbox.type';
import {} from './TutorialCheckbox.constants';
import styles from './TutorialCheckbox.module.scss';
import './TutorialCheckbox.scss';
//import classnames from 'classnames';

const TutorialCheckbox = ({ options, value: propValue, unit = '', onChange }: ITutorialCheckbox): React.ReactElement => {
  const [checkedList, setChecked] = useState(propValue ? propValue : []);

  const ul = useRef();

  const isChecked = (value) => {
    const numberValue = parseInt(value, 10);
    return checkedList.includes(numberValue);
  };
  const check = (e) => {
    const { checked, value } = e.target;
    const numberValue = parseInt(value, 10);
    if (!checked) {
      setChecked(checkedList.filter((status) => status !== numberValue));
    } else {
      setChecked([numberValue, ...checkedList]);
    }
  };

  useEffect(() => {
    onChange(checkedList.sort((a, b) => parseInt(a, 10) - parseInt(b, 10)));
  }, [checkedList]);

  const handleResize = () => {
    if (!ul.current) return;
    const { clientHeight, scrollHeight } = ul.current;
    if (clientHeight < scrollHeight) {
      // @ts-ignore
      ul.current.classList.add('scroll');
    } else {
      // @ts-ignore
      ul.current.classList.remove('scroll');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const unitize = (option) => {
    if (unit === '층') return `${option}층`.replace('-', '지하 ');
    return option + unit;
  };
  return (
    <div className="tutorial-checkbox" ref={ul}>
      <ul>
        {options.map((option, i) => (
          <li key={option}>
            <label className="tblm-rc">
              <input type="checkbox" name="filter_group" value={i} checked={isChecked(i)} onChange={check} />
              <i />
              <span>{unitize(option)}</span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TutorialCheckbox;
