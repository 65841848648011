import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import CommonProvider from '@/provider/common.provider';
import AuthIndexPage from '@/pages/auth/index';
import IndexPage from '@/pages/index';
import ReservationAcceptStep_1IndexPage from '@/pages/reservation/accept/step-1/index';
import ReservationAcceptStep_2IndexPage from '@/pages/reservation/accept/step-2/index';
import ReservationAcceptStep_3IndexPage from '@/pages/reservation/accept/step-3/index';
import ReservationAcceptStep_4IndexPage from '@/pages/reservation/accept/step-4/index';
import ReservationAcceptStep_5IndexPage from '@/pages/reservation/accept/step-5/index';
import ReservationDetail$IdPage from '@/pages/reservation/detail/[id]';
import ReservationListIndexPage from '@/pages/reservation/list/index';
import ReservationUserSearchIndexPage from '@/pages/reservation/user-search/index';
import SettingAgreementPage from '@/pages/setting/agreement';
import SettingChannelIndexPage from '@/pages/setting/channel/index';
import SettingEssentialEditPage from '@/pages/setting/essential/edit';
import SettingEssentialIndexPage from '@/pages/setting/essential/index';
import SettingIndexPage from '@/pages/setting/index';
import SettingPolicyAvailableReservationIndexPage from '@/pages/setting/policy/available-reservation/index';
import SettingPolicyDepositIndexPage from '@/pages/setting/policy/deposit/index';
import SettingPolicyIndexPage from '@/pages/setting/policy/index';
import SettingPolicyMaxHoursUseIndexPage from '@/pages/setting/policy/max-hours-use/index';
import SettingPolicyReceiveReservationIndexPage from '@/pages/setting/policy/receive-reservation/index';
import SettingPolicySameDayReservationIndexPage from '@/pages/setting/policy/same-day-reservation/index';
import SettingScheduleIndexPage from '@/pages/setting/schedule/index';
import SettingScheduleManagementDayIndexPage from '@/pages/setting/schedule/management-day/index';
import SettingTableIndexPage from '@/pages/setting/table/index';
import SettingTestPage from '@/pages/setting/test';
import TablemanagerApplyConsultationIndexPage from '@/pages/tablemanager/apply-consultation/index';
import TablemanagerCallAlarmSettingIndexPage from '@/pages/tablemanager/call-alarm-setting/index';
import TablemanagerCallAlarmSettingNotiExceptionIndexPage from '@/pages/tablemanager/call-alarm-setting/noti-exception/index';
import TablemanagerCallHistoryIndexPage from '@/pages/tablemanager/call-history/index';
import TablemanagerCustomerDetail$IdPage from '@/pages/tablemanager/customer-detail/[id]';
import TablemanagerFirstTimeCustomerIndexPage from '@/pages/tablemanager/first-time-customer/index';
import TablemanagerMyStoreClearCacheIndexPage from '@/pages/tablemanager/my-store/clear-cache/index';
import TablemanagerMyStoreIndexPage from '@/pages/tablemanager/my-store/index';
import TutorialEssentialPage from '@/pages/tutorial/essential';
import TutorialIndexPage from '@/pages/tutorial/index';
import TutorialReservationPage from '@/pages/tutorial/reservation';
import TutorialTablePage from '@/pages/tutorial/table';

const CustomRouter = (props = {}) => <BrowserRouter>
  <Routes {...props}>
    <Route key="AuthIndexPage" path="/auth/" element={<AuthIndexPage/> }/>
    <Route key="IndexPage" path="/" element={<CommonProvider><IndexPage/></CommonProvider> }/>
    <Route key="ReservationAcceptStep_1IndexPage" path="/reservation/accept/step-1/" element={<CommonProvider><ReservationAcceptStep_1IndexPage/></CommonProvider> }/>
    <Route key="ReservationAcceptStep_2IndexPage" path="/reservation/accept/step-2/" element={<CommonProvider><ReservationAcceptStep_2IndexPage/></CommonProvider> }/>
    <Route key="ReservationAcceptStep_3IndexPage" path="/reservation/accept/step-3/" element={<CommonProvider><ReservationAcceptStep_3IndexPage/></CommonProvider> }/>
    <Route key="ReservationAcceptStep_4IndexPage" path="/reservation/accept/step-4/" element={<CommonProvider><ReservationAcceptStep_4IndexPage/></CommonProvider> }/>
    <Route key="ReservationAcceptStep_5IndexPage" path="/reservation/accept/step-5/" element={<CommonProvider><ReservationAcceptStep_5IndexPage/></CommonProvider> }/>
    <Route key="ReservationDetail$IdPage" path="/reservation/detail/:id" element={<CommonProvider><ReservationDetail$IdPage/></CommonProvider> }/>
    <Route key="ReservationListIndexPage" path="/reservation/list/" element={<CommonProvider><ReservationListIndexPage/></CommonProvider> }/>
    <Route key="ReservationUserSearchIndexPage" path="/reservation/user-search/" element={<CommonProvider><ReservationUserSearchIndexPage/></CommonProvider> }/>
    <Route key="SettingAgreementPage" path="/setting/agreement" element={<CommonProvider><SettingAgreementPage/></CommonProvider> }/>
    <Route key="SettingChannelIndexPage" path="/setting/channel/" element={<CommonProvider><SettingChannelIndexPage/></CommonProvider> }/>
    <Route key="SettingEssentialEditPage" path="/setting/essential/edit" element={<CommonProvider><SettingEssentialEditPage/></CommonProvider> }/>
    <Route key="SettingEssentialIndexPage" path="/setting/essential/" element={<CommonProvider><SettingEssentialIndexPage/></CommonProvider> }/>
    <Route key="SettingIndexPage" path="/setting/" element={<CommonProvider><SettingIndexPage/></CommonProvider> }/>
    <Route key="SettingPolicyAvailableReservationIndexPage" path="/setting/policy/available-reservation/" element={<CommonProvider><SettingPolicyAvailableReservationIndexPage/></CommonProvider> }/>
    <Route key="SettingPolicyDepositIndexPage" path="/setting/policy/deposit/" element={<CommonProvider><SettingPolicyDepositIndexPage/></CommonProvider> }/>
    <Route key="SettingPolicyIndexPage" path="/setting/policy/" element={<CommonProvider><SettingPolicyIndexPage/></CommonProvider> }/>
    <Route key="SettingPolicyMaxHoursUseIndexPage" path="/setting/policy/max-hours-use/" element={<CommonProvider><SettingPolicyMaxHoursUseIndexPage/></CommonProvider> }/>
    <Route key="SettingPolicyReceiveReservationIndexPage" path="/setting/policy/receive-reservation/" element={<CommonProvider><SettingPolicyReceiveReservationIndexPage/></CommonProvider> }/>
    <Route key="SettingPolicySameDayReservationIndexPage" path="/setting/policy/same-day-reservation/" element={<CommonProvider><SettingPolicySameDayReservationIndexPage/></CommonProvider> }/>
    <Route key="SettingScheduleIndexPage" path="/setting/schedule/" element={<CommonProvider><SettingScheduleIndexPage/></CommonProvider> }/>
    <Route key="SettingScheduleManagementDayIndexPage" path="/setting/schedule/management-day/" element={<CommonProvider><SettingScheduleManagementDayIndexPage/></CommonProvider> }/>
    <Route key="SettingTableIndexPage" path="/setting/table/" element={<CommonProvider><SettingTableIndexPage/></CommonProvider> }/>
    <Route key="SettingTestPage" path="/setting/test" element={<CommonProvider><SettingTestPage/></CommonProvider> }/>
    <Route key="TablemanagerApplyConsultationIndexPage" path="/tablemanager/apply-consultation/" element={<CommonProvider><TablemanagerApplyConsultationIndexPage/></CommonProvider> }/>
    <Route key="TablemanagerCallAlarmSettingIndexPage" path="/tablemanager/call-alarm-setting/" element={<CommonProvider><TablemanagerCallAlarmSettingIndexPage/></CommonProvider> }/>
    <Route key="TablemanagerCallAlarmSettingNotiExceptionIndexPage" path="/tablemanager/call-alarm-setting/noti-exception/" element={<CommonProvider><TablemanagerCallAlarmSettingNotiExceptionIndexPage/></CommonProvider> }/>
    <Route key="TablemanagerCallHistoryIndexPage" path="/tablemanager/call-history/" element={<CommonProvider><TablemanagerCallHistoryIndexPage/></CommonProvider> }/>
    <Route key="TablemanagerCustomerDetail$IdPage" path="/tablemanager/customer-detail/:id" element={<CommonProvider><TablemanagerCustomerDetail$IdPage/></CommonProvider> }/>
    <Route key="TablemanagerFirstTimeCustomerIndexPage" path="/tablemanager/first-time-customer/" element={<CommonProvider><TablemanagerFirstTimeCustomerIndexPage/></CommonProvider> }/>
    <Route key="TablemanagerMyStoreClearCacheIndexPage" path="/tablemanager/my-store/clear-cache/" element={<CommonProvider><TablemanagerMyStoreClearCacheIndexPage/></CommonProvider> }/>
    <Route key="TablemanagerMyStoreIndexPage" path="/tablemanager/my-store/" element={<CommonProvider><TablemanagerMyStoreIndexPage/></CommonProvider> }/>
    <Route key="TutorialEssentialPage" path="/tutorial/essential" element={<CommonProvider><TutorialEssentialPage/></CommonProvider> }/>
    <Route key="TutorialIndexPage" path="/tutorial/" element={<CommonProvider><TutorialIndexPage/></CommonProvider> }/>
    <Route key="TutorialReservationPage" path="/tutorial/reservation" element={<CommonProvider><TutorialReservationPage/></CommonProvider> }/>
    <Route key="TutorialTablePage" path="/tutorial/table" element={<CommonProvider><TutorialTablePage/></CommonProvider> }/>
  </Routes>
</BrowserRouter>;

export default CustomRouter;
