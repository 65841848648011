import * as React from 'react';
import { Controller } from 'react-hook-form';
import { HookFormProps, ICheckBox } from './HookFormProps';

export const ControlledToggle: React.FC<HookFormProps> = (props) => {
  const { defaultChecked = [] } = props;

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue ?? ''}
      render={({ field: { onChange, name: fieldName, value } }) => {
        return (
          <div className="content custom-check-wrapper">
            <label className="tblm-switch">
              <input
                {...props}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(e);
                  props.onChange && props.onChange(e);
                }}
                type="checkbox"
                name={fieldName}
                value={value ?? ''}
                readOnly
              />
              <i />
            </label>
          </div>
        );
      }}
    />
  );
};
