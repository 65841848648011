import * as yup from 'yup';

import { useEffect, useId, useMemo, useState } from 'react';

import { ControlledInput } from '@/hook-form/ControlledInput';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import { MallSetting } from '@/shared/utils/common.type';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import Terces from '@/shared/apis/terces';
import { autoHypenCompany } from '@/shared/lib/functions/auto-hypen-company';
import { autoHypenTel } from '@/shared/lib/functions/auto-hypen-tel';
import { devServerApi } from '@/shared/apis/devServerApi';
import { instantDisabler, parseJwt } from '@/shared/lib/functions';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useForm } from 'react-hook-form';
import { useModalStore } from '@/stores/common/useModalStore';
import { useQuery } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import { useNavigate } from 'react-router-dom';

const Channel = () => {
  const { accessToken, payload, setPayload } = useAuthStore();
  const { setAlertModal, setMenuModal } = useModalStore();
  const [failedChatbotMessage, setFailedChatbotMessage] = useState(null);
  const [channelConnect, setChannelConnect] = useState({});
  const navigate = useNavigate();
  const errorModalId = useId();
  const [errorMessage, setErrorMessage] = useState<String>('');
  const [data, setData] = useState(null);
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
  useEffect(()=> {
    setChannelConnect(JSON.parse(localStorage['channel-connect'] || '{}'));
    const { pid, pmid, tmid } = parseJwt(accessToken);
    setPayload({ pid, pmid, tmid });
  }, [accessToken]);

  const { isFetching } = useQuery([QUERY_KEYS.SETTINGS_MALL], () => devServerApi.api.get<MallSetting>('/mall/inquiry/onlineReservation').then((enc_res)=>Terces.decrypt(enc_res)), {
    cacheTime: 0,
    onSuccess(res) {
      if (res.data.status < 0) {
        setErrorMessage(res.data.message);
        setAlertModal({ visible: true, key: errorModalId });
        return;
      }
      setData(res.data);
    },
  });
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      mallName: yup.string().required('매장명을 입력해주세요.'),
      subscriber: yup.string().required('신청인 이름을 입력해주세요.'),
      phone: yup.string().required('신청인 연락처를 입력해주세요.').max(13, '올바른 연락처를 입력해주세요.'),
      eid: yup.string().required('사업자 번호를 입력해주세요.').min(12, '올바른 사업자번호를 입력해주세요.').max(12, '올바른 사업자번호를 입력해주세요.'),
    });
  }, []);

  const methods = useForm({
    defaultValues: {
      mallName: '',
      subscriber: '',
      phone: '',
      eid: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { handleSubmit, control, setValue, reset } = methods;

  const onClickYes = () => {
    setMenuModal({ visible: true, key: 'naverkakaoform' });
  };

  const onSubmit = (form) => {
    devServerApi.api.post('/mall/inquiry/onlineReservation', Terces.encrypt(form)).then(({ data: enc_res }: any) => {
      const res = Terces.decrypt(enc_res);
      if (res.status < 0) {
        setFailedChatbotMessage(res.message);
        setAlertModal({ visible: true, key: 'failedChatbot' });

        return;
      }
      channelConnect[payload.tmid] = true;
      localStorage['channel-connect'] = JSON.stringify(channelConnect);
      // onNext(true);
      setMenuModal({ visible: false, key: 'naverkakaoform' });
      setAlertModal({ visible: true, key: 'successChatbot' });
      reset();
    });
  };

  if (isFetching) return <SpinnerEffector loading={isFetching} />;
  if (errorMessage) return (
      <>
        <Modal.Alert key={errorModalId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">알림</div>
          <div className="content font__body_sm">{errorMessage}</div>
          <div
            className="footer success font__body_sm_sb"
            onClick={() => {
              navigate(-1);
              setErrorMessage('');
              setAlertModal({ visible: false, key: errorModalId });
            }}
          >
            확인
          </div>
        </Modal.Alert>
      </>
    );
  return (
    <>
      <HeaderBack inBack={false}>
        <div className="font__subtitle">온라인 예약 연동</div>
      </HeaderBack>
      <section className="settings-policy-detail-wrapper">
        <div className="switch-area pointer">
          <div className="left-area" style={{ flexBasis: 'auto' }}>
            <div className="font__title_20_sb">현재 연동 중인 온라인 예약 채널</div>
            <div className="font__body_sm_m keep-all">
              {/* @ts-ignore */}
              {data?.enabledPartnerNames.join(', ') || (channelConnect[payload?.tmid] ? '연동 신청 완료' : '없음')}
            </div>
          </div>
          <div className="right-area gap-16">
            {/* @ts-ignore*/}
            <button className="tblm-button-normal tblm-btn-paleblue font__small_m" style={{ minWidth: '61px', height: '32px' }} disabled={channelConnect[payload?.tmid] || (data && data.enabledPartnerNames?.length > 0) || data.inquiryExists} onClick={() => onClickYes()}>
              추가
            </button>
          </div>
        </div>
      </section>
      <div className="font__small" style={{ padding: '16px 20px', color: '#4F5861' }}>
        네이버 예약, 카카오 챗봇으로도 예약을 받아보세요.
        <br />
        늘어난 예약량을 통화비서에서 한 번에 관리할 수 있어요.
      </div>
      <ModalPortal>
        <Modal.Menu key="naverkakaoform" modal="menu" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb with-note">
            서비스 신청에 필요한 정보를 입력합니다.
            <div className="title-note font__s_small">*필수 입력</div>
          </div>
          <ul className={'form-wrapper'}>
            <li>
              <div className="form-label">*매장명</div>
              <div className="form-value">
                <ControlledInput
                  name="mallName"
                  control={control}
                  placeholder="매장명을 입력해주세요."
                  onChange={(e) => {
                    setValue('mallName', e.target.value.replace(/[^\w\sㄱ-힣]|[_]/g, ''));
                  }}
                />
              </div>
            </li>
            <li>
              <div className="form-label">*신청인 이름</div>
              <div className="form-value">
                <ControlledInput
                  name="subscriber"
                  control={control}
                  placeholder="신청인 이름을 입력해주세요."
                  onChange={(e) => {
                    setValue('subscriber', e.target.value.replace(/[^\w\sㄱ-힣]|[_]/g, ''));
                  }}
                />
              </div>
            </li>
            <li>
              <div className="form-label">*신청인 연락처</div>
              <div className="form-value three">
                <ControlledInput
                  name="phone"
                  type="text"
                  control={control}
                  placeholder="010-0000-0000"
                  onChange={(e) => {
                    setValue('phone', autoHypenTel(e.target.value));
                  }}
                  maxLength={13}
                />
              </div>
            </li>
            <li>
              <div className="form-label">*사업자 번호</div>
              <div className="form-value three">
                <ControlledInput
                  name="eid"
                  control={control}
                  placeholder="000-00-00000"
                  onChange={(e) => {
                    setValue('eid', autoHypenCompany(e.target.value));
                  }}
                  maxLength={12}
                />
              </div>
            </li>
          </ul>
          <div className="form-button-container">
            <button className="next" onClick={instantDisabler(handleSubmit((result) => onSubmit(result)))}>
              완료
            </button>
          </div>
        </Modal.Menu>
        <Modal.Alert key="failedChatbot" modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__subtitle">연동신청에 실패하였습니다.</div>
          <div className="content font__body_sm">{failedChatbotMessage}</div>
          <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: 'failedChatbot' })}>
            확인
          </div>
        </Modal.Alert>
        <Modal.Alert key="successChatbot" modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__subtitle">온라인 예약 연동 신청이 완료되었어요!</div>
          <div className="content font__body_sm">
            서비스 가입을 위해 테이블매니저 팀에서 “1544-2017” 번호로 연락드릴 거예요.
            <br />잘 받아주세요!
          </div>
          <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: 'successChatbot' })}>
            확인
          </div>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default Channel;
