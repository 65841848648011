import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Close_Textfield } from '@/assets/images/icons/Close_Textfield.svg';
import { ReactComponent as SearchInputIcon } from '@/assets/images/icons/Search_Input.svg';
import './SearchInput.scss';

interface ISearchInput {
  error?: any;
  value?: any;
  placeholder?: any;
  onEnter?: any;
  onClear?: any;
  [key: string]: any;
}

const SearchInput = ({ error, value, placeholder, onEnter, onClear, ...props }: ISearchInput) => {
  // const  = props;
  const [isClose, setIsClose] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setIsClose(inputRef.current.value.length);
  }, []);

  const handleOnChange = (e) => {
    setIsClose(Boolean(e.target.value.length));
    props.onChange && props.onChange(e);
  };
  const handleOnKeypress = (e) => {
    if (e.keyCode === 13) {
      onEnter(e);
    }
  };
  return (
    <div className="custom-search-input-wrapper flex-col gap-4">
      <div className="input-area">
        <SearchInputIcon />
        <input {...props} ref={inputRef} onChange={handleOnChange} onKeyDown={handleOnKeypress} className="font__body_sm" value={value} placeholder={placeholder} />
        {isClose ? (
          <Close_Textfield
            onClick={() => {
              setIsClose(false);
              onClear();
            }}
          />
        ) : null}
      </div>

      {error && <span className="font__s_small">{error}</span>}
    </div>
  );
};

export default SearchInput;
