import React from 'react';
import './NamePhoneNumber.scss';
import { ReactComponent as Arrow_Right } from '@/assets/images/icons/Arrow_Right.svg';
import { INamePhoneNumber, INamePhoneNumberItem } from './NamePhoneNumber.type';

const NamePhoneNumber = ({ data, onClick }: INamePhoneNumber): React.ReactElement => {
  const handleOnClick = (target) => onClick(target);

  return (
    <div className="name-phone-list-wrapper">
      {data.map((item: INamePhoneNumberItem, i) => (
        <div key={`${item.userId}_${i}`} className="name-phone-list-item" onClick={() => handleOnClick(item)}>
          <div className="top-area">
            <div className="font__body_sm_sb">{item.userName}</div>
            <div className="font__body_sm">{item.userPhone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}</div>
            <Arrow_Right />
          </div>
          {item?.userNickname || item?.userMemo ? (
            <div className="bottom-area">
              {item?.userNickname && (
                <div>
                  <div className="tag font__ss_small_m">별칭</div>
                  <div className="font__small flex-container items-center">{item.userNickname}</div>
                </div>
              )}
              {item?.userMemo && (
                <div>
                  <div className="tag font__ss_small_m">고객메모</div>
                  <div className="font__small flex-container items-center">{item.userMemo}</div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default NamePhoneNumber;
