import React from 'react';
import './SelectTables.scss';

interface ITableQueryListItem {
  id: string;
  name: string;
}

interface ISelectTables {
  tables: ITableQueryListItem;
  active: boolean;
  reservations: any[];
  onClick: (id) => void;
}

const SelectTables = ({ tables, active, reservations, onClick }: ISelectTables): React.ReactElement => {
  const { id, name } = tables;

  return (
    <div className={`table-item ${active ? 'active' : ''}`} onClick={() => onClick(id)}>
      <div className="table-title font__body_sm_sb">{name}</div>
      {reservations.length ? (
        <div className="table-reservation-wrapper">
          {reservations.map((reservation) => {
            const h = String(Math.floor(reservation.visitTime / 60)).padStart(2, '0');
            const m = String(Math.floor(reservation.visitTime % 60)).padStart(2, '0');
            const totalParties = reservation.parties.map((item) => item.size).reduce((a, b) => a + b);
            return <div className="table-reservation-item font__body_sm" key={reservation.reservationId}>{`${h}:${m} ${totalParties}명 ${reservation.userName}`}</div>;
          })}
        </div>
      ) : (
        <div className="no-reservation font__body_sm">예약없음</div>
      )}
    </div>
  );
};

export default SelectTables;
