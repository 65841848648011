import React, { useEffect } from 'react';
import { IModalProps } from '@/components/Modal/Modal.type';
import { useModalStore } from '@/stores/common/useModalStore';

const Toast = ({ autoHideDuration, isAnimation, position = 'top', margin, children }: IModalProps) => {
  const { toastModal, setToastModal } = useModalStore();

  useEffect(() => {
    let hideDuration = null;
    if (toastModal) {
      hideDuration = setTimeout(() => {
        setToastModal(false);
      }, autoHideDuration);
    }
    return () => clearTimeout(hideDuration);
  }, [toastModal]);

  if (!toastModal.visible) return <></>;
  return (
    <div className={`modal-wrapper toast ${isAnimation ? 'animation' : ''}`}>
      <div className={`modal-content ${position}`} style={margin ? { transform: `translate(-50%, -50%) translateY(${position === 'bottom' ? '-' : ''}${margin}px)` } : null}>
        {children}
      </div>
    </div>
  );
};

export default Toast;
