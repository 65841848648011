import cloneDeep from 'lodash/cloneDeep';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { getSelectValues } from '@/shared/lib/functions';
import { IPersistStore, IQuestion, IQuestionAction, Nullable } from '@/shared/utils/common.type';

const INITIAL_QUESTIONS: IQuestion = {
  step0_0: {
    value: null,
    question: '예약 정책 설정을\n시작할게요.',
    note: '예약을 받으시려면\n기본 세팅을 마쳐주셔야 해요!',
    type: 'button',
    nextStep: {
      to: 'step1_0',
    },
  },
  step1_0: {
    value: null,
    question: '당일 예약을\n받을까요?',
    type: 'button',
    nextStep: {
      bool: ['step2_0', 'step4_0'],
    },
  },
  step2_0: {
    value: null,
    question: '이용 몇 시간 전까지\n예약을 허용하시겠어요?',
    note: '최소한 언제까지는 예약해야\n매장을 이용할 수 있나요?',
    type: 'button',
    boolText: ['직접 시간을 설정할게요', '제한 없이 받을게요'],
    nextStep: {
      bool: ['step3_0', 'step4_0'],
    },
  },
  step3_0: {
    value: null,
    question: '이용 몇 시간 전까지\n예약을 허용하시겠어요?',
    note: '최소한 언제까지는 예약해야\n매장을 이용할 수 있나요?',
    type: 'select',
    option: [new Array(7).fill(1).map((_, e) => e), new Array(6).fill(1).map((__, m) => m * 10)],
    optionUnit: ['시간', '분전'],
    nextStep: {
      to: 'step4_0',
    },
  },
  step4_0: {
    value: null,
    question: '예약을 몇 시간 단위로\n받을까요?',
    note: '예약을 10:00~10:30 단위로 받을지,\n10:00~11:00 단위로 받을지\n결정해주시면 돼요.',
    type: 'select',
    option: [[10, 30, 60]],
    optionUnit: ['시간분'],
    nextStep: {
      to: 'step5_0',
    },
  },
  step5_0: {
    value: null,
    question: '테이블 당\n최대 이용 시간은 \n몇 시간으로 할까요?',
    note: '한 팀이 테이블에\n최대 얼마 동안 머무를 수 있나요?',
    type: 'select',
    option: [[30, 60 * 1, 60 * 1.5, 60 * 2, 60 * 3, 60 * 4]],
    optionUnit: ['시간분'],
    nextStep: {
      to: 'step6_0',
    },
  },
  step6_0: {
    value: null,
    question: '{day}에\n예약 받으세요?',
    note: '(day===0)이제 요일별 예약 일정을\n설정해볼게요!',
    notePosition: 'before',
    type: 'time',
    nextStep: {
      to: 'step7_0',
    },
  },
  step7_0: {
    value: null,
    question: '오늘부터 며칠 후까지\n예약을 열어둘까요?',
    // note: '최소한 언제까지는 예약해야\n매장을 이용할 수 있나요?',
    type: 'until',
    nextStep: {
      to: 'step8_0',
    },
  },
  step8_0: {
    value: null,
    question: '예약 정책이\n제대로 설정되었는지\n확인해주세요.',
    type: 'reservation',
    nextStep: {
      api: ['reservation', 'step9_0'],
      // api: ['reservation', 'done'],
    },
  },
  step9_0: {
    value: null,
    question: '네이버 예약,\n카카오 챗봇으로도\n예약을 받으시겠어요?',
    note: '통화비서가\n한 번에 관리해 드려요!',
    type: 'naverkakao',
    nextStep: {
      bool: ['step10_0', 'done'],
    },
  },
  step10_0: {
    value: null,
    question: '연동 신청이\n완료되었어요!',
    note: '서비스 가입을 위해 테이블매니저 팀에서 "1544-2017" 번호로 연락 드릴 거예요.\n잘 받아주세요!',
    type: 'plain',
    nextStep: {
      to: 'done',
    },
  },
};

export const questionReducer = (state: IQuestion, action: IQuestionAction) => {
  const { type, value, step, multiple, idx } = action;
  const copyState = cloneDeep(state);
  switch (type) {
    case 'SET_CHANGE': {
      copyState[step].value = value;
      return copyState;
    }
    case 'SET_REF_CHANGE': {
      copyState[step].value[idx][1] = value;
      return copyState;
    }
    case 'SET_SELECT_CHANGE': {
      if (multiple) {
        copyState[step].value = getSelectValues(value);
      } else {
        copyState[step].value = value.value;
      }
      return copyState;
    }
    default: {
      return state;
    }
  }
};

export const useReservationSettingStore = create(
  persist<IPersistStore>(
    (set) => ({
      // @ts-ignore
      storageQuestion: INITIAL_QUESTIONS,
      setStorageQuestion: (storageQuestion) => set({ storageQuestion: storageQuestion }),
      resetStorageQuestion: () => set({ storageQuestion: INITIAL_QUESTIONS }),
    }),
    { name: 'reservation-setting', getStorage: () => localStorage, version: 10 }
  )
);
