/* eslint-disable */
import React from 'react';

const marketing_doc = ({ className }: {className: string}): React.ReactElement => {
  return (<div className={className} dangerouslySetInnerHTML={{__html : `<span class='h2'>홍보 및 마케팅 수집/이용 동의서 (할인혜택 안내, 뉴스레터, 이벤트 안내, 그 밖에 상품 및 사업관련 정보 발송)</span>테이블매니저(테이블매니저(주))의 개인정보 수집 이용 목적은 다음과 같습니다. 내용을 자세히 읽어 보신 후 동의 여부를 결정하여 주시기 바랍니다.<table>
<tbody>
  <tr>
    <td>수집 목적</td>
    <td>테이블매니저 사용 매장</td>
  </tr>
  <tr>
    <td>필수항목</td>
    <td>이름, 이메일, 휴대전화번호, CI, 서비스 이용내역</td>
  </tr>
  <tr>
    <td>선택항목</td>
    <td>성별, 생년월일</td>
  </tr>
  <tr>
    <td>보유/이용기간</td>
    <td>동의철회까지 또는 회원탈퇴까지</td>
  </tr>
</tbody>
</table><div class='dotted'>원활한 서비스 운영을 위해 개인정보처리업무를 주식회사 카카오(알림톡)(대한민국), Facebook business(미국)가 대행하고 있습니다. 자세한 내용은 개인정보처리방침(링크)을 확인해주세요.</div>
<div class='dotted'>귀하는 위와 같이 개인정보를 수집/이용하는데 동의를 거부할 권리가 있습니다. 선택 수집 항목에 동의하지 않으시는 경우 별도의 불이익은 없습니다.</div>`}} />);
};

export default marketing_doc;
