import create from 'zustand';
import { ReservationExtravalues } from '../useReservationInformationStore';

interface OperationTime {
  openTime: number;
  closeTime: number;
}

interface UserExtravalue {
  id: string;
  name: string;
  type: string;
  choices: string[];
  required: boolean;
  backgroundColor: string;
  fontColor: string;
}
interface reservationExtravalue {
  backgroundColor: string;
  choices: string[];
  fontColor: string;
  hashtagDisplay: string;
  id: string;
  max: number;
  min: number;
  name: string;
  position: string;
  required: boolean;
  type: string;
}

interface MarkingColor {
  id: string;
  color: string;
}

interface Room {
  id: string;
  name: string;
  roomGroupId: string;
  roomGroupName: string;
}
interface RoomGroup {
  roomGroupId: string;
  roomGroupName: string;
}
interface RoomGroups {
  [key: RoomGroup['roomGroupId']]: RoomGroup['roomGroupName'];
}

interface IMenus {
  categoryId: string;
  categoryName: string;
  menuId: string;
  menuName: string;
  menuPrice: number;
}
// interface IMenus {
//   categoryId: '61bfe030a9d12300080fe9ff';
//   categoryName: 'N예약메뉴';
//   menuId: '61bfe030a9d12300080fea02';
//   menuName: '11시부터4시메뉴';
//   menuPrice: 2000;
// }

interface IMessageTemplates {
  type: string;
  name: string;
}

interface IDivision {
  divisionDay: number;
  divisionId: string;
  koName: string;
  enName: string;
  start: number;
  end: number;
}

const initialMall = {
  messageTemplates: [],
  rooms: [],
  roomGroups: {},
  menus: [],
  partyNames: [],
  depositPaymentTypes: [],
  depositPaymentBankTypes: [],
  reservationMarkingColors: [],
  userExtravalues: [],
  reservationExtravalues: [],
  maxPartySize: 0,
  operationTime: {
    openTime: 639,
    closeTime: 1280,
  },
  supportDeposit: false,
  multipleDivision: [],
  mallName: '',
  mallPhone: '',
};

export interface IMall {
  messageTemplates: IMessageTemplates[];
  rooms: Room[];
  roomGroups: RoomGroups;
  menus: IMenus[];
  partyNames: string[];
  reservationMarkingColors: MarkingColor[];
  userExtravalues: UserExtravalue[];
  reservationExtravalues: ReservationExtravalues[];
  maxPartySize: number;
  operationTime: OperationTime;
  depositPaymentTypes: string[];
  depositPaymentBankTypes: string[];
  supportDeposit: boolean;
  multipleDivision: IDivision[];
  mallName: string;
  mallPhone: string;
}
interface IUseMallStore {
  mall: IMall;
  setMall: (props) => void;
  mallTime: any;
  setMallTime: (props) => void;
}

export const useMallStore = create<IUseMallStore>((set) => ({
  mall: initialMall,
  setMall: (mall) => set({ mall }),
  mallTime: {
    monday: {},
    tuesday: {},
    wednesday: {},
    thursday: {},
    friday: {},
    saturday: {},
    sunday: {},
  },
  setMallTime: (mallTime) => set({ mallTime }),
}));
