import { ReactComponent as Close } from '@/assets/images/icons/Close.svg';
import classNames from 'classnames';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './HeaderSub.scss';
import { IHeaderSub } from './HeaderSub.type';

/**
 * IHeaderSub
 * @param {boolean} isScroll 스크롤 기능 여부
 * @param {boolean} isBorder border 표시 여부
 * @param {boolean} scrollMove header 스크롤 움직임 감지 (최상단 top에 닿았는지 아닌지 여부 - 닿았을 때 false, 닿지 않았을 때 true)
 * @param {boolean} isScrollTitleVisible 스크롤 되었을 때 header text 보여주지 않을 건지 여부
 * @param {function} onClose 닫기 버튼 기능
 * @param {children} children children
 * @param {boolean} isNeedPlaceholder 스크롤 될 경우를 대비하여 placeholder가 숨겨있어서 가상의 요소를 추가하는 용도
 * @param {boolean} isInitFixedPosition 첫 DOM load 후 is-border, is-fixed className을 갖고 있을 건지의 여부 (캘린더의 경우 DOM load 이후 두번째 달(이번달)로 스크롤 이동 되는데 초기값은 무조건 is-border, is-fixed로 설정되어야 css가 바로 적용되므로 필요 -> UI 덜컹거림 이슈 개선)
 * @param {boolean} isFixedShortHeader 짧은 header로 고정 스타일 적용할 때 사용
 * @param {boolean} isShowClose Close 영역 보일지 여부
 */

const HeaderSub = ({
  isScroll = true,
  isBorder = true,
  scrollMove = false,
  isScrollTitleVisible = false,
  onClose,
  children,
  isNeedPlaceholder,
  isInitFixedPosition = false,
  isFixedShortHeader,
  isShowClose = true,
}: IHeaderSub) => {
  const [isScrollMove, setIsScrollMove] = useState(isInitFixedPosition ? !scrollMove : scrollMove);
  const [borderClassName, setBorderClassName] = useState(isInitFixedPosition && 'is-border');
  const [scrollMoveClassName, setScrollMoveClassName] = useState(isInitFixedPosition && 'is-fixed');
  const [placeholder, setPlaceholder] = useState('');
  const scrollElement = document.querySelector('.main-container-desktop');

  const handleScroll = () => {
    if (isScroll && !scrollMove) {
      const position = scrollElement.scrollTop;
      const scrollBoolean = Boolean(position <= 0);
      const scrollPlaceholder = Boolean(position <= 80);

      if (isNeedPlaceholder) setIsScrollMove(!scrollPlaceholder);
      else setIsScrollMove(!scrollBoolean);
    }
  };

  useEffect(() => {
    if (isFixedShortHeader) {
      setBorderClassName(null);
      setScrollMoveClassName('is-fixed');
      return;
    }
    if (isScroll) scrollElement.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      if (isScroll) scrollElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setBorderClassName(isScrollMove && isBorder ? 'is-border' : '');
    setScrollMoveClassName(isScrollMove ? 'is-fixed' : '');
    if (isNeedPlaceholder) setPlaceholder(isScrollMove ? 'header-placeholder' : '');
  }, [isScrollMove]);

  return (
    <>
      <div className={placeholder} />
      <nav className={`header-sub-wrapper header-sub ${borderClassName} ${scrollMoveClassName}`}>
        {isShowClose ? <div className="close">
          <Close onClick={onClose} />
        </div> : <></>}
        <div className={classNames('title', { 'font__subtitle remove-br': isScrollMove, font__title_20_sb: !isScrollMove, 'is-scroll-title-visible': isScrollTitleVisible, 'middle-align': !isShowClose })}>
          {children}
        </div>
      </nav>
    </>
  );
};

export default HeaderSub;
