import 'react-responsive-carousel/lib/styles/carousel.min.css';

import React, { Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';

// 타입 지정
const ImageCarousel = ({ items, ...props }: any): React.ReactElement => {
  return (
    <Carousel {...props}>
      {items.map((item) => (
        <Fragment key={item.key}>{item.render()}</Fragment>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
