import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import {} from './ListFilter.constants';
import { devServerApi } from '@/shared/apis/devServerApi';
import { IReservation, ITables } from '@/shared/utils/common.type';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useMallStore } from '@/stores/common/useMallStore';
import styles from './ListFilter.module.scss';
import { IListFilter } from './ListFilter.type';

const ListFilter = ({ className, onClose, filter, setFilter }: IListFilter): React.ReactElement => {
  const { accessToken } = useAuthStore();
  const { roomGroups } = useMallStore((state) => state.mall);
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
  const [statusList, setStatusList] = useState<IReservation['reservationState'][]>([]);
  const [selectedGroup, setSelectedGroup] = useState('all');

  useEffect(() => {
    setStatusList(filter.statusList);
    setSelectedGroup(filter.selectedGroup);
  }, [filter]);

  const isChecked = (status: IReservation['reservationState']) => {
    return statusList.includes(status);
  };
  const check = (e) => {
    const { checked, value } = e.target;
    if (!checked) {
      setStatusList(statusList.filter((status) => status !== value));
    } else {
      setStatusList([value, ...statusList]);
    }
  };
  const selectGroup = (e) => {
    const { checked, value } = e.target;
    setSelectedGroup(value);
  };
  return (
    <div className={classnames(styles.list_filter, className)}>
      <div className={styles.list__filter_title}>예약상태</div>
      <div className={classnames(styles.list__filter_content, styles.list__filter_status_list)}>
        <label>
          <input type="checkbox" className="tblm-chip-filter-check-handle" name="filter_status" value={'valid'} checked={isChecked('valid')} onChange={check} />
          <div className="tblm-chip-filter-check">접수</div>
        </label>
        <label>
          <input type="checkbox" className="tblm-chip-filter-check-handle" name="filter_status" value={'enter'} checked={isChecked('enter')} onChange={check} />{' '}
          <div className="tblm-chip-filter-check">입장</div>
        </label>
        <label>
          <input type="checkbox" className="tblm-chip-filter-check-handle" name="filter_status" value={'exit'} checked={isChecked('exit')} onChange={check} />
          <div className="tblm-chip-filter-check">퇴장</div>
        </label>
        <label>
          <input type="checkbox" className="tblm-chip-filter-check-handle" name="filter_status" value={'canceled'} checked={isChecked('canceled')} onChange={check} />
          <div className="tblm-chip-filter-check">취소</div>
        </label>
        <label>
          <input type="checkbox" className="tblm-chip-filter-check-handle" name="filter_status" value={'noShow'} checked={isChecked('noShow')} onChange={check} />
          <div className="tblm-chip-filter-check">노쇼</div>
        </label>
      </div>
      <div className={styles.list__filter_title}>테이블 그룹</div>
      <ul className={classnames(styles.list__filter_content, styles.list__filter_group_list)}>
        <li>
          <label className="tblm-rc">
            <input type="radio" name="filter_group" value={'all'} checked={selectedGroup === 'all'} onChange={selectGroup} />
            <i />
            <span>전체</span>
          </label>
        </li>
        <li>
          <label className="tblm-rc">
            <input type="radio" name="filter_group" value={'unset'} checked={selectedGroup === 'unset'} onChange={selectGroup} />
            <i />
            <span>미지정</span>
          </label>
        </li>
        {Object.entries(roomGroups)?.map((group) => (
          <li key={group[0]}>
            <label className="tblm-rc">
              <input type="radio" name="filter_group" value={group[0]} checked={selectedGroup === group[0]} onChange={selectGroup} />
              <i />
              <span>{group[1]}</span>
            </label>
          </li>
        ))}
      </ul>
      <hr />
      <button
        className={styles.list__filter_apply}
        onClick={() => {
          setFilter({ statusList, selectedGroup });
          onClose(false);
        }}
      >
        필터 적용
      </button>
    </div>
  );
};

export default ListFilter;
