import 'dayjs/locale/ko';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';

import FooterSub from '@/components/FooterSub/FooterSub';
import HeaderSub from '@/components/HeaderSub/HeaderSub';
import MonthCalendar from '@/components/MonthCalendar/MonthCalendar';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useQuery } from 'react-query';
import { useReservationCalendarStore } from '@/stores/reservation/useReservationCalendarStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import { useCallHistoryStore } from '@/stores/useCallHistoryStore';

dayjs.locale('ko');
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const INITIAL_STEP_TEXTS = {
  header: (
    <>
      예약일을 <br />
      선택 해주세요
    </>
  ),
  footer: { save: '저장', back: '뒤로', next: '다음' },
  depositHeader: (
    <>
      언제까지 예약금을 <br />
      결제해야 하나요?
    </>
  ),
  callHistoryHeader: (
    <>
      언제부터의 <br />
      통화기록을 보시겠어요?
    </>
  ),
};

const Step1 = (): React.ReactElement => {
  const { visitDate, setVisitDate, setVisitTime, setParty, setRooms, depositInfo, setDepositInfo } = useReservationStore();
  const { setReservationResetStore } = useReservationStore();
  const { reservationList, setReservationList } = useReservationCalendarStore();
  const { accessToken } = useAuthStore();
  const { endDate, startDate, setEndDate, setStartDate } = useCallHistoryStore();

  const [eventDays, setEventDays] = useState([]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams({ type: 'default' });

  const today = useMemo(() => dayjs(), []);

  const { isFetching } = useQuery([QUERY_KEYS.GET_EVENT_DAY], () => devServerApi.authGet('/eventDays', accessToken), {
    onSuccess: (res: any) => {
      setEventDays(res.eventdays?.map((item) => ({ date: dayjs(`${item.year}-${item.month}-${item.day}`), memo: '테스트 휴무' })));
    },
  });

  const { isLoading: isCalendarLoading } = useQuery(
    [QUERY_KEYS.LIST_CALENDAR, accessToken],
    () => devServerApi.authGet(`reservation/calendar/?from=${today.add(-1, 'month').format('YYYY-MM')}&to=${today.add(3, 'month').format('YYYY-MM')}`, accessToken),
    {
      onSuccess: (res: any) => {
        const convertDate = res.reservationSummary.map((item) => ({ ...item, date: dayjs(item.date) }));
        setReservationList(convertDate);
      },
      enabled: !reservationList || reservationList.length === 0,
    }
  );

  const handleOnClose = () => {
    if (searchParams.get('type') === 'default') {
      setReservationResetStore();
      navigate('/reservation/list');
    }
    if (searchParams.get('type') === 'modify' || searchParams.get('type') === 'deposit' || searchParams.get('type').includes('callHistory')) navigate(-1);
  };

  const handleOnRouterMove = (path) => navigate(path);

  const selectHeader = () => {
    switch (searchParams.get('type')) {
      case 'deposit':
        return INITIAL_STEP_TEXTS.depositHeader;

      case 'callHistoryStart':
        return INITIAL_STEP_TEXTS.callHistoryHeader;

      case 'callHistoryEnd':
        return INITIAL_STEP_TEXTS.callHistoryHeader;

      default:
        return INITIAL_STEP_TEXTS.header;
    }
  };

  const disabledAfterDayController = () => {
    if (searchParams.get('type') === 'deposit') return visitDate;
    else if (searchParams.get('type') === 'callHistoryStart') return today;
    else if (searchParams.get('type') === 'callHistoryEnd') return today;
    return null;
  };

  const disabledBeforeDayController = () => {
    if (searchParams.get('type') === 'callHistoryStart') return null;
    if (searchParams.get('type') === 'callHistoryEnd') return startDate;
    return today;
  };

  const selectedDateController = () => {
    if (searchParams.get('type') === 'deposit') return depositInfo?.depositDue;
    else if (searchParams.get('type') === 'callHistoryStart') return startDate;
    else if (searchParams.get('type') === 'callHistoryEnd') return endDate;
    return visitDate;
  };

  useEffect(() => {
    if (searchParams.get('visitDate')) setVisitDate(dayjs(searchParams.get('visitDate')));
  }, []);

  if (!searchParams.get('type').includes('callHistory')) {
    if (!reservationList || isFetching || isCalendarLoading) return <SpinnerEffector loading />;
  }

  return (
    <>
      <HeaderSub isInitFixedPosition onClose={handleOnClose}>
        {selectHeader()}
      </HeaderSub>
      <section className="month-calendar-wrapper">
        <MonthCalendar
          select={(target) => {
            if (searchParams.get('type') === 'deposit') {
              if (target.isSameOrAfter(today, 'd') && target.isSameOrBefore(visitDate, 'd')) {
                setDepositInfo({ ...depositInfo, depositDue: target });
              }
            } else if (searchParams.get('type') === 'callHistoryStart') {
              setStartDate(target);
              if (endDate.isBefore(target)) {
                setEndDate(target);
              }
              handleOnRouterMove(-1);
            } else if (searchParams.get('type') === 'callHistoryEnd') {
              setEndDate(target);
              handleOnRouterMove(-1);
            } else if (searchParams.get('type') === 'modify') {
              setVisitDate(target);
              setVisitTime(null);
              setParty([]);
              setRooms([]);
              handleOnRouterMove(-1);
            } else {
              setVisitDate(target);
              handleOnRouterMove('/reservation/accept/step-2/');
            }
          }}
          startDate={today.add(-1, 'month')}
          targetDate={today}
          selectedDate={selectedDateController()}
          disabledBeforeDay={disabledBeforeDayController()}
          disabledAfterDay={disabledAfterDayController()}
          orderList
          period={6}
          holidays={eventDays}
          headerHeight={55}
          reservationList={reservationList}
        />
      </section>
      <FooterSub isVisible isBorder isBackArea>
        {searchParams.get('type') === 'modify' || searchParams.get('type') === 'default' ? (
          <div className="font__subtitle flex-container items-center">{visitDate?.format('MM.DD(ddd)')} </div>
        ) : null}
        {searchParams.get('type') === 'deposit' || searchParams.get('type').includes('callHistory') ? (
          <div className="font__subtitle flex-container items-center">{depositInfo?.depositDue?.format('MM.DD(ddd)')}</div>
        ) : null}

        <div className="flex-container gap-8">
          {searchParams.get('type') === 'modify' ? (
            <Link to={-1 as any}>
              <button className="footer tblm-button-normal tblm-btn-primary" disabled={Boolean(!visitDate)}>
                {INITIAL_STEP_TEXTS.footer.save}
              </button>
            </Link>
          ) : null}
          {searchParams.get('type') === 'default' ? (
            <Link to={'/reservation/accept/step-2/'}>
              <button className="footer tblm-button-normal tblm-btn-primary" disabled={Boolean(!visitDate)}>
                {INITIAL_STEP_TEXTS.footer.next}
              </button>
            </Link>
          ) : null}
          {searchParams.get('type') === 'deposit' ? (
            <Link to={'/reservation/accept/step-2?type=deposit'}>
              <button className="footer tblm-button-normal tblm-btn-primary" disabled={Boolean(!depositInfo.depositDue)}>
                {INITIAL_STEP_TEXTS.footer.next}
              </button>
            </Link>
          ) : null}
          {searchParams.get('type').includes('callHistory') ? (
            <Link to={-1 as any}>
              <button className="footer tblm-button-normal tblm-btn-primary" disabled={Boolean(!startDate || !endDate)}>
                확인
              </button>
            </Link>
          ) : null}
        </div>
      </FooterSub>
    </>
  );
};

export default Step1;
