import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { datadogRum } from '@datadog/browser-rum';
import App from './App';


try {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_KEY,
    clientToken: process.env.REACT_APP_DATADOG_TOKEN,
    site: 'datadoghq.com',
    service: process.env.REACT_APP_DATADOG_SERVICE,

    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();
} catch (e) {
  console.log(e);
}


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);



