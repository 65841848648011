import React, { useEffect, useId, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Select from '@/components/Select/Select';
import { ControlledInput } from '@/hook-form/ControlledInput';
import { ControlledRadioBox } from '@/hook-form/ControlledRadioBox';
import { ISelectBoxOptions } from '@/hook-form/HookFormProps';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import { ControlledToggle } from '@/hook-form/ControlledToggle';

import './DepositSetting.scss';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import RefundDepositMenuModal from '@/components/RefundDepositMenuModal/RefundDepositMenuModal';
import { getBankLabel } from '@/shared/lib/functions';
import { depositEmailLanguageOptions } from './DepositSetting.constants';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useQuery } from 'react-query';
import { useAuthStore } from '@/stores/common/useAuthStore';

const DepositSetting = ({ handleMoveRoute }): React.ReactElement => {
  const { setMenuModal, setAlertModal } = useModalStore();
  const { mall } = useMallStore();
  const { accessToken } = useAuthStore();
  const { depositInfo, setDepositInfo, visitDate, visitTime, rooms, parties } = useReservationStore();
  const methods = useFormContext();
  const { control, getValues, watch, setValue } = methods;
  const scrollTargetRef = useRef(null);
  const [searchParams] = useSearchParams({ type: 'default' });
  const depositPaymentTypeOptions: ISelectBoxOptions[] = useMemo(() => mall.depositPaymentTypes.map((item) => {
    if (item === 'BANK') {
      return { label: '가상계좌', value: item };
    }
    if (item === 'CARD') {
      return { label: '카드결제', value: item };
    }
    return { label: item, value: item };
  }), [mall]);
  const depositBankTypeOptions: ISelectBoxOptions[] = useMemo(() => mall.depositPaymentBankTypes.map((item) => {
    return { label: getBankLabel(item) ?? item, value: item };
  }), [mall]);

  const { data: depositSettingData, isFetching: isFetching } = useQuery(['DEPOSIT_SETTING'], () => devServerApi.authGet<any>('/mall/deposit/settings', accessToken));

  const paymentTypeId = useId();
  const paymentBankTypeId = useId();
  const dontUseDepositSettingId = useId();
  const depositEmailLanguageId = useId();
  const depositLimitId = useId();
  const timeNotSettingId = useId();
  const today = dayjs();

  watch('useDeposit');

  useEffect(() => {
    if (searchParams.get('scrollTo') && searchParams.get('scrollTo') === 'deposit') {
      const scrollElement = document.querySelector('.main-container-desktop');
      scrollElement.scrollTo(0, scrollElement.scrollHeight);
    }
  }, [searchParams.get('scrollTo')]);

  useEffect(() => {
    // 예약금 설정 불가 예외처리
    if (depositInfo.useDeposit) {
      if (visitTime && today && visitDate) {
        const gap = visitDate.diff(today, 'day');
        if ((gap === 0 && today.get('h') >= visitTime.get('h')) || gap < 0) {
          setAlertModal({ visible: true, key: depositLimitId });
          setValue('useDeposit', false);
          setDepositInfo({ ...depositInfo, useDeposit: false });
          return;
        }
      } else {
        setAlertModal({ visible: true, key: timeNotSettingId });
        setValue('useDeposit', false);
        setDepositInfo({ ...depositInfo, useDeposit: false });
        return;
      }
    }
    // 예약금 기본 일시 세팅
    if (!depositInfo?.depositDue && depositInfo?.useDeposit) {
      let depositDue = dayjs();
      depositDue = depositDue.set('minute', 0);
      const gap = visitDate.diff(today, 'd');
      const nowHour = today.get('h');

      if (gap === 0) {
        if (visitTime.get('h') > 24) depositDue = depositDue.set('h', 23);
        else if (nowHour + 1 === visitTime.get('h')) depositDue = depositDue.set('h', visitTime.get('h'));
        else if (nowHour + 1 < visitTime.get('h')) depositDue = depositDue.set('h', visitTime.get('h') - 1);
      } else if (gap === 1) {
        depositDue = depositDue.add(1, 'd');
        if (nowHour >= visitTime.get('h')) depositDue = depositDue.set('h', visitTime.get('h') - 1);
        else depositDue = depositDue.set('h', nowHour);
      } else if (gap > 1) {
        depositDue = depositDue.add(1, 'd');
        depositDue = depositDue.set('h', nowHour);
      }
      setDepositInfo({ ...depositInfo, depositDue: depositDue, depositAmount: 0, depositPaymentType: 'CARD' });
      setValue('depositDue', depositDue);
      setValue('depositPaymentType', 'CARD');
    }
    if (depositSettingData?.deposit?.use) {
      const defaultType = depositSettingData.deposit.defaultType;
      const defaultFee = depositSettingData.deposit.defaultFee;
      let depositFee = 0;
      let totalPartySize = parties.reduce((acc, o) => {
        return acc + o.size;
      }, 0);
      switch (defaultType) {
        case 'RESERVATION':
          depositFee = defaultFee;
          break;
        case 'TABLE':
          depositFee = defaultFee * rooms.length;
          break;
        case 'PERSON':
          depositFee = defaultFee * totalPartySize;
          break;
      }
      setValue('depositAmount', depositFee);
    } else {
      setValue('depositAmount', 0);
    }

  }, [depositInfo?.useDeposit, visitDate, visitTime, depositSettingData]);

  return (
    <>
      <div className="detail-content deposit-setting-content" ref={scrollTargetRef}>
        <div className="flex-container justify-between items-center">
          <div className="font__body_sm_sb">예약금 설정</div>
          <ControlledToggle
            name="useDeposit"
            control={control}
            onChange={(e) => {
              if (!mall.supportDeposit) {
                setValue('useDeposit', false);
                return setAlertModal({ visible: true, key: dontUseDepositSettingId });
              }
              return setDepositInfo({ ...depositInfo, useDeposit: e.target.checked });
            }}
            defaultChecked={getValues('useDeposit')}
            checked={getValues('useDeposit')}
          />
        </div>
        <div className={`detail-body ${getValues('useDeposit') ? null : 'hide-deposit-content'}`}>
          <div>
            <div className="font__body_sm required">예약금액</div>
            <ControlledInput
              type="number"
              name="depositAmount"
              control={control}
              placeholder="예약금을 입력해주세요"
              disabled={!getValues('useDeposit')}
              onChange={(e) => {
                setDepositInfo({ ...depositInfo, depositAmount: e.target.value });
              }}
            />
          </div>
          <div>
            <div className="font__body_sm required">결제 수단</div>
            <Select
              onClick={() => {
                if (getValues('useDeposit')) setMenuModal({ visible: true, key: paymentTypeId });
              }}
              disabled={!getValues('useDeposit')}
              name="depositPaymentType"
              value={depositPaymentTypeOptions.find((options) => options.value === getValues('depositPaymentType'))?.label}
            />
            <ModalPortal>
              <Modal.Menu key={paymentTypeId} modal="menu" isDim={true} isAnimation={true}>
                <div className="user-info-menu-modal-wrapper">
                  <div className="title font__subtitle">결제 수단</div>
                  <ControlledRadioBox
                    name="depositPaymentType"
                    options={depositPaymentTypeOptions}
                    control={control}
                    defaultChecked={getValues('depositPaymentType')}
                    onChange={(e) => {
                      setDepositInfo({ ...depositInfo, depositPaymentType: e.target.value });
                      setMenuModal({ visible: false, key: paymentTypeId });
                    }}
                  />
                </div>
              </Modal.Menu>
            </ModalPortal>
          </div>
          {depositInfo.depositPaymentType === 'BANK' ? (
            <div>
              <div className="font__body_sm required">입금 은행</div>
              <Select
                onClick={() => {
                  if (getValues('useDeposit')) setMenuModal({ visible: true, key: paymentBankTypeId });
                }}
                disabled={!getValues('useDeposit')}
                name="depositPaymentBankType"
                value={depositBankTypeOptions.find((options) => options.value === getValues('depositPaymentBankType'))?.label}
              />
              <ModalPortal>
                <Modal.Menu key={paymentBankTypeId} modal="menu" isDim={true} isAnimation={true}>
                  <div className="user-info-menu-modal-wrapper">
                    <div className="title font__subtitle">결제 수단</div>
                    <ControlledRadioBox
                      name="depositPaymentBankType"
                      options={depositBankTypeOptions}
                      control={control}
                      defaultChecked={getValues('depositPaymentBankType')}
                      onChange={(e) => {
                        setDepositInfo({ ...depositInfo, depositPaymentBankType: e.target.value });
                        setMenuModal({ visible: false, key: paymentBankTypeId });
                      }}
                    />
                  </div>
                </Modal.Menu>
              </ModalPortal>
            </div>
          ) : null}

          <div>
            <div className="font__body_sm required">결제기한</div>
            <div className="flex-container justify-between items-center">
              <div>
                {depositInfo?.depositDue ? (
                  <>
                    <div className="font__subtitle1_sb">{depositInfo?.depositDue?.format('YYYY년 MM월 DD일')}</div>
                    <div className="font__subtitle1">{depositInfo?.depositDue?.format('HH:mm')}</div>
                  </>
                ) : (
                  <div className="font__subtitle1_sb">없음</div>
                )}
              </div>
              <button
                className="tblm-button-small"
                disabled={!getValues('useDeposit')}
                onClick={() => {
                  handleMoveRoute('/reservation/accept/step-1?type=deposit');
                }}
              >
                수정
              </button>
            </div>
          </div>
        </div>
        <ModalPortal>
          <Modal.Alert key={dontUseDepositSettingId} modal="alert" isDim={true} isAnimation={true}>
            <div className="title font__subtitle">예약금 기능은 계약 후 사용 가능합니다.</div>
            <div className="content">사용을 원하시는 경우 테이블매니저 고객센터(1544-2017)로 문의하여 주세요.</div>
            <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: dontUseDepositSettingId })}>
              확인
            </div>
          </Modal.Alert>
        </ModalPortal>
        <RefundDepositMenuModal modalKey={'refundModalId'} />
      </div>
      {/* <div className={`detail-content deposit-setting-content ${getValues('useDeposit') ? null : 'hide-deposit-content'}`}>
        <div className="flex-container justify-between items-center">
          <div className="font__body_sm_sb">
            메일발송 설정
          </div>
          <ControlledToggle
            name="useDepositEmail"
            control={control}
            onChange={(e) => {
              return setDepositInfo({ ...depositInfo, useDepositEmail: e.target.checked });
            }}
            defaultChecked={getValues('useDepositEmail')}
            checked={getValues('useDepositEmail')}
          />
        </div>
        <div className={`detail-body ${getValues('useDeposit') && getValues('useDepositEmail') ? null : 'hide-deposit-content'}`}>
          <div>
            <div className="font__body_sm required">발송언어</div>
            <Select
              onClick={() => {
                if (getValues('useDeposit')) setMenuModal({ visible: true, key: depositEmailLanguageId });
              }}
              disabled={!getValues('useDeposit')}
              name="depositEmailLanguage"
              value={depositEmailLanguageOptions.find((options) => options.value === getValues('depositEmailLanguage'))?.label}
            />
            <ModalPortal>
              <Modal.Menu key={depositEmailLanguageId} modal="menu" isDim={true} isAnimation={true}>
                <div className="user-info-menu-modal-wrapper">
                  <div className="title font__subtitle">발송언어</div>
                  <ControlledRadioBox
                    name="depositEmailLanguage"
                    options={depositEmailLanguageOptions}
                    control={control}
                    defaultChecked={getValues('depositEmailLanguage')}
                    onChange={(e) => {
                      setDepositInfo({ ...depositInfo, depositEmailLanguage: e.target.value });
                      setMenuModal({ visible: false, key: depositEmailLanguageId });
                    }}
                  />
                </div>
              </Modal.Menu>
            </ModalPortal>
          </div>
        </div>
      </div> */}
      <ModalPortal>
        <Modal.Alert key={depositLimitId} modal="alert" isDim={true} isAnimation={true}>
          <div className="content">
            예약금을 설정할 수 없는 날짜 또는 시간입니다.
            <br />
            확인 후 다시 클릭해주세요.
          </div>
          <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: depositLimitId })}>
            확인
          </div>
        </Modal.Alert>
        <Modal.Alert key={timeNotSettingId} modal="alert" isDim={true} isAnimation={true}>
          <div className="content">예약 날짜와 시간을 설정 후 예약금을 설정하실 수 있습니다.</div>
          <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: depositLimitId })}>
            확인
          </div>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default DepositSetting;
